var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "",
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _c("div", { staticClass: "all-h-print" }, [
        _vm.collectMoney.show
          ? _c(
              "div",
              { attrs: { id: "printorder" } },
              [
                _c("div", { staticClass: "detail-box" }, [
                  _c("div", { staticClass: "flex" }, [
                    _vm.merData.config && _vm.merData.config.mer_print_logo
                      ? _c("img", {
                          staticStyle: {
                            height: "70px",
                            "margin-right": "20px",
                          },
                          attrs: {
                            src: _vm.merData.config.mer_print_logo + "!120a",
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "detail-left" }, [
                      _c("div", { staticClass: "detail-left-title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.collectMoney.data.merConfig.company_name
                            ) +
                            " | " +
                            _vm._s(_vm.detail.title.china) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "detail-left-desc",
                          staticStyle: {
                            "font-size": "14px",
                            "font-family": "'printEN'",
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.collectMoney.data.merConfig
                                  .company_english_name
                              ) +
                              " " +
                              _vm._s(_vm.detail.title.english) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "qrcodeBox" }, [
                    _c("div", { staticClass: "detail-abso" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.switchLang("Buyer")) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "secTit" }, [
                  _vm._v(_vm._s(_vm.detail.buyerCompany.china)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-left-desc",
                    staticStyle: {
                      "font-size": "13px",
                      "font-family": "'printEN'",
                      "font-weight": "500",
                    },
                  },
                  [_vm._v(_vm._s(_vm.detail.buyerCompany.english))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-left-title",
                    staticStyle: { "margin-top": "40px" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.collectMoney.data.venueInfo.title) + " "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-list",
                    staticStyle: { "margin-top": "5px" },
                  },
                  [
                    _vm.collectMoney.data.userVerifyInfo
                      ? _c("div", { staticClass: "flex" }, [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.switchLang("BuyerName")) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(" BUYER'S NAME\n          "),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "500",
                                "font-size": "16px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.collectMoney.data.userVerifyInfo.real_name
                                ) +
                                  " " +
                                  _vm._s(
                                    _vm.collectMoney.data.userVerifyInfo
                                      .originPhone
                                  )
                              ),
                            ]
                          ),
                        ])
                      : _vm.collectMoney.data.userInfo
                      ? _c("div", { staticClass: "flex" }, [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.switchLang("BuyerName")) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(" BUYER'S NAME\n          "),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "500",
                                "font-size": "16px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.collectMoney.data.userInfo.real_name
                                ) +
                                  " " +
                                  _vm._s(
                                    _vm.collectMoney.data.userInfo.originPhone
                                  )
                              ),
                            ]
                          ),
                        ])
                      : _c("div", [
                          _vm._v(
                            _vm._s(_vm.switchLang("BuyerName")) +
                              " BUYER'S NAME"
                          ),
                        ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.switchLang("BuyerNumber")) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(" PADDLE NO.\n          "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-weight": "500",
                            "font-size": "16px",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.collectMoney.data.numberPlate
                                ? _vm.collectMoney.data.numberPlate.number
                                : ""
                            )
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.switchLang("BuyerTime")) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(" DATE OF SALE\n          "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-weight": "500",
                            "font-size": "16px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.collectMoney.data.order_time))]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm._l(
                  _vm.isNo ? [_vm.collectMoney.data.orderData] : _vm.result,
                  function (item, ind) {
                    return _c(
                      "div",
                      {
                        key: ind,
                        staticClass: "ptd-box all-print-main-el",
                        style:
                          ind <
                          (_vm.isNo
                            ? _vm.collectMoney.data.orderData.length
                            : _vm.result.length) -
                            1
                            ? "page-break-after: always;"
                            : "",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ptd",
                            staticStyle: { "font-weight": "bold" },
                          },
                          [
                            _vm._l(_vm.detail.tableColumn, function (m, i) {
                              return [
                                m.open && m.name == "金额"
                                  ? [
                                      _vm.fee_type !=
                                      _vm.feeTypeInfo.mer_fee_type
                                        ? _c(
                                            "div",
                                            {
                                              key: "col" + i + "_1",
                                              staticClass: "ptd-title",
                                              style: m.w
                                                ? "width: " + m.w + "px;"
                                                : "flex: 1",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.feeTypeInfo.name) +
                                                    _vm._s(
                                                      m.showName || m.name
                                                    ) +
                                                    "(" +
                                                    _vm._s(
                                                      _vm.fee_type.replace(
                                                        "CNY",
                                                        "RMB"
                                                      )
                                                    ) +
                                                    ")"
                                                ),
                                              ]),
                                              m.english
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-family":
                                                          "'printEN'",
                                                        "white-space": "nowrap",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.feeTypeInfo.type
                                                        ) +
                                                          " " +
                                                          _vm._s(m.english)
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          key: "col" + i,
                                          staticClass: "ptd-title",
                                          style: m.w
                                            ? "width: " + m.w + "px;"
                                            : "flex: 1",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(m.showName || m.name) +
                                                _vm._s(
                                                  _vm.fee_type !=
                                                    _vm.feeTypeInfo.mer_fee_type
                                                    ? "(" +
                                                        _vm.feeTypeInfo.mer_fee_type.replace(
                                                          "CNY",
                                                          "RMB"
                                                        ) +
                                                        ")"
                                                    : _vm.feeTypeInfo
                                                        .mer_fee_type != "CNY"
                                                    ? "(" +
                                                      _vm.feeTypeInfo
                                                        .mer_fee_type +
                                                      ")"
                                                    : ""
                                                )
                                            ),
                                          ]),
                                          m.english
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-family": "'printEN'",
                                                    "white-space": "nowrap",
                                                  },
                                                },
                                                [_vm._v(_vm._s(m.english))]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  : m.open
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          key: "col" + i,
                                          staticClass: "ptd-title",
                                          style: m.w
                                            ? "width: " + m.w + "px;"
                                            : "flex: 1",
                                        },
                                        [
                                          _c(
                                            "span",
                                            [
                                              _vm._v(
                                                _vm._s(m.showName || m.name)
                                              ),
                                              m.name != "图录号" &&
                                              m.name != "拍品名称"
                                                ? [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.fee_type !=
                                                          _vm.feeTypeInfo
                                                            .mer_fee_type
                                                          ? "(" +
                                                              _vm.fee_type.replace(
                                                                "CNY",
                                                                "RMB"
                                                              ) +
                                                              ")"
                                                          : _vm.fee_type !=
                                                            "CNY"
                                                          ? "(" +
                                                            _vm.fee_type +
                                                            ")"
                                                          : ""
                                                      )
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          m.english
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-family": "'printEN'",
                                                    "white-space": "nowrap",
                                                  },
                                                },
                                                [_vm._v(_vm._s(m.english))]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm._l(item, function (m, i) {
                          return _c(
                            "div",
                            { key: "order" + i, staticClass: "ptd calcul" },
                            [
                              _vm.detail.tableColumn[0] &&
                              _vm.detail.tableColumn[0].open
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      staticStyle: { flex: "1" },
                                    },
                                    [_vm._v(_vm._s(m.album_info.album_name))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.detail.tableColumn[1] &&
                              _vm.detail.tableColumn[1].open
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      staticStyle: { flex: "1" },
                                    },
                                    [_vm._v(_vm._s(m.auction_info.store_name))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.detail.tableColumn[2] &&
                              _vm.detail.tableColumn[2].open
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      style:
                                        "width: " +
                                        _vm.detail.tableColumn[2].w +
                                        "px;",
                                    },
                                    [_vm._v(_vm._s(m.auction_info.lot))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.detail.tableColumn[3] &&
                              _vm.detail.tableColumn[3].open
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      style:
                                        "width: " +
                                        _vm.detail.tableColumn[3].w +
                                        "px;",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatPriceNumber(
                                            m.auction_now_price,
                                            2
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.detail.tableColumn[4] &&
                              _vm.detail.tableColumn[4].open
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      style:
                                        "width: " +
                                        _vm.detail.tableColumn[4].w +
                                        "px;flex-wrap: wrap;",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatPriceNumber(
                                            m.current_commission_price,
                                            2
                                          )
                                        )
                                      ),
                                      m.current_commission_rate
                                        ? [
                                            _vm._v(" / "),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "white-space": "nowrap",
                                                  display: "inline-block",
                                                  width: "auto",
                                                  "line-height": "unset",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    m.current_commission_rate
                                                  ) + "%"
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.detail.tableColumn[5] &&
                              _vm.detail.tableColumn[5].open
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      style:
                                        "width: " +
                                        _vm.detail.tableColumn[5].w +
                                        "px;",
                                    },
                                    [
                                      m.total_postage && m.delivery_type == 1
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.switchLang("Freight")
                                              ) +
                                                "：" +
                                                _vm._s(
                                                  _vm.formatPriceNumber(
                                                    m.total_postage,
                                                    2
                                                  )
                                                )
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      m.admin_discount_price
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.switchLang("Discount")
                                              ) +
                                                "：-" +
                                                _vm._s(
                                                  _vm.formatPriceNumber(
                                                    m.admin_discount_price,
                                                    2
                                                  )
                                                )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.detail.tableColumn[6] &&
                              _vm.detail.tableColumn[6].open &&
                              _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      style:
                                        "width: " +
                                        _vm.detail.tableColumn[6].w +
                                        "px;",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatPriceNumber(
                                            m.fee_origin_total_price,
                                            2
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.detail.tableColumn[6] &&
                              _vm.detail.tableColumn[6].open
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      style:
                                        "width: " +
                                        _vm.detail.tableColumn[6].w +
                                        "px;",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatPriceNumber(
                                            m.current_total_price,
                                            2
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  }
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "ptd-box",
                    staticStyle: { "margin-top": "0", "border-top": "none" },
                  },
                  [
                    _c("div", { staticClass: "ptd calcul" }, [
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          staticStyle: {
                            flex: "1",
                            "padding-left": "17px",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "text-align": "left" } },
                            [_vm._v(_vm._s(_vm.switchLang("Total")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-family": "'printEN'",
                                "text-align": "left",
                              },
                            },
                            [_vm._v("TOTAL")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          style:
                            "width: " + _vm.detail.tableColumn[2].w + "px;",
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.collectMoney.orderLength) + "件"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          style:
                            "width: " + _vm.detail.tableColumn[3].w + "px;",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatPriceNumber(
                                _vm.collectMoney.data.total_auction_now_price,
                                2
                              )
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          style:
                            "width: " + _vm.detail.tableColumn[4].w + "px;",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatPriceNumber(
                                _vm.collectMoney.data
                                  .total_current_commission_price,
                                2
                              )
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          style:
                            "width: " + _vm.detail.tableColumn[5].w + "px;",
                        },
                        [
                          _vm.collectMoney.data.total_postage
                            ? _c("div", [
                                _vm._v(
                                  _vm._s(_vm.switchLang("Freight")) +
                                    "：" +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        _vm.collectMoney.data.total_postage,
                                        2
                                      )
                                    )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.collectMoney.data.total_admin_discount_price
                            ? _c("div", [
                                _vm._v(
                                  _vm._s(_vm.switchLang("Discount")) +
                                    "：-" +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        _vm.collectMoney.data
                                          .total_admin_discount_price,
                                        2
                                      )
                                    )
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                        ? _c("div", {
                            staticClass: "ptd-body",
                            style:
                              "width: " + _vm.detail.tableColumn[6].w + "px;",
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          style:
                            "width: " + _vm.detail.tableColumn[6].w + "px;",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatPriceNumber(
                                _vm.collectMoney.data.total_current_pay_price,
                                2
                              )
                            )
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ptd calcul" }, [
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          staticStyle: {
                            flex: "1",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "text-align": "left",
                                "padding-left": "14px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.switchLang("BuyerPending")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-family": "'printEN'",
                                "text-align": "left",
                                "padding-left": "14px",
                              },
                            },
                            [_vm._v("AMOUNT PENDING SETTLEMENT")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          style: _vm.getForeWidth + ";font-weight: bold;",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatPriceNumber(
                                _vm.collectMoney.data.total_current_pay_price,
                                2
                              )
                            )
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.feeTypeInfo.mer_fee_type == "CNY"
                      ? _c("div", { staticClass: "ptd calcul" }, [
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: {
                                flex: "1",
                                "flex-direction": "column",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "text-align": "left",
                                    "padding-left": "14px",
                                  },
                                },
                                [_vm._v("人民币（大写）")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-family": "'printEN'",
                                    "text-align": "left",
                                    "padding-left": "14px",
                                  },
                                },
                                [_vm._v("CAPITAL IN RMB")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              style: _vm.getForeWidth + ";font-weight: bold;",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.collectMoney.data
                                    .total_current_pay_price_rmb
                                )
                              ),
                            ]
                          ),
                        ])
                      : _c("div", { staticClass: "ptd calcul" }, [
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: {
                                flex: "1",
                                "flex-direction": "column",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "text-align": "left",
                                    "padding-left": "14px",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.feeTypeInfo.unit_name))]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-family": "'printEN'",
                                    "text-align": "left",
                                    "padding-left": "14px",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.feeTypeInfo.mer_fee_type) +
                                      " DOLLARS"
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              style: _vm.getForeWidth + ";font-weight: bold;",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.collectMoney.data
                                    .total_current_pay_price_rmb
                                )
                              ),
                            ]
                          ),
                        ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "print-bottom-dom" },
                  [
                    _vm.collectMoney.data.commissionConfig.after_commission
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "secTit",
                              staticStyle: { "margin-top": "30px" },
                            },
                            [
                              _vm._v(
                                "*" +
                                  _vm._s(_vm.switchLang("BuyerOneText")) +
                                  " "
                              ),
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.parseTime(
                                      _vm.collectMoney.data.commissionConfig
                                        .commission_time,
                                      "{y}年{m}月{d}日"
                                    )
                                  ) + "前"
                                ),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.switchLang("BuyerTwoText")) +
                                  " "
                              ),
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  _vm._s(_vm.switchLang("BuyerTheText")) +
                                    _vm._s(
                                      _vm.collectMoney.data.commissionConfig
                                        .commission
                                    ) +
                                    "%"
                                ),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.switchLang("BuyerFourText")) +
                                  ": "
                              ),
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data
                                        .total_current_pay_price,
                                      2
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "detail-left-desc",
                              staticStyle: {
                                "font-size": "13px",
                                "font-family": "'printEN'",
                                "font-weight": "500",
                              },
                            },
                            [
                              _vm._v(
                                "\n            If the buyer pays in full by the " +
                                  _vm._s(
                                    _vm.parseTime(
                                      _vm.collectMoney.data.commissionConfig
                                        .commission_time,
                                      "{y}-{m}-{d}"
                                    )
                                  ) +
                                  ", they can enjoy a " +
                                  _vm._s(
                                    _vm.collectMoney.data.commissionConfig
                                      .commission
                                  ) +
                                  "%, discount on the commission, bringing the total to $" +
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data
                                        .total_current_pay_price,
                                      2
                                    )
                                  ) +
                                  ".\n          "
                              ),
                            ]
                          ),
                        ]
                      : _c("div", { staticStyle: { "margin-top": "30px" } }),
                    _vm._v(" "),
                    _vm.detail.signColumnTab == "system"
                      ? _c(
                          "div",
                          {
                            staticClass: "detail-list",
                            staticStyle: {
                              "margin-top": "30px",
                              "flex-wrap": "wrap",
                            },
                          },
                          [
                            _vm._l(_vm.detail.signColumn, function (m, i) {
                              return [
                                m.open
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          key: i,
                                          style:
                                            "width: " +
                                            (m.w == "auto"
                                              ? "auto"
                                              : m.w + "%") +
                                            ";font-weight:500;margin-bottom: 10px;padding-right: 5px;white-space: nowrap;" +
                                            (m.name == "打印时间"
                                              ? "text-align: right;"
                                              : ""),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(m.showName || m.name) + ":"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          i == "accountName"
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml5" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.merData.config
                                                          .bank_name
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : i == "accountBank"
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml5" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.merData.config.bank
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : i == "accountNumber"
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml5" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.merData.config
                                                          .bank_number
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : i == "accountAddress"
                                            ? [
                                                _c(
                                                  "span",
                                                  { staticClass: "ml5" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.merData.config
                                                          .company_address
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            }),
                            _vm._v(" "),
                            _c("div", {
                              staticStyle: {
                                width: "100%",
                                "border-top": "2px solid #000",
                                "margin-top": "40px",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-bottom",
                                staticStyle: { width: "100%" },
                                attrs: { id: "ptd-bottom" },
                              },
                              [
                                _c("div", { staticClass: "ptd-bottom-left" }, [
                                  _c(
                                    "div",
                                    { staticClass: "ptd-bottom-left-list" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.switchLang("CompanyAddress")
                                          ) +
                                          "：" +
                                          _vm._s(
                                            _vm.merData.config.company_address
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "ptd-bottom-left-list" },
                                    [
                                      _vm._v(
                                        "\n                Company Address: " +
                                          _vm._s(
                                            _vm.merData.config
                                              .company_english_address || ""
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "ptd-bottom-left-list" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.switchLang("CompanyPhone")) +
                                          "/TEL： " +
                                          _vm._s(
                                            _vm.merData.config.contact_phone
                                          )
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "ptd-bottom-right" },
                                  [
                                    _vm._l(
                                      _vm.merData.config.mer_qrcode_data,
                                      function (item, index) {
                                        return [
                                          item.imgUrl
                                            ? _c(
                                                "div",
                                                {
                                                  key: "rcimg" + index,
                                                  staticClass:
                                                    "ptd-bottom-img-list",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      crossorigin: "anonymous",
                                                      src:
                                                        item.imgUrl + "!120a",
                                                      alt: "",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ptd-bottom-img-list-title",
                                                    },
                                                    [_vm._v(_vm._s(item.name))]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ],
                          2
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "detail-list",
                            staticStyle: {
                              "margin-top": "30px",
                              "flex-wrap": "wrap",
                            },
                          },
                          [
                            _c("div", {
                              staticStyle: { flex: "1", overflow: "hidden" },
                              domProps: {
                                innerHTML: _vm._s(_vm.detail.signColumnCustome),
                              },
                            }),
                          ]
                        ),
                  ],
                  2
                ),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintorder },
            },
            [_vm._v("打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }