"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
var _userdetail = _interopRequireDefault(require("@/views/user/list/userdetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'comre',
  components: {
    userdetail: _userdetail.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      roterPre: _settings.roterPre,
      tableFrom: {
        page: 1,
        limit: 20,
        title: ''
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      showdetail: false,
      row: '',
      uid: ''
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 详情
    onDetails: function onDetails(row) {
      this.row = row;
      this.uid = row.uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this = this;
      var data = (0, _objectSpread2.default)({
        export_type: 'activitySyntheticRecord'
      }, this.tableFrom);
      (0, _system.exportexcel)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this.$createElement;
        _this.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this.$msgbox.close();
                _this.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        _this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      var type = 'activitySyntheticRecord';
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(type);
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _marketing.synthesisRecordListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};