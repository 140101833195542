"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _auction = require("@/api/auction");
var _user = require("@/api/user");
var _addUser = _interopRequireDefault(require("@/components/addUser.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    AddUserCom: _addUser.default
  },
  data: function data() {
    return {
      venue_id: '',
      albumData: [],
      formState: {
        type: 1,
        entrustAuctionData: [{
          auction_id: '',
          entrust_price: '',
          auctionData: []
        }]
      },
      ruleValidate: {
        entrust_uid: [{
          required: true,
          message: "请输入客户",
          trigger: "blur"
        }],
        album_id: [{
          required: true,
          message: "请选择专场",
          trigger: "blur"
        }],
        auction_id: [{
          required: true,
          message: "请选择拍品",
          trigger: "blur"
        }],
        entrust_price: [{
          required: true,
          message: "请输入委托价格",
          trigger: "blur"
        }]
      },
      userList: [],
      userLotList: [],
      auctionData: [],
      loading: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.venue_id = this.$route.query.venue_id;
    (0, _auction.albumlist)({
      page: 1,
      limit: 9999,
      venue_id: this.venue_id
    }).then(function (response) {
      _this.albumData = response.data.list;
    });
    if (this.$route.query.id) {
      (0, _auction.entrustDetailApi)(this.$route.query.id).then(function (res) {
        console.log(res);
        _this.formState = {
          id: res.data.id,
          entrust_uid: res.data.entrust_uid,
          album_id: res.data.album_id,
          auction_id: res.data.auction_id,
          entrust_price: res.data.entrust_price,
          remark: res.data.remark
        };
        _this.searchUserAction(res.data.entrust_uid);
        _this.onAlbumChange('');
        _this.onLotAuction('');
      });
    }
  },
  methods: {
    createUser: function createUser() {
      this.$refs.userREf.adduser();
    },
    onAdd: function onAdd() {
      this.$set(this.formState.entrustAuctionData, this.formState.entrustAuctionData.length, {
        auction_id: '',
        entrust_price: '',
        auctionData: []
      });
    },
    onRemove: function onRemove(index) {
      this.formState.entrustAuctionData.splice(index, 1);
    },
    saveAction: function saveAction() {
      var _this2 = this;
      this.loading = true;
      this.$refs['formRef'].validate(function (valid) {
        var model = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this2.formState), {}, {
          venue_id: _this2.venue_id
        });
        if (_this2.formState.id) {
          (0, _auction.entrustUpdateApi)(_this2.formState.id, model).then(function (res) {
            _this2.loading = false;
            _this2.$message.success('操作成功');
            _this2.$router.back();
          });
        } else {
          (0, _auction.entrustCreateApi)(model).then(function (res) {
            _this2.loading = false;
            _this2.$message.success('操作成功');
            _this2.$router.back();
          });
        }
      });
    },
    searchUserAction: function searchUserAction(data) {
      var _this3 = this;
      var params = {
        keyword: data,
        page: 1,
        limit: 10
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this3.userList = res.data.list;
      });
    },
    searchAuctionAction: function searchAuctionAction(data) {
      this.onAlbumChange(data);
    },
    searchAuctionToo: function searchAuctionToo(val, row) {
      (0, _auction.auctionList)({
        page: 1,
        limit: 20,
        venue_id: this.venue_id,
        album_id: this.formState.album_id,
        lot: val
      }).then(function (response) {
        row.auctionData = response.data.list;
      });
    },
    onAuctionChange: function onAuctionChange(row) {
      var aucRow = row.auctionData.find(function (item) {
        return item.product_id == row.auction_id;
      });
      row.album_id = aucRow.album_id;
    },
    onAuctionChangeToo: function onAuctionChangeToo() {
      var _this4 = this;
      var aucRow = this.auctionData.find(function (item) {
        return item.product_id == _this4.formState.auction_id;
      });
      this.formState.album_id = aucRow.album_id;
    },
    onChangeAlbum: function onChangeAlbum() {
      this.onAlbumChange('');
    },
    onAlbumChange: function onAlbumChange(val) {
      var _this5 = this;
      (0, _auction.auctionList)({
        page: 1,
        limit: 20,
        venue_id: this.venue_id,
        keyword: val
      }).then(function (response) {
        _this5.auctionData = response.data.list;
      });
    },
    onLotAuction: function onLotAuction(val) {
      var _this6 = this;
      (0, _auction.numberPlateUserList)({
        page: 1,
        limit: 20,
        venue_id: this.venue_id,
        number: val
      }).then(function (res) {
        _this6.userLotList = res.data.list;
      });
    }
  }
};