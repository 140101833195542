"use strict";

var _interopRequireWildcard = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var echarts = _interopRequireWildcard(require("echarts/core"));
var _charts = require("echarts/charts");
var _renderers = require("echarts/renderers");
var _data_template = _interopRequireDefault(require("./modules/data_template"));
var _data_drawer_template = _interopRequireDefault(require("./modules/data_drawer_template.vue"));
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _index = require("@/utils/index");
var _auction = require("@/api/auction");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 注册必须的组件
echarts.use([_charts.PieChart, _renderers.CanvasRenderer]);
var _default = exports.default = {
  components: {
    dataTemplate: _data_template.default,
    dataDrawerTemplate: _data_drawer_template.default,
    userdetail: _userdetail.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      venue_id: '',
      venueStatistical: {},
      albumStatistical: [],
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      listLoading: false,
      list: [],
      total: 0,
      queryParams: {
        page: 1,
        limit: 10,
        export_type: 'special_user_deal'
      },
      echart1: {
        type: 'userNumData',
        result: {},
        chart: null,
        noData: false
      },
      echart2: {
        type: 'userNumData',
        result: {},
        chart: null,
        noData: false
      },
      echart3: {
        type: 'userNumData',
        result: {},
        chart: null,
        noData: false
      },
      echart4: {
        type: 'userNumData',
        result: {},
        chart: null,
        noData: false
      },
      echart5: {
        type: 'userNumData',
        result: {},
        chart: null,
        noData: false
      },
      echart6: {
        type: 'userNumData',
        result: {},
        chart: null,
        noData: false
      },
      clickList: [],
      bidNumList: [],
      priceList: [],
      ideaPriceRanking: [],
      ideaNumRanking: [],
      bidNumRanking: [],
      showdetail: null,
      uid: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.venue_id = this.$route.query.vid || '';
    this.queryParams.venue_id = this.$route.query.vid || '';
    (0, _auction.venueStatisticalInfoApi)({
      venue_id: this.venue_id
    }).then(function (res) {
      _this.venueStatistical = res.data.venueStatistical;
      _this.albumStatistical = res.data.albumStatistical;
    }).catch(function () {});
    var params = {
      page: 1,
      limit: 10,
      venue_id: this.venue_id
    };
    (0, _auction.auctionList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
      order: 'click_desc'
    })).then(function (res) {
      _this.clickList = res.data.list;
    });
    (0, _auction.auctionList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
      order: 'bid_num_desc'
    })).then(function (res) {
      _this.bidNumList = res.data.list;
    });
    (0, _auction.auctionList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
      order: 'price_desc'
    })).then(function (res) {
      _this.priceList = res.data.list;
    });
    (0, _auction.userIdeaPriceRankingApi)(params).then(function (res) {
      _this.ideaPriceRanking = res.data.list;
    });
    (0, _auction.userIdeaNumRankingApi)(params).then(function (res) {
      _this.ideaNumRanking = res.data.list;
    });
    (0, _auction.userBidNumRankingApi)(params).then(function (res) {
      _this.bidNumRanking = res.data.list;
    });
    (0, _auction.dealCategoryChartsDataApi)({
      venue_id: this.venue_id
    }).then(function (res) {
      _this.echart1.result = res.data;
      _this.setPieData('echart1');
    });
    (0, _auction.userAreaChartsDataApi)({
      venue_id: this.venue_id
    }).then(function (res) {
      _this.echart2.result = res.data;
      _this.setPieData('echart2');
    });
    (0, _auction.userSexChartsDataApi)({
      venue_id: this.venue_id
    }).then(function (res) {
      _this.echart3.result = res.data;
      _this.setPieData('echart3');
    });
    (0, _auction.aroundUserChartsDataApi)({
      venue_id: this.venue_id
    }).then(function (res) {
      _this.echart4.result = res.data;
      _this.setPieData('echart4');
    });
    (0, _auction.userSourceChartsDataApi)({
      venue_id: this.venue_id
    }).then(function (res) {
      _this.echart5.result = res.data;
      _this.setPieData('echart5');
    });
    (0, _auction.userNumberPlateChartsDataApi)({
      venue_id: this.venue_id
    }).then(function (res) {
      _this.echart6.result = res.data;
      _this.setPieData('echart6');
    });
    this.load();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.echart1.chart) {
      return;
    }
    this.echart1.chart.dispose();
    this.echart1.chart = null;
    if (!this.echart2.chart) {
      return;
    }
    this.echart2.chart.dispose();
    this.echart2.chart = null;
    if (!this.echart3.chart) {
      return;
    }
    this.echart3.chart.dispose();
    this.echart3.chart = null;
    if (!this.echart4.chart) {
      return;
    }
    this.echart4.chart.dispose();
    this.echart4.chart = null;
    if (!this.echart5.chart) {
      return;
    }
    this.echart5.chart.dispose();
    this.echart5.chart = null;
    if (!this.echart6.chart) {
      return;
    }
    this.echart6.chart.dispose();
    this.echart6.chart = null;
  },
  methods: {
    // 导出
    exportRecord: function exportRecord() {
      var _this2 = this;
      (0, _system.exportexcel)({
        export_type: 'venueTransactionData',
        venue_id: this.venue_id
      }).then(function (res) {
        var h = _this2.$createElement;
        _this2.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this2.$msgbox.close();
                _this2.getExportssFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 导出列表
    getExportssFileList: function getExportssFileList() {
      this.$refs.exportList.exportFileList('venueTransactionData');
    },
    exportTJ: function exportTJ() {
      var _this3 = this;
      (0, _auction.exportStatistical)({
        venue_id: this.venue_id
      }).then(function (res) {
        var h = _this3.$createElement;
        _this3.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this3.$msgbox.close();
                _this3.getExportTJ();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportTJ: function getExportTJ() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('venueStatistical');
    },
    changeTypeAction: function changeTypeAction(key) {
      this.loadPieAction(key);
    },
    load: function load() {
      var _this4 = this;
      this.listLoading = true;
      (0, _auction.specialUserDealListApi)(this.queryParams).then(function (response) {
        _this4.total = response.data.count;
        _this4.list = response.data.list;
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.load();
    },
    showMoreAction: function showMoreAction(type, title) {
      this.$refs.dataDrawerTemplateEL.init(type, title, 'venue_id', this.venue_id);
    },
    setPieData: function setPieData(key) {
      this[key].chart = echarts.init(this.$refs[key]);
      this.loadPieAction(key);
    },
    loadPieAction: function loadPieAction(key) {
      if (this[key].result[this[key].type].length > 0) {
        this[key].noData = false;
        this[key].chart.setOption(this.setPie(this[key].result[this[key].type], this[key].type), key);
      } else {
        this[key].noData = true;
        console.log('no data');
      }
    },
    setPie: function setPie(data, type) {
      return {
        series: [{
          color: ['#2479f2', '#84b8fa', '#5f9bf8', '#cde4fc', '#aad0fb', '#e5f4ff'],
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '60%'],
          avoidLabelOverlap: false,
          emphasis: {
            label: {
              show: true,
              fontSize: '14',
              fontWeight: 'bold'
            }
          },
          label: {
            show: true,
            color: '#000000',
            formatter: function formatter(data) {
              var val = data.name + ' ' + data.percent + '% ';
              if (type === 'userNumData') {
                val += (0, _index.formatPriceNumber)(data.value) + '人';
              } else if (type === 'dealNumData') {
                val += (0, _index.formatPriceNumber)(data.value) + '件';
              } else if (type === 'dealPriceData') {
                val += '￥' + (0, _index.formatPriceNumber)(data.value);
              }
              return val;
            }
          },
          data: data
        }]
      };
    },
    goAlbumDataAction: function goAlbumDataAction(id, name) {
      this.$router.push("/merchant/pageManagement/auction/album_data?id=".concat(id, "&name=").concat(name));
    },
    onDetails: function onDetails(uid) {
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = null;
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this5 = this;
      (0, _system.exportexcel)(this.queryParams).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this5.$createElement;
        _this5.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this5.$msgbox.close();
                _this5.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('special_user_deal');
    }
  }
};