"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _user = require("@/api/user.js");
var _index = require("@/utils/index");
var _order = require("@/api/order");
var _printJs = _interopRequireDefault(require("print-js"));
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ids: '',
      source: '',
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      },
      merData: {},
      printPhotos: 0
    };
  },
  computed: {
    feeTypeObj: function feeTypeObj() {
      return this.$store.getters.feeTypeInfo;
    }
  },
  mounted: function mounted() {
    var _this = this;
    console.log(this.feeTypeObj);
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this.merData = res.data;
    });
    (0, _system.getConfigClassKeys)('auction_rule_set').then(function (res) {
      console.log(res.data);
      _this.printPhotos = res.data.printPhotos;
    });
  },
  methods: {
    bindPrintorder: function bindPrintorder() {
      //console.log("是不是修改的这里啊");
      (0, _printJs.default)({
        printable: "printorder",
        type: "html",
        header: null,
        targetStyles: ["*"],
        scanStyles: true,
        maxWidth: 1000,
        font_size: "auto",
        style: "@page {margin:0 10mm}"
      });
    },
    loadData: function loadData(row) {
      var model = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, row), {}, {
        user_verify: JSON.parse(row.user_verify),
        user_address: JSON.parse(row.user_address),
        DxMoney: this.convertToChineseCapital(Number(row.frozen_price))
      });
      console.log(model);
      this.collectMoney = {
        data: model,
        show: true
      };
    },
    loadDataOne: function loadDataOne(row) {
      var model = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, row), {}, {
        user_verify: row.userVerify,
        user_address: row.userAddress,
        DxMoney: this.convertToChineseCapital(Number(row.frozen_price))
      });
      console.log(model);
      this.collectMoney = {
        data: model,
        show: true
      };
    },
    convertToChineseCapital: function convertToChineseCapital(n) {
      var fraction = ['角', '分'];
      var digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
      var unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']];
      var head = n < 0 ? '欠' : '';
      n = Math.abs(n);
      var s = '';
      for (var i = 0; i < fraction.length; i++) {
        s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
      }
      s = s || '整';
      n = Math.floor(n);
      for (var i = 0; i < unit[0].length && n > 0; i++) {
        var p = '';
        for (var j = 0; j < unit[1].length && n > 0; j++) {
          p = digit[n % 10] + unit[1][j] + p;
          n = Math.floor(n / 10);
        }
        s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
      }
      return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
    }
  }
};