"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auction = require("@/api/auction");
var _index = require("@/utils/index");
var _userdetail = _interopRequireDefault(require("../../views/user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      isProduct: false,
      albumVisitData: {
        show: false,
        uv: 0,
        num: 0,
        list: []
      },
      otherTotal: 0,
      query: {
        page: 1,
        limit: 10
      },
      uid: '',
      showdetail: false
    };
  },
  methods: {
    showAlbumVisitDataAction: function showAlbumVisitDataAction(id, uv, pv, a_id) {
      this.isProduct = !!a_id;
      this.albumVisitData.show = true;
      this.albumVisitData.uv = uv;
      this.albumVisitData.num = pv;
      this.albumVisitData.list = [];
      this.query = {
        page: 1,
        limit: 10,
        album_id: id
      };
      if (a_id) {
        this.query.auction_id = a_id;
      }
      this.loadAlbumVisitData();
    },
    loadAlbumVisitData: function loadAlbumVisitData() {
      var _this = this;
      (0, _auction.albumVisitListApi)(this.query).then(function (res) {
        _this.albumVisitData.list = res.data.list;
        _this.otherTotal = res.data.count;
      }).catch(function () {});
    },
    pageOtherChange: function pageOtherChange(page) {
      this.query.page = page;
      this.loadAlbumVisitData();
    },
    // 详情
    onDetails: function onDetails(uid) {
      if (uid === 8888) {
        return false;
      }
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    }
  }
};