var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("header-breadcrumb", {
        attrs: {
          name: _vm.id ? "编辑专家" : "添加专家",
          "has-back": true,
          "is-fixed": true,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "default-setting" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "setting-content" },
          [
            _c("el-input", {
              staticClass: "w200",
              attrs: { clearable: "clearable", placeholder: "请输入专家姓名" },
              model: {
                value: _vm.uname,
                callback: function ($$v) {
                  _vm.uname = $$v
                },
                expression: "uname",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "default-setting mt-10" }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "setting-content" },
          [
            _vm._l(_vm.uploadList, function (item, i) {
              return _c("div", { key: i, staticClass: "upload-list" }, [
                _c("img", { attrs: { src: item } }),
                _vm._v(" "),
                _c("div", { staticClass: "upload-list-cover" }, [
                  _c("i", {
                    staticClass: "el-icon-view",
                    attrs: { size: "20" },
                    on: {
                      click: function ($event) {
                        return _vm.handleView(item)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "el-icon-delete",
                    attrs: { size: "20" },
                    on: {
                      click: function ($event) {
                        return _vm.handleRemove(item)
                      },
                    },
                  }),
                ]),
              ])
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.uploadList.length,
                    expression: "!uploadList.length",
                  },
                ],
                staticClass: "border-h",
                staticStyle: {
                  width: "58px",
                  height: "58px",
                  "line-height": "58px",
                },
                on: {
                  click: function ($event) {
                    return _vm.onUpload(1)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-camera-solid",
                  attrs: { size: "20" },
                }),
              ]
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "default-setting mt-10" }, [
        _vm._m(2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "setting-content" },
          [
            _vm._l(_vm.uploadListNew, function (item, i) {
              return _c("div", { staticClass: "upload-list" }, [
                _c("img", { attrs: { src: item } }),
                _vm._v(" "),
                _c("div", { staticClass: "upload-list-cover" }, [
                  _c("i", {
                    staticClass: "el-icon-view",
                    attrs: { size: "20" },
                    on: {
                      click: function ($event) {
                        return _vm.handleView(item)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "el-icon-delete",
                    attrs: { size: "20" },
                    on: {
                      click: function ($event) {
                        return _vm.handleRemoveNew(item)
                      },
                    },
                  }),
                ]),
              ])
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.uploadListNew.length,
                    expression: "!uploadListNew.length",
                  },
                ],
                staticClass: "border-h",
                staticStyle: {
                  width: "58px",
                  height: "58px",
                  "line-height": "58px",
                },
                on: {
                  click: function ($event) {
                    return _vm.onUpload(2)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-camera-solid",
                  attrs: { size: "20" },
                }),
              ]
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "default-setting mt-10" }, [
        _vm._m(3),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "setting-content" },
          [
            _c(
              "el-select",
              {
                staticClass: "w200",
                attrs: {
                  filterable: "filterable",
                  remote: "remote",
                  "remote-method": _vm.searchPost,
                  loading: _vm.searchLoading,
                },
                model: {
                  value: _vm.uid,
                  callback: function ($$v) {
                    _vm.uid = $$v
                  },
                  expression: "uid",
                },
              },
              _vm._l(_vm.userList, function (item) {
                return _c(
                  "el-option",
                  {
                    key: item.uid,
                    attrs: {
                      value: item.uid,
                      label: item.real_name || item.nickname,
                    },
                  },
                  [
                    _c("div", { staticClass: "avatar flex align-items-c" }, [
                      _c("img", { attrs: { src: item.avatar, alt: "" } }),
                      _vm._v(" "),
                      _c("div", { staticClass: "user-r flex-one" }, [
                        _c("div", { staticClass: "line1" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.real_name || item.nickname) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(item.phone))]),
                      ]),
                    ]),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "default-setting mt-10" }, [
        _c("div", { staticClass: "setting-title" }, [_vm._v("手机号：")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "setting-content" },
          [
            _c("el-input", {
              staticClass: "w200",
              attrs: { clearable: "clearable", placeholder: "请输入电话" },
              model: {
                value: _vm.mobile,
                callback: function ($$v) {
                  _vm.mobile = $$v
                },
                expression: "mobile",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "default-setting mt-10" }, [
        _vm._m(4),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "setting-content" },
          [
            _c("el-input", {
              staticClass: "w200",
              attrs: { clearable: "clearable", placeholder: "请输入酬金" },
              model: {
                value: _vm.money,
                callback: function ($$v) {
                  _vm.money = $$v
                },
                expression: "money",
              },
            }),
            _c("span", { staticClass: "ml10 ft14" }, [_vm._v("元/次")]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "default-setting mt-10" }, [
        _vm._m(5),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "setting-content remove-control" },
          [
            _c("el-input", {
              staticClass: "w200",
              attrs: {
                type: "number",
                clearable: "clearable",
                placeholder: "专家提现比例",
              },
              model: {
                value: _vm.withdrawRatio,
                callback: function ($$v) {
                  _vm.withdrawRatio = $$v
                },
                expression: "withdrawRatio",
              },
            }),
            _c("span", { staticClass: "ml10 ft14" }, [
              _vm._v("%，如果默认为0，使用默认比例"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "default-setting mt-10" }, [
        _vm._m(6),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "setting-content" },
          [
            _c(
              "el-checkbox-group",
              {
                on: { change: _vm.onSelectCategory },
                model: {
                  value: _vm.category,
                  callback: function ($$v) {
                    _vm.category = $$v
                  },
                  expression: "category",
                },
              },
              _vm._l(_vm.categoryList, function (m) {
                return _c(
                  "el-checkbox",
                  { key: m.id, attrs: { value: "" + m.id, label: "" + m.id } },
                  [_vm._v(_vm._s(m.category_name))]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "default-setting mt-10" }, [
        _c("div", { staticClass: "setting-title" }, [_vm._v("展示状态：")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "setting-content" },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.is_show,
                  callback: function ($$v) {
                    _vm.is_show = $$v
                  },
                  expression: "is_show",
                },
              },
              [
                _c("el-radio", { attrs: { label: 0 } }, [_vm._v("隐藏")]),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "default-setting mt-10" }, [
        _c("div", { staticClass: "setting-title" }, [_vm._v("擅长分类说明：")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "setting-content" },
          [
            _c("el-input", {
              staticClass: "w500",
              attrs: {
                type: "textarea",
                rows: 6,
                clearable: "clearable",
                placeholder: "请输入擅长分类说明",
              },
              model: {
                value: _vm.cateintro,
                callback: function ($$v) {
                  _vm.cateintro = $$v
                },
                expression: "cateintro",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "default-setting",
          staticStyle: { "margin-top": "20px" },
        },
        [
          _c("div", { staticClass: "setting-title" }, [_vm._v("详情介绍：")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-content flex-one" },
            [
              _c("Tinymce", {
                ref: "editor",
                attrs: { height: 400 },
                model: {
                  value: _vm.intro,
                  callback: function ($$v) {
                    _vm.intro = $$v
                  },
                  expression: "intro",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "60px" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "formFixedBox" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v("确认提交")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "查看图片", visible: _vm.visible },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _vm.visible
            ? _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.imgName },
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-title" }, [
      _c("span", { staticClass: "p" }, [_vm._v("*")]),
      _c("span", [_vm._v("专家姓名：")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-title" }, [
      _c("span", { staticClass: "p" }, [_vm._v("*")]),
      _c("span", [_vm._v("用户头像：")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-title" }, [
      _c("span", [_vm._v("专家封面图：")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-title" }, [
      _c("span", { staticClass: "p" }, [_vm._v("*")]),
      _c("span", [_vm._v("绑定用户：")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-title" }, [
      _c("span", { staticClass: "p" }, [_vm._v("*")]),
      _c("span", [_vm._v("酬金：")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-title" }, [
      _c("span", [_vm._v("专家提现比例：")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-title" }, [
      _c("span", [_vm._v("擅长分类：")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }