"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _vuex = require("vuex");
var _auction = require("@/api/auction");
var _digitalHall = require("@/api/digitalHall");
var _open3d = require("@/api/open3d");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      venue_id: '',
      venue_type: 1,
      album_id: '',
      status: '',
      ruleForm: {
        image_show_style: 1,
        album_image: '',
        video: '',
        is_create_live: 1,
        // is_link_exhibition: 0,
        // exhibition_template_id: '',
        qrcode_url: '',
        is_open_video_live: 1,
        live_screen_direction: 1,
        is_watch_video_playback: 1,
        live_stream_type: 'LEB',
        album_name: '',
        album_name_zh_tw: '',
        album_name_en: '',
        end_type: 1,
        bond_type: 1,
        bond_price: undefined,
        bond_bid_ratio: undefined,
        commission: 10,
        after_commission: undefined,
        check_user_idCard: 1,
        is_show: 1,
        addtop: 0,
        is_show_turnovers: 1,
        lose_price_status: 0,
        is_show_bidRecord: 0,
        is_start_can_pay_bond: 0,
        is_pre_bid: 0,
        is_show_page_notice: 0,
        record_number: '',
        message: '',
        interval_time: 5,
        click_hammer_num: 10,
        pm_list_num: 2,
        important_document_url: '',
        share_img: '',
        page_notice_msg: '',
        user_exhibition_id: '',
        record_number_image: ''
      },
      rules: {
        album_name: {
          required: true,
          message: '请输入专场名称',
          trigger: 'blur'
        },
        exhibition_template_id: {
          required: true,
          message: '请选择空间模板',
          trigger: 'blur'
        },
        commission: {
          required: true,
          message: '请输入买方佣金',
          trigger: 'blur'
        }
      },
      startPlayTime: {
        year: undefined,
        month: undefined,
        date: undefined,
        hour: undefined,
        minute: undefined
      },
      previewStartTime: {
        year: undefined,
        month: undefined,
        date: undefined,
        hour: undefined,
        minute: undefined
      },
      startTime: {
        year: undefined,
        month: undefined,
        date: undefined,
        hour: undefined,
        minute: undefined
      },
      endTime: {
        year: undefined,
        month: undefined,
        date: undefined,
        hour: undefined,
        minute: undefined
      },
      commissionTime: {
        year: undefined,
        month: undefined,
        date: undefined,
        hour: undefined,
        minute: undefined
      },
      reset_time_1: 5,
      reset_time_2: 5,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20
      },
      showSetSpaceTemplate: false,
      etid: '',
      exhibitionList: [],
      hasOpen: false,
      forceBondType: false
    };
  },
  computed: (0, _objectSpread2.default)({
    merId: function merId() {
      return this.$store.getters.mer_id;
    }
  }, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    }
  })),
  watch: {
    venue_type: function venue_type(val) {
      console.log(val);
      if (val < 4) {
        this.ruleForm.is_create_live = 1;
        this.ruleForm.is_open_video_live = 1;
      }
      // if (val === 1 || val === 4) {
      //   this.ruleForm.is_link_exhibition = 0;
      // }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.venue_id = this.$route.query.vid || '';
    // meetingDetail(this.venue_id).then(res => {
    //   if (res.data && Number(res.data.bond_price)) {
    //     this.forceBondType = true;
    //     this.ruleForm.bond_type = 1;
    //   }
    // });
    this.venue_type = Number(this.$route.query.vtype || '1');
    this.album_id = this.$route.query.id || '';
    if (this.album_id) {
      this.loadAlbum();
    } else {
      this.createInit();
      (0, _auction.lastAlbumInfoApi)({
        album_type: this.venue_type
      }).then(function (res) {
        _this.setData(res.data);
      });
    }
    (0, _digitalHall.userExhibitionListApi)({
      page: 1,
      limit: 100
    }).then(function (res) {
      _this.exhibitionList = res.data.list;
    });
  },
  methods: {
    setData: function setData(data) {
      var d = {
        end_type: data.end_type,
        bond_type: data.bond_type,
        commission: data.commission_config_json ? data.commission_config_json.commission : undefined,
        after_commission: data.commission_config_json ? data.commission_config_json.after_commission : undefined,
        check_user_idCard: data.album_config.check_user_idCard,
        is_show: data.is_show,
        addtop: data.addtop,
        is_show_turnovers: data.album_config.is_show_turnovers,
        lose_price_status: data.album_config.lose_price_status || 0,
        is_show_bidRecord: data.album_config.is_show_bidRecord,
        is_start_can_pay_bond: data.album_config.is_start_can_pay_bond,
        is_pre_bid: data.album_config.is_pre_bid,
        click_hammer_num: data.album_config.click_hammer_num,
        interval_time: data.interval_time / 60
      };
      this.ruleForm = Object.assign({}, this.ruleForm, d);
      if (this.ruleForm.bond_type == 1) {
        this.ruleForm.bond_price = data.bond_price || undefined;
        this.ruleForm.bond_bid_ratio = undefined;
      }
      if (this.ruleForm.bond_type == 2) {
        this.ruleForm.bond_price = undefined;
        this.ruleForm.bond_bid_ratio = data.bond_bid_ratio || undefined;
      }
      if (this.ruleForm.bond_type == 3) {
        this.ruleForm.bond_price = data.bond_price || undefined;
        this.ruleForm.bond_bid_ratio = data.bond_bid_ratio || undefined;
      }
      if (data.reset_time) {
        var rTime = data.reset_time.split(',');
        if (data.album_type === 1 || data.album_type === 4) {
          this.reset_time_1 = Number(rTime[0]) / 60;
          this.reset_time_2 = Number(rTime[1]) / 60;
        } else {
          this.reset_time_1 = Number(rTime[0]);
          this.reset_time_2 = Number(rTime[1]);
        }
      }
      if (this.ruleForm.after_commission) {
        this.setTime('commissionTime', data.commission_config_json.commission_time * 1000);
      }
    },
    createInit: function createInit() {
      if (this.venue_type !== 1) {
        this.reset_time_1 = 120;
        this.reset_time_2 = 10;
      }
      if (this.venue_type !== 4) {
        var nowDate = new Date();
        var endDate = new Date(Date.parse(nowDate) + 86400000);
        this.setTime('startPlayTime', nowDate);
        this.setTime('previewStartTime', nowDate);
        this.setTime('startTime', nowDate);
        if (this.venue_type === 1) {
          this.setTime('endTime', endDate);
        }
      } else {
        this.ruleForm.is_create_live = 0;
        this.reset_time_1 = 5;
        this.reset_time_2 = 10;
        this.ruleForm.interval_time = 10;
      }
    },
    isAuth: function isAuth() {
      var is3dAuth = localStorage.getItem('is3dAuth');
      if (is3dAuth) {
        this.hasOpen = true;
        return false;
      } else {
        this.hasOpen = true;
        if (this.hasOpen) {
          localStorage.setItem('is3dAuth', '1');
        }
      }
      // isOpen3dApi().then(res => {
      //   this.hasOpen = !!res.data;
      //   if (this.hasOpen) {
      //     localStorage.setItem('is3dAuth', '1');
      //   }
      // });
    },
    setTemplate: function setTemplate() {
      if (!this.tableData.data.length) {
        this.pageChange(1);
      }
      this.etid = this.ruleForm.exhibition_template_id;
      this.showSetSpaceTemplate = true;
    },
    selectExhibition: function selectExhibition(item) {
      this.etid = item.exhibition_template_id;
    },
    changeTmpAction: function changeTmpAction() {
      this.ruleForm.exhibition_template_id = this.etid;
      this.showSetSpaceTemplate = false;
    },
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _digitalHall.getTempListApi)(this.tableFrom).then(function (res) {
        if (_this2.$route.query.exhibitionTemplateId) {
          var listIds = res.data.list.map(function (m) {
            return m.exhibition_template_id;
          });
          var index = listIds.indexOf(Number(_this2.$route.query.exhibitionTemplateId));
          if (index !== -1) {
            _this2.selectedItem = res.data.list[index];
            _this2.num = _this2.selectedItem.config.booths;
          }
        }
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
        _this2.loading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    delImgAction: function delImgAction() {},
    loadAlbum: function loadAlbum() {
      var _this3 = this;
      (0, _auction.albumDetail)(this.album_id).then(function (res) {
        var data = res.data;
        _this3.status = res.data.album_status;
        _this3.ruleForm = {
          album_message_title: data.album_config.album_message_title,
          image_show_style: data.album_config.image_show_style,
          album_image: data.album_image,
          record_number_image: data.album_config.record_number_image,
          video: data.video,
          is_create_live: Number(!!data.live_id),
          // is_link_exhibition: Number(!!data.exhibition_template_id),
          // exhibition_template_id: data.exhibition_template_id || '',
          live_screen_direction: data.live ? data.live.extend_data.live_screen_direction : 1,
          qrcode_url: data.live ? data.live.extend_data.qrcode_url : '',
          is_watch_video_playback: data.live ? data.live.extend_data.is_watch_video_playback : 1,
          is_open_video_live: data.live ? data.live.extend_data.is_open_video_live : 1,
          live_stream_type: data.live ? data.live.live_stream_type : 'LEB',
          live_stream_url: data.live ? data.live.extend_data.third_party_stream_url : '',
          album_name: data.album_name,
          album_name_zh_tw: data.album_name_language ? data.album_name_language.zh_tw : '',
          album_name_en: data.album_name_language ? data.album_name_language.en : '',
          end_type: data.end_type,
          bond_type: data.bond_type,
          commission: data.commission_config_json ? data.commission_config_json.commission : undefined,
          after_commission: data.commission_config_json ? data.commission_config_json.after_commission : undefined,
          check_user_idCard: data.album_config.check_user_idCard,
          is_show: data.is_show,
          addtop: data.addtop,
          is_show_turnovers: data.album_config.is_show_turnovers,
          lose_price_status: data.album_config.lose_price_status || 0,
          is_show_bidRecord: data.album_config.is_show_bidRecord,
          is_start_can_pay_bond: data.album_config.is_start_can_pay_bond,
          is_show_page_notice: data.album_config.is_show_page_notice,
          record_number: data.album_config.record_number,
          is_pre_bid: data.album_config.is_pre_bid,
          message: data.message,
          page_notice_msg: data.album_config.page_notice_msg || '',
          click_hammer_num: data.album_config.click_hammer_num,
          interval_time: data.interval_time / 60,
          important_document_url: data.album_config.important_document_url,
          share_img: data.share_img,
          user_exhibition_id: data.user_exhibition_id || ''
        };
        if (_this3.ruleForm.bond_type == 1) {
          _this3.ruleForm.bond_price = data.bond_price || undefined;
          _this3.ruleForm.bond_bid_ratio = undefined;
        }
        if (_this3.ruleForm.bond_type == 2) {
          _this3.ruleForm.bond_price = undefined;
          _this3.ruleForm.bond_bid_ratio = data.bond_bid_ratio || undefined;
        }
        if (_this3.ruleForm.bond_type == 3) {
          _this3.ruleForm.bond_price = data.bond_price || undefined;
          _this3.ruleForm.bond_bid_ratio = data.bond_bid_ratio || undefined;
        }
        _this3.$refs['editor'].setContent(data.message);
        if (_this3.$route.query.editnotice == 1) _this3.$refs['editor1'].setContent(data.album_config.page_notice_msg || '');
        if (data.reset_time) {
          var rTime = data.reset_time.split(',');
          if (data.album_type === 1 || data.album_type === 4) {
            _this3.reset_time_1 = Number(rTime[0]) / 60;
            _this3.reset_time_2 = Number(rTime[1]) / 60;
          } else {
            _this3.reset_time_1 = Number(rTime[0]);
            _this3.reset_time_2 = Number(rTime[1]);
          }
        }
        if (data.live) {
          _this3.setTime('startPlayTime', data.live.start_time * 1000);
        }
        if (data.scheduled_show_time) {
          _this3.setTime('previewStartTime', data.scheduled_show_time * 1000);
        }
        if (data.album_start_time) {
          _this3.setTime('startTime', data.album_start_time * 1000);
        }
        if (data.album_type === 1) {
          _this3.setTime('endTime', data.first_end_time * 1000);
        }
        if (_this3.ruleForm.after_commission) {
          _this3.setTime('commissionTime', data.commission_config_json.commission_time * 1000);
        }
      }).catch(function (e) {
        console.log(e);
        _this3.$router.back();
      });
    },
    changeImg: function changeImg(key) {
      var _this4 = this;
      this.$modalUpload(function (img) {
        if (key === 'qrcode_url') {
          _this4.ruleForm.qrcode_url = img[0];
        } else {
          _this4.ruleForm.album_image = img[0];
        }
      }, 1, '1', false, 2);
    },
    changeImg1: function changeImg1(key) {
      var _this5 = this;
      this.$modalUpload(function (img) {
        if (key) {
          _this5.ruleForm[key] = img[0];
        } else {
          _this5.ruleForm.important_document_url = img[0];
        }
      }, 1, '1', false, 2);
    },
    uploadVideo: function uploadVideo() {
      var _this6 = this;
      this.$modalUpload(function (img) {
        _this6.ruleForm.video = img[0];
      }, 1, '2', false, 2);
    },
    saveAction: function saveAction() {
      var _this7 = this;
      var data = JSON.parse(JSON.stringify(this.ruleForm));
      if (!data.album_image) {
        this.$message.error('请上传专场图片');
        return false;
      }
      if (!data.album_name) {
        this.$message.error('请输入专场名称');
        return false;
      }
      if (Number(this.venue_type) !== 4) {
        if (data.is_create_live) {
          // 直播开始时间处理
          var time = this.getTime('startPlayTime');
          if (typeof time === 'string') {
            this.$message.error("".concat(time, "\u5F00\u64AD\u65F6\u95F4"));
            return false;
          }
          data.live_stime = time / 1000;
          // data.live_stream_type = 'LEB';
        }
        // 预展时间处理
        var previewStartTime = this.getTime('previewStartTime');
        if (typeof previewStartTime === 'string') {
          this.$message.error("".concat(previewStartTime, "\u9884\u5C55\u5F00\u59CB\u65F6\u95F4"));
          return false;
        }
        data.scheduled_show_time = previewStartTime / 1000;

        // 开拍时间处理
        var startTime = this.getTime('startTime');
        if (typeof startTime === 'string') {
          this.$message.error("".concat(startTime, "\u4E13\u573A\u5F00\u62CD\u65F6\u95F4"));
          return false;
        }
        data.album_start_time = startTime / 1000;

        // 限时拍 有结拍时间
        if (this.venue_type === 1) {
          // 结拍时间处理
          var endTime = this.getTime('endTime');
          if (typeof endTime === 'string') {
            this.$message.error("".concat(endTime, "\u4E13\u573A\u7ED3\u62CD\u65F6\u95F4"));
            return false;
          }
          data.album_end_time = endTime / 1000;
          // if (data.album_start_time + 1800 >= data.album_end_time) {
          //   this.$message.error('专场必须在开始30分钟后结束');
          //   return false;
          // }
          if (data.album_end_time <= data.album_start_time) {
            this.$message.error('结拍时间不能小于开拍时间');
            return false;
          }
          if (data.album_end_time - data.album_start_time > 15552000) {
            this.$message.error('专场必须在开始后半年内结束');
            return false;
          }
        }
      } else {
        data.album_start_time = Date.parse(new Date()) / 1000;
        data.is_create_live = 0;
      }

      // if (Number(this.venue_type) === 1 || Number(this.venue_type) === 4) {
      //   data.is_link_exhibition = 0;
      //   data.exhibition_template_id = '';
      // }

      // if (data.is_link_exhibition && !data.exhibition_template_id) {
      //   this.$message.error('请选择空间模板');
      //   return false;
      // }

      // 固定保证金 设置保证金
      // if (data.bond_type === 1) {
      //   data.bond_price = data.bond_price || 0;
      // }
      data.bond_price = data.bond_price || 0;
      data.bond_bid_ratio = data.bond_bid_ratio || 0;
      if (Number(data.bond_price) && Number(data.bond_bid_ratio)) {
        data.bond_type = 3;
      } else if (Number(data.bond_bid_ratio)) {
        data.bond_type = 2;
      } else {
        data.bond_type = 1;
      }
      // 限时拍  重置时间设置
      if (this.venue_type === 1 || this.venue_type === 4) {
        data.reset_time = "".concat((this.reset_time_1 || 0) * 60, ",").concat((this.reset_time_2 || 0) * 60);
        data.interval_time = (data.interval_time || 0) * 60;
      } else if (this.venue_type === 2 || this.venue_type === 5) {
        data.reset_time = "".concat(this.reset_time_1 || 0, ",").concat(this.reset_time_2 || 0);
        data.interval_time = (data.interval_time || 0) * 60;
      } else if (this.venue_type === 3) {
        data.interval_time = 60;
      }
      // 限时拍  结拍类型为逐件结拍  分钟处理成秒

      // 如果设置到期后的佣金   必须设置 到期时间
      if (data.after_commission) {
        // 结拍时间处理
        var commissionTime = this.getTime('commissionTime');
        if (typeof commissionTime === 'string') {
          this.$message.error("".concat(commissionTime, "\u4F63\u91D1\u8FC7\u671F\u65F6\u95F4"));
          return false;
        }
        data.commission_time = commissionTime / 1000;
      } else {
        data.after_commission = '';
      }
      data.venue_id = this.venue_id;
      data.album_type = this.venue_type;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var action = _auction.createAlbum;
      if (this.album_id) {
        action = _auction.editAlbum;
      }
      // console.log(data);
      // return
      action(this.album_id, data).then(function () {
        loading.close();
        _this7.$message.success('操作成功');
        _this7.$router.back();
      }).catch(function (msg) {
        loading.close();
      });
    },
    getTime: function getTime(key, noHour) {
      if (!this[key].year || !this[key].month || !this[key].date) {
        return '请完整设置';
      }
      var d = new Date("".concat(this[key].year, "/").concat(this[key].month, "/").concat(this[key].date));
      if (!noHour) {
        d.setHours(this[key].hour || 0);
        d.setMinutes(this[key].minute || 0);
        d.setSeconds(0);
      } else {
        d.setHours(23);
        d.setMinutes(59);
        d.setSeconds(59);
      }
      return Date.parse(d);
    },
    setTime: function setTime(key, date, noHour) {
      var d = new Date(date);
      this[key].year = d.getFullYear();
      this[key].month = d.getMonth() + 1;
      this[key].date = d.getDate();
      if (!noHour) {
        this[key].hour = d.getHours();
        this[key].minute = d.getMinutes();
      }
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};