var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "鉴定管理", "is-fixed": true },
        scopedSlots: _vm._u([
          {
            key: "btn",
            fn: function () {
              return [
                _c(
                  "el-dropdown",
                  { attrs: { type: "primary", placement: "bottom" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex align-items-c",
                        staticStyle: { color: "#3480F4", cursor: "pointer" },
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "16px",
                            height: "16px",
                            "margin-right": "5px",
                          },
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/202464/image/1717490887632187719.png",
                            alt: "",
                          },
                        }),
                        _vm._v("\n          配置\n        "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push(
                                    "/merchant/authenticate/praisalSetting"
                                  )
                                },
                              },
                            },
                            [_vm._v("规则配置")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push(
                                    "/merchant/authenticate/praisalCategory"
                                  )
                                },
                              },
                            },
                            [_vm._v("分类设置")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo default-tabs ",
              attrs: {
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "default-active": _vm.activeMenu,
              },
              on: { select: _vm.onSelectTit },
            },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [
                _vm._v("鉴定列表"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "2" } }, [
                _vm._v("专家列表"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "6" } }, [
                _vm._v("投诉管理"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "3" } }, [
                _vm._v("数据报表"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "top-data flex" }, [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "t" }, [
                  _vm._v(
                    _vm._s(
                      _vm.formatPriceNumber(_vm.alldata.totalPayPrice || 0, 2)
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "b" }, [_vm._v("平台收款总额(元)")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "t" }, [
                  _vm._v(
                    _vm._s(
                      _vm.formatPriceNumber(
                        _vm.alldata.totalExpertPrice || 0,
                        2
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "b" }, [_vm._v("专家提现总额(元)")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "t" }, [
                  _vm._v(
                    _vm._s(
                      _vm.formatPriceNumber(
                        _vm.alldata.totalRefundAutoPrice || 0,
                        2
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "b" }, [_vm._v("超时自动退款(元)")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "t" }, [
                  _vm._v(
                    _vm._s(
                      _vm.formatPriceNumber(
                        _vm.alldata.totalRefundAdminPrice || 0,
                        2
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "b" }, [_vm._v("后台人工退款(元)")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "t" }, [
                  _vm._v(
                    _vm._s(
                      _vm.formatPriceNumber(
                        _vm.alldata.totalWaitAnswerOrder || 0,
                        2
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "b" }, [_vm._v("待完成订单(个)")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "line-data flex mt40" }, [
              _c(
                "div",
                {
                  staticClass: "flex-one",
                  staticStyle: { "max-width": "900px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "t flex align-items-c",
                      staticStyle: { "justify-content": "space-between" },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "18px" } }, [
                        _vm._v("鉴定订单"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          on: {
                            change: function (data) {
                              return _vm.load(data, "count")
                            },
                          },
                          model: {
                            value: _vm.orderDay,
                            callback: function ($$v) {
                              _vm.orderDay = $$v
                            },
                            expression: "orderDay",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: "today", label: "当日" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: "yesterday", label: "昨日" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: "lately7", label: "近7天" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: "lately30", label: "近30天" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: {
                              value: "lately_half_year",
                              label: "近半年",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: "lately_year", label: "近1年" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "c mt20",
                      staticStyle: { overflow: "hidden'" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "c-view",
                          staticStyle: { margin: "0 -20px" },
                        },
                        [
                          _c("div", {
                            staticStyle: { height: "400px", width: "100%" },
                            attrs: { id: "order" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex-one",
                  staticStyle: { "margin-left": "40px", "max-width": "900px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "t flex align-items-c",
                      staticStyle: { "justify-content": "space-between" },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "18px" } }, [
                        _vm._v("鉴定收益"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          on: {
                            change: function (data) {
                              return _vm.load(data, "price")
                            },
                          },
                          model: {
                            value: _vm.payDay,
                            callback: function ($$v) {
                              _vm.payDay = $$v
                            },
                            expression: "payDay",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: "today", label: "当日" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: "yesterday", label: "昨日" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: "lately7", label: "近7天" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: "lately30", label: "近30天" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: {
                              value: "lately_half_year",
                              label: "近半年",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: "lately_year", label: "近1年" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "c mt20",
                      staticStyle: { overflow: "hidden'" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "c-view",
                          staticStyle: { margin: "0 -20px" },
                        },
                        [
                          _c("div", {
                            staticStyle: { height: "400px", width: "100%" },
                            attrs: { id: "money" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "default-box" }, [
              _c(
                "div",
                {
                  staticClass: "default-setting",
                  staticStyle: { "margin-top": "20px" },
                },
                [
                  _c("div", { staticClass: "orderBox" }, [
                    _c("div", { staticClass: "orderLeft" }, [
                      _c("div", { staticClass: "orderTitle" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.status == 0,
                                expression: "status == 0",
                              },
                            ],
                          },
                          [_vm._v("鉴定订单报表")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.status == 1,
                                expression: "status == 1",
                              },
                            ],
                          },
                          [_vm._v("专家订单报表")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.status == 2,
                                expression: "status == 2",
                              },
                            ],
                          },
                          [_vm._v("偷学订单报表")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.status != 1,
                              expression: "status != 1",
                            },
                          ],
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "orderState",
                              class:
                                "" +
                                (_vm.queryParams.select_date == "day"
                                  ? "active"
                                  : ""),
                              on: {
                                click: function ($event) {
                                  return _vm.changeTableType("day")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                  按日\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "orderState",
                              class:
                                "" +
                                (_vm.queryParams.select_date == "month"
                                  ? "active"
                                  : ""),
                              on: {
                                click: function ($event) {
                                  return _vm.changeTableType("month")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                  按月\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "orderState",
                              class:
                                "" +
                                (_vm.queryParams.select_date == "year"
                                  ? "active"
                                  : ""),
                              on: {
                                click: function ($event) {
                                  return _vm.changeTableType("year")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                  按年\n                "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.status == 1,
                              expression: "status == 1",
                            },
                          ],
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "300px" },
                            attrs: {
                              type: "daterange",
                              format: "yyyy/MM/dd",
                              "value-format": "yyyy/MM/dd",
                              clearable: "",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            on: { change: _vm.selectPeopleOrder },
                            model: {
                              value: _vm.etime,
                              callback: function ($$v) {
                                _vm.etime = $$v
                              },
                              expression: "etime",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticClass: "w200 ml10",
                              attrs: {
                                filterable: "filterable",
                                clearable: "",
                                remote: "remote",
                                "remote-method": _vm.searchPost,
                                placeholder: "搜索专家",
                              },
                              on: { change: _vm.selectPeopleOrder },
                              model: {
                                value: _vm.suid,
                                callback: function ($$v) {
                                  _vm.suid = $$v
                                },
                                expression: "suid",
                              },
                            },
                            _vm._l(_vm.announcerList, function (item) {
                              return _c("el-option", {
                                key: item.uid,
                                attrs: {
                                  value: item.uid,
                                  label: item.nickname,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "orderRight flex" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "medium" },
                              on: { change: _vm.changeTableStatus },
                              model: {
                                value: _vm.status,
                                callback: function ($$v) {
                                  _vm.status = $$v
                                },
                                expression: "status",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _vm._v("鉴定"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("专家"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: 2 } }, [
                                _vm._v("偷学"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.status == 0 || _vm.status == 1,
                          expression: "status == 0 || status == 1",
                        },
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadLoading,
                          expression: "loadLoading",
                        },
                      ],
                      staticClass: "mt20",
                      staticStyle: { height: "400px" },
                      attrs: {
                        border: "",
                        data: _vm.resultData,
                        size: "small",
                      },
                    },
                    [
                      _c("template", { slot: "empty" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loadLoading,
                                expression: "!loadLoading",
                              },
                            ],
                            staticClass: "tableEmpty",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "tableDiv" }, [
                              _vm._v("暂无数据"),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: _vm.status === 0 ? "日期" : "专家" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm.status === 0
                                  ? _c("div", [_vm._v(_vm._s(row.table_times))])
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          row.expertUser
                                            ? row.expertUser.nickname
                                            : ""
                                        )
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "新增订单数" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.formatPriceNumber(row.total_count)
                                    ) +
                                    "\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "新增订单额" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        row.total_pay_price / 100,
                                        2
                                      )
                                    ) +
                                    "元\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "完成订单数" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        row.total_answer_count
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "完成订单额" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        row.total_answer_price / 100,
                                        2
                                      )
                                    ) +
                                    "元\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "退款订单数",
                          prop: "total_refund_order",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        row.total_refund_count
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "退款订单额",
                          prop: "total_refund_pay_money",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        row.total_refund_price / 100,
                                        2
                                      )
                                    ) +
                                    "元\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "待完成订单数",
                          prop: "total_wait_over_order",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        row.total_wait_answer_count
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "待完成订单额",
                          prop: "total_wait_over_pay_money",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        row.total_wait_answer_price / 100,
                                        2
                                      )
                                    ) +
                                    "元\n              "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.status == 2,
                          expression: "status == 2",
                        },
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadLoading,
                          expression: "loadLoading",
                        },
                      ],
                      staticClass: "mt20",
                      staticStyle: { height: "400px" },
                      attrs: {
                        border: "",
                        data: _vm.resultData,
                        size: "small",
                      },
                    },
                    [
                      _c("template", { slot: "empty" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loadLoading,
                                expression: "!loadLoading",
                              },
                            ],
                            staticClass: "tableEmpty",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "tableDiv" }, [
                              _vm._v("暂无数据"),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "偷学时间", prop: "table_times" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "偷学数量", prop: "total_count" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPriceNumber(row.total_count, 2)
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "偷学金额" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        row.total_pay_price / 100,
                                        2
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "paginaBox flex" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.status == 0,
                              expression: "status == 0",
                            },
                          ],
                          staticClass: "export-view",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "orderState flex-c-c" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.exportAction(
                                        "identificationV2OrderTable"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("导出报表")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "orderState ml10" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getExportFileList(
                                        "identificationV2OrderTable"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("导出记录\n                ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.status == 2,
                              expression: "status == 2",
                            },
                          ],
                          staticClass: "export-view",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "orderState flex-c-c" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.exportAction(
                                        "identificationV2StudyOrderTable"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("导出报表")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "orderState ml10" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getExportFileList(
                                        "identificationV2StudyOrderTable"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("导出记录\n                ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-pagination", {
                        staticStyle: { "text-align": "right" },
                        attrs: {
                          total: _vm.total,
                          background: "",
                          "page-size": _vm.queryParams.limit,
                          "show-sizer": "show-sizer",
                          "show-total": "show-total",
                          "show-elevator": "show-elevator",
                          "current-page": _vm.queryParams.page,
                        },
                        on: {
                          "size-change": _vm.changePageSize,
                          "current-change": _vm.changePage,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("file-list", { ref: "exportList" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }