var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "padding-top": "10px" } },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { size: "small", "label-width": "80px" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c mt5" },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "w300",
                          attrs: { placeholder: "权益名称", size: "small" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "keyword", $$v)
                            },
                            expression: "tableFrom.keyword",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml20",
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.addBenefitsAction },
                        },
                        [_vm._v("添加权益\n          ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "mt20",
              staticStyle: { width: "100%", color: "#000" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "权益图标", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticStyle: {
                            width: "37px",
                            height: "37px",
                            display: "block",
                          },
                          attrs: {
                            src: scope.row.right_image + "!120a",
                            alt: "",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "权益名称", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.right_name))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "权益介绍", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.right_type == 2
                          ? _c("div", [
                              _vm._v(
                                "赠送商品×" + _vm._s(scope.row.goodsCount)
                              ),
                            ])
                          : scope.row.right_type == 3
                          ? _c("div", [
                              _vm._v(
                                "赠送门票×" + _vm._s(scope.row.goodsCount)
                              ),
                            ])
                          : scope.row.right_type == 6
                          ? _c("div", [
                              _vm._v(
                                "赠送优惠券×" + _vm._s(scope.row.goodsCount)
                              ),
                            ])
                          : scope.row.right_type == 4
                          ? _c("div", [
                              _vm._v(
                                "赠送" +
                                  _vm._s(scope.row.right_give_data[0].number) +
                                  "积分"
                              ),
                            ])
                          : scope.row.right_type == 5
                          ? _c("div", [
                              _vm._v(
                                "赠送" +
                                  _vm._s(scope.row.right_give_data[0].number) +
                                  "余额"
                              ),
                            ])
                          : _c("div", [
                              _vm._v(_vm._s(scope.row.right_type_text)),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建时间", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.create_time))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editAction(scope.row.right_id)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delAction(scope.row.right_id)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                staticStyle: { "text-align": "right" },
                attrs: {
                  background: "",
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, prev, pager, next, sizes",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { editMark: _vm.getList, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }