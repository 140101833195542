var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: {
          name: _vm.$route.query.id ? "编辑委托" : "新增委托",
          hasBack: true,
          isFixed: true,
        },
      }),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "formRef",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            rules: _vm.ruleValidate,
            model: _vm.formState,
            "label-width": "130px",
            "label-position": "right",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择客户:", prop: "entrust_uid" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.formState.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formState, "type", $$v)
                    },
                    expression: "formState.type",
                  },
                },
                [
                  _c("el-option", { attrs: { value: 1, label: "按关键词" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 2, label: "按号牌" } }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formState.type == 1
                ? _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: {
                        placeholder: "搜索客户昵称",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchUserAction,
                      },
                      model: {
                        value: _vm.formState.entrust_uid,
                        callback: function ($$v) {
                          _vm.$set(_vm.formState, "entrust_uid", $$v)
                        },
                        expression: "formState.entrust_uid",
                      },
                    },
                    [
                      _vm._l(_vm.userList, function (m, i) {
                        return _c(
                          "el-option",
                          {
                            key: "user" + i,
                            attrs: { value: m.uid, label: m.nickname },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "avatar flex align-items-c" },
                              [
                                _c("img", {
                                  attrs: { src: m.avatar, alt: "" },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "user-r flex-one" }, [
                                  _c(
                                    "div",
                                    { staticClass: "line1" },
                                    [
                                      m.real_name
                                        ? [_vm._v(_vm._s(m.real_name) + " | ")]
                                        : _vm._e(),
                                      _vm._v(_vm._s(m.nickname)),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(m.phone))]),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("template", { slot: "empty" }, [
                        _c("div", { staticClass: "select-empty" }, [
                          _vm._v("没有此用户"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "select-button" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: { click: _vm.createUser },
                              },
                              [
                                _vm._v(
                                  "\n                确认创建\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formState.type == 2
                ? _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: {
                        placeholder: "搜索客户号牌",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.onLotAuction,
                      },
                      model: {
                        value: _vm.formState.entrust_uid,
                        callback: function ($$v) {
                          _vm.$set(_vm.formState, "entrust_uid", $$v)
                        },
                        expression: "formState.entrust_uid",
                      },
                    },
                    _vm._l(_vm.userLotList, function (m, i) {
                      return _c(
                        "el-option",
                        {
                          key: "user" + i,
                          attrs: { value: m.uid, label: m.user.nickname },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avatar flex align-items-c" },
                            [
                              _c("img", {
                                attrs: { src: m.user.avatar, alt: "" },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "user-r flex-one" }, [
                                _c(
                                  "div",
                                  { staticClass: "line1" },
                                  [
                                    m.user.real_name
                                      ? [
                                          _vm._v(
                                            _vm._s(m.user.real_name) + " | "
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm._v(_vm._s(m.user.nickname)),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("div", [_vm._v(_vm._s(m.number))]),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.formState.id
            ? _vm._l(_vm.formState.entrustAuctionData, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "flex" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "选择拍品:" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w300",
                            attrs: {
                              placeholder: "输入拍品LOT号",
                              filterable: "",
                              remote: "",
                              "remote-method": function (e) {
                                return _vm.searchAuctionToo(e, item)
                              },
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onAuctionChange(item)
                              },
                            },
                            model: {
                              value: item.auction_id,
                              callback: function ($$v) {
                                _vm.$set(item, "auction_id", $$v)
                              },
                              expression: "item.auction_id",
                            },
                          },
                          _vm._l(item.auctionData, function (m, i) {
                            return _c("el-option", {
                              key: "auction" + i,
                              attrs: {
                                value: m.product_id,
                                label: m.lot + "-" + m.product.store_name,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: "委托价格:" } }, [
                      _c(
                        "div",
                        { staticClass: "flex justify-b align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            staticStyle: { "margin-right": "20px" },
                            attrs: { placeholder: "请输入委托价格" },
                            model: {
                              value: item.entrust_price,
                              callback: function ($$v) {
                                _vm.$set(item, "entrust_price", $$v)
                              },
                              expression: "item.entrust_price",
                            },
                          }),
                          _vm._v(" "),
                          index == 0
                            ? _c("i", {
                                staticClass: "el-icon-circle-plus cur",
                                staticStyle: { "font-size": "24px" },
                                on: { click: _vm.onAdd },
                              })
                            : _c("i", {
                                staticClass: "el-icon-remove cur",
                                staticStyle: { "font-size": "24px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onRemove(index)
                                  },
                                },
                              }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.formState.album_id && _vm.formState.id
            ? _c(
                "el-form-item",
                { attrs: { label: "选择拍品:", prop: "auction_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: {
                        placeholder: "搜索拍品名称",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchAuctionAction,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onAuctionChangeToo()
                        },
                      },
                      model: {
                        value: _vm.formState.auction_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formState, "auction_id", $$v)
                        },
                        expression: "formState.auction_id",
                      },
                    },
                    _vm._l(_vm.auctionData, function (m, i) {
                      return _c("el-option", {
                        key: "auction" + i,
                        attrs: {
                          value: m.product_id,
                          label: m.lot + "-" + m.product.store_name,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.formState.id
            ? _c(
                "el-form-item",
                { attrs: { label: "委托价格:", prop: "entrust_price" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入委托价格" },
                    model: {
                      value: _vm.formState.entrust_price,
                      callback: function ($$v) {
                        _vm.$set(_vm.formState, "entrust_price", $$v)
                      },
                      expression: "formState.entrust_price",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注:", prop: "" } },
            [
              _c("el-input", {
                staticClass: "w300",
                attrs: {
                  type: "textarea",
                  rows: 5,
                  placeholder: "请输入备注内容",
                },
                model: {
                  value: _vm.formState.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.formState, "remark", $$v)
                  },
                  expression: "formState.remark",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "formFixedBox" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.saveAction },
            },
            [_vm._v("确认提交")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("AddUserCom", { ref: "userREf" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }