var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: "",
            visible: _vm.showview,
            direction: "rtl",
            "append-to-body": "",
            size: "1000px",
            "before-close": _vm.changeshowdetail,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showview = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "drawer-title" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantDetailUser",
                      expression: "'merchantDetailUser'",
                    },
                  ],
                  staticClass: "drawer-title-list",
                  class: { active: _vm.drawtype == "detail" },
                  on: {
                    click: function ($event) {
                      return _vm.changedrawtype("detail")
                    },
                  },
                },
                [_vm._v("客户详情")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantAuctionAlbumAuctionBondList",
                      expression: "'merchantAuctionAlbumAuctionBondList'",
                    },
                  ],
                  staticClass: "drawer-title-list",
                  class: { active: _vm.drawtype == "time" },
                  on: {
                    click: function ($event) {
                      return _vm.changedrawtype("time")
                    },
                  },
                },
                [_vm._v("参拍记录")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantUserEntrustProductData",
                      expression: "'merchantUserEntrustProductData'",
                    },
                  ],
                  staticClass: "drawer-title-list",
                  class: { active: _vm.drawtype == "log" },
                  on: {
                    click: function ($event) {
                      return _vm.changedrawtype("log")
                    },
                  },
                },
                [_vm._v("送拍记录")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantWalletUserWalletHistoryList",
                      expression: "'merchantWalletUserWalletHistoryList'",
                    },
                  ],
                  staticClass: "drawer-title-list",
                  class: { active: _vm.drawtype == "yue" },
                  on: {
                    click: function ($event) {
                      return _vm.changedrawtype("yue")
                    },
                  },
                },
                [_vm._v("余额变动")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "drawer-title-list",
                  class: { active: _vm.drawtype == "nft" },
                  on: {
                    click: function ($event) {
                      return _vm.changedrawtype("nft")
                    },
                  },
                },
                [_vm._v("数字藏品")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.drawtype == "detail"
            ? [
                _c("div", { staticClass: "drawer-title-bg" }),
                _vm._v(" "),
                _c("div", { staticClass: "detail-box" }, [
                  _c(
                    "div",
                    { staticClass: "detail-left" },
                    [
                      _c("el-avatar", {
                        attrs: {
                          size: 80,
                          src: _vm.userdetail.avatar
                            ? _vm.userdetail.avatar
                            : _vm.moren,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "detail-right" },
                    [
                      _vm.pressionListField["my_account.balance"] ||
                      _vm.pressionListField["data.pay_count"] ||
                      _vm.pressionListField["data.pay_price"]
                        ? _c("div", { staticClass: "detail-right-top" }, [
                            _vm.pressionListField["my_account.balance"]
                              ? _c("div", { staticClass: "detail-r-t-list" }, [
                                  _c(
                                    "div",
                                    { staticClass: "detail-r-t-price" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.userdetail.my_account
                                            ? _vm.userdetail.my_account.balance
                                            : 0
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "detail-r-t-name" },
                                    [_vm._v("钱包余额")]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.pressionListField["data.pay_count"]
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "detail-r-t-list",
                                    staticStyle: { cursor: "pointer" },
                                    on: { click: _vm.goOrderAction },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "detail-r-t-price" },
                                      [_vm._v(_vm._s(_vm.userdetail.pay_count))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "detail-r-t-name" },
                                      [_vm._v("总订单数")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.pressionListField["data.pay_price"]
                              ? _c("div", { staticClass: "detail-r-t-list" }, [
                                  _c(
                                    "div",
                                    { staticClass: "detail-r-t-price" },
                                    [_vm._v(_vm._s(_vm.userdetail.pay_price))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "detail-r-t-name" },
                                    [_vm._v("总消费额")]
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { staticClass: "detail-row", attrs: { gutter: 20 } },
                        [
                          _vm.pressionListField["data.userVerify"]
                            ? _c(
                                "el-col",
                                { attrs: { span: 8, offset: 0 } },
                                [
                                  _vm._v("真实姓名："),
                                  _vm.userdetail.userVerify
                                    ? _c("span", { staticClass: "color-334" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.userdetail.userVerify.real_name
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: "merchantEditUser",
                                          expression: "'merchantEditUser'",
                                        },
                                      ],
                                      staticStyle: { "font-size": "14px" },
                                      attrs: { type: "text" },
                                      on: { click: _vm.onUpdUser },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                            _vm._v("昵称：" + _vm._s(_vm.userdetail.nickname)),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                            _vm._v("性别："),
                            _vm.userdetail.sex == 1
                              ? _c("span", { staticClass: "color-334" }, [
                                  _vm._v("女"),
                                ])
                              : _vm._e(),
                            _vm.userdetail.sex == 0
                              ? _c("span", { staticClass: "color-334" }, [
                                  _vm._v("男"),
                                ])
                              : _vm._e(),
                            _vm.userdetail.sex == 2
                              ? _c("span", { staticClass: "color-334" }, [
                                  _vm._v("未知"),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { staticClass: "detail-row", attrs: { gutter: 20 } },
                        [
                          _vm.pressionListField["data.userVerify"]
                            ? _c(
                                "el-col",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                  attrs: { span: 8, offset: 0 },
                                },
                                [
                                  _vm._v("身份证号："),
                                  _vm.userdetail.userVerify &&
                                  _vm.userdetail.userVerify.idcard
                                    ? _c("span", { staticClass: "color-334" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.userdetail.userVerify.idcard
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.userdetail.userVerify &&
                                  _vm.userdetail.userVerify.idcard_back_photo &&
                                  _vm.isPhone
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showvimage(
                                                _vm.userdetail.userVerify
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("图片")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                            _vm._v("出生日期："),
                            _vm.userdetail.userVerify
                              ? _c("span", { staticClass: "color-334" }, [
                                  _vm._v(
                                    _vm._s(_vm.userdetail.userVerify.birth_date)
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _vm.pressionListField["data.phone"]
                            ? _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                                _vm._v("手机号："),
                                _c("span", { staticClass: "color-334" }, [
                                  _vm._v(_vm._s(_vm.userdetail.phone)),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { staticClass: "detail-row", attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                            _vm._v("用户备注：" + _vm._s(_vm.userdetail.mark)),
                            _c(
                              "span",
                              {
                                staticClass: "cur",
                                staticStyle: {
                                  color: "#69d",
                                  "margin-left": "15px",
                                },
                                on: { click: _vm.editMarkAction },
                              },
                              [_vm._v("修改")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { staticClass: "detail-row", attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                            _vm._v("最近访问："),
                            _c("span", { staticClass: "color-334" }, [
                              _vm._v(_vm._s(_vm.userdetail.last_time)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                            _vm._v("首次访问："),
                            _c("span", { staticClass: "color-334" }, [
                              _vm._v(_vm._s(_vm.userdetail.create_time)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.userdetail.companyInfo &&
                      _vm.userdetail.companyInfo.business_license
                        ? _c(
                            "el-row",
                            {
                              staticClass: "detail-row",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8, offset: 0 } },
                                [
                                  _vm._v("营业执照："),
                                  _c(
                                    "el-link",
                                    {
                                      staticStyle: {
                                        position: "relative",
                                        bottom: "2px",
                                      },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImgAction(
                                            _vm.userdetail.companyInfo
                                              .business_license,
                                            "营业执照"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("图片")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.userdetail.userNftAccount
                        ? _c(
                            "el-row",
                            {
                              staticClass: "detail-row",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c("el-col", { attrs: { span: 16, offset: 0 } }, [
                                _vm._v("区块链地址："),
                                _c("span", { staticClass: "color-334" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.userdetail.userNftAccount.account
                                    )
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pressionListField["data.userAddress"]
                        ? _c(
                            "el-row",
                            {
                              staticClass: "detail-row",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                                _vm._v("收件地址："),
                                _c(
                                  "span",
                                  {
                                    staticClass: "cur",
                                    staticStyle: { color: "#69d" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onAdd()
                                      },
                                    },
                                  },
                                  [_vm._v("新增")]
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.addressList, function (item, index) {
                        return _c(
                          "el-row",
                          {
                            key: index,
                            staticClass: "detail-row",
                            attrs: { gutter: 20 },
                          },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "color-334",
                                attrs: { span: 24, offset: 0 },
                              },
                              [
                                _vm._v(
                                  _vm._s(item.province) +
                                    " " +
                                    _vm._s(item.city) +
                                    " " +
                                    _vm._s(item.district) +
                                    " " +
                                    _vm._s(item.detail) +
                                    " " +
                                    _vm._s(item.real_name) +
                                    "  " +
                                    _vm._s(item.phone) +
                                    " "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "cur",
                                    staticStyle: { color: "#69d" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onAddupd(item)
                                      },
                                    },
                                  },
                                  [_vm._v("修改")]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { staticClass: "detail-row", attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 8, offset: 0 } }, [
                            _vm._v("客户分组："),
                            _vm.userdetail.group
                              ? _c("span", { staticClass: "color-334" }, [
                                  _vm._v(
                                    _vm._s(_vm.userdetail.group.group_name)
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { staticClass: "detail-row", attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 20, offset: 0 } }, [
                            _c(
                              "div",
                              { staticClass: "tag-box" },
                              [
                                _vm._v(
                                  "\n                兴趣标签：\n                "
                                ),
                                _vm._l(
                                  _vm.userdetail.label,
                                  function (item, index) {
                                    return _vm.userdetail.label
                                      ? _c(
                                          "div",
                                          { key: index, staticClass: "tag" },
                                          [_vm._v("#" + _vm._s(item))]
                                        )
                                      : _vm._e()
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          (_vm.drawtype == "time" || _vm.drawtype == "detail") &&
          _vm.pressionColumn["merchantAuctionAlbumAuctionBondList"]
            ? [
                _c("auctionrecord", {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantAuctionAlbumAuctionBondList",
                      expression: "'merchantAuctionAlbumAuctionBondList'",
                    },
                  ],
                  attrs: { uid: _vm.uid },
                  on: { goOrder: _vm.goOrderAction },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          (_vm.drawtype == "log" || _vm.drawtype == "detail") &&
          _vm.pressionColumn["merchantUserEntrustProductData"]
            ? [
                _c("upauctionrecord", {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantUserEntrustProductData",
                      expression: "'merchantUserEntrustProductData'",
                    },
                  ],
                  attrs: { uid: _vm.uid },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.drawtype == "yue" || _vm.drawtype == "detail"
            ? [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "merchantWalletUserWalletHistoryList",
                        expression: "'merchantWalletUserWalletHistoryList'",
                      },
                    ],
                  },
                  [
                    _c("div", { staticClass: "r-title" }, [_vm._v("余额变动")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { padding: "20px" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.listLoading,
                                expression: "listLoading",
                              },
                            ],
                            staticStyle: { width: "100%", color: "#000" },
                            attrs: {
                              data: _vm.tableData.data,
                              "highlight-current-row": "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "类型", width: "100" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", { staticClass: "ft14" }, [
                                          _vm._v(
                                            _vm._s(scope.row.category_text)
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1482697042
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "金额", "min-width": "50" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", { staticClass: "num" }, [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.direction == 1
                                                ? "+"
                                                : "-"
                                            ) +
                                              _vm._s(
                                                _vm.formatPriceNumber(
                                                  scope.row.amount,
                                                  2
                                                )
                                              )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                655701966
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "余额(不含冻结)",
                                "min-width": "70",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", { staticClass: "num" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatPriceNumber(
                                                scope.row.balance +
                                                  (scope.row.direction == 1
                                                    ? scope.row.amount
                                                    : -scope.row.amount),
                                                2
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1653237923
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "时间", "min-width": "100" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", [
                                          _vm._v(_vm._s(scope.row.create_time)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3407778047
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "支付方式", "min-width": "50" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.pay_type_text || ""
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3376199196
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "场景", width: "80" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", [
                                          _vm._v(_vm._s(scope.row.scene_type)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2898000616
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "用途", "min-width": "80" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(scope.row.related_type_text)
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                113133051
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "其他", "min-width": "80" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.category == "recharge"
                                          ? [
                                              scope.row.related_order
                                                .pay_type == "bank_transfer" &&
                                              scope.row.related_order
                                                .pay_evidence
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "color-lan",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showImgAction(
                                                              scope.row
                                                                .related_order
                                                                .pay_evidence
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("查看凭证")]
                                                    ),
                                                  ]
                                                : scope.row.related_order
                                                    .pay_type == "wechat_h5"
                                                ? [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row
                                                            .related_order
                                                            .out_trade_no
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                : _vm._e(),
                                            ]
                                          : scope.row.category == "extract"
                                          ? [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "right",
                                                    width: "400",
                                                    trigger: "hover",
                                                  },
                                                },
                                                [
                                                  scope.row.related_order &&
                                                  scope.row.related_order
                                                    .refund &&
                                                  scope.row.related_order.refund
                                                    .length
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "el-table",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                                color: "#000",
                                                              },
                                                              attrs: {
                                                                data: scope.row
                                                                  .related_order
                                                                  .refund,
                                                                "highlight-current-row":
                                                                  "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "退款",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function (
                                                                            params
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "num",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.formatPriceNumber(
                                                                                        params
                                                                                          .row
                                                                                          .amount,
                                                                                        2
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "退款时间",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function (
                                                                            params
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "num",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      params
                                                                                        .row
                                                                                        .create_time
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "color-lan",
                                                      attrs: {
                                                        slot: "reference",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [_vm._v("退款记录")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                571142879
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "block mt10" },
                          [
                            _c("el-pagination", {
                              attrs: {
                                "page-sizes": [6, 20, 40, 60, 80],
                                "page-size": _vm.tableFrom.limit,
                                "current-page": _vm.tableFrom.page,
                                layout:
                                  "total, sizes, prev, pager, next, jumper",
                                total: _vm.tableData.total,
                              },
                              on: {
                                "size-change": _vm.handleSizeChange,
                                "current-change": _vm.pageChange,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.drawtype == "nft" || _vm.drawtype == "detail"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "r-title",
                    staticStyle: { "margin-bottom": "0" },
                  },
                  [_vm._v("数字藏品")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { padding: "20px" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c mb20" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "ft14 fWeight500",
                            staticStyle: { "margin-left": "4px" },
                          },
                          [_vm._v("状态选择：")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio-group",
                          {
                            attrs: { type: "button", size: "small" },
                            on: {
                              change: function ($event) {
                                return _vm.getNft(1)
                              },
                            },
                            model: {
                              value: _vm.nftTableFrom.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.nftTableFrom, "status", $$v)
                              },
                              expression: "nftTableFrom.status",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "" } }, [
                              _vm._v("全部"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("上链成功"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: "2" } }, [
                              _vm._v("转赠中"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: "3" } }, [
                              _vm._v("已销毁"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: "4" } }, [
                              _vm._v("已回收"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.drawtype == "nft"
                      ? _c(
                          "div",
                          { staticStyle: { "margin-bottom": "20px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "primary" },
                                on: { click: _vm.exportOrder },
                              },
                              [_vm._v("导出")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: { click: _vm.getExportFileList },
                              },
                              [_vm._v("导出已生成列表")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.nftListLoading,
                            expression: "nftListLoading",
                          },
                        ],
                        staticStyle: { width: "100%", color: "#000" },
                        attrs: {
                          data: _vm.nftTableData.data,
                          "highlight-current-row": "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "作品信息" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "flex align-items-c" },
                                      [
                                        _c("div", { staticClass: "work-img" }, [
                                          _c("img", {
                                            staticStyle: {
                                              width: "40px",
                                              height: "40px",
                                              "border-radius": "4px",
                                            },
                                            attrs: {
                                              src: row.product.image + "!120a",
                                              alt: "",
                                            },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "user-info flex-one ml10",
                                            staticStyle: { overflow: "hidden" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "fWeight500" },
                                              [
                                                _vm._v(
                                                  _vm._s(row.product.store_name)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2752159601
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "编号" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "flex align-items-c" },
                                      [_vm._v(_vm._s(row.issue_number))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2130173743
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "获得时间" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(row.own_time || row.create_time)
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3130715653
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "状态" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(row.status_text) +
                                          " " +
                                          _vm._s(row.is_sell ? "寄售中" : "")
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2582201917
                          ),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "block mt10" },
                      [
                        _c("el-pagination", {
                          attrs: {
                            "page-sizes": [6, 20, 40, 60, 80],
                            "page-size": _vm.nftTableFrom.limit,
                            "current-page": _vm.nftTableFrom.page,
                            layout: "total, sizes, prev, pager, next, jumper",
                            total: _vm.nftTableData.total,
                          },
                          on: {
                            "size-change": _vm.nftHandleSizeChange,
                            "current-change": _vm.nftPageChange,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "z-index": "999" },
          attrs: {
            title: "",
            visible: _vm.showusercard,
            width: "800px",
            "append-to-body": true,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showusercard = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "600px", display: "block" },
            attrs: { src: _vm.userjson.idcard_just_photo, alt: "" },
          }),
          _vm._v(" "),
          _c("img", {
            staticStyle: { width: "600px", display: "block" },
            attrs: { src: _vm.userjson.idcard_back_photo, alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            "close-on-click-modal": false,
            "append-to-body": "",
            center: "",
            title: _vm.imgT,
            width: _vm.imgT == "营业执照" ? "800px" : "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.imgSrc + "!m640", alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.addressForm.isUpd ? "修改收货地址" : "新增收货地址",
            visible: _vm.addressVisible,
            top: "5vh",
            width: "450px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addressVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              attrs: { model: _vm.addressForm, "label-width": "90px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "收货姓名：" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请输入收货姓名" },
                    model: {
                      value: _vm.addressForm.real_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.addressForm, "real_name", $$v)
                      },
                      expression: "addressForm.real_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "收货手机号：" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请输入收货手机号" },
                    model: {
                      value: _vm.addressForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.addressForm, "phone", $$v)
                      },
                      expression: "addressForm.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "收货地址：" } }, [
                _vm.isNo
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.addressForm.province) +
                          " " +
                          _vm._s(_vm.addressForm.city) +
                          " " +
                          _vm._s(_vm.addressForm.district) +
                          " "
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "cur",
                          staticStyle: { color: "#69d" },
                          on: {
                            click: function ($event) {
                              _vm.isNo = !_vm.isNo
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                    ])
                  : _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w300",
                            attrs: {
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.editAreaAction(1)
                              },
                            },
                            model: {
                              value: _vm.areaIndex,
                              callback: function ($$v) {
                                _vm.areaIndex = $$v
                              },
                              expression: "areaIndex",
                            },
                          },
                          _vm._l(_vm.areaList, function (m, i) {
                            return _c("el-option", {
                              key: "area" + i,
                              attrs: { label: m.name, value: i },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticClass: "w300 mt20",
                            attrs: {
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.editAreaAction(2)
                              },
                            },
                            model: {
                              value: _vm.districtIndex,
                              callback: function ($$v) {
                                _vm.districtIndex = $$v
                              },
                              expression: "districtIndex",
                            },
                          },
                          _vm._l(_vm.districtList, function (m, i) {
                            return _c("el-option", {
                              key: "districtList" + i,
                              attrs: { label: m.name, value: i },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        !_vm.areaList.length ||
                        !_vm.areaList[_vm.areaIndex] ||
                        _vm.areaList[_vm.areaIndex].city_id !== "其他"
                          ? _c(
                              "el-select",
                              {
                                staticClass: "w300 mt20",
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  placeholder: "请选择",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.editAreaAction(3)
                                  },
                                },
                                model: {
                                  value: _vm.cityIndex,
                                  callback: function ($$v) {
                                    _vm.cityIndex = $$v
                                  },
                                  expression: "cityIndex",
                                },
                              },
                              _vm._l(_vm.cityList, function (m, i) {
                                return _c("el-option", {
                                  key: "cityList" + i,
                                  attrs: { label: m.name, value: i },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请输入详细地址" },
                    model: {
                      value: _vm.addressForm.detail,
                      callback: function ($$v) {
                        _vm.$set(_vm.addressForm, "detail", $$v)
                      },
                      expression: "addressForm.detail",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100px" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("AddUserCom", { ref: "userREf", on: { onRefresh: _vm.getRefresh } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }