"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var settingRouter = {
  path: "".concat(_settings.roterPre, "/setting"),
  name: 'system_group',
  meta: {
    icon: 'dashboard',
    title: '权限管理'
  },
  component: _layout.default,
  children: [{
    path: 'systemRule',
    name: 'setting_rule',
    meta: {
      title: '身份管理'
    },
    // 
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/index'));
      });
    },
    children: [{
      path: 'systemRole',
      name: 'allSetting',
      meta: {
        title: '权限设置'
      },
      redirect: "".concat(_settings.roterPre, "/setting/systemRule/systemRole/role"),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/all'));
        });
      },
      children: [{
        path: 'role',
        name: 'setting_role',
        meta: {
          title: '身份管理',
          activeMenu: "".concat(_settings.roterPre, "/setting/systemRule/systemRole"),
          permissionKey: '/setting/systemRule/systemRole/role'
        },
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemRole/index'));
          });
        }
      }, {
        path: 'systemAdmin',
        name: 'setting_systemAdmin',
        meta: {
          title: '员工管理',
          activeMenu: "".concat(_settings.roterPre, "/setting/systemRule/systemRole"),
          permissionKey: '/setting/systemRule/systemRole/systemAdmin'
        },
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAdmin/index'));
          });
        }
      }, {
        path: 'systemDepartment',
        name: 'setting_systemAdmin',
        meta: {
          title: '部门管理',
          activeMenu: "".concat(_settings.roterPre, "/setting/systemRule/systemRole"),
          permissionKey: '/setting/systemRule/systemRole/systemDepartment'
        },
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemDepartment/index'));
          });
        }
      }, {
        path: 'systemLog',
        name: 'setting_systemLog',
        meta: {
          title: '操作日志',
          activeMenu: "".concat(_settings.roterPre, "/setting/systemRule/systemRole")
        },
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemLog/index'));
          });
        }
      }]
    }, {
      path: 'appSetting',
      name: 'app_setting',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/page-renovation/appSetting'));
        });
      },
      meta: {
        title: 'AAPP版本PP设置',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/setting/systemRule/systemRole")
      }
    }, {
      path: 'list',
      name: 'setting_systemAgreementList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAgreement/list'));
        });
      },
      meta: {
        title: '协议配置',
        activeMenu: "".concat(_settings.roterPre, "/setting/systemRule/systemRole"),
        permissionKey: '/systemForm/systemAgreement/list'
      }
    }, {
      path: 'print',
      name: 'setting_systemprint',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAgreement/print'));
        });
      },
      meta: {
        title: '打印配置',
        activeMenu: "".concat(_settings.roterPre, "/setting/systemRule/systemRole")
      }
    }, {
      path: 'modifyStoreInfo',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/systemForm/setSystem/modifyStoreInfo'));
        });
      },
      name: 'ModifyStoreInfo',
      meta: {
        title: ' 商户基本信息',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/setting/systemRule/systemRole"),
        permissionKey: '/setting/systemRule/modifyStoreInfo'
      }
    }, {
      path: 'setting',
      name: 'csetting',
      meta: {
        title: '审批设置',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/setting/systemRule/systemRole"),
        permissionKey: '/basic/setting'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/basic/setting'));
        });
      }
    }, {
      path: 'service',
      name: 'Service',
      meta: {
        title: '客服管理',
        activeMenu: "".concat(_settings.roterPre, "/setting/systemRule/systemRole"),
        permissionKey: '/setting/systemRule/service'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/service/index'));
        });
      }
    }]
  }, {
    path: 'detail',
    name: 'setting_systemAgreementDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAgreement/detail'));
      });
    },
    meta: {
      title: '协议详情',
      activeMenu: "".concat(_settings.roterPre, "/setting/systemRule/systemRole")
    }
  }, {
    path: 'systemRule/printSet',
    name: 'setting_systemprintSet',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAgreement/print_set'));
      });
    },
    meta: {
      title: '打印配置',
      activeMenu: "".concat(_settings.roterPre, "/setting/systemRule/systemRole")
    }
  }]
};
var _default = exports.default = settingRouter;