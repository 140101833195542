var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "posterMain" },
    [
      _c("div", { staticClass: "flex posterHead" }, [
        _c(
          "div",
          { staticClass: "genePosterBox", on: { click: _vm.onOpenPoster } },
          [
            _c("div", { staticClass: "poster-tit" }, [_vm._v("生成海报")]),
            _vm._v(" "),
            _c("div", { staticClass: "poster-con" }, [
              _vm._v("海报AI生成 即刻获得"),
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "poster-img",
              attrs: { src: require("@/assets/images/wei.png"), alt: "" },
            }),
            _vm._v(" "),
            _vm._m(0),
          ]
        ),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
      ]),
      _vm._v(" "),
      _vm._l(_vm.posterList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticStyle: { "margin-bottom": "20px", "padding-top": "20px" },
          },
          [
            _c("div", { staticClass: "posterEndHead" }, [
              item.order_status != 3
                ? _c(
                    "div",
                    { staticClass: "poster-tag" },
                    [
                      item.order_status == 1
                        ? [
                            _vm._m(3, true),
                            _vm._v(" "),
                            _c("div", { staticClass: "poster-tag-status" }, [
                              _vm._v("生成中"),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      item.order_status == 2
                        ? [
                            _c("img", {
                              staticClass: "poster-daiimg",
                              attrs: {
                                src: require("@/assets/images/poster/dai.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "poster-tag-status" }, [
                              _vm._v("待确认"),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "poster-tag" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("posTypeFilter")(item.generate_demand.poster_type)
                  ) +
                    " × " +
                    _vm._s(item.generate_demand.generate_count)
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "poster-tag" }, [
                _vm._v(_vm._s(item.generate_demand.poster_size)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "poster-tag" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("posQuaFilter")(item.generate_demand.poster_quality)
                  )
                ),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(item.create_time))]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "posterEndBox" },
              [
                item.order_status == 1
                  ? _c("div", { staticClass: "posterEndItem" }, [
                      _vm._m(4, true),
                      _vm._v(" "),
                      _vm._m(5, true),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(item.orderData, function (ele, ind) {
                  return _c("div", { key: ind, staticClass: "posterEndItem" }, [
                    _c(
                      "div",
                      { staticClass: "poster-item-con" },
                      [
                        ele.generate_result_status == 2
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass: "position-img",
                                  staticStyle: { right: "50px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpd(ele)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/poster/upd.png"),
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "position-img",
                                  staticStyle: { right: "10px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSwitchResult(ele.id)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/poster/dui.png"),
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        ele.generate_result_status == 3
                          ? [
                              ele.is_like == 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "position-img",
                                      staticStyle: { right: "50px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onSwitchLink(ele.id, 1)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/poster/zan.png"),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "position-img",
                                      staticStyle: { right: "50px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onSwitchLink(ele.id, 0)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/poster/redlod.png"),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "position-img",
                                  staticStyle: { right: "10px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadImage(ele)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/poster/lod.png"),
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        ele.isGroup
                          ? _c(
                              "div",
                              {
                                staticClass: "position-img",
                                class: ele.isGroup ? "posY" : "",
                                staticStyle: { left: "10px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onCheckChange(
                                      ele,
                                      false,
                                      index,
                                      ind
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/poster/check.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "position-img",
                                staticStyle: { left: "10px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onCheckChange(
                                      ele,
                                      true,
                                      index,
                                      ind
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/poster/checknull.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                        _vm._v(" "),
                        _c("el-image", {
                          staticClass: "posIimg",
                          attrs: {
                            src: "" + ele.generate_result.imageUrl,
                            "preview-src-list": [ele.generate_result.imageUrl],
                          },
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "poster-item-title" }, [
                      _c("div", { staticClass: "poster-item-title-text" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "poster-item-status" }, [
                        ele.generate_result_status == 2
                          ? _c("div", { staticClass: "hb-conStatus" }, [
                              _vm._v("待确认"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        ele.generate_result_status == 3
                          ? _c("div", { staticClass: "hb3-conStatus" }, [
                              _vm._v("已确认"),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                }),
              ],
              2
            ),
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-b orderFootOne" },
        [
          _c(
            "div",
            { staticClass: "flex-one flex" },
            [
              _vm.selectAll
                ? _c(
                    "div",
                    {
                      staticClass: "position-sele-img",
                      on: {
                        click: function ($event) {
                          return _vm.onALlSelect(false)
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/poster/check.png"),
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "position-sele-img",
                      on: {
                        click: function ($event) {
                          return _vm.onALlSelect(true)
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/poster/checknull.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: {
                    background: "#6415D7",
                    border: "1px solid #6415D7",
                  },
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.onDownloadAll },
                },
                [_vm._v("批量下载")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [5, 10, 20],
              "page-size": _vm.limit,
              "current-page": _vm.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.total,
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("createPoster", {
        ref: "createPosterRef",
        on: { onRefresh: _vm.onRefresh },
      }),
      _vm._v(" "),
      _c("updatePoster", {
        ref: "updatePosterRef",
        on: { result: _vm.onSwitchResult },
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "iconImgBox" }, [
      _c("img", {
        staticClass: "posterImg",
        attrs: { src: require("@/assets/images/mbang.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "genItem meTemplate" }, [
      _c("div", { staticClass: "poster-tit", staticStyle: { color: "#fff" } }, [
        _vm._v("我的模板"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "poster-con", staticStyle: { color: "#fff" } }, [
        _vm._v("管理我的模板库"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "iconImgBox", staticStyle: { background: "#171818" } },
        [
          _c("img", {
            staticClass: "posterImg",
            attrs: { src: require("@/assets/images/mb.png"), alt: "" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "genItem meSquare" }, [
      _c("div", { staticClass: "poster-tit", staticStyle: { color: "#fff" } }, [
        _vm._v("模板广场"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "poster-con", staticStyle: { color: "#fff" } }, [
        _vm._v("海量模板任您挑选"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "iconImgBox", staticStyle: { background: "#171818" } },
        [
          _c("img", {
            staticClass: "posterImg",
            attrs: { src: require("@/assets/images/fax.png"), alt: "" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "poster-loading-box" }, [
      _c("img", {
        staticClass: "poster-loading-img animRotaOne",
        attrs: { src: require("@/assets/images/poster/ani1.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "poster-loading-img animRotaTwo",
        attrs: { src: require("@/assets/images/poster/ani2.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "poster-loading-img animRotaThe",
        attrs: { src: require("@/assets/images/poster/ani3.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "poster-item-con" }, [
      _c("div", { staticClass: "poster-item-dia" }, [
        _c("img", {
          staticClass: "poster-loading-img animRotaOne",
          attrs: { src: require("@/assets/images/poster/ani1.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "poster-loading-img animRotaTwo",
          attrs: { src: require("@/assets/images/poster/ani2.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "poster-loading-img animRotaThe",
          attrs: { src: require("@/assets/images/poster/ani3.png"), alt: "" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "poster-item-title" }, [
      _c("div", { staticClass: "poster-item-title-text" }),
      _vm._v(" "),
      _c("div", { staticClass: "poster-item-status" }, [
        _c("div", { staticClass: "hb-status" }, [_vm._v("生成中")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }