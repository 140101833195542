var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "创建调拨单", "has-back": "" },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "demo-promoterForm",
              attrs: {
                model: _vm.formValidate,
                rules: _vm.rules,
                "label-width": "100px",
                inline: "",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "转出库房", prop: "out_warehouse_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.id != "",
                        placeholder: "请选择库房",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.selectoutp },
                      model: {
                        value: _vm.formValidate.out_warehouse_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "out_warehouse_id", $$v)
                        },
                        expression: "formValidate.out_warehouse_id",
                      },
                    },
                    _vm._l(_vm.storeArr, function (item) {
                      return _c("el-option", {
                        key: item.warehouse_id,
                        attrs: { label: item.name, value: item.warehouse_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "转入库房", prop: "push_warehouse_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择库房",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formValidate.push_warehouse_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "push_warehouse_id", $$v)
                        },
                        expression: "formValidate.push_warehouse_id",
                      },
                    },
                    _vm._l(_vm.storeArr, function (item) {
                      return _c("el-option", {
                        key: item.warehouse_id,
                        attrs: { label: item.name, value: item.warehouse_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.reductjson.erp_v2_transfers_warehouse == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "审批人", prop: "reviewAduitId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selwidth",
                          attrs: {
                            placeholder: "请选择审批人",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.reviewAduitId,
                            callback: function ($$v) {
                              _vm.reviewAduitId = $$v
                            },
                            expression: "reviewAduitId",
                          },
                        },
                        _vm._l(_vm.reductjson.adminData, function (item) {
                          return _c("el-option", {
                            key: item.merchant_admin_id,
                            attrs: {
                              label: item.real_name,
                              value: item.merchant_admin_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "multipleSelection",
                  staticClass: "table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "tooltip-effect": "dark",
                    "row-key": function (row) {
                      return row.id
                    },
                    data: _vm.goodsArr,
                    size: "mini",
                    border: "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "货品图" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "demo-image__preview" },
                              [
                                scope.row.image
                                  ? _c("el-image", {
                                      staticStyle: {
                                        width: "20px",
                                        height: "20px",
                                      },
                                      attrs: {
                                        src: scope.row.image + "!120a",
                                        "preview-src-list": [scope.row.image],
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "编号", prop: "product_no" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "货品名称", prop: "product_name" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "标签" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.labelNames.join(",")) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "货架", prop: "shelf.shelf_no" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delGoods(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("\n              移除\n            ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [_vm._v("\n        确认创建\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "font-size": "20px", "font-weight": "500" } },
            [_vm._v("从库中选择")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
                width: "100%",
                border: "",
              },
            },
            [
              _c("ytx-table-column", {
                attrs: {
                  label: "编号",
                  width: "100",
                  prop: "",
                  "search-key": "start_product_no",
                  "search-other-key": "end_product_no",
                  "search-type": "inputPacker",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(_vm._s(row.product_no))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "货架",
                  prop: "",
                  "search-key": "keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.shelf
                          ? _c("span", [
                              _vm._v(_vm._s(scope.row.shelf.shelf_no)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "委托方",
                  width: "120",
                  prop: "",
                  "search-key": "entrust_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n        " +
                            _vm._s(scope.row.entrustUser.real_name) +
                            "(" +
                            _vm._s(scope.row.entrustUser.phone) +
                            ")\n      "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "货品名称",
                  width: "120",
                  prop: "",
                  "search-key": "product_name",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n        " +
                            _vm._s(scope.row.product_name) +
                            "\n      "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "合同编号",
                  "min-width": "140",
                  prop: "",
                  "search-key": "contract_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n        " +
                            _vm._s(scope.row.contract.contract_no) +
                            "\n      "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "业务员",
                  "min-width": "140",
                  prop: "",
                  "search-key": "salesman_admin_id",
                  "search-type": "radio",
                  options: _vm.userList,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.contract
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.contract.salesmanAdmin.real_name
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  prop: "",
                  label: "签约时间",
                  "min-width": "140",
                  "search-key": "date",
                  "search-type": "date",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n        " + _vm._s(row.create_time) + "\n      "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "操作",
                  width: "60",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "font-size": "14px" },
                            attrs: { type: "text", size: "small" },
                            on: { click: _vm.saleGoodsAll },
                          },
                          [_vm._v(" 全选 ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.saleGoods(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.goodsIdArr.indexOf(scope.row.id) > -1
                                        ? "已选择"
                                        : "选择"
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block paginaBox" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, prev, pager, next, sizes",
                  total: _vm.tableData.total,
                  background: "",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }