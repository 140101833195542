"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    entrustDisable: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      userTit: '',
      isNo: true,
      isSM: true,
      isPhone: false,
      createFlag: false,
      ruleValidate: {
        nickname: [{
          required: true,
          message: "请输入委托方名称",
          trigger: "blur"
        }],
        sex: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }],
        idcard_type: [{
          required: true,
          message: "请选择",
          trigger: "blur"
        }],
        real_name: [{
          required: true,
          message: "请输入真实姓名",
          trigger: "blur"
        }],
        idcard: [{
          required: true,
          message: "请输入证件号码",
          trigger: "blur"
        }],
        contact_address: [{
          required: true,
          message: "请输入通讯地址",
          trigger: "blur"
        }],
        account_bank: [{
          required: true,
          message: "请输入开户行",
          trigger: "blur"
        }],
        account_name: [{
          required: true,
          message: "请输入开户名称",
          trigger: "blur"
        }],
        account_bank_card_number: [{
          required: true,
          message: "请输入银行账号",
          trigger: "blur"
        }]
      },
      formValidate: {
        sex: 0
      },
      frontData: {},
      backData: {},
      companyOpen: false,
      is_entrust: false
    };
  },
  mounted: function mounted() {},
  methods: {
    switChange: function switChange() {
      if (!this.isSM) this.isNo = false;
    },
    onUpdRow: function onUpdRow(row, is_entrust) {
      var _this = this;
      (0, _user.getUserDetail)(row.uid).then(function (res) {
        // console.log(row);
        _this.is_entrust = !!is_entrust;
        _this.userTit = _this.is_entrust ? '编辑委托方' : '编辑用户';
        _this.isNo = row.is_entrust == 1 ? true : false;
        _this.isSM = res.data.userVerify ? true : false;
        _this.companyOpen = !!row.companyInfo;
        _this.formValidate = {
          uid: row.uid,
          avatar: row.avatar,
          sex: row.sex ? row.sex : 0,
          nickname: row.nickname,
          mark: row.mark,
          phone: res.data.phone,
          idcard_type: row.userVerify ? row.userVerify.idcard_type + '' : '1',
          real_name: row.userVerify ? row.userVerify.real_name : '',
          idcard: row.userVerify ? row.userVerify.idcard : '',
          idcard_just_photo: row.userVerify ? row.userVerify.idcard_just_photo : '',
          idcard_back_photo: row.userVerify ? row.userVerify.idcard_back_photo : '',
          entrust_contact_name: row.entrust ? row.entrust.entrust_contact_name : '',
          nationality: row.entrust ? row.entrust.nationality : '',
          contact_address: row.entrust ? row.entrust.contact_address : '',
          postal_code: row.entrust ? row.entrust.postal_code : '',
          weixin: row.entrust ? row.entrust.weixin : '',
          fixed_phone: row.entrust ? row.entrust.fixed_phone : '',
          fax: row.entrust ? row.entrust.fax : '',
          email: row.entrust ? row.entrust.email : '',
          account_bank: row.entrust ? row.entrust.account_bank : '',
          account_name: row.entrust ? row.entrust.account_name : '',
          account_bank_card_number: row.entrust ? row.entrust.account_bank_card_number : '',
          begin_amount: row.entrust ? row.entrust.begin_amount : '',
          end_amount: row.entrust ? row.entrust.end_amount : '',
          remark: row.entrust ? row.entrust.remark : '',
          rate: row.entrust ? row.entrust.rate : '',
          companyInfo: res.data.companyInfo || {
            business_license: ''
          }
        };
        _this.backData = {
          expiration_date: res.data.userVerify ? res.data.userVerify.expiration_date : '',
          push_police: res.data.userVerify ? res.data.userVerify.push_police : '',
          push_date: res.data.userVerify ? res.data.userVerify.push_date : ''
        };
        _this.frontData = {
          birth_date: res.data.userVerify ? res.data.userVerify.birth_date : '',
          nation: res.data.userVerify ? res.data.userVerify.nation : '',
          address: res.data.userVerify ? res.data.userVerify.address : '',
          sex: res.data.userVerify ? res.data.userVerify.se : ''
        };
        _this.$store.dispatch("user/getEnterInfo").then(function (res) {
          // console.log(res);
          _this.isPhone = res.merchantAdmin.level != 0 && res.merchantAdmin.is_visible_user_phone == 0;
          if (!_this.isPhone) {
            _this.$set(_this.ruleValidate, 'phone', [{
              required: true,
              message: "请输入手机号",
              trigger: "blur"
            }]);
          }
        });
        _this.createFlag = true;
      });
    },
    adduser: function adduser(is_entrust) {
      this.is_entrust = !!is_entrust;
      this.userTit = this.is_entrust ? '新增委托方' : '新增用户';
      this.isNo = !!is_entrust;
      this.isSM = true;
      this.companyOpen = !!is_entrust;
      this.formValidate = {
        avatar: '',
        sex: 0,
        nickname: '',
        mark: '',
        phone: '',
        idcard_type: '1',
        real_name: '',
        idcard: '',
        idcard_just_photo: '',
        idcard_back_photo: '',
        entrust_contact_name: '',
        nationality: '',
        contact_address: '',
        postal_code: '',
        weixin: '',
        fixed_phone: '',
        fax: '',
        email: '',
        account_bank: '',
        account_name: '',
        account_bank_card_number: '',
        begin_amount: '',
        end_amount: '',
        remark: '',
        rate: '',
        companyInfo: {
          business_license: ''
        }
      };
      this.isPhone = false;
      this.$set(this.ruleValidate, 'phone', [{
        required: true,
        message: "请输入手机号",
        trigger: "blur"
      }]);
      // this.userTit = this.userTit ? '新增委托方' : '新增用户';
      this.frontData = {};
      this.backData = {};
      this.createFlag = true;
    },
    handleSubmit: function handleSubmit(name) {
      var _this2 = this;
      var that = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          if (_this2.formValidate.sex == 2) {
            that.$message.error('请选择性别');
            return false;
          }
          var model = {
            nickname: _this2.formValidate.nickname,
            mark: _this2.formValidate.mark,
            phone: _this2.formValidate.phone,
            sex: _this2.formValidate.sex,
            avatar: _this2.formValidate.avatar
          };
          if (_this2.isSM) {
            model.verifyInfo = (0, _objectSpread2.default)((0, _objectSpread2.default)({
              idcard_type: _this2.formValidate.idcard_type,
              real_name: _this2.formValidate.real_name,
              idcard: _this2.formValidate.idcard,
              idcard_just_photo: _this2.formValidate.idcard_just_photo,
              idcard_back_photo: _this2.formValidate.idcard_back_photo
            }, _this2.frontData), _this2.backData);
          }
          if (_this2.isNo) {
            model.entrustInfo = {
              entrust_contact_name: _this2.formValidate.entrust_contact_name,
              nationality: _this2.formValidate.nationality,
              contact_address: _this2.formValidate.contact_address,
              postal_code: _this2.formValidate.postal_code,
              weixin: _this2.formValidate.weixin,
              fixed_phone: _this2.formValidate.fixed_phone,
              fax: _this2.formValidate.fax,
              email: _this2.formValidate.email,
              account_bank: _this2.formValidate.account_bank,
              account_name: _this2.formValidate.account_name,
              account_bank_card_number: _this2.formValidate.account_bank_card_number,
              begin_amount: _this2.formValidate.begin_amount,
              end_amount: _this2.formValidate.end_amount,
              remark: _this2.formValidate.remark,
              rate: _this2.formValidate.rate
            };
          }
          if (_this2.companyOpen && _this2.formValidate.companyInfo) {
            model.companyInfo = _this2.formValidate.companyInfo;
          }
          if (_this2.formValidate.uid) {
            (0, _user.updateUserApi)(_this2.formValidate.uid, model).then(function (res) {
              that.createFlag = false;
              that.$message.success('保存成功');
              _this2.$emit('onRefresh');
            });
          } else {
            (0, _user.createUserApi)(model).then(function (res) {
              that.createFlag = false;
              that.$message.success('保存成功');
              // that.$refs.userChild.$options.methods.getList.call(this.$refs.userChild);
            });
          }
        }
      });
    },
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this3 = this;
      var that = this;
      this.$modalUpload(function (img) {
        console.log(img);
        if (tit == '3') {
          that.formValidate.avatar = img[0];
        } else if (tit == '4') {
          that.formValidate.companyInfo = {
            business_license: img[0]
          };
        } else if (tit == '1' || tit == '2') {
          if (_this3.formValidate.idcard_type == 1) {
            (0, _user.checkUserIdCardImage)({
              idCardImage: img[0],
              idCardSide: tit == '1' ? 'front' : 'back'
            }).then(function (res) {
              if (tit == '1') {
                _this3.formValidate.real_name = res.data.name;
                _this3.formValidate.idcard = res.data.idCard_num;
                _this3.formValidate.sex = res.data.sex == '男' ? 0 : 1;
                _this3.frontData = {
                  birth_date: res.data.birth_date,
                  sex: res.data.sex,
                  nation: res.data.nation,
                  address: res.data.address
                };
                that.formValidate.idcard_just_photo = res.data.card_url;
              } else {
                _this3.backData = {
                  expiration_date: res.data.ExpirationDate,
                  push_police: res.data.PushPolice,
                  push_date: res.data.PushDate
                };
                that.formValidate.idcard_back_photo = res.data.card_url;
              }
              that.$forceUpdate();
            }).catch(function () {});
          } else {
            if (tit == '1') that.formValidate.idcard_just_photo = img[0];
            if (tit == '2') that.formValidate.idcard_back_photo = img[0];
            that.$forceUpdate();
          }
        }
      }, 1);
    },
    editIdCardType: function editIdCardType() {
      this.formValidate.idcard_just_photo = '';
      this.formValidate.idcard_back_photo = '';
      // this.formValidate.real_name = '';
      this.formValidate.idcard = '';
      this.frontData = {};
      this.backData = {};
    }
  }
};