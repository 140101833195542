"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _llpay = _interopRequireDefault(require("./llpay.js"));
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _accounts = require("@/api/accounts");
var _lianlianpay = require("@/api/lianlianpay");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    userdetail: _userdetail.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      status: '1',
      keywords: '',
      loadStatus: '1',
      showdetail: null,
      uid: '',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20,
        pay_type: '',
        date: '',
        keywords: ''
      },
      timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      numberData: {},
      openUserInfo: false,
      userStatus: '1',
      userData: {
        tableData: {
          data: [],
          total: 0
        },
        listLoading: false,
        tableFrom: {
          page: 1,
          limit: 20,
          uid: ''
        }
      },
      lianlianMerchantInfo: {
        acctinfo_list: [],
        bank_account: ''
      },
      lianlianaccount: '',
      accountMoney: '',
      remark: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    // tipsNumber().then(res => {
    //   this.numberData = res.data;
    // });
    // this.getList(1);
    (0, _lianlianpay.getLianLianMerchantInfo)().then(function (res) {
      _this.lianlianMerchantInfo = res.data;
      _this.lianlianaccount = res.data.bank_account;
    });
    (0, _llpay.default)().then(function (res) {
      console.log("加载完成");
      (0, _lianlianpay.getPasswordRandomFactor)().then(function (res) {
        console.log(res);
        _this.getrandomKey(res.data);
      });
    });
  },
  methods: {
    goreset: function goreset() {
      this.$router.push({
        path: '/merchant/accounts/llAccount/resetpassword'
      });
    },
    kong: function kong() {},
    getrandomKey: function getrandomKey(data) {
      // 初始化密码控件
      function initPassword() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        // eslint-disable-next-line
        var passowrd = new showKeyBord({
          png: "//mpay-static.oss-cn-hangzhou.aliyuncs.com/html/lib/state-secret/img",
          // 图片路径，用这个就行了，也可以下载到本地
          click_objId: "#keyboardBtn2",
          // 触发显示键盘的Dom节点Id
          regExp1: "[\\S\\s]",
          // 输入过程限制的正则，不匹配正则的字符不让输入
          regExp2: "^[\\S\\s]{6,12}$",
          // 输入完成后的正则，与getValid()对应，判断输入值是否匹配该正则
          Input_texId: "#LOGPASS2",
          // 接收内容的Dom节点Id
          parent_objId: "#ipt_pwd2",
          // 将键盘插入到何处的Dom节点Id
          placeholderId: "#LOGPASS2_SHOW",
          // span标签的id，该span用于模拟Input_texId的placeholder
          len: "12",
          // 表示最大允许输入多少个字符[可以不传递]
          keyBordLeft: "",
          keyBordTop: "",
          chaosMode: 0,
          // 混乱模式,0:无混乱;1:打开时乱一次;2:每输入一个字符乱一次,默认值0
          clickStatus: 1,
          // 按键状态,0:鼠标划过、悬浮时按键无变化;1:鼠标划过、悬浮时按键的颜色变化,默认值0
          titlefont: "安全支付输入",
          backfont: "退 格",
          capsfont: "切换大/小写",
          surefont: "确定",
          isen: false,
          callBack: function callBack() {},
          // 密码校验成功回调
          errorCallBack: function errorCallBack() {},
          // 密码校验失败回调
          rsaPublicKey: options.rsaPublicKey || "",
          // RSA加密必须参数，国密加密可不要
          sm2KeyHex: options.sm2KeyHex || "",
          // 国密加密必须参数，RSA加密可不要
          license: options.license || "" // 必须参数
        });
        passowrd.initOptions();
        console.log("init");
        return passowrd;
      }
      // 随机因子，必须包含sm2KeyHex、license、randomKey，这里只是示例，正常使用应该由服务端调起连连随机因子申请接口获取
      var options = {
        license: data.license,
        sm2KeyHex: data.sm2_key_hex,
        randomKey: data.random_key,
        rsaPublicKey: data.rsa_public_content
      };
      this.randomKey = data.random_key;
      this.randomValue = data.random_value;
      this.passwordInstance = initPassword(options);
      $("#get-password").click(function () {
        // 获取国密密文
        passwordInstance.setRandKey(options.randomKey); // 先设置随机key
        var password = passwordInstance.getOutputSM(); // 国密加密密码
        var rsaPassword = passwordInstance.getOutput(); // RSA加密密码
        // $('#password').text(rsaPassword);
        console.log("国密密码是", password);
        console.log("RSA密码是", rsaPassword);
        // // 清空密码框
        // passwordInstance.clearPWD()

        // 其他API请参考文档
      });
    },
    withdraw: function withdraw() {
      var _this2 = this;
      if (!this.accountMoney) {
        this.$message.error("请输入提现金额");
        return;
      }
      if (this.accountMoney < 10) {
        this.$message.error("提现金额不能小于10元");
        return;
      }
      // if(this.accountMoney > 50000) {
      //   this.$message.error("提现金额不能大于50000");
      //   return;
      // }
      if (!this.remark) {
        this.$message.error("请输入提现备注");
        return;
      }
      this.passwordInstance.setRandKey(this.randomValue); // 先设置随机key
      (0, _lianlianpay.getWithdrawApply)({
        extract_amount: this.accountMoney,
        random_key: this.randomKey,
        password: this.passwordInstance.getOutputSM(),
        remark: this.remark
      }).then(function (res) {
        if (res.data.ret_code === '8888') {
          var that = _this2;
          _this2.$prompt("", "输入验证码", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            inputPattern: /\S/,
            inputPlaceholder: "请输入验证码",
            inputErrorMessage: "请输入验证码",
            inputValue: ''
          }).then(function (_ref) {
            var value = _ref.value;
            (0, _lianlianpay.getWithdrawSmsVerify)({
              'order_no': res.data.txn_seqno,
              'verify_code': value,
              'total_amount': res.data.total_amount,
              'token': res.data.token
            }).then(function (req) {
              if (req.data.ret_code === '0000') {
                _this2.$message.success("提现申请成功");
              } else {
                _this2.$message.error(req.data.ret_msg);
              }
            });
          }).catch(function () {
            _this2.$message({
              type: "info",
              message: "取消输入"
            });
          });
        } else {
          _this2.$message.error(res.data.ret_msg);
        }
      });
    },
    goStatusAction: function goStatusAction(val) {
      this.status = "".concat(val);
      if (this.status === '2') {
        this.tableFrom.frozen_status = '1';
      } else if (this.status === '3') {
        this.tableFrom.extract_status = '1';
      } else if (this.status === '4') {
        this.tableFrom.pay_status = '1';
      }
      this.tableFrom.keywords = '';
      this.tableData.total = 0;
      this.tableData.data = [];
      this.tableFrom.page = 1;
      this.loadStatus = this.status;
      this.getList();
    },
    onDetails: function onDetails(uid) {
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = null;
    },
    selectStatus: function selectStatus() {
      this.getList(1);
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.date = tab;
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      var action = _accounts.userWalletList;
      switch (this.loadStatus) {
        case '2':
          action = _accounts.userWalletFrozenList;
          break;
        case '3':
          action = _accounts.userExtractList;
          break;
        case '4':
          action = _accounts.userRechargeList;
          break;
        default:
          action = _accounts.userWalletList;
          break;
      }
      action(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.data.list;
        _this3.tableData.total = res.data.count;
        _this3.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this3.listLoading = false;
      });
    },
    changeStatusAction: function changeStatusAction() {
      if (this.loadStatus !== this.status) {
        if (this.status === '2') {
          this.tableFrom.frozen_status = '';
        } else if (this.status === '3') {
          this.tableFrom.extract_status = '';
        } else if (this.status === '4') {
          this.tableFrom.pay_status = '';
        }
        this.tableData.total = 0;
        this.tableFrom.keywords = '';
        this.tableData.data = [];
        this.tableFrom.page = 1;
        this.loadStatus = this.status;
        this.getList();
      }
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    openUserAction: function openUserAction(data) {
      this.userStatus = data.status;
      this.userData = {
        tableData: {
          data: [],
          total: 0
        },
        listLoading: false,
        tableFrom: {
          page: 1,
          limit: 20,
          uid: data.uid
        }
      };
      this.openUserInfo = true;
      this.getUserList(data.status);
    },
    loadAll: function loadAll() {
      this.getList();
      this.getUserList(this.userStatus);
    },
    changeUserStatusAction: function changeUserStatusAction(data) {
      this.userData.tableData = {
        data: [],
        total: 0
      };
      this.getUserList(this.userStatus, 1);
    },
    getUserList: function getUserList(status, num) {
      var _this4 = this;
      this.userData.listLoading = true;
      this.userData.tableFrom.page = num || this.userData.tableFrom.page;
      var action = _accounts.userWalletHistoryList;
      switch (status) {
        case '2':
          action = _accounts.userWalletFrozenList;
          break;
        case '3':
          action = _accounts.userRechargeList;
          break;
        case '4':
          action = _accounts.userExtractList;
          break;
        case '5':
          this.userData.tableFrom.type = 'pay';
          action = _accounts.userWalletHistoryList;
          break;
        default:
          this.userData.tableFrom.type = '';
          action = _accounts.userWalletHistoryList;
          break;
      }
      action(this.userData.tableFrom).then(function (res) {
        _this4.userData.tableData.data = res.data.list;
        _this4.userData.tableData.total = res.data.count;
        _this4.userData.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.userData.listLoading = false;
      });
    },
    pageChangeTwo: function pageChangeTwo(page) {
      this.userData.tableFrom.page = page;
      this.getUserList(this.userStatus);
    },
    handleSizeChangeTwo: function handleSizeChangeTwo(val) {
      this.userData.tableFrom.limit = val;
      this.getUserList(this.userStatus);
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this5 = this;
      this.tableFrom.export_type = 'user_wallet';
      (0, _system.exportexcel)(this.tableFrom).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this5.$createElement;
        _this5.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this5.$msgbox.close();
                _this5.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('user_wallet');
    }
  }
};