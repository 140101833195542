"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _data_template = _interopRequireDefault(require("./modules/data_template"));
var _data_drawer_template = _interopRequireDefault(require("./modules/data_drawer_template.vue"));
var _auction = require("@/api/auction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    dataTemplate: _data_template.default,
    dataDrawerTemplate: _data_drawer_template.default
  },
  data: function data() {
    return {
      id: this.$route.query.id || '',
      name: this.$route.query.name || '',
      album_id: '',
      albumStatistical: {},
      clickList: [],
      bidNumList: [],
      priceList: [],
      ideaPriceRanking: [],
      ideaNumRanking: [],
      bidNumRanking: []
    };
  },
  created: function created() {
    var _this = this;
    this.album_id = this.$route.query.id || '';
    (0, _auction.albumStatisticalInfoApi)({
      album_id: this.album_id
    }).then(function (res) {
      _this.albumStatistical = res.data;
    }).catch(function () {});
    var params = {
      page: 1,
      limit: 10,
      album_id: this.album_id
    };
    (0, _auction.auctionList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
      order: 'click_desc'
    })).then(function (res) {
      _this.clickList = res.data.list;
    });
    (0, _auction.auctionList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
      order: 'bid_num_desc'
    })).then(function (res) {
      _this.bidNumList = res.data.list;
    });
    (0, _auction.auctionList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
      order: 'price_desc'
    })).then(function (res) {
      _this.priceList = res.data.list;
    });
    (0, _auction.userIdeaPriceRankingApi)(params).then(function (res) {
      _this.ideaPriceRanking = res.data.list;
    });
    (0, _auction.userIdeaNumRankingApi)(params).then(function (res) {
      _this.ideaNumRanking = res.data.list;
    });
    (0, _auction.userBidNumRankingApi)(params).then(function (res) {
      _this.bidNumRanking = res.data.list;
    });
  },
  methods: {
    showMoreAction: function showMoreAction(type, title) {
      this.$refs.dataDrawerTemplateEL.init(type, title, 'album_id', this.album_id);
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};