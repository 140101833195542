"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _contract = require("@/api/contract");
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list: [],
      reductjson: {
        erp_v2_out_warehouse: '0',
        is_approval_admin: 0,
        adminData: []
      },
      reviewAduitId: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (!this.$route.query.ids) {
      this.$message.error("请选择拍品");
      return;
    }
    (0, _contract.createEntrustCheckOrderPreReturnApi)({
      check_auction_ids: this.$route.query.ids
    }).then(function (res) {
      _this.list = res.data;
    });
    (0, _setting.getApprovalApi)({
      type: "erp_v2_out_warehouse_refund"
    }).then(function (res) {
      console.log(res);
      _this.reductjson.erp_v2_out_warehouse = res.data["erp_v2_out_warehouse_refund"];
      _this.reductjson.adminData = res.data.adminData;
    });
  },
  methods: {
    createPushOrder: function createPushOrder() {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4\u521B\u5EFA\u51FA\u5E93\u5355?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var model = {
          check_auction_ids: _this2.$route.query.ids
        };
        if (_this2.reductjson.erp_v2_out_warehouse == 1) {
          if (!_this2.reviewAduitId) {
            _this2.$message.error('请选择审批人');
            return;
          }
          model.audit_admin_id = _this2.reviewAduitId;
        }
        (0, _contract.createEntrustCheckOrderReturnApi)(model).then(function (res) {
          _this2.$message.success("创建成功");
          _this2.$router.back();
        });
      }).catch(function () {});
    }
  }
};