var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: _vm.title, is_tab: false } },
    [
      _vm.content.style == 1
        ? [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.facade,
                  "label-width": "100px",
                  size: "mini",
                  "label-position": "left",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c("choose", {
                  staticClass: "mt20",
                  attrs: {
                    data: _vm.pointerData,
                    title: "指示器样式",
                    margin: "0 10px 0 0",
                    width: "75px",
                    height: "30px",
                  },
                  model: {
                    value: _vm.facade.indicatorStyle,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "indicatorStyle", $$v)
                    },
                    expression: "facade.indicatorStyle",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "指示器对齐" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "le-radio-group",
                        model: {
                          value: _vm.facade.indicatorAlign,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "indicatorAlign", $$v)
                          },
                          expression: "facade.indicatorAlign",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "left" } }, [
                          _vm._v("居左"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "center" } }, [
                          _vm._v("居中"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "right" } }, [
                          _vm._v("居右"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "选中颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.indicatorColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "indicatorColor", $$v)
                        },
                        expression: "facade.indicatorColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "横图名称高度" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 200 },
                      model: {
                        value: _vm.facade.horHeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "horHeight", $$v)
                        },
                        expression: "facade.horHeight",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "横图名称底色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.horNameBackground,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "horNameBackground", $$v)
                        },
                        expression: "facade.horNameBackground",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "底色渐变" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.horNameGradientBackground,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "horNameGradientBackground", $$v)
                        },
                        expression: "facade.horNameGradientBackground",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "渐变方向" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.horNameGradientDirection,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade,
                              "horNameGradientDirection",
                              $$v
                            )
                          },
                          expression: "facade.horNameGradientDirection",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "to bottom" } }, [
                          _vm._v("向下"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "to right" } }, [
                          _vm._v("向右"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          { attrs: { label: "to right bottom" } },
                          [_vm._v("右下")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "横图名称颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.horNameColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "horNameColor", $$v)
                        },
                        expression: "facade.horNameColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "横图名称字号" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.horNameFontSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "horNameFontSize", $$v)
                        },
                        expression: "facade.horNameFontSize",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "横图名称样式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.horNameFontWeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "horNameFontWeight", $$v)
                          },
                          expression: "facade.horNameFontWeight",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 400 } }, [
                          _vm._v("正常"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 500 } }, [
                          _vm._v("加粗"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 300 } }, [
                          _vm._v("加细"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "横图时间颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.horTimeColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "horTimeColor", $$v)
                        },
                        expression: "facade.horTimeColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "横图时间字号" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.horTimeFontSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "horTimeFontSize", $$v)
                        },
                        expression: "facade.horTimeFontSize",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "横图时间样式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.horTimeFontWeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "horTimeFontWeight", $$v)
                          },
                          expression: "facade.horTimeFontWeight",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 400 } }, [
                          _vm._v("正常"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 500 } }, [
                          _vm._v("加粗"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 300 } }, [
                          _vm._v("加细"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.style == 2
        ? [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.facade,
                  "label-width": "100px",
                  size: "mini",
                  "label-position": "left",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "专场底色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.itemBackground,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "itemBackground", $$v)
                        },
                        expression: "facade.itemBackground",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "底色渐变" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.itemGradientBackground,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "itemGradientBackground", $$v)
                        },
                        expression: "facade.itemGradientBackground",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "渐变方向" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.itemGradientDirection,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "itemGradientDirection", $$v)
                          },
                          expression: "facade.itemGradientDirection",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "to bottom" } }, [
                          _vm._v("向下"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "to right" } }, [
                          _vm._v("向右"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          { attrs: { label: "to right bottom" } },
                          [_vm._v("右下")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "专场间距" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.itemMargin,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "itemMargin", $$v)
                        },
                        expression: "facade.itemMargin",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "专场圆角" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "radius-list tl" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.itemTopLeftRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "itemTopLeftRadius", $$v)
                              },
                              expression: "facade.itemTopLeftRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list tr" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.itemTopRightRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "itemTopRightRadius", $$v)
                              },
                              expression: "facade.itemTopRightRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list bl" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.itemBottomLeftRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "itemBottomLeftRadius",
                                  $$v
                                )
                              },
                              expression: "facade.itemBottomLeftRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list br" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.itemBottomRightRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "itemBottomRightRadius",
                                  $$v
                                )
                              },
                              expression: "facade.itemBottomRightRadius",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "阴影" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.itemShadow,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "itemShadow", $$v)
                        },
                        expression: "facade.itemShadow",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "阴影大小" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.itemShadowSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "itemShadowSize", $$v)
                        },
                        expression: "facade.itemShadowSize",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "阴影偏移X轴" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.itemShadowX,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "itemShadowX", $$v)
                        },
                        expression: "facade.itemShadowX",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "阴影偏移Y轴" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.itemShadowY,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "itemShadowY", $$v)
                        },
                        expression: "facade.itemShadowY",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "专场状态位置" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "radius-list t" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.statusMarginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "statusMarginTop", $$v)
                              },
                              expression: "facade.statusMarginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list l" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.statusMarginLeft,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "statusMarginLeft", $$v)
                              },
                              expression: "facade.statusMarginLeft",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "状态内间距" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "radius-list t" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.statusPaddingTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "statusPaddingTop", $$v)
                              },
                              expression: "facade.statusPaddingTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list b" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.statusPaddingBottom,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "statusPaddingBottom", $$v)
                              },
                              expression: "facade.statusPaddingBottom",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list l" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.statusPaddingLeft,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "statusPaddingLeft", $$v)
                              },
                              expression: "facade.statusPaddingLeft",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list r" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.statusPaddingRight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "statusPaddingRight", $$v)
                              },
                              expression: "facade.statusPaddingRight",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "专场状态圆角" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "radius-list tl" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.statusTopLeftRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "statusTopLeftRadius", $$v)
                              },
                              expression: "facade.statusTopLeftRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list tr" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.statusTopRightRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "statusTopRightRadius",
                                  $$v
                                )
                              },
                              expression: "facade.statusTopRightRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list bl" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.statusBottomLeftRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "statusBottomLeftRadius",
                                  $$v
                                )
                              },
                              expression: "facade.statusBottomLeftRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list br" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.statusBottomRightRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "statusBottomRightRadius",
                                  $$v
                                )
                              },
                              expression: "facade.statusBottomRightRadius",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "状态字号" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.statusFontSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "statusFontSize", $$v)
                        },
                        expression: "facade.statusFontSize",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "状态样式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.statusFontWeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "statusFontWeight", $$v)
                          },
                          expression: "facade.statusFontWeight",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 400 } }, [
                          _vm._v("正常"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 500 } }, [
                          _vm._v("加粗"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 300 } }, [
                          _vm._v("加细"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                    _vm._v("预展中图标"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "waitingBackgoundUrl")
                        },
                      },
                    },
                    [
                      _vm.facade.waitingBackgoundUrl
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delFacadeImg("waitingBackgoundUrl")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.waitingBackgoundUrl
                        ? _c("img", {
                            attrs: {
                              src: _vm.facade.waitingBackgoundUrl + "!300",
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "预展中背景" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.waitingBackgoundColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "waitingBackgoundColor", $$v)
                        },
                        expression: "facade.waitingBackgoundColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "预展中文字" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.waitingColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "waitingColor", $$v)
                        },
                        expression: "facade.waitingColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                    _vm._v("进行中图标"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "ingBackgoundUrl")
                        },
                      },
                    },
                    [
                      _vm.facade.ingBackgoundUrl
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delFacadeImg("ingBackgoundUrl")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.ingBackgoundUrl
                        ? _c("img", {
                            attrs: { src: _vm.facade.ingBackgoundUrl + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "进行中背景" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.ingBackgoundColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "ingBackgoundColor", $$v)
                        },
                        expression: "facade.ingBackgoundColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "进行中文字" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.ingColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "ingColor", $$v)
                        },
                        expression: "facade.ingColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                    _vm._v("直播中图标"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "liveingBackgoundUrl")
                        },
                      },
                    },
                    [
                      _vm.facade.liveingBackgoundUrl
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delFacadeImg("liveingBackgoundUrl")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.liveingBackgoundUrl
                        ? _c("img", {
                            attrs: {
                              src: _vm.facade.liveingBackgoundUrl + "!300",
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "直播中背景" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.liveingBackgoundColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "liveingBackgoundColor", $$v)
                        },
                        expression: "facade.liveingBackgoundColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "直播中文字" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.liveingColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "liveingColor", $$v)
                        },
                        expression: "facade.liveingColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                    _vm._v("已结束图标"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "endBackgoundUrl")
                        },
                      },
                    },
                    [
                      _vm.facade.endBackgoundUrl
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delFacadeImg("endBackgoundUrl")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.endBackgoundUrl
                        ? _c("img", {
                            attrs: { src: _vm.facade.endBackgoundUrl + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "已结束背景" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.endBackgoundColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "endBackgoundColor", $$v)
                        },
                        expression: "facade.endBackgoundColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "已结束文字" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.endColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "endColor", $$v)
                        },
                        expression: "facade.endColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "图片背景颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.imgBackground,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "imgBackground", $$v)
                        },
                        expression: "facade.imgBackground",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "图片区域圆角" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "radius-list tl" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.imgTopLeftRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "imgTopLeftRadius", $$v)
                              },
                              expression: "facade.imgTopLeftRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list tr" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.imgTopRightRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "imgTopRightRadius", $$v)
                              },
                              expression: "facade.imgTopRightRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list bl" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.imgBottomLeftRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "imgBottomLeftRadius", $$v)
                              },
                              expression: "facade.imgBottomLeftRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list br" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.imgBottomRightRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "imgBottomRightRadius",
                                  $$v
                                )
                              },
                              expression: "facade.imgBottomRightRadius",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "竖图内容距离" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "radius-list t" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.descMarginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "descMarginTop", $$v)
                              },
                              expression: "facade.descMarginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list b" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.descMarginBottom,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "descMarginBottom", $$v)
                              },
                              expression: "facade.descMarginBottom",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list l" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.descMarginLeft,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "descMarginLeft", $$v)
                              },
                              expression: "facade.descMarginLeft",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list r" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.descMarginRight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "descMarginRight", $$v)
                              },
                              expression: "facade.descMarginRight",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "横图内容距离" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "radius-list t" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.hDescMarginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "hDescMarginTop", $$v)
                              },
                              expression: "facade.hDescMarginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list b" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.hDescMarginBottom,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "hDescMarginBottom", $$v)
                              },
                              expression: "facade.hDescMarginBottom",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list l" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.hDescMarginLeft,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "hDescMarginLeft", $$v)
                              },
                              expression: "facade.hDescMarginLeft",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list r" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.hDescMarginRight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "hDescMarginRight", $$v)
                              },
                              expression: "facade.hDescMarginRight",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "标题距离" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "radius-list t" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.nameMarginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "nameMarginTop", $$v)
                              },
                              expression: "facade.nameMarginTop",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "标题颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.nameColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "nameColor", $$v)
                        },
                        expression: "facade.nameColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "标题字号" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.nameFontSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "nameFontSize", $$v)
                        },
                        expression: "facade.nameFontSize",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "标题样式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.nameFontWeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "nameFontWeight", $$v)
                          },
                          expression: "facade.nameFontWeight",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 400 } }, [
                          _vm._v("正常"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 500 } }, [
                          _vm._v("加粗"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 300 } }, [
                          _vm._v("加细"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "描述距离" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "radius-list t" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.albumInfoMarginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "albumInfoMarginTop", $$v)
                              },
                              expression: "facade.albumInfoMarginTop",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "描述颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.albumInfoColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "albumInfoColor", $$v)
                        },
                        expression: "facade.albumInfoColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "描述字号" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.albumInfoFontSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "albumInfoFontSize", $$v)
                        },
                        expression: "facade.albumInfoFontSize",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "描述样式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.albumInfoFontWeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "albumInfoFontWeight", $$v)
                          },
                          expression: "facade.albumInfoFontWeight",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 400 } }, [
                          _vm._v("正常"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 500 } }, [
                          _vm._v("加粗"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 300 } }, [
                          _vm._v("加细"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "类型距离" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "radius-list t" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.albumTypeMarginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "albumTypeMarginTop", $$v)
                              },
                              expression: "facade.albumTypeMarginTop",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "类型颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.albumTypeColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "albumTypeColor", $$v)
                        },
                        expression: "facade.albumTypeColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "类型字号" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.albumTypeFontSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "albumTypeFontSize", $$v)
                        },
                        expression: "facade.albumTypeFontSize",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "类型样式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.albumTypeFontWeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "albumTypeFontWeight", $$v)
                          },
                          expression: "facade.albumTypeFontWeight",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 400 } }, [
                          _vm._v("正常"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 500 } }, [
                          _vm._v("加粗"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 300 } }, [
                          _vm._v("加细"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "时间距离" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "radius-list t" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.albumTimeMarginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "albumTimeMarginTop", $$v)
                              },
                              expression: "facade.albumTimeMarginTop",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "时间颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.albumTimeColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "albumTimeColor", $$v)
                        },
                        expression: "facade.albumTimeColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "时间字号" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.albumTimeFontSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "albumTimeFontSize", $$v)
                        },
                        expression: "facade.albumTimeFontSize",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "时间样式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.albumTimeFontWeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "albumTimeFontWeight", $$v)
                          },
                          expression: "facade.albumTimeFontWeight",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 400 } }, [
                          _vm._v("正常"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 500 } }, [
                          _vm._v("加粗"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 300 } }, [
                          _vm._v("加细"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "当前拍品距离" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "radius-list t" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.nowAuctionMarginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "nowAuctionMarginTop", $$v)
                              },
                              expression: "facade.nowAuctionMarginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list b" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.nowAuctionMarginBottom,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "nowAuctionMarginBottom",
                                  $$v
                                )
                              },
                              expression: "facade.nowAuctionMarginBottom",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                    _vm._v("当前拍品图标"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "nowAuctionIcon")
                        },
                      },
                    },
                    [
                      _vm.facade.nowAuctionIcon
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delFacadeImg("nowAuctionIcon")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.nowAuctionIcon
                        ? _c("img", {
                            attrs: { src: _vm.facade.nowAuctionIcon + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "当前拍品颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.nowAuctionColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "nowAuctionColor", $$v)
                        },
                        expression: "facade.nowAuctionColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "当前数量颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.nowAuctionCurrColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "nowAuctionCurrColor", $$v)
                        },
                        expression: "facade.nowAuctionCurrColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "当前拍品字号" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.nowAuctionFontSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "nowAuctionFontSize", $$v)
                        },
                        expression: "facade.nowAuctionFontSize",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "当前拍品样式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.nowAuctionFontWeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "nowAuctionFontWeight", $$v)
                          },
                          expression: "facade.nowAuctionFontWeight",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 400 } }, [
                          _vm._v("正常"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 500 } }, [
                          _vm._v("加粗"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 300 } }, [
                          _vm._v("加细"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.style == 3
        ? [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.facade,
                  "label-width": "100px",
                  size: "mini",
                  "label-position": "left",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "展示行数" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.line,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "line", $$v)
                          },
                          expression: "facade.line",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("1行"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("2行"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 3 } }, [
                          _vm._v("3行"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.facade.line == 2 || _vm.facade.line == 3
                  ? _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "专场行间距" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.albumLineMargin,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "albumLineMargin", $$v)
                            },
                            expression: "facade.albumLineMargin",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "专场间距" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.albumMargin,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "albumMargin", $$v)
                        },
                        expression: "facade.albumMargin",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center" }, [
                  _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                    _vm._v("图片尺寸"),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "item-align-center" },
                      [
                        _c(
                          "el-input",
                          {
                            staticStyle: { width: "80px" },
                            attrs: {
                              type: "text",
                              placeholder: "宽",
                              disabled: _vm.facade.width_auto,
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                              },
                            },
                            model: {
                              value: _vm.facade.imgWidth,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "imgWidth", $$v)
                              },
                              expression: "facade.imgWidth",
                            },
                          },
                          [_c("template", { slot: "suffix" }, [_vm._v("px")])],
                          2
                        ),
                        _vm._v(" "),
                        _c("img", {
                          staticStyle: { margin: "0 6px" },
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-input",
                          {
                            staticStyle: { width: "80px" },
                            attrs: { type: "text", placeholder: "宽" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                              },
                            },
                            model: {
                              value: _vm.facade.imgHeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "imgHeight", $$v)
                              },
                              expression: "facade.imgHeight",
                            },
                          },
                          [_c("template", { slot: "suffix" }, [_vm._v("px")])],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                    _vm._v("宽度自适应"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-one" },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#009406",
                          "inactive-color": "#cccccc",
                        },
                        model: {
                          value: _vm.facade.width_auto,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "width_auto", $$v)
                          },
                          expression: "facade.width_auto",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "圆角" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "radius-list tl" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.simplicityTopLeftRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "simplicityTopLeftRadius",
                                  $$v
                                )
                              },
                              expression: "facade.simplicityTopLeftRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list tr" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.simplicityTopRightRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "simplicityTopRightRadius",
                                  $$v
                                )
                              },
                              expression: "facade.simplicityTopRightRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list bl" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.simplicityBottomLeftRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "simplicityBottomLeftRadius",
                                  $$v
                                )
                              },
                              expression: "facade.simplicityBottomLeftRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list br" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.simplicityBottomRightRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "simplicityBottomRightRadius",
                                  $$v
                                )
                              },
                              expression: "facade.simplicityBottomRightRadius",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "内容内间距" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "radius-list t" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.simplicityNamePaddingTop,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "simplicityNamePaddingTop",
                                  $$v
                                )
                              },
                              expression: "facade.simplicityNamePaddingTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list r" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.simplicityNamePaddingRight,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "simplicityNamePaddingRight",
                                  $$v
                                )
                              },
                              expression: "facade.simplicityNamePaddingRight",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list b" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.simplicityNamePaddingBottom,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "simplicityNamePaddingBottom",
                                  $$v
                                )
                              },
                              expression: "facade.simplicityNamePaddingBottom",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radius-list l" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.simplicityNamePaddingLeft,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "simplicityNamePaddingLeft",
                                  $$v
                                )
                              },
                              expression: "facade.simplicityNamePaddingLeft",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "图片底色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.simplicityBackground,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "simplicityBackground", $$v)
                        },
                        expression: "facade.simplicityBackground",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "名称底色高度" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 750 },
                      model: {
                        value: _vm.facade.simplicityNameBgHeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "simplicityNameBgHeight", $$v)
                        },
                        expression: "facade.simplicityNameBgHeight",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "名称底色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.simplicityNameBackground,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "simplicityNameBackground", $$v)
                        },
                        expression: "facade.simplicityNameBackground",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "名称距离" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        { staticClass: "radius-list t" },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false },
                            model: {
                              value: _vm.facade.simplicityNameMarginTop,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "simplicityNameMarginTop",
                                  $$v
                                )
                              },
                              expression: "facade.simplicityNameMarginTop",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "名称颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.simplicityNameColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "simplicityNameColor", $$v)
                        },
                        expression: "facade.simplicityNameColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "名称字号" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.simplicityNameFontSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "simplicityNameFontSize", $$v)
                        },
                        expression: "facade.simplicityNameFontSize",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "名称样式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.simplicityNameFontWeight,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade,
                              "simplicityNameFontWeight",
                              $$v
                            )
                          },
                          expression: "facade.simplicityNameFontWeight",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 400 } }, [
                          _vm._v("正常"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 500 } }, [
                          _vm._v("加粗"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 300 } }, [
                          _vm._v("加细"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "时间颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.simplicityTimeColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "simplicityTimeColor", $$v)
                        },
                        expression: "facade.simplicityTimeColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "时间字号" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.simplicityTimeFontSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "simplicityTimeFontSize", $$v)
                        },
                        expression: "facade.simplicityTimeFontSize",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "时间样式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.simplicityTimeFontWeight,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.facade,
                              "simplicityTimeFontWeight",
                              $$v
                            )
                          },
                          expression: "facade.simplicityTimeFontWeight",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 400 } }, [
                          _vm._v("正常"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 500 } }, [
                          _vm._v("加粗"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 300 } }, [
                          _vm._v("加细"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }