var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "标题栏", is_tab: false } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "80px",
            size: "small",
            "label-position": "left",
          },
        },
        [
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
              _vm._v("LOGO"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-one ft12" }, [
              _c("div", { staticClass: "item-align-center" }, [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "logo")
                        },
                      },
                    },
                    [
                      _vm.pageInfo.headerSetting.logo
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("logo", "headerSetting")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pageInfo.headerSetting.logo
                        ? _c("img", {
                            attrs: {
                              src: _vm.pageInfo.headerSetting.logo + "!300",
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "align-c color-black mt5" }, [
                    _vm._v("默认"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ml20" }, [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "logo_slide")
                        },
                      },
                    },
                    [
                      _vm.pageInfo.headerSetting.logo_slide
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg(
                                    "logo_slide",
                                    "headerSetting"
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pageInfo.headerSetting.logo_slide
                        ? _c("img", {
                            attrs: {
                              src:
                                _vm.pageInfo.headerSetting.logo_slide + "!300",
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "align-c color-black mt5" }, [
                    _vm._v("滑动后"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "color-gray ml20" }, [
                  _c("div", [_vm._v("高度66px")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("宽度700px以内")]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "LOGO位置" } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "le-radio-group",
                  model: {
                    value: _vm.pageInfo.headerSetting.logo_position,
                    callback: function ($$v) {
                      _vm.$set(_vm.pageInfo.headerSetting, "logo_position", $$v)
                    },
                    expression: "pageInfo.headerSetting.logo_position",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("居左")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("居中")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.pageInfo.headerSetting.logo &&
          _vm.pageInfo.headerSetting.logo_position == 1
            ? _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "LOGO距左" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 200 },
                    model: {
                      value: _vm.pageInfo.headerSetting.logo_left_margin,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.pageInfo.headerSetting,
                          "logo_left_margin",
                          $$v
                        )
                      },
                      expression: "pageInfo.headerSetting.logo_left_margin",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "标题栏背景" } },
            [
              _c("colour", {
                attrs: { colour: "#ffffff" },
                model: {
                  value: _vm.pageInfo.headerSetting.header_background,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.pageInfo.headerSetting,
                      "header_background",
                      $$v
                    )
                  },
                  expression: "pageInfo.headerSetting.header_background",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "文字颜色" } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "le-radio-group",
                  model: {
                    value: _vm.pageInfo.headerSetting.header_color,
                    callback: function ($$v) {
                      _vm.$set(_vm.pageInfo.headerSetting, "header_color", $$v)
                    },
                    expression: "pageInfo.headerSetting.header_color",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "black" } }, [
                    _vm._v("黑色"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "white" } }, [
                    _vm._v("白色"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "字号" } },
            [
              _c("slider", {
                attrs: { min: 12, max: 50 },
                model: {
                  value: _vm.pageInfo.headerSetting.header_text_size,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.pageInfo.headerSetting,
                      "header_text_size",
                      $$v
                    )
                  },
                  expression: "pageInfo.headerSetting.header_text_size",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "h30" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-align-center mt20" },
            [
              _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                _vm._v("隐藏标题文字"),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "flex-one",
                staticStyle: { "font-size": "12px", color: "#999" },
              }),
              _vm._v(" "),
              _c("el-switch", {
                attrs: {
                  "active-color": "#009406",
                  "inactive-color": "#cccccc",
                },
                model: {
                  value: _vm.pageInfo.headerSetting.hide_title_text,
                  callback: function ($$v) {
                    _vm.$set(_vm.pageInfo.headerSetting, "hide_title_text", $$v)
                  },
                  expression: "pageInfo.headerSetting.hide_title_text",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-align-center mt20" },
            [
              _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                _vm._v("隐藏左上角返回键"),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "flex-one",
                staticStyle: { "font-size": "12px", color: "#999" },
              }),
              _vm._v(" "),
              _c("el-switch", {
                attrs: {
                  "active-color": "#009406",
                  "inactive-color": "#cccccc",
                },
                model: {
                  value: _vm.pageInfo.headerSetting.hide_back_btn,
                  callback: function ($$v) {
                    _vm.$set(_vm.pageInfo.headerSetting, "hide_back_btn", $$v)
                  },
                  expression: "pageInfo.headerSetting.hide_back_btn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-align-center mt20" },
            [
              _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                _vm._v("透明标题栏"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex-one ml10",
                  staticStyle: { "font-size": "12px", color: "#999" },
                },
                [_vm._v("打开后标题栏悬浮在内容上边")]
              ),
              _vm._v(" "),
              _c("el-switch", {
                attrs: {
                  "active-color": "#009406",
                  "inactive-color": "#cccccc",
                },
                model: {
                  value: _vm.pageInfo.headerSetting.header_air,
                  callback: function ($$v) {
                    _vm.$set(_vm.pageInfo.headerSetting, "header_air", $$v)
                  },
                  expression: "pageInfo.headerSetting.header_air",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "h30" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-align-center mt20" },
            [
              _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                _vm._v("两端布局"),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "flex-one",
                staticStyle: { "font-size": "12px", color: "#999" },
              }),
              _vm._v(" "),
              _c("el-switch", {
                attrs: {
                  "active-color": "#009406",
                  "inactive-color": "#cccccc",
                },
                model: {
                  value: _vm.pageInfo.headerSetting.justifyB,
                  callback: function ($$v) {
                    _vm.$set(_vm.pageInfo.headerSetting, "justifyB", $$v)
                  },
                  expression: "pageInfo.headerSetting.justifyB",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.pageInfo.headerSetting.justifyB
            ? _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "右侧距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 300 },
                    model: {
                      value: _vm.pageInfo.headerSetting.header_search_right,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.pageInfo.headerSetting,
                          "header_search_right",
                          $$v
                        )
                      },
                      expression: "pageInfo.headerSetting.header_search_right",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "h30" }),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              { staticClass: "item-align-center mt20" },
              [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("标题栏搜索"),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "flex-one",
                  staticStyle: { "font-size": "12px", color: "#999" },
                }),
                _vm._v(" "),
                _c("el-switch", {
                  attrs: {
                    "active-color": "#009406",
                    "inactive-color": "#cccccc",
                  },
                  model: {
                    value: _vm.pageInfo.headerSetting.open_header_search,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.pageInfo.headerSetting,
                        "open_header_search",
                        $$v
                      )
                    },
                    expression: "pageInfo.headerSetting.open_header_search",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.pageInfo.headerSetting.open_header_search
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("标题栏类型")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex-one" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "le-radio-group",
                              model: {
                                value:
                                  _vm.pageInfo.headerSetting.header_search_type,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pageInfo.headerSetting,
                                    "header_search_type",
                                    $$v
                                  )
                                },
                                expression:
                                  "pageInfo.headerSetting.header_search_type",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("圆形无文字"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("图标带文字"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.pageInfo.headerSetting.header_search_type === 2
                      ? _c("div", { staticClass: "item-align-center mt20" }, [
                          _c(
                            "div",
                            { staticClass: "w80 fontW400 ft14 color-black" },
                            [_vm._v("框内文字")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex-one" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "设置文字",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return (function () {})($event)
                                  },
                                },
                                model: {
                                  value:
                                    _vm.pageInfo.headerSetting
                                      .header_search_text,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageInfo.headerSetting,
                                      "header_search_text",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "pageInfo.headerSetting.header_search_text",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.pageInfo.headerSetting.header_search_type === 2
                      ? _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "文字颜色" } },
                          [
                            _c("colour", {
                              attrs: { colour: "#000000" },
                              model: {
                                value:
                                  _vm.pageInfo.headerSetting
                                    .header_search_text_color,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pageInfo.headerSetting,
                                    "header_search_text_color",
                                    $$v
                                  )
                                },
                                expression:
                                  "pageInfo.headerSetting.header_search_text_color",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.pageInfo.headerSetting.header_search_type === 2
                      ? _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "文字风格" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                staticClass: "le-radio-group",
                                model: {
                                  value:
                                    _vm.pageInfo.headerSetting
                                      .header_search_text_style,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageInfo.headerSetting,
                                      "header_search_text_style",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "pageInfo.headerSetting.header_search_text_style",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 400 } }, [
                                  _vm._v("常规"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 500 } }, [
                                  _vm._v("加粗"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 300 } }, [
                                  _vm._v("加细"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.pageInfo.headerSetting.header_search_type === 2
                      ? _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "字号" } },
                          [
                            _c("slider", {
                              attrs: { min: 0, max: 60 },
                              model: {
                                value:
                                  _vm.pageInfo.headerSetting
                                    .header_search_text_size,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pageInfo.headerSetting,
                                    "header_search_text_size",
                                    $$v
                                  )
                                },
                                expression:
                                  "pageInfo.headerSetting.header_search_text_size",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "左侧间距" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 750 },
                          model: {
                            value:
                              _vm.pageInfo.headerSetting
                                .header_search_margin_left,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.headerSetting,
                                "header_search_margin_left",
                                $$v
                              )
                            },
                            expression:
                              "pageInfo.headerSetting.header_search_margin_left",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.pageInfo.headerSetting.header_search_type === 2
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "mt20",
                            attrs: { label: "搜索框长度" },
                          },
                          [
                            _c("slider", {
                              attrs: { min: 0, max: 700 },
                              model: {
                                value:
                                  _vm.pageInfo.headerSetting
                                    .header_search_width_style,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pageInfo.headerSetting,
                                    "header_search_width_style",
                                    $$v
                                  )
                                },
                                expression:
                                  "pageInfo.headerSetting.header_search_width_style",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.pageInfo.headerSetting.header_search_type === 2
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "mt20",
                            attrs: { label: "搜索框圆角" },
                          },
                          [
                            _c("slider", {
                              attrs: { min: 0, max: 70 },
                              model: {
                                value:
                                  _vm.pageInfo.headerSetting
                                    .header_search_border_radius,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pageInfo.headerSetting,
                                    "header_search_border_radius",
                                    $$v
                                  )
                                },
                                expression:
                                  "pageInfo.headerSetting.header_search_border_radius",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value:
                              _vm.pageInfo.headerSetting
                                .header_search_background_color,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.headerSetting,
                                "header_search_background_color",
                                $$v
                              )
                            },
                            expression:
                              "pageInfo.headerSetting.header_search_background_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "边框颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value:
                              _vm.pageInfo.headerSetting
                                .header_search_border_color,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.headerSetting,
                                "header_search_border_color",
                                $$v
                              )
                            },
                            expression:
                              "pageInfo.headerSetting.header_search_border_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "图标颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value:
                              _vm.pageInfo.headerSetting
                                .header_search_icon_color,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.headerSetting,
                                "header_search_icon_color",
                                $$v
                              )
                            },
                            expression:
                              "pageInfo.headerSetting.header_search_icon_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.pageInfo.headerSetting.header_search_type === 1
                      ? _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "图标大小" } },
                          [
                            _c("slider", {
                              attrs: { min: 0, max: 60 },
                              model: {
                                value:
                                  _vm.pageInfo.headerSetting
                                    .header_search_text_size,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.pageInfo.headerSetting,
                                    "header_search_text_size",
                                    $$v
                                  )
                                },
                                expression:
                                  "pageInfo.headerSetting.header_search_text_size",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "h30" }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "item-align-center mt20" },
                [
                  _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                    _vm._v("切换语言"),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "flex-one",
                    staticStyle: { "font-size": "12px", color: "#999" },
                  }),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#009406",
                      "inactive-color": "#cccccc",
                    },
                    model: {
                      value: _vm.pageInfo.headerSetting.open_switch_language,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.pageInfo.headerSetting,
                          "open_switch_language",
                          $$v
                        )
                      },
                      expression: "pageInfo.headerSetting.open_switch_language",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.pageInfo.headerSetting.open_switch_language
                ? [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "左侧间距" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 750 },
                          model: {
                            value:
                              _vm.pageInfo.headerSetting
                                .header_language_margin_left,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.headerSetting,
                                "header_language_margin_left",
                                $$v
                              )
                            },
                            expression:
                              "pageInfo.headerSetting.header_language_margin_left",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value:
                              _vm.pageInfo.headerSetting
                                .header_language_background_color,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.headerSetting,
                                "header_language_background_color",
                                $$v
                              )
                            },
                            expression:
                              "pageInfo.headerSetting.header_language_background_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "边框颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value:
                              _vm.pageInfo.headerSetting
                                .header_language_border_color,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.headerSetting,
                                "header_language_border_color",
                                $$v
                              )
                            },
                            expression:
                              "pageInfo.headerSetting.header_language_border_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "圆角" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 375 },
                          model: {
                            value:
                              _vm.pageInfo.headerSetting
                                .header_language_border_radius,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.headerSetting,
                                "header_language_border_radius",
                                $$v
                              )
                            },
                            expression:
                              "pageInfo.headerSetting.header_language_border_radius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "图标颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000", noAlpha: "1" },
                          model: {
                            value:
                              _vm.pageInfo.headerSetting
                                .header_language_icon_color,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.headerSetting,
                                "header_language_icon_color",
                                $$v
                              )
                            },
                            expression:
                              "pageInfo.headerSetting.header_language_icon_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "图标大小" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 375 },
                          model: {
                            value:
                              _vm.pageInfo.headerSetting
                                .header_language_text_size,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.headerSetting,
                                "header_language_text_size",
                                $$v
                              )
                            },
                            expression:
                              "pageInfo.headerSetting.header_language_text_size",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "菜单背景色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value:
                              _vm.pageInfo.headerSetting
                                .header_language_menu_background_color,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.headerSetting,
                                "header_language_menu_background_color",
                                $$v
                              )
                            },
                            expression:
                              "pageInfo.headerSetting.header_language_menu_background_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "菜单文字色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value:
                              _vm.pageInfo.headerSetting
                                .header_language_menu_color,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.headerSetting,
                                "header_language_menu_color",
                                $$v
                              )
                            },
                            expression:
                              "pageInfo.headerSetting.header_language_menu_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "菜单行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 750 },
                          model: {
                            value:
                              _vm.pageInfo.headerSetting
                                .header_language_menu_lineheight,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.headerSetting,
                                "header_language_menu_lineheight",
                                $$v
                              )
                            },
                            expression:
                              "pageInfo.headerSetting.header_language_menu_lineheight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "菜单字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 60 },
                          model: {
                            value:
                              _vm.pageInfo.headerSetting
                                .header_language_menu_fontsize,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.headerSetting,
                                "header_language_menu_fontsize",
                                $$v
                              )
                            },
                            expression:
                              "pageInfo.headerSetting.header_language_menu_fontsize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字样式" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "le-radio-group",
                            model: {
                              value:
                                _vm.pageInfo.headerSetting
                                  .header_language_menu_fontweight,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.pageInfo.headerSetting,
                                  "header_language_menu_fontweight",
                                  $$v
                                )
                              },
                              expression:
                                "pageInfo.headerSetting.header_language_menu_fontweight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("常规"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "菜单分隔线" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value:
                              _vm.pageInfo.headerSetting
                                .header_language_menu_line,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.pageInfo.headerSetting,
                                "header_language_menu_line",
                                $$v
                              )
                            },
                            expression:
                              "pageInfo.headerSetting.header_language_menu_line",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }