"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.to-string");
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _order = require("@/api/order");
var _system = require("@/api/system");
var _auction = require("@/api/auction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    userdetail: _userdetail.default,
    fileList: _fileList.default
  },
  name: 'OrderInvoice',
  data: function data() {
    return {
      showdetail: null,
      uid: '',
      logisticsName: 'refund',
      id: 0,
      type: '',
      tableData: {
        data: [],
        total: 0
      },
      invoiceStatusList: [{
        label: '已开票',
        value: 1
      }, {
        label: '未开票',
        value: 0
      }],
      listLoading: true,
      tableFrom: {
        username: '',
        type: '',
        date: '',
        page: 1,
        limit: 20,
        receipt_sn: '',
        order_type: '',
        keyword: '',
        status: ''
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      selectionList: [],
      tableFromLog: {
        page: 1,
        limit: 20
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      loading: false,
      dialogVisible: false,
      orderDatalist: null,
      invoiceInfo: {},
      invoiceData: {
        number: '',
        mark: ''
      },
      searchQuery: {},
      albumlistOption: [],
      orderTypeList: [{
        label: '全部',
        value: 8
      }, {
        label: '未支付',
        value: 1
      }, {
        label: '待发货',
        value: 2
      }, {
        label: '待收货',
        value: 3
      }, {
        label: '待评价',
        value: 4
      }, {
        label: '已完成',
        value: 5
      }, {
        label: '已退款',
        value: 6
      }, {
        label: '已删除',
        value: 7
      }]
    };
  },
  mounted: function mounted() {
    if (this.$route.query.hasOwnProperty('sn')) {
      this.tableFrom.order_sn = this.$route.query.sn;
    } else {
      this.tableFrom.order_sn = '';
    }
    this.albumInit('');
    this.getList(1);
  },
  methods: {
    onSelectFilterChange: function onSelectFilterChange(val) {
      this.albumInit(val);
    },
    albumInit: function albumInit(val) {
      var _this = this;
      (0, _auction.albumSearchList)({
        page: 1,
        limit: 10,
        keyword: val
      }).then(function (res) {
        // console.log('1------',res);
        _this.albumlistOption = res.data.list.map(function (item) {
          return {
            label: item.album_name,
            value: item.album_id
          };
        });
      });
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this2 = this;
      this.tableFrom.export_type = 'orderReceipt';
      (0, _system.exportexcel)(this.tableFrom).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this2.$createElement;
        _this2.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this2.$msgbox.close();
                _this2.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('orderReceipt');
    },
    onDetails: function onDetails(uid) {
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = null;
    },
    invoiceReceiptForm: function invoiceReceiptForm(id) {
      var _this3 = this;
      this.$modalForm((0, _order.invoiceReceiptFormApi)(id)).then(function () {
        return _this3.getList(1);
      });
    },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: 'OrderList',
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList('');
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList('');
    },
    // 开票信息
    getInvoiceInfo: function getInvoiceInfo(id, type) {
      var _this4 = this;
      this.id = id;
      this.type = type;
      var ids = id ? id.toString() : this.getInvoic();
      var params = {
        ids: ids
      };
      type === 'invoice' ? (0, _order.invoiceInfoApi)(params).then(function (res) {
        _this4.listLoading = false;
        _this4.dialogVisible = true;
        if (res.data.receipt_image) res.data.receipt_image = res.data.receipt_image.split(',');else res.data.receipt_image = [];
        _this4.invoiceInfo = res.data;
        _this4.invoiceData = {
          number: '',
          mark: ''
        };
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.listLoading = false;
      }) : (0, _order.invoiceDetailApi)(id) // 编辑详情
      .then(function (res) {
        _this4.listLoading = false;
        _this4.dialogVisible = true;
        if (res.data.receipt_image) res.data.receipt_image = res.data.receipt_image.split(',');else res.data.receipt_image = [];
        _this4.invoiceInfo = res.data;
        _this4.invoiceData = {
          number: res.data.receipt_no,
          mark: res.data.mer_mark
        };
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.listLoading = false;
      });
    },
    // 开票
    handleInvoic: function handleInvoic() {
      var _this5 = this;
      var ids = this.id ? this.id.toString() : this.getInvoic();
      var params = {
        ids: ids,
        receipt_sn: this.invoiceInfo.receipt_sn,
        receipt_price: this.invoiceInfo.receipt_price,
        receipt_no: this.invoiceData.number,
        receipt_image: this.invoiceInfo.receipt_image,
        mer_mark: this.invoiceData.mark ? this.invoiceData.mark : ''
      };
      this.type === 'invoice' ? (0, _order.invoiceApi)(params).then(function (res) {
        _this5.$message.success(res.message);
        _this5.dialogVisible = false;
        _this5.getList();
        _this5.id = '';
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this5.listLoading = false;
      }) : (0, _order.invoiceUpdateApi)(this.id, {
        receipt_no: this.invoiceData.number,
        mer_mark: this.invoiceData.mark ? this.invoiceData.mark : '',
        receipt_image: this.invoiceInfo.receipt_image
      }).then(function (res) {
        _this5.$message.success(res.message);
        _this5.dialogVisible = false;
        _this5.getList();
        _this5.id = '';
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this5.listLoading = false;
      });
    },
    // 获取开票订单id
    getInvoic: function getInvoic() {
      var ids = [];
      this.multipleSelection.forEach(function (item, index) {
        ids.push(item.order_receipt_id);
      });
      return ids.toString();
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this6 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _order.invoiceOrderListApi)(this.tableFrom).then(function (res) {
        _this6.orderChartType = res.data.stat;
        _this6.tableData.data = res.data.list;
        _this6.tableData.total = res.data.count;
        _this6.listLoading = false;
        _this6.$nextTick(function () {
          if (_this6.$refs.invoiceRef) _this6.$refs.invoiceRef.doLayout();
        });
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this6.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    },
    changeImg: function changeImg() {
      var _this7 = this;
      this.$modalUpload(function (img) {
        _this7.invoiceInfo.receipt_image = _this7.invoiceInfo.receipt_image.concat(img);
      }, 9, '1');
    },
    delImgAction: function delImgAction(i) {
      this.invoiceInfo.receipt_image.splice(i, 1);
    }
  }
};