"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _answerTemplate = _interopRequireDefault(require("../../components/answer-template"));
var _system = require("@/api/system");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
var _authenticate = require("@/api/authenticate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var selectType = {
  '1': 'Expert',
  '3': 'PraisalReport',
  '4': 'PraisalCategory',
  '5': 'PraisalSetting',
  '6': 'AppraisalComplaint',
  '2': 'Appraisal'
};
var _default = exports.default = {
  name: 'Appraisal',
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      date: '',
      activeMenu: '1',
      showGoAnswer: {
        question_id: '',
        show: false,
        category_id: '',
        categoryIndex: 0,
        answer_content: '',
        identification_result: 1,
        category_level_id: 1,
        auction_type: '',
        expertIsAuction: 0,
        user_is_auction: 0
      },
      is_auction: '',
      userIsAuction: false,
      expertIsAuction: false,
      showSeparate: false,
      questionId: '',
      separateUid: '',
      expertList: [],
      loading1: false,
      uname: '',
      uid: '',
      studyTotal: 0,
      studyParams: {
        page: 1,
        limit: 10
      },
      qid: '',
      showStudy: false,
      studyList: [],
      showAnswer: false,
      showAnswerType: 1,
      answerList: [],
      showImg: false,
      img: [],
      categoryList: [],
      showVideo: false,
      video: '',
      complaintReasons: '',
      category: '',
      loading: true,
      total: 0,
      showDetail: false,
      showDetailType: 1,
      showDetailTitle: '查看详情',
      questionDetail: {},
      cursorResault: '',
      auction_type: '',
      queryParams: {
        limit: 10,
        question_status: '',
        page: Number(this.$route.query.page || 1)
      },
      stime: '',
      etime: '',
      expertUname: '',
      exportUid: '',
      announcerList: [],
      showCategory: false,
      showCategoryId: 0,
      searchLoading: false,
      resultData: [],
      row: '',
      userUid: '',
      showUserdetail: false,
      open_expert_can_auction: null,
      open_auction_type: null,
      open_category_level: null
    };
  },
  created: function created() {
    var _this = this;
    (0, _authenticate._getCartgorySetList)().then(function (res) {
      _this.categoryList = res.data.list;
      _this.load();
    }).catch(function (err) {
      console.log(err);
      _this.load();
    });
  },
  methods: {
    getLevelName: function getLevelName(item) {
      if (!item.category_level_id) return '';
      var cateIndex = this.categoryList.map(function (m) {
        return m.id;
      }).indexOf(item.category_id);
      if (cateIndex !== -1) {
        var level = this.categoryList[cateIndex].category_level;
        if (level && level.length > 0) {
          var levelIndex = level.map(function (m) {
            return m.id;
          }).indexOf(item.category_level_id);
          if (levelIndex != -1) {
            return "\u63A8\u8350\u7B49\u7EA7\uFF1A".concat(level[levelIndex].level_name);
          }
        }
        return '';
      }
    },
    onChangeDate: function onChangeDate(e) {
      this.date = e;
      this.searchAction();
    },
    onBigImg: function onBigImg(row) {
      this.img = row.image_url;
      this.showImg = true;
    },
    onLookHuida: function onLookHuida(row) {
      this.answerList = row.answer;
      this.showAnswerType = 1;
      this.showAnswer = true;
    },
    onLookZhuida: function onLookZhuida(row) {
      if (row.nextLevelQuestion) {
        this.answerList = row.nextLevelQuestion.answer;
        this.showAnswerType = 2;
        this.showAnswer = true;
      }
    },
    onLookTouxue: function onLookTouxue(row) {
      this.showStudy = true;
      this.qid = row.question_id;
      this.loadStudyAction();
    },
    onHuidawenti: function onHuidawenti(row) {
      var _this2 = this;
      if (this.open_expert_can_auction === null) {
        (0, _authenticate._getSettingInfo)().then(function (res) {
          _this2.open_expert_can_auction = res.data.expert_can_auction || false;
          _this2.open_auction_type = res.data.auction_type || false;
          _this2.open_category_level = res.data.category_level || false;
        }).catch(function () {
          _this2.open_expert_can_auction = false;
          _this2.open_auction_type = false;
          _this2.open_category_level = false;
        });
      }
      // _getSettingInfo
      var cIndex = this.categoryList.map(function (m) {
        return m.id;
      }).indexOf(row.category.id);
      this.showGoAnswer = {
        question_id: row.question_asked_id ? row.question_asked_id : row.question_id,
        show: true,
        category_id: this.categoryList[cIndex === -1 ? 0 : cIndex].id,
        categoryIndex: cIndex === -1 ? 0 : cIndex,
        answer_content: '',
        identification_result: 1,
        category_level_id: '',
        auction_type: '',
        expertIsAuction: 0,
        user_is_auction: row.user_is_auction
      };
    },
    onFendan: function onFendan(row) {
      console.log(row);
      this.qid = row.question_id;
      this.showSeparate = true;
      this.remoteMethod1(row);
    },
    onJiandingxiangqingi: function onJiandingxiangqingi(row, type) {
      this.qid = row.question_id;
      this.showDetail = true;
      this.showDetailType = type || '1';
      if (type == 2) {
        this.showDetailTitle = '查看问题';
      } else if (type == 3) {
        this.showDetailTitle = '查看追问';
      } else {
        this.showDetailTitle = '查看详情';
      }
      this.getDetail();
    },
    onXiugaifenlei: function onXiugaifenlei(row) {
      this.qid = row.question_id;
      this.showCategoryId = row.categoory_id;
      this.showCategory = true;
    },
    onDelete: function onDelete(row) {
      var _this3 = this;
      (0, _authenticate._deleteQuestion)({
        question_id: row.question_id
      }).then(function (res) {
        if (res.status === 200) {
          _this3.$message.success('删除成功');
          _this3.load();
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    onTuikuan: function onTuikuan(row) {
      var _this4 = this;
      var state = {
        order_id: row.order_id,
        question_id: row.question_id
      };
      (0, _authenticate._orderRefund)(state).then(function (res) {
        if (res.status === 200) {
          _this4.$message.success('退款成功');
          _this4.load();
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    onShowTiwen: function onShowTiwen(row) {
      this.setHide(row.question_id, 1);
    },
    onHideTiwen: function onHideTiwen(row) {
      this.setHide(row.question_id, 0);
    },
    onLookImg: function onLookImg(row) {
      if (row.answer_image && row.answer_image.length) {
        this.img = row.answer_image;
      } else {
        this.img = row.image_url;
      }
      this.showImg = true;
    },
    onLookvideo: function onLookvideo(row) {
      this.video = row.answer_video || row.video_url;
      this.showVideo = true;
    },
    onSelectTit: function onSelectTit(index) {
      var _this5 = this;
      this.activeMenu = index;
      this.$router.push({
        name: selectType[index]
      });
      setTimeout(function () {
        _this5.activeMenu = '1';
      });
    },
    setHide: function setHide(id, val) {
      var _this6 = this;
      var state = {
        question_id: id,
        is_show: val
      };
      (0, _authenticate._changeHide)(state).then(function (res) {
        if (res.status === 200) {
          _this6.load();
          _this6.$message.success('操作成功');
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    saveAnswerAction: function saveAnswerAction() {
      var _this7 = this;
      if (!this.showGoAnswer.answer_content) {
        this.$message.error('请输入回答');
        return false;
      }
      // if (this.showGoAnswer.expertIsAuction) {
      //   if (!this.showGoAnswer.category_level_id) {
      //     this.$message.error('请选择推荐等级');
      //     return false;
      //   }
      // }
      this.showGoAnswer.auction_recommend = Number(this.showGoAnswer.expertIsAuction);
      (0, _authenticate._createAnswer)(this.showGoAnswer).then(function (res) {
        if (res.status === 200) {
          _this7.showGoAnswer.show = false;
          _this7.$message.success('回答成功');
          _this7.load();
        }
      }).catch(function (err) {
        console.log(err);
      });
      return false;
    },
    changeCategoryAction: function changeCategoryAction(val) {
      this.showGoAnswer.category_id = this.categoryList[val].id;
    },
    remoteMethod1: function remoteMethod1(data) {
      var _this8 = this;
      console.log(data);
      this.loading1 = true;
      var params = {
        page: 1,
        limit: 10,
        status: 1
      };
      if (data.category) {
        params.category = data.category.id;
      }
      (0, _authenticate._getExpertList)(params).then(function (res) {
        if (res.status === 200) {
          _this8.expertList = res.data.list;
        }
        _this8.loading1 = false;
      }).catch(function (err) {
        _this8.loading1 = false;
        console.log(err);
      });
    },
    separateAction: function separateAction() {
      var _this9 = this;
      if (!this.separateUid) {
        this.$message.error('请选择鉴定师');
        return;
      }
      var params = {
        question_id: this.qid,
        expert_uid: this.separateUid
      };
      (0, _authenticate._distributionExpert)(params).then(function (res) {
        if (res.status === 200) {
          _this9.$message.success('修改成功');
          _this9.showSeparate = false;
          _this9.load();
        }
        _this9.separateUid = '';
      }).catch(function (err) {
        console.log(err);
        _this9.separateUid = '';
      });
    },
    exportAction: function exportAction() {
      var _this10 = this;
      this.queryParams.category_id = this.category;
      this.queryParams.uname = this.uname;
      this.queryParams.uid = this.uid;
      this.queryParams.title = this.questionId;
      var params = JSON.parse(JSON.stringify(this.queryParams));
      delete params.status;
      if (params.category_id == 0) {
        params.category_id = '';
      }
      params.identification_result = this.cursorResault;
      params.auction_type = this.auction_type;
      if (this.date && this.date.length) {
        params.date = this.date.join('-');
      } else {
        params.date = '';
      }
      params.expert_uname = this.expertUname;
      params.expert_uid = this.exportUid;
      // 导出
      (0, _system.exportexcel)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
        export_type: 'identificationV2Question'
      })).then(function (res) {
        var h = _this10.$createElement;
        _this10.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this10.$msgbox.close();
                _this10.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      var type = 'identificationV2Question';
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(type);
    },
    searchPost: function searchPost(data) {
      var _this11 = this;
      var params = {
        uname: data,
        page: 1,
        limit: 20
      };
      this.searchLoading = true;
      (0, _authenticate._getSelectExpert)(params).then(function (res) {
        if (res.status === 200) {
          _this11.announcerList = res.data.list;
        }
        _this11.searchLoading = false;
      }).catch(function (err) {
        console.log(err);
        _this11.searchLoading = false;
      });
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.limit = size;
      this.queryParams.page = 1;
      this.load();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      var newPage = page;
      this.$router.push({
        path: this.$route.path,
        query: {
          page: newPage
        }
      });
      this.load();
    },
    changeCategory: function changeCategory() {
      var _this12 = this;
      if (!this.showCategoryId) {
        this.$message.error('请选择分类');
        return;
      }
      var params = {
        question_id: this.qid,
        category_id: this.showCategoryId
      };
      (0, _authenticate._changeQuestionCategory)(params).then(function (res) {
        if (res.status === 200) {
          _this12.$message.success('修改成功');
          _this12.showCategory = false;
          _this12.load();
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    load: function load() {
      var _this13 = this;
      this.loading = true;
      var params = JSON.parse(JSON.stringify(this.queryParams));
      delete params.status;
      if (params.category == 0) {
        params.category = '';
      }
      params.identification_result = this.cursorResault;
      params.auction_type = this.auction_type;
      if (this.date && this.date.length) {
        params.date = this.date.join('-');
      } else {
        params.date = '';
      }
      params.expert_uname = this.expertUname;
      params.expert_uid = this.exportUid;
      (0, _authenticate._getQuestionList)(params).then(function (res) {
        if (res.status === 200) {
          res.data.list.forEach(function (m) {
            var newM = m;
            newM.video = m.video_url ? m.video_url.split(',') : [];
            newM.audio = m.answer_radio ? m.answer_radio.split(',') : [];
          });
          _this13.total = res.data.count;
          _this13.resultData = res.data.list;
          _this13.loading = false;
        }
      }).catch(function (err) {
        console.log(err);
        _this13.loading = false;
      });
    },
    searchAction: function searchAction() {
      this.queryParams.category_id = this.category;
      this.queryParams.uname = this.uname;
      this.queryParams.uid = this.uid;
      this.queryParams.title = this.questionId;
      // this.queryParams.is_auction = this.is_auction;
      // this.queryParams.expert_is_auction = Number(this.expertIsAuction);
      // this.queryParams.user_is_auction = Number(this.userIsAuction);
      this.changePage(1);
    },
    changeStateEvent: function changeStateEvent(name) {
      this.queryParams.question_status = name;
      this.queryParams.status = name;
      this.queryParams.page = 1;
      this.category = '';
      this.uname = '';
      this.uid = '';
      this.questionId = '';
      this.is_auction = '';
      // this.expertIsAuction = false;
      // this.userIsAuction = false;
      this.searchAction();
    },
    loadStudyAction: function loadStudyAction() {
      var _this14 = this;
      var params = this.studyParams;
      params.question_id = this.qid;
      (0, _authenticate._getStudyOrderList)(params).then(function (res) {
        if (res.status === 200) {
          _this14.studyList = res.data.list;
          _this14.studyTotal = res.data.count;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    getDetail: function getDetail() {
      var _this15 = this;
      var params = {
        question_id: this.qid
      };
      (0, _authenticate._getQuestionDetail)(this.qid).then(function (res) {
        if (res.status === 200) {
          console.log('xxxxxxx', res);
          var data = res.data;
          _this15.questionDetail = res.data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    changeStudyPage: function changeStudyPage(page) {
      this.studyParams.page = page;
      this.loadStudyAction();
    },
    // 详情
    onDetails: function onDetails(row) {
      this.row = row;
      this.userUid = row.uid;
      // this.visibleDetail = true
      this.showUserdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showUserdetail = false;
    }
  }
};