var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.editLoading,
          expression: "editLoading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c("header-breadcrumb", { attrs: { name: _vm.title, "has-back": "" } }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "demo-promoterForm",
              attrs: {
                model: _vm.formValidate,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _vm.formValidate.contract_type == 1
                ? _c(
                    "div",
                    [
                      _c("span", [_vm._v("签约时间:")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "80px" },
                        attrs: { placeholder: "年" },
                        model: {
                          value: _vm.crdate.year,
                          callback: function ($$v) {
                            _vm.$set(_vm.crdate, "year", $$v)
                          },
                          expression: "crdate.year",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("年")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "80px" },
                        attrs: { placeholder: "月" },
                        model: {
                          value: _vm.crdate.month,
                          callback: function ($$v) {
                            _vm.$set(_vm.crdate, "month", $$v)
                          },
                          expression: "crdate.month",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("月")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "80px" },
                        attrs: { placeholder: "日" },
                        model: {
                          value: _vm.crdate.day,
                          callback: function ($$v) {
                            _vm.$set(_vm.crdate, "day", $$v)
                          },
                          expression: "crdate.day",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("日")]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.contract_type == 1
                ? _c(
                    "div",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _c("span", [_vm._v("合同编号:")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: { placeholder: "请输入合同编号" },
                        on: { input: _vm.onRepeat },
                        model: {
                          value: _vm.contractNo,
                          callback: function ($$v) {
                            _vm.contractNo = $$v
                          },
                          expression: "contractNo",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.contract_type == 1
                ? _c(
                    "el-row",
                    {
                      staticStyle: {
                        "margin-top": "24px",
                        "font-size": "13px",
                      },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "uploadImg",
                              staticClass: "upload-demo mr10 mb15",
                              attrs: {
                                action: "",
                                "show-file-list": false,
                                multiple: "",
                                "http-request": _vm.modeUpload,
                                "file-list": _vm.fileList,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-connection",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              上传合同照片（pdf或jpg,png）\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "mb20" },
                            _vm._l(_vm.successFileList, function (item, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  staticClass: "fileLi flex align-items-c",
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-document",
                                    staticStyle: {
                                      "font-size": "14px",
                                      "margin-right": "10px",
                                    },
                                  }),
                                  _vm._v(" "),
                                  item.url
                                    ? [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showImgAction(
                                                  item.url
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.name) +
                                            "\n              "
                                        ),
                                      ],
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-circle-close",
                                    staticStyle: {
                                      "font-size": "14px",
                                      "margin-left": "10px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDele(index)
                                      },
                                    },
                                  }),
                                ],
                                2
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.goodsArr,
                size: "small",
                border: "",
                "highlight-current-row": "",
                width: "100%",
              },
            },
            [
              _c("template", { slot: "empty" }, [_c("div")]),
              _vm._v(" "),
              _vm._l(_vm.erpsettingList, function (item, index) {
                return [
                  item.types == "erp_v2_contract_create_product"
                    ? [
                        item.field == "product_no"
                          ? [
                              _c("el-table-column", {
                                key: index,
                                attrs: { label: item.name, prop: "product_no" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input", {
                                            staticClass: "sortCol",
                                            on: {
                                              focus: function ($event) {
                                                return _vm.focus($event)
                                              },
                                            },
                                            model: {
                                              value: scope.row.product_no,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "product_no",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.product_no",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          : item.field == "product_name"
                          ? [
                              _c("el-table-column", {
                                key: index,
                                attrs: {
                                  label: item.name,
                                  prop: "product_name",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.product_id > 0
                                            ? _c("div", [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      scope.row.product_name
                                                    ) +
                                                    "\n                "
                                                ),
                                              ])
                                            : _c(
                                                "div",
                                                [
                                                  _c("el-input", {
                                                    staticClass: "sortCol",
                                                    on: {
                                                      focus: function ($event) {
                                                        return _vm.focus($event)
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.product_name,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "product_name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.product_name",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          : item.field == "image"
                          ? [
                              _c("el-table-column", {
                                key: index,
                                attrs: { label: item.name },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "upLoadPicBox",
                                              attrs: { title: "750*750px" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.modalPicTap(
                                                    "1",
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              scope.row.image
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "pictrue",
                                                      staticStyle: {
                                                        width: "36px",
                                                        height: "36px",
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: scope.row.image,
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass: "upLoad",
                                                      staticStyle: {
                                                        width: "36px",
                                                        height: "36px",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-camera cameraIconfont",
                                                      }),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          : item.field == "retain_price"
                          ? [
                              _c("el-table-column", {
                                key: index,
                                attrs: { label: item.name },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input", {
                                            staticClass: "sortCol",
                                            on: {
                                              focus: function ($event) {
                                                return _vm.focus($event)
                                              },
                                            },
                                            model: {
                                              value: scope.row.retain_price,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "retain_price",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.retain_price",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          : item.field == "take_home_price"
                          ? [
                              _c("el-table-column", {
                                key: index,
                                attrs: { label: item.name },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input", {
                                            staticClass: "sortCol",
                                            on: {
                                              focus: function ($event) {
                                                return _vm.focus($event)
                                              },
                                            },
                                            model: {
                                              value: scope.row.take_home_price,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "take_home_price",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.take_home_price",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          : item.field == "label"
                          ? [
                              _c("el-table-column", {
                                key: index,
                                attrs: { label: item.name },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input", {
                                            staticClass: "sortCol",
                                            on: {
                                              focus: function ($event) {
                                                return _vm.focus($event)
                                              },
                                            },
                                            model: {
                                              value: scope.row.label_names,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "label_names",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.label_names",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          : item.newtype == "attribute"
                          ? [
                              item.name.indexOf("尺寸") != -1
                                ? [
                                    _c("el-table-column", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        "show-overflow-tooltip": "",
                                        width: "100",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("div", {
                                                  staticClass: "nowrap cur",
                                                  staticStyle: {
                                                    height: "100%",
                                                  },
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      scope.row.attribute[
                                                        item.field
                                                      ]
                                                    ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onClickSize(
                                                        scope.row.attribute[
                                                          item.field
                                                        ],
                                                        scope.$index,
                                                        scope.row,
                                                        item.field
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ]
                                : item.attr_type == 1
                                ? [
                                    _c("el-table-column", {
                                      key: index,
                                      attrs: { label: item.name },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "sortCol",
                                                  on: {
                                                    focus: function ($event) {
                                                      return _vm.focus($event)
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.attribute[
                                                        item.field
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row.attribute,
                                                        item.field,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.attribute[item.field]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ]
                                : item.attr_type == 2
                                ? [
                                    _c("el-table-column", {
                                      key: index,
                                      attrs: { label: item.name, width: "150" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass: "sortCol",
                                                    model: {
                                                      value:
                                                        scope.row.attribute[
                                                          item.field
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row.attribute,
                                                          item.field,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.attribute[item.field]",
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.attrValue,
                                                    function (m) {
                                                      return _c("el-option", {
                                                        key: m.attr_value_name,
                                                        attrs: {
                                                          label:
                                                            m.attr_value_name,
                                                          value:
                                                            m.attr_value_name,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ]
                                : item.attr_type == 3
                                ? [
                                    _c("el-table-column", {
                                      key: index,
                                      attrs: { label: item.name, width: "150" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticClass: "sortCol",
                                                    attrs: {
                                                      multiple: "",
                                                      "collapse-tags": "",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.attribute[
                                                          item.field
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row.attribute,
                                                          item.field,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.attribute[item.field]",
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.attrValue,
                                                    function (m) {
                                                      return _c("el-option", {
                                                        key: m.attr_value_name,
                                                        attrs: {
                                                          label:
                                                            m.attr_value_name,
                                                          value:
                                                            m.attr_value_name,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ]
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  item.types == "store_attribute"
                    ? [
                        item.name.indexOf("尺寸") != -1
                          ? [
                              _c("el-table-column", {
                                key: index,
                                attrs: {
                                  label: item.name,
                                  "show-overflow-tooltip": "",
                                  width: "100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", {
                                            staticClass: "nowrap cur",
                                            staticStyle: { height: "100%" },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                scope.row.attribute[item.field]
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onClickSize(
                                                  scope.row.attribute[
                                                    item.field
                                                  ],
                                                  scope.$index,
                                                  scope.row,
                                                  item.field
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          : item.attr_type == 1
                          ? [
                              _c("el-table-column", {
                                key: index,
                                attrs: { label: item.name },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input", {
                                            staticClass: "sortCol",
                                            on: {
                                              focus: function ($event) {
                                                return _vm.focus($event)
                                              },
                                            },
                                            model: {
                                              value:
                                                scope.row.attribute[item.field],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row.attribute,
                                                  item.field,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.attribute[item.field]",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          : item.attr_type == 2
                          ? [
                              _c("el-table-column", {
                                key: index,
                                attrs: { label: item.name, width: "150" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "sortCol",
                                              model: {
                                                value:
                                                  scope.row.attribute[
                                                    item.field
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row.attribute,
                                                    item.field,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.attribute[item.field]",
                                              },
                                            },
                                            _vm._l(
                                              item.attrValue,
                                              function (m) {
                                                return _c("el-option", {
                                                  key: m.attr_value_name,
                                                  attrs: {
                                                    label: m.attr_value_name,
                                                    value: m.attr_value_name,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          : item.attr_type == 3
                          ? [
                              _c("el-table-column", {
                                key: index,
                                attrs: { label: item.name, width: "150" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "sortCol",
                                              attrs: {
                                                multiple: "",
                                                "collapse-tags": "",
                                              },
                                              model: {
                                                value:
                                                  scope.row.attribute[
                                                    item.field
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row.attribute,
                                                    item.field,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.attribute[item.field]",
                                              },
                                            },
                                            _vm._l(
                                              item.attrValue,
                                              function (m) {
                                                return _c("el-option", {
                                                  key: m.attr_value_name,
                                                  attrs: {
                                                    label: m.attr_value_name,
                                                    value: m.attr_value_name,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              }),
              _vm._v(" "),
              _vm.inventoryConfig.switch.sold_price_switch
                ? _c(
                    "el-table-column",
                    { attrs: { label: "成交收费", align: "center" } },
                    [
                      _vm._l(
                        _vm.inventoryConfig.price.sold_price,
                        function (item, index) {
                          return [
                            item.name
                              ? _c("el-table-column", {
                                  key: "s_" + index,
                                  attrs: {
                                    "min-width":
                                      item.name == "个税" ? 140 : 100,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(item.name) +
                                                " "
                                            ),
                                            _c("el-input", {
                                              staticClass: "tabHeadInp",
                                              attrs: {
                                                readonly:
                                                  _vm.goodsRuleConfigForm
                                                    .erp_contract_create_entrust_price_update_switch ==
                                                  1,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.onTheadInput(
                                                    $event,
                                                    "success",
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.number,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "number", $$v)
                                                },
                                                expression: "item.number",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.unit == 1 ? "元" : "%"
                                                ) +
                                                "\n              "
                                            ),
                                            item.name == "个税"
                                              ? [
                                                  _c("el-switch", {
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.onChangeSwitch(
                                                          "success",
                                                          index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.changeIntRate,
                                                      callback: function ($$v) {
                                                        _vm.changeIntRate = $$v
                                                      },
                                                      expression:
                                                        "changeIntRate",
                                                    },
                                                  }),
                                                ]
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              staticClass: "sortCol",
                                              attrs: {
                                                readonly:
                                                  _vm.goodsRuleConfigForm
                                                    .erp_contract_create_entrust_price_update_switch ==
                                                  1,
                                              },
                                              on: {
                                                focus: function ($event) {
                                                  return _vm.focus($event)
                                                },
                                              },
                                              model: {
                                                value:
                                                  scope.row.success[index]
                                                    .number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row.success[index],
                                                    "number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.success[index].number",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                          ]
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.inventoryConfig.switch.unsold_price_switch
                ? _c(
                    "el-table-column",
                    { attrs: { label: "未成交收费", align: "center" } },
                    [
                      _vm._l(
                        _vm.inventoryConfig.price.unsold_price,
                        function (item, index) {
                          return [
                            item.name
                              ? _c("el-table-column", {
                                  key: "s_" + index,
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(item.name) +
                                                " "
                                            ),
                                            _c("el-input", {
                                              staticClass: "tabHeadInp",
                                              on: {
                                                input: function ($event) {
                                                  return _vm.onTheadInput(
                                                    $event,
                                                    "fail",
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.number,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "number", $$v)
                                                },
                                                expression: "item.number",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.unit == 1 ? "元" : "%"
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              staticClass: "sortCol",
                                              on: {
                                                focus: function ($event) {
                                                  return _vm.focus($event)
                                                },
                                              },
                                              model: {
                                                value:
                                                  scope.row.fail[index].number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row.fail[index],
                                                    "number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.fail[index].number",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                          ]
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.inventoryConfig.switch.other_price_switch
                ? _c(
                    "el-table-column",
                    { attrs: { label: "其他费用", align: "center" } },
                    [
                      _vm._l(
                        _vm.inventoryConfig.price.other_price,
                        function (item, index) {
                          return [
                            item.name
                              ? _c("el-table-column", {
                                  key: "s_" + index,
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(item.name) +
                                                " "
                                            ),
                                            _c("el-input", {
                                              staticClass: "tabHeadInp",
                                              on: {
                                                input: function ($event) {
                                                  return _vm.onTheadInput(
                                                    $event,
                                                    "other",
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.number,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "number", $$v)
                                                },
                                                expression: "item.number",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.unit == 1 ? "元" : "%"
                                                ) +
                                                "\n            "
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              staticClass: "sortCol",
                                              on: {
                                                focus: function ($event) {
                                                  return _vm.focus($event)
                                                },
                                              },
                                              model: {
                                                value:
                                                  scope.row.other[index].number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row.other[index],
                                                    "number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.other[index].number",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                          ]
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "60", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delGoods(scope.$index, scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n              删除\n            ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "10px" }, attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        type: "primary",
                        size: "mini",
                      },
                      on: { click: _vm.createActiveGoods },
                    },
                    [_vm._v("新增")]
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "sortCol",
                    staticStyle: { width: "20px" },
                    model: {
                      value: _vm.addNum,
                      callback: function ($$v) {
                        _vm.addNum = $$v
                      },
                      expression: "addNum",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { on: { click: _vm.createActiveGoods } }, [
                    _vm._v("条"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "post" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.createActiveGoods },
                        },
                        [_vm._v(" 批量导入 ")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "export-file",
                        attrs: { type: "file" },
                        on: {
                          change: function ($event) {
                            return _vm.changeExselAction($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.getFile } },
                    [_vm._v(" 下载导入表格 ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12, offset: 0 } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex justify-b",
              staticStyle: { "font-size": "20px", "font-weight": "500" },
            },
            [_vm._v("货品档案  \n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-table",
                {
                  staticClass: "table",
                  attrs: {
                    data: _vm.tableData.data,
                    size: "small",
                    "highlight-current-row": "",
                    width: "100%",
                    border: "",
                  },
                  on: { "cell-dblclick": _vm.onCellDbclick },
                },
                [
                  _c("template", { slot: "empty" }, [
                    _c("div", { staticClass: "tableEmpty" }, [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tableDiv" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "货品编号",
                      "min-width": "140",
                      prop: "",
                      "search-key": "start_product_no",
                      "search-other-key": "end_product_no",
                      "search-type": "inputPacker",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n        " +
                                _vm._s(scope.row.product_no) +
                                "\n      "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "LOT号",
                      "min-width": "140",
                      prop: "",
                      "search-key": "start_lot",
                      "search-other-key": "end_lot",
                      "search-type": "inputPacker",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n        " + _vm._s(scope.row.lot) + "\n      "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "货品名称",
                      "min-width": "140",
                      prop: "",
                      "search-key": "product_name",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n        " +
                                _vm._s(scope.row.product_name) +
                                "\n      "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "业务员",
                      "min-width": "140",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return scope.row.createAdmin
                              ? [
                                  _vm._v(
                                    "\n        " +
                                      _vm._s(scope.row.createAdmin.real_name) +
                                      "\n      "
                                  ),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "备注",
                      "min-width": "140",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.remark) +
                                  "\n        "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "操作",
                      width: "80",
                      align: "center",
                      "search-clear": true,
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "font-size": "14px" },
                                attrs: { type: "text", size: "small" },
                                on: { click: _vm.onAllCheck },
                              },
                              [_vm._v(" 全选 ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saleGoods(
                                          scope.row,
                                          "goods",
                                          scope.row.entrust_uid
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.goodsIdArr.indexOf(scope.row.id) >
                                            -1
                                            ? "已选择"
                                            : "选择"
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.tempdetail.signature_area_fields_data
            ? _c(
                "div",
                {
                  staticStyle: { width: "0", height: "0", overflow: "hidden" },
                  attrs: { id: "prieview" },
                },
                [
                  _c("preview", {
                    attrs: {
                      inventoryConfig: _vm.inventoryConfig,
                      Catearr: _vm.goodsArr,
                      timeVal: _vm.timeVal,
                      attachmentContent: _vm.attachmentContent,
                      title: _vm.title,
                      contractno: _vm.autocontractno,
                      titleDisponsejson:
                        _vm.tempdetail.title_fields_data.config,
                      signDisponsejson:
                        _vm.tempdetail.signature_area_fields_data.config,
                      expiry_date_switch: _vm.tempdetail.expiry_date_switch,
                      entrust_order_switch: _vm.tempdetail.entrust_order_switch,
                      owndata: _vm.tempdetail.title_fields_data.owndata,
                      otherdata: _vm.tempdetail.title_fields_data.otherdata,
                      ownsigndata:
                        _vm.tempdetail.signature_area_fields_data.ownsigndata,
                      othersigndata:
                        _vm.tempdetail.signature_area_fields_data.othersigndata,
                      content: _vm.content,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c goodslist-page-box" },
            [
              _c(
                "div",
                {
                  staticClass: "flex-one flex",
                  staticStyle: { "margin-left": "20px" },
                },
                [
                  _vm.formValidate.contract_type == 2
                    ? [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              size: "small",
                              type: "primary",
                              loading: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.submitForm("formValidate", "1")
                              },
                            },
                          },
                          [_vm._v("保存")]
                        ),
                        _vm._v(" "),
                        _vm.reductjson.erp_v2_contract == 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  size: "small",
                                  type: "primary",
                                  loading: _vm.loading,
                                },
                                on: { click: _vm.sendreview },
                              },
                              [_vm._v("提交审批")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.reductjson.erp_v2_contract == 0
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  size: "small",
                                  type: "primary",
                                  loading: _vm.loading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitForm("formValidate", "3")
                                  },
                                },
                              },
                              [_vm._v("发起签约")]
                            )
                          : _vm._e(),
                      ]
                    : _vm.formValidate.contract_type == 1
                    ? [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              size: "small",
                              type: "primary",
                              loading: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.submitForm("formValidate", "1")
                              },
                            },
                          },
                          [_vm._v("保存草稿")]
                        ),
                        _vm._v(" "),
                        _vm.reductjson.erp_v2_contract == 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  size: "small",
                                  type: "primary",
                                  loading: _vm.loading,
                                },
                                on: { click: _vm.sendreview },
                              },
                              [_vm._v("提交审批")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.reductjson.erp_v2_contract == 0
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  size: "small",
                                  type: "primary",
                                  loading: _vm.loading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitForm("formValidate", "4")
                                  },
                                },
                              },
                              [_vm._v("确认完成")]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.goback } },
                    [_vm._v("取消操作")]
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, prev, pager, next, sizes",
                  total: _vm.tableData.total,
                  background: "",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "30px" } }),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "发送审批",
                visible: _vm.showreview,
                width: "400px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showreview = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "divBox" },
                [
                  _c(
                    "el-form",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "审批人",
                            "label-width": "120px",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selwidth",
                              attrs: {
                                placeholder: "请选择审批人",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.reviewAduitId,
                                callback: function ($$v) {
                                  _vm.reviewAduitId = $$v
                                },
                                expression: "reviewAduitId",
                              },
                            },
                            _vm._l(_vm.reductjson.adminData, function (item) {
                              return _c("el-option", {
                                key: item.merchant_admin_id,
                                attrs: {
                                  label: item.real_name,
                                  value: item.merchant_admin_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", "label-width": "120px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.cursorsendreview },
                            },
                            [_vm._v("确 定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { attrs: { slot: "footer" }, slot: "footer" }),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "",
                visible: _vm.showVisible,
                width: "750px",
                top: "5vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { "label-position": "top" } },
                [
                  _vm._l(_vm.formArr, function (item, ind) {
                    return _c(
                      "div",
                      { key: ind, staticClass: "formLine" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "15px" },
                            attrs: { label: "输入:" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: {
                                      width: "155px",
                                      "margin-right": "15px",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onChangeSize(item)
                                      },
                                    },
                                    model: {
                                      value: item.long,
                                      callback: function ($$v) {
                                        _vm.$set(item, "long", $$v)
                                      },
                                      expression: "item.long",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("长(L)"),
                                    ]),
                                    _vm._v(" "),
                                    _c("template", { slot: "append" }, [
                                      _vm._v("cm"),
                                    ]),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: {
                                      width: "155px",
                                      "margin-right": "15px",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onChangeSize(item)
                                      },
                                    },
                                    model: {
                                      value: item.width,
                                      callback: function ($$v) {
                                        _vm.$set(item, "width", $$v)
                                      },
                                      expression: "item.width",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("宽(W)"),
                                    ]),
                                    _vm._v(" "),
                                    _c("template", { slot: "append" }, [
                                      _vm._v("cm"),
                                    ]),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: {
                                      width: "155px",
                                      "margin-right": "15px",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onChangeSize(item)
                                      },
                                    },
                                    model: {
                                      value: item.height,
                                      callback: function ($$v) {
                                        _vm.$set(item, "height", $$v)
                                      },
                                      expression: "item.height",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("高(H)"),
                                    ]),
                                    _vm._v(" "),
                                    _c("template", { slot: "append" }, [
                                      _vm._v("cm"),
                                    ]),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: { width: "185px" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onChangeSize(item)
                                      },
                                    },
                                    model: {
                                      value: item.diam,
                                      callback: function ($$v) {
                                        _vm.$set(item, "diam", $$v)
                                      },
                                      expression: "item.diam",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("直径(D)"),
                                    ]),
                                    _vm._v(" "),
                                    _c("template", { slot: "append" }, [
                                      _vm._v("cm"),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "15px" },
                            attrs: { label: "展示:" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _c("el-input", {
                                  staticClass: "flex-one sortCol",
                                  staticStyle: { "margin-right": "5px" },
                                  model: {
                                    value: item.sizeStr,
                                    callback: function ($$v) {
                                      _vm.$set(item, "sizeStr", $$v)
                                    },
                                    expression: "item.sizeStr",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "flex align-items-c" },
                                  [
                                    !item.height && !item.diam
                                      ? _c("div", [_vm._v("平尺小数点:")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !item.height && !item.diam
                                      ? _c(
                                          "el-select",
                                          {
                                            staticClass: "sortCol",
                                            staticStyle: {
                                              height: "36px",
                                              width: "80px",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.onChangeSize(item)
                                              },
                                            },
                                            model: {
                                              value: item.toNum,
                                              callback: function ($$v) {
                                                _vm.$set(item, "toNum", $$v)
                                              },
                                              expression: "item.toNum",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: { value: 0, label: "0位" },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: { value: 1, label: "1位" },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: { value: 2, label: "2位" },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: { value: 3, label: "3位" },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("数量:")]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "sortCol",
                                      staticStyle: {
                                        height: "36px",
                                        width: "40px",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.onChangeSize(item)
                                        },
                                      },
                                      model: {
                                        value: item.vice,
                                        callback: function ($$v) {
                                          _vm.$set(item, "vice", $$v)
                                        },
                                        expression: "item.vice",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-right": "5px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            !item.height && !item.diam
                                              ? "幅"
                                              : "件"
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("el-switch", {
                                      on: {
                                        change: function ($event) {
                                          return _vm.onChangeSize(item)
                                        },
                                      },
                                      model: {
                                        value: item.showVice,
                                        callback: function ($$v) {
                                          _vm.$set(item, "showVice", $$v)
                                        },
                                        expression: "item.showVice",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "text",
                        icon: "el-icon-circle-plus-outline",
                      },
                      on: { click: _vm.onAdd },
                    },
                    [_vm._v("继续增加")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex justify-e" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看",
            visible: _vm.showImage,
            width: "750px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImage = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-c" }, [
            _c("img", {
              staticStyle: { width: "700px" },
              attrs: { src: _vm.showImageUrl },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }