"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _aiMark = require("@/api/aiMark");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      routerUrl: '1',
      ruleData: {},
      voiceList: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _aiMark.getMarketMediaConfigApi)().then(function (res) {
      // console.log(res);
      _this.ruleData = res.data;
    });
    (0, _aiMark.iceVideoVoiceListApi)().then(function (res) {
      _this.voiceList = res.data.list;
    });
  },
  methods: {
    changeTab: function changeTab() {},
    saveAction: function saveAction() {
      var _this2 = this;
      (0, _aiMark.saveMarketMediaConfigApi)(this.ruleData).then(function (res) {
        _this2.$message.success('设置成功');
      });
    }
  }
};