"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      setTitleData: {
        zh_cn: '',
        zh_tw: '',
        en: ''
      },
      showSetTitle: false
    };
  },
  methods: {
    init: function init(data) {
      this.setTitleData = {
        zh_cn: data.zh_cn,
        zh_tw: data.zh_tw,
        en: data.en
      };
      this.showSetTitle = true;
    },
    saveTitleText: function saveTitleText() {
      this.$emit('save', Object.assign({}, this.setTitleData));
      this.showSetTitle = false;
    }
  }
};