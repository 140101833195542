var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelBox" },
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox", staticStyle: { top: "-52px" } },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-circle-plus-outline" },
                on: { click: _vm.addAccount },
              },
              [_vm._v("添加账号")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "danger", icon: "el-icon-refresh" },
                on: { click: _vm.checkAllLoginStatus },
              },
              [_vm._v("一键检测登录状态")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "promotionBox",
          staticStyle: { height: "calc(100vh - 200px)" },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              attrs: { border: "", height: "100%", data: _vm.resultData },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { width: "30", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            staticClass: "tabPop",
                            attrs: {
                              placement: "top-start",
                              width: "100",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "spBlock onHand",
                                  class: { check: _vm.chkName === "dan" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onHandle("dan", scope.$index)
                                    },
                                  },
                                },
                                [_vm._v("选中本页")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-checkbox", {
                              attrs: {
                                slot: "reference",
                                value:
                                  (_vm.chkName === "dan" &&
                                    _vm.checkedPage.indexOf(
                                      _vm.tableFrom.page
                                    ) > -1) ||
                                  _vm.chkName === "duo",
                              },
                              on: { change: _vm.changeType },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: {
                            value:
                              _vm.checkedIds.indexOf(scope.row.id) > -1 ||
                              (_vm.chkName === "duo" &&
                                _vm.noChecked.indexOf(scope.row.id) === -1),
                          },
                          on: {
                            change: function (v) {
                              return _vm.changeOne(v, scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "80",
                  align: "center",
                  label: "序号",
                },
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "chan1",
                attrs: {
                  label: "账号名称",
                  width: "300",
                  prop: "",
                  "search-key": "account_name",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "account-box" }, [
                          _c("div", { staticClass: "account-img" }, [
                            _c("img", {
                              staticStyle: {
                                width: "45px",
                                height: "45px",
                                "border-radius": "50%",
                              },
                              attrs: { src: scope.row.account_avatar, alt: "" },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "margin-left": "8px" } }, [
                            _c("div", [_vm._v(_vm._s(scope.row.account_name))]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(scope.row.account_unique)),
                            ]),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "login",
                attrs: {
                  label: "登录状态",
                  width: "150",
                  prop: "",
                  "search-key": "account_login_status",
                  "search-type": "radio",
                  "search-query": _vm.searchQuery,
                  options: [
                    { label: "登录成功", value: 1 },
                    { label: "登录失效", value: 0 },
                  ],
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "login-status",
                            style:
                              "" +
                              (scope.row.account_login_status != 1
                                ? "cursor:pointer"
                                : ""),
                            on: {
                              click: function ($event) {
                                return _vm.refreshLogin(
                                  scope.row.account_login_status,
                                  scope.row.channels_type
                                )
                              },
                            },
                          },
                          [
                            scope.row.account_login_status == 1
                              ? _c("div", { staticClass: "status-dot-green" })
                              : _c("div", { staticClass: "status-dot-red" }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                style:
                                  "color: " +
                                  (scope.row.account_login_status == 1
                                    ? "#00c05c"
                                    : "#ea3636"),
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.account_login_status == 1
                                      ? "登录成功"
                                      : " 重新登录"
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "chan2",
                attrs: {
                  label: "平台",
                  width: "120",
                  prop: "",
                  "search-key": "channels_type",
                  "search-type": "radio",
                  "search-query": _vm.searchQuery,
                  options: [
                    { label: "抖音", value: "douyin" },
                    { label: "视频号", value: "tencent" },
                  ],
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.channelsTypeText)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "account_bind_type",
                attrs: {
                  label: "内容类型",
                  width: "150",
                  prop: "",
                  "search-query": _vm.searchQuery,
                  "search-key": "account_bind_type",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.accountBindTypeText)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "account_bind_label",
                attrs: {
                  label: "账号标签",
                  "min-width": "100",
                  "search-key": "account_bind_label",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.account_bind_label)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "release_method",
                attrs: {
                  label: "发布方式",
                  width: "120",
                  "search-key": "release_method",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.releaseMethodText)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "运营人",
                  width: "130",
                  prop: "",
                  "search-key": "manager_admin_keyword",
                  "search-type": "radio",
                  options: _vm.searchAdminarr,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.managerAdmin
                          ? _c("span", [
                              _vm._v(_vm._s(scope.row.managerAdmin.real_name)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "chan11",
                attrs: {
                  label: "",
                  width: "100",
                  align: "center",
                  fixed: "right",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onTopinfo(scope.row)
                              },
                            },
                          },
                          [_vm._v("置顶")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onUpdHand(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.addtagsFlag
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "设置标签",
                visible: _vm.addtagsFlag,
                "close-on-click-modal": false,
                width: "300px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.addtagsFlag = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  attrs: { "label-width": "80px" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入标签",
                          size: "normal",
                          clearable: "",
                        },
                        model: {
                          value: _vm.batchInputTag,
                          callback: function ($$v) {
                            _vm.batchInputTag = $$v
                          },
                          expression: "batchInputTag",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.addtagsFlag = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.cursoraddTag },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.editmanagerFlag
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "修改运营人",
                visible: _vm.editmanagerFlag,
                "close-on-click-modal": false,
                width: "300px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.editmanagerFlag = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  attrs: { "label-width": "80px" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "运营人：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.batchSelectmanager,
                            callback: function ($$v) {
                              _vm.batchSelectmanager = $$v
                            },
                            expression: "batchSelectmanager",
                          },
                        },
                        _vm._l(_vm.adminarr, function (item) {
                          return _c("el-option", {
                            key: item.merchant_admin_id,
                            attrs: {
                              label: item.real_name,
                              value: item.merchant_admin_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.editmanagerFlag = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.cursorEditmanager },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-b orderFoot" },
        [
          _c(
            "div",
            { staticClass: "flex-one" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "4px" },
                  attrs: {
                    disabled: this.checkedIds.length == 0,
                    type: "primary",
                    size: "small",
                  },
                  on: { click: _vm.batchAddtag },
                },
                [_vm._v("加账号标签")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: this.checkedIds.length == 0,
                    type: "primary",
                    size: "small",
                  },
                  on: { click: _vm.batchEditmanager },
                },
                [_vm._v("改运营人")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableFrom.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "800px",
            visible: _vm.showpromotionAccountDraw,
            "with-header": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showpromotionAccountDraw = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "user-info" },
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo default-tabs mt10",
                  attrs: { "default-active": "1", mode: "horizontal" },
                  on: { select: _vm.changeTab },
                },
                [
                  _c("el-menu-item", { attrs: { index: "1" } }, [
                    _vm._v("基本信息"),
                  ]),
                  _vm._v(" "),
                  _c("el-menu-item", { attrs: { index: "2" } }, [
                    _vm._v("绑定信息"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "close",
                  on: {
                    click: function ($event) {
                      _vm.showpromotionAccountDraw = false
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/image/20220301/362f7b7322403e49853f206b02a8eaab.png",
                      alt: "",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm.userStatus == 1
                ? [
                    _c("div", { staticClass: "user-info-box" }, [
                      _vm.promotionAccountUserInfo.account_avatar
                        ? _c("div", { staticClass: "user-info-image" }, [
                            _c("img", {
                              staticClass: "user-avatar",
                              attrs: {
                                src: _vm.promotionAccountUserInfo
                                  .account_avatar,
                                alt: "",
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "user-info-right" }, [
                        _c("div", { staticClass: "use-info-name" }, [
                          _vm._v(
                            _vm._s(_vm.promotionAccountUserInfo.account_name)
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "use-info-id",
                            staticStyle: { color: "#708295" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.promotionAccountUserInfo.account_unique
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "setting-table-box mt-30" }, [
                      _c("div", { staticClass: "setting-table-list" }, [
                        _c("div", { staticClass: "setting-table-title" }, [
                          _vm._v("账号别名"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "setting-table-content" },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { placeholder: "请输入别名" },
                              model: {
                                value: _vm.promotionAccount.account_alias,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.promotionAccount,
                                    "account_alias",
                                    $$v
                                  )
                                },
                                expression: "promotionAccount.account_alias",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "setting-table-list" }, [
                        _c("div", { staticClass: "setting-table-title" }, [
                          _vm._v("运营负责人"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "setting-table-content" },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  placeholder: "请选择运营负责人",
                                  clearable: "",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.promotionAccount.manager_admin_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.promotionAccount,
                                      "manager_admin_id",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "promotionAccount.manager_admin_id",
                                },
                              },
                              _vm._l(_vm.adminarr, function (item) {
                                return _c("el-option", {
                                  key: item.merchant_admin_id,
                                  attrs: {
                                    label: item.real_name,
                                    value: item.merchant_admin_id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "setting-table-list" }, [
                        _c("div", { staticClass: "setting-table-title" }, [
                          _vm._v("账号标签"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "setting-table-content" },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { placeholder: "请输入账号标签" },
                              model: {
                                value: _vm.promotionAccount.account_bind_label,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.promotionAccount,
                                    "account_bind_label",
                                    $$v
                                  )
                                },
                                expression:
                                  "promotionAccount.account_bind_label",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "setting-table-list" }, [
                        _c("div", { staticClass: "setting-table-title" }, [
                          _vm._v("账号状态"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "setting-table-content" }, [
                          _c(
                            "div",
                            { staticStyle: { "padding-left": "10px" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "login-status",
                                  style:
                                    "" +
                                    (_vm.promotionAccount.status != 1
                                      ? "cursor:pointer"
                                      : ""),
                                  on: {
                                    click: function ($event) {
                                      return _vm.refreshLogin(
                                        _vm.promotionAccount.status,
                                        _vm.promotionAccount.channels_type
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.promotionAccount.status == 1
                                    ? _c("div", {
                                        staticClass: "status-dot-green",
                                      })
                                    : _c("div", {
                                        staticClass: "status-dot-red",
                                      }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      style:
                                        "color: " +
                                        (_vm.promotionAccount.status == 1
                                          ? "#00c05c"
                                          : "#ea3636"),
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.promotionAccount.status == 1
                                            ? "登录成功"
                                            : " 重新登录"
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "setting-table-box mt-30" }, [
                      _c("div", { staticClass: "setting-table-list" }, [
                        _c("div", { staticClass: "setting-table-title" }, [
                          _vm._v("账号类型"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "setting-table-content" },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                staticStyle: { "padding-left": "10px" },
                                model: {
                                  value: _vm.bindcheckTypearr,
                                  callback: function ($$v) {
                                    _vm.bindcheckTypearr = $$v
                                  },
                                  expression: "bindcheckTypearr",
                                },
                              },
                              [
                                _c("el-checkbox", { attrs: { label: "1" } }, [
                                  _vm._v("图文"),
                                ]),
                                _vm._v(" "),
                                _c("el-checkbox", { attrs: { label: "2" } }, [
                                  _vm._v("横版视频"),
                                ]),
                                _vm._v(" "),
                                _c("el-checkbox", { attrs: { label: "3" } }, [
                                  _vm._v("竖版视频"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "setting-table-list" }, [
                        _c("div", { staticClass: "setting-table-title" }, [
                          _vm._v("图文话题预设"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "setting-table-content" },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  "allow-create": "",
                                  placeholder: "图文话题",
                                  clearable: "",
                                  filterable: "",
                                  multiple: "",
                                  "multiple-limit": 5,
                                },
                                model: {
                                  value: _vm.tweenTopic,
                                  callback: function ($$v) {
                                    _vm.tweenTopic = $$v
                                  },
                                  expression: "tweenTopic",
                                },
                              },
                              _vm._l(_vm.tweenTopicarr, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "setting-table-content-num" },
                              [
                                _vm._v(
                                  _vm._s(_vm.tweenTopic.length || 0) + "/5"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "setting-table-list" }, [
                        _c("div", { staticClass: "setting-table-title" }, [
                          _vm._v("横板视频话题预设"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "setting-table-content" },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  "allow-create": "",
                                  placeholder: "横板视频话题",
                                  clearable: "",
                                  filterable: "",
                                  multiple: "",
                                  "multiple-limit": 5,
                                },
                                model: {
                                  value: _vm.rowvideoTopic,
                                  callback: function ($$v) {
                                    _vm.rowvideoTopic = $$v
                                  },
                                  expression: "rowvideoTopic",
                                },
                              },
                              _vm._l(_vm.rowvideoTopicarr, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "setting-table-content-num" },
                              [
                                _vm._v(
                                  _vm._s(_vm.rowvideoTopic.length || 0) + "/5"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "setting-table-list" }, [
                        _c("div", { staticClass: "setting-table-title" }, [
                          _vm._v("竖版视频话题预设"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "setting-table-content" },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  "allow-create": "",
                                  placeholder: "竖版视频话题",
                                  clearable: "",
                                  filterable: "",
                                  multiple: "",
                                  "multiple-limit": 5,
                                },
                                model: {
                                  value: _vm.columnvideoTopic,
                                  callback: function ($$v) {
                                    _vm.columnvideoTopic = $$v
                                  },
                                  expression: "columnvideoTopic",
                                },
                              },
                              _vm._l(_vm.columnvideoTopicarr, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "setting-table-content-num" },
                              [
                                _vm._v(
                                  _vm._s(_vm.columnvideoTopic.length || 0) +
                                    "/5"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "user-setting-sign",
                        staticStyle: { "margin-top": "10px" },
                      },
                      [
                        _vm._v("\n          您可添加"),
                        _c(
                          "span",
                          { staticStyle: { color: "rgb(255, 61, 61)" } },
                          [_vm._v("5")]
                        ),
                        _vm._v("个标签，每个标签不可以超过"),
                        _c(
                          "span",
                          { staticStyle: { color: "rgb(255, 61, 61)" } },
                          [_vm._v("50")]
                        ),
                        _vm._v("个字，按回车键确认\n        "),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "setting-table-box mt-30" }, [
                      _c("div", { staticClass: "setting-table-list" }, [
                        _c("div", { staticClass: "setting-table-title" }, [
                          _vm._v("发布方式"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "setting-table-content" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                staticStyle: { "padding-left": "10px" },
                                model: {
                                  value: _vm.promotionAccount.release_method,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.promotionAccount,
                                      "release_method",
                                      $$v
                                    )
                                  },
                                  expression: "promotionAccount.release_method",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "0" } }, [
                                  _vm._v("人工半自动"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: "1" } }, [
                                  _vm._v("定时全自动"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.userStatus == 2
                ? [
                    _c("div", { staticClass: "setting-table-box" }, [
                      _c("div", { staticClass: "setting-table-list" }, [
                        _c("div", { staticClass: "setting-table-title" }, [
                          _vm._v("账号"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "setting-table-content" },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { placeholder: "请输入账号" },
                              model: {
                                value:
                                  _vm.promotionAccount.account_bind_info
                                    .account,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.promotionAccount.account_bind_info,
                                    "account",
                                    $$v
                                  )
                                },
                                expression:
                                  "promotionAccount.account_bind_info.account",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "setting-table-list" }, [
                        _c("div", { staticClass: "setting-table-title" }, [
                          _vm._v("密码"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "setting-table-content" },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { placeholder: "请输入密码" },
                              model: {
                                value:
                                  _vm.promotionAccount.account_bind_info
                                    .password,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.promotionAccount.account_bind_info,
                                    "password",
                                    $$v
                                  )
                                },
                                expression:
                                  "promotionAccount.account_bind_info.password",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "setting-table-list" }, [
                        _c("div", { staticClass: "setting-table-title" }, [
                          _vm._v("绑定手机"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "setting-table-content" },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { placeholder: "请输入绑定手机" },
                              model: {
                                value:
                                  _vm.promotionAccount.account_bind_info.phone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.promotionAccount.account_bind_info,
                                    "phone",
                                    $$v
                                  )
                                },
                                expression:
                                  "promotionAccount.account_bind_info.phone",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "setting-table-list" }, [
                        _c("div", { staticClass: "setting-table-title" }, [
                          _vm._v("绑定邮箱"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "setting-table-content" },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { placeholder: "请输入绑定邮箱" },
                              model: {
                                value:
                                  _vm.promotionAccount.account_bind_info.email,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.promotionAccount.account_bind_info,
                                    "email",
                                    $$v
                                  )
                                },
                                expression:
                                  "promotionAccount.account_bind_info.email",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticStyle: { height: "80px" } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "user-info-bottombtnbox" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "default" },
                      on: { click: _vm.saveEditinfo },
                    },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", plain: "", size: "default" },
                      on: { click: _vm.onDeleHand },
                    },
                    [_vm._v("删除账号")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }