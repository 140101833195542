var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "breadcrumb flex align-items-c",
        class: { fixed: _vm.isFixed, "fixed-menu": _vm.isMenuFixed },
      },
      [
        _c("div", { staticClass: "icon" }, [
          _vm.hasBack
            ? _c("img", {
                staticClass: "back",
                attrs: {
                  src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024529/image/1716954194860207587.png!120a",
                  alt: "返回",
                },
                on: { click: _vm.backAction },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-one pageName" },
          [_vm._v(_vm._s(_vm.name) + " "), _vm._t("leftHander")],
          2
        ),
        _vm._v(" "),
        _vm._t("btn"),
      ],
      2
    ),
    _vm._v(" "),
    _vm.isFixed ? _c("div", { staticClass: "breadcrumb unset" }) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }