var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "posterMain" }, [
        _c("div", { staticClass: "flex posterHead" }, [
          _c(
            "div",
            { staticClass: "genePosterBox", on: { click: _vm.onOpenPoster } },
            [
              _c("div", { staticClass: "poster-tit" }, [_vm._v("生成海报")]),
              _vm._v(" "),
              _c("div", { staticClass: "poster-con" }, [
                _vm._v("海报AI生成 即刻获得"),
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "poster-img",
                attrs: { src: require("@/assets/images/wei.png"), alt: "" },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "genItem meSquare",
              on: { click: _vm.onOpenModule },
            },
            [
              _c(
                "div",
                { staticClass: "poster-tit", staticStyle: { color: "#fff" } },
                [_vm._v("模板广场")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "poster-con", staticStyle: { color: "#fff" } },
                [_vm._v("海量模板任您挑选")]
              ),
              _vm._v(" "),
              _vm._m(2),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm._m(3),
      ]),
      _vm._v(" "),
      _c("createPoster", { ref: "createPosterRef" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "iconImgBox" }, [
      _c("img", {
        staticClass: "posterImg",
        attrs: { src: require("@/assets/images/mbang.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "genItem meTemplate" }, [
      _c("div", { staticClass: "poster-tit", staticStyle: { color: "#fff" } }, [
        _vm._v("我的模板"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "poster-con", staticStyle: { color: "#fff" } }, [
        _vm._v("管理我的模板库"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "iconImgBox", staticStyle: { background: "#171818" } },
        [
          _c("img", {
            staticClass: "posterImg",
            attrs: { src: require("@/assets/images/mb.png"), alt: "" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "iconImgBox", staticStyle: { background: "#171818" } },
      [
        _c("img", {
          staticClass: "posterImg",
          attrs: { src: require("@/assets/images/fax.png"), alt: "" },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "posterpp" }, [
      _c("div", { staticClass: "posterpp-tit" }, [_vm._v("模板广场")]),
      _vm._v(" "),
      _c("div", { staticClass: "posterpp-con" }, [_vm._v("3分钟做一张海报")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }