"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var areaOtherList = [{
  "initial": "A",
  "areaCodeInitialVOS": [{
    "code": "EG",
    "name": "埃及",
    "isLast": true
  }, {
    "code": "ET",
    "name": "埃塞俄比亚",
    "isLast": true
  }, {
    "code": "AX",
    "name": "奥兰群岛",
    "isLast": true
  }, {
    "code": "AT",
    "name": "奥地利",
    "isLast": true
  }, {
    "code": "AO",
    "name": "安哥拉",
    "isLast": true
  }, {
    "code": "AI",
    "name": "安圭拉",
    "isLast": true
  }, {
    "code": "AG",
    "name": "安提瓜和巴布达",
    "isLast": true
  }, {
    "code": "AD",
    "name": "安道尔",
    "isLast": true
  }, {
    "code": "AU",
    "name": "澳大利亚",
    "isLast": true
  }, {
    "code": "IE",
    "name": "爱尔兰",
    "isLast": true
  }, {
    "code": "EE",
    "name": "爱沙尼亚",
    "isLast": true
  }, {
    "code": "AZ",
    "name": "阿塞拜疆",
    "isLast": true
  }, {
    "code": "AF",
    "name": "阿富汗",
    "isLast": true
  }, {
    "code": "DZ",
    "name": "阿尔及利亚",
    "isLast": true
  }, {
    "code": "AL",
    "name": "阿尔巴尼亚",
    "isLast": true
  }, {
    "code": "OM",
    "name": "阿曼",
    "isLast": true
  }, {
    "code": "AR",
    "name": "阿根廷",
    "isLast": true
  }, {
    "code": "AE",
    "name": "阿联酋",
    "isLast": true
  }, {
    "code": "AW",
    "name": "阿鲁巴",
    "isLast": true
  }]
}, {
  "initial": "B",
  "areaCodeInitialVOS": [{
    "code": "BT",
    "name": "不丹",
    "isLast": true
  }, {
    "code": "BZ",
    "name": "伯利兹",
    "isLast": true
  }, {
    "code": "BG",
    "name": "保加利亚",
    "isLast": true
  }, {
    "code": "IS",
    "name": "冰岛",
    "isLast": true
  }, {
    "code": "MK",
    "name": "北马其顿",
    "isLast": true
  }, {
    "code": "MP",
    "name": "北马里亚纳",
    "isLast": true
  }, {
    "code": "BW",
    "name": "博茨瓦纳",
    "isLast": true
  }, {
    "code": "PS",
    "name": "巴勒斯坦",
    "isLast": true
  }, {
    "code": "BS",
    "name": "巴哈马",
    "isLast": true
  }, {
    "code": "PK",
    "name": "巴基斯坦",
    "isLast": true
  }, {
    "code": "BB",
    "name": "巴巴多斯",
    "isLast": true
  }, {
    "code": "PG",
    "name": "巴布亚新几内亚",
    "isLast": true
  }, {
    "code": "PY",
    "name": "巴拉圭",
    "isLast": true
  }, {
    "code": "PA",
    "name": "巴拿马",
    "isLast": true
  }, {
    "code": "BH",
    "name": "巴林",
    "isLast": true
  }, {
    "code": "BR",
    "name": "巴西",
    "isLast": true
  }, {
    "code": "BF",
    "name": "布基纳法索",
    "isLast": true
  }, {
    "code": "BV",
    "name": "布维岛",
    "isLast": true
  }, {
    "code": "BI",
    "name": "布隆迪",
    "isLast": true
  }, {
    "code": "BE",
    "name": "比利时",
    "isLast": true
  }, {
    "code": "PL",
    "name": "波兰",
    "isLast": true
  }, {
    "code": "PR",
    "name": "波多黎各",
    "isLast": true
  }, {
    "code": "BA",
    "name": "波黑",
    "isLast": true
  }, {
    "code": "BO",
    "name": "玻利维亚",
    "isLast": true
  }, {
    "code": "BY",
    "name": "白俄罗斯",
    "isLast": true
  }, {
    "code": "BM",
    "name": "百慕大",
    "isLast": true
  }, {
    "code": "BJ",
    "name": "贝宁",
    "isLast": true
  }]
}, {
  "initial": "C",
  "areaCodeInitialVOS": [{
    "code": "KP",
    "name": "朝鲜",
    "isLast": true
  }, {
    "code": "GQ",
    "name": "赤道几内亚",
    "isLast": true
  }]
}, {
  "initial": "D",
  "areaCodeInitialVOS": [{
    "code": "TL",
    "name": "东帝汶",
    "isLast": true
  }, {
    "code": "DK",
    "name": "丹麦",
    "isLast": true
  }, {
    "code": "TG",
    "name": "多哥",
    "isLast": true
  }, {
    "code": "DM",
    "name": "多米尼克",
    "isLast": true
  }, {
    "code": "DO",
    "name": "多米尼加",
    "isLast": true
  }, {
    "code": "DE",
    "name": "德国",
    "isLast": true
  }]
}, {
  "initial": "E",
  "areaCodeInitialVOS": [{
    "code": "RU",
    "name": "俄罗斯联邦",
    "isLast": true
  }, {
    "code": "EC",
    "name": "厄瓜多尔",
    "isLast": true
  }, {
    "code": "ER",
    "name": "厄立特里亚",
    "isLast": true
  }]
}, {
  "initial": "F",
  "areaCodeInitialVOS": [{
    "code": "CV",
    "name": "佛得角",
    "isLast": true
  }, {
    "code": "FJ",
    "name": "斐济",
    "isLast": true
  }, {
    "code": "VA",
    "name": "梵蒂冈",
    "isLast": true
  }, {
    "code": "FR",
    "name": "法国",
    "isLast": true
  }, {
    "code": "TF",
    "name": "法属南部领地",
    "isLast": true
  }, {
    "code": "GF",
    "name": "法属圭亚那",
    "isLast": true
  }, {
    "code": "PF",
    "name": "法属波利尼西亚",
    "isLast": true
  }, {
    "code": "FO",
    "name": "法罗群岛",
    "isLast": true
  }, {
    "code": "FK",
    "name": "福克兰群岛（马尔维纳斯）",
    "isLast": true
  }, {
    "code": "FI",
    "name": "芬兰",
    "isLast": true
  }, {
    "code": "PH",
    "name": "菲律宾",
    "isLast": true
  }]
}, {
  "initial": "G",
  "areaCodeInitialVOS": [{
    "code": "GU",
    "name": "关岛",
    "isLast": true
  }, {
    "code": "GM",
    "name": "冈比亚",
    "isLast": true
  }, {
    "code": "CG",
    "name": "刚果（布）",
    "isLast": true
  }, {
    "code": "CD",
    "name": "刚果（金）",
    "isLast": true
  }, {
    "code": "CU",
    "name": "古巴",
    "isLast": true
  }, {
    "code": "CO",
    "name": "哥伦比亚",
    "isLast": true
  }, {
    "code": "CR",
    "name": "哥斯达黎加",
    "isLast": true
  }, {
    "code": "GY",
    "name": "圭亚那",
    "isLast": true
  }, {
    "code": "GG",
    "name": "格恩西岛",
    "isLast": true
  }, {
    "code": "GD",
    "name": "格林纳达",
    "isLast": true
  }, {
    "code": "GL",
    "name": "格陵兰",
    "isLast": true
  }, {
    "code": "GE",
    "name": "格鲁吉亚",
    "isLast": true
  }, {
    "code": "GP",
    "name": "瓜德罗普",
    "isLast": true
  }]
}, {
  "initial": "H",
  "areaCodeInitialVOS": [{
    "code": "KZ",
    "name": "哈萨克斯坦",
    "isLast": true
  }, {
    "code": "HN",
    "name": "洪都拉斯",
    "isLast": true
  }, {
    "code": "HT",
    "name": "海地",
    "isLast": true
  }, {
    "code": "NL",
    "name": "荷兰",
    "isLast": true
  }, {
    "code": "AN",
    "name": "荷属安的列斯",
    "isLast": true
  }, {
    "code": "HM",
    "name": "赫德岛和麦克唐纳岛",
    "isLast": true
  }, {
    "code": "KR",
    "name": "韩国",
    "isLast": true
  }, {
    "code": "ME",
    "name": "黑山",
    "isLast": true
  }]
}, {
  "initial": "J",
  "areaCodeInitialVOS": [{
    "code": "GN",
    "name": "几内亚",
    "isLast": true
  }, {
    "code": "GW",
    "name": "几内亚比绍",
    "isLast": true
  }, {
    "code": "CA",
    "name": "加拿大",
    "isLast": true
  }, {
    "code": "GH",
    "name": "加纳",
    "isLast": true
  }, {
    "code": "GA",
    "name": "加蓬",
    "isLast": true
  }, {
    "code": "KG",
    "name": "吉尔吉斯斯坦",
    "isLast": true
  }, {
    "code": "DJ",
    "name": "吉布提",
    "isLast": true
  }, {
    "code": "KI",
    "name": "基里巴斯",
    "isLast": true
  }, {
    "code": "CZ",
    "name": "捷克",
    "isLast": true
  }, {
    "code": "KH",
    "name": "柬埔寨",
    "isLast": true
  }, {
    "code": "ZW",
    "name": "津巴布韦",
    "isLast": true
  }]
}, {
  "initial": "K",
  "areaCodeInitialVOS": [{
    "code": "HR",
    "name": "克罗地亚",
    "isLast": true
  }, {
    "code": "QA",
    "name": "卡塔尔",
    "isLast": true
  }, {
    "code": "CM",
    "name": "喀麦隆",
    "isLast": true
  }, {
    "code": "CK",
    "name": "库克群岛",
    "isLast": true
  }, {
    "code": "KY",
    "name": "开曼群岛",
    "isLast": true
  }, {
    "code": "KW",
    "name": "科威特",
    "isLast": true
  }, {
    "code": "KM",
    "name": "科摩罗",
    "isLast": true
  }, {
    "code": "CI",
    "name": "科特迪瓦",
    "isLast": true
  }, {
    "code": "CC",
    "name": "科科斯（基林）群岛",
    "isLast": true
  }, {
    "code": "KE",
    "name": "肯尼亚",
    "isLast": true
  }]
}, {
  "initial": "L",
  "areaCodeInitialVOS": [{
    "code": "LI",
    "name": "列支敦士登",
    "isLast": true
  }, {
    "code": "LY",
    "name": "利比亚",
    "isLast": true
  }, {
    "code": "LR",
    "name": "利比里亚",
    "isLast": true
  }, {
    "code": "RW",
    "name": "卢旺达",
    "isLast": true
  }, {
    "code": "LU",
    "name": "卢森堡",
    "isLast": true
  }, {
    "code": "LV",
    "name": "拉脱维亚",
    "isLast": true
  }, {
    "code": "RE",
    "name": "留尼汪",
    "isLast": true
  }, {
    "code": "LT",
    "name": "立陶宛",
    "isLast": true
  }, {
    "code": "RO",
    "name": "罗马尼亚",
    "isLast": true
  }, {
    "code": "LA",
    "name": "老挝",
    "isLast": true
  }, {
    "code": "LS",
    "name": "莱索托",
    "isLast": true
  }, {
    "code": "LB",
    "name": "黎巴嫩",
    "isLast": true
  }]
}, {
  "initial": "M",
  "areaCodeInitialVOS": [{
    "code": "MX",
    "name": "墨西哥",
    "isLast": true
  }, {
    "code": "BD",
    "name": "孟加拉国",
    "isLast": true
  }, {
    "code": "FM",
    "name": "密克罗尼西亚联邦",
    "isLast": true
  }, {
    "code": "MD",
    "name": "摩尔多瓦",
    "isLast": true
  }, {
    "code": "MA",
    "name": "摩洛哥",
    "isLast": true
  }, {
    "code": "MC",
    "name": "摩纳哥",
    "isLast": true
  }, {
    "code": "MR",
    "name": "毛利塔尼亚",
    "isLast": true
  }, {
    "code": "MU",
    "name": "毛里求斯",
    "isLast": true
  }, {
    "code": "PE",
    "name": "秘鲁",
    "isLast": true
  }, {
    "code": "MM",
    "name": "缅甸",
    "isLast": true
  }, {
    "code": "US",
    "name": "美国",
    "isLast": true
  }, {
    "code": "UM",
    "name": "美国本土外小岛屿",
    "isLast": true
  }, {
    "code": "VI",
    "name": "美属维尔京群岛",
    "isLast": true
  }, {
    "code": "AS",
    "name": "美属萨摩亚",
    "isLast": true
  }, {
    "code": "MZ",
    "name": "莫桑比克",
    "isLast": true
  }, {
    "code": "MN",
    "name": "蒙古",
    "isLast": true
  }, {
    "code": "MS",
    "name": "蒙特塞拉特",
    "isLast": true
  }, {
    "code": "MV",
    "name": "马尔代夫",
    "isLast": true
  }, {
    "code": "MW",
    "name": "马拉维",
    "isLast": true
  }, {
    "code": "MQ",
    "name": "马提尼克",
    "isLast": true
  }, {
    "code": "MY",
    "name": "马来西亚",
    "isLast": true
  }, {
    "code": "YT",
    "name": "马约特",
    "isLast": true
  }, {
    "code": "MH",
    "name": "马绍尔群岛",
    "isLast": true
  }, {
    "code": "MT",
    "name": "马耳他",
    "isLast": true
  }, {
    "code": "MG",
    "name": "马达加斯加",
    "isLast": true
  }, {
    "code": "ML",
    "name": "马里",
    "isLast": true
  }]
}, {
  "initial": "N",
  "areaCodeInitialVOS": [{
    "code": "GS",
    "name": "南乔治亚岛和南桑德韦奇岛",
    "isLast": true
  }, {
    "code": "AQ",
    "name": "南极洲",
    "isLast": true
  }, {
    "code": "ZA",
    "name": "南非",
    "isLast": true
  }, {
    "code": "NI",
    "name": "尼加拉瓜",
    "isLast": true
  }, {
    "code": "NG",
    "name": "尼日利亚",
    "isLast": true
  }, {
    "code": "NE",
    "name": "尼日尔",
    "isLast": true
  }, {
    "code": "NP",
    "name": "尼泊尔",
    "isLast": true
  }, {
    "code": "NO",
    "name": "挪威",
    "isLast": true
  }, {
    "code": "NR",
    "name": "瑙鲁",
    "isLast": true
  }, {
    "code": "NA",
    "name": "纳米比亚",
    "isLast": true
  }, {
    "code": "NU",
    "name": "纽埃",
    "isLast": true
  }, {
    "code": "NF",
    "name": "诺福克岛",
    "isLast": true
  }]
}, {
  "initial": "P",
  "areaCodeInitialVOS": [{
    "code": "PW",
    "name": "帕劳",
    "isLast": true
  }, {
    "code": "PN",
    "name": "皮特凯恩",
    "isLast": true
  }, {
    "code": "PT",
    "name": "葡萄牙",
    "isLast": true
  }]
}, {
  "initial": "R",
  "areaCodeInitialVOS": [{
    "code": "JP",
    "name": "日本",
    "isLast": true
  }, {
    "code": "SE",
    "name": "瑞典",
    "isLast": true
  }, {
    "code": "CH",
    "name": "瑞士",
    "isLast": true
  }]
}, {
  "initial": "S",
  "areaCodeInitialVOS": [{
    "code": "LC",
    "name": "圣卢西亚",
    "isLast": true
  }, {
    "code": "KN",
    "name": "圣基茨和尼维斯",
    "isLast": true
  }, {
    "code": "ST",
    "name": "圣多美和普林西比",
    "isLast": true
  }, {
    "code": "VC",
    "name": "圣文森特和格林纳丁斯",
    "isLast": true
  }, {
    "code": "PM",
    "name": "圣皮埃尔和密克隆",
    "isLast": true
  }, {
    "code": "CX",
    "name": "圣诞岛",
    "isLast": true
  }, {
    "code": "SH",
    "name": "圣赫勒拿",
    "isLast": true
  }, {
    "code": "SM",
    "name": "圣马力诺",
    "isLast": true
  }, {
    "code": "SN",
    "name": "塞内加尔",
    "isLast": true
  }, {
    "code": "RS",
    "name": "塞尔维亚",
    "isLast": true
  }, {
    "code": "SL",
    "name": "塞拉利昂",
    "isLast": true
  }, {
    "code": "CY",
    "name": "塞浦路斯",
    "isLast": true
  }, {
    "code": "SC",
    "name": "塞舌尔",
    "isLast": true
  }, {
    "code": "SB",
    "name": "所罗门群岛",
    "isLast": true
  }, {
    "code": "SZ",
    "name": "斯威士兰",
    "isLast": true
  }, {
    "code": "SK",
    "name": "斯洛伐克",
    "isLast": true
  }, {
    "code": "SI",
    "name": "斯洛文尼亚",
    "isLast": true
  }, {
    "code": "SJ",
    "name": "斯瓦尔巴岛和扬马延岛",
    "isLast": true
  }, {
    "code": "LK",
    "name": "斯里兰卡",
    "isLast": true
  }, {
    "code": "SA",
    "name": "沙特阿拉伯",
    "isLast": true
  }, {
    "code": "SO",
    "name": "索马里",
    "isLast": true
  }, {
    "code": "SD",
    "name": "苏丹",
    "isLast": true
  }, {
    "code": "SR",
    "name": "苏里南",
    "isLast": true
  }, {
    "code": "SV",
    "name": "萨尔瓦多",
    "isLast": true
  }, {
    "code": "WS",
    "name": "萨摩亚",
    "isLast": true
  }]
}, {
  "initial": "T",
  "areaCodeInitialVOS": [{
    "code": "TV",
    "name": "图瓦卢",
    "isLast": true
  }, {
    "code": "TM",
    "name": "土库曼斯坦",
    "isLast": true
  }, {
    "code": "TR",
    "name": "土耳其",
    "isLast": true
  }, {
    "code": "TZ",
    "name": "坦桑尼亚",
    "isLast": true
  }, {
    "code": "TJ",
    "name": "塔吉克斯坦",
    "isLast": true
  }, {
    "code": "TK",
    "name": "托克劳",
    "isLast": true
  }, {
    "code": "TO",
    "name": "汤加",
    "isLast": true
  }, {
    "code": "TH",
    "name": "泰国",
    "isLast": true
  }, {
    "code": "TC",
    "name": "特克斯和凯科斯群岛",
    "isLast": true
  }, {
    "code": "TT",
    "name": "特立尼达和多巴哥",
    "isLast": true
  }, {
    "code": "TN",
    "name": "突尼斯",
    "isLast": true
  }]
}, {
  "initial": "W",
  "areaCodeInitialVOS": [{
    "code": "UA",
    "name": "乌克兰",
    "isLast": true
  }, {
    "code": "UZ",
    "name": "乌兹别克斯坦",
    "isLast": true
  }, {
    "code": "UG",
    "name": "乌干达",
    "isLast": true
  }, {
    "code": "UY",
    "name": "乌拉圭",
    "isLast": true
  }, {
    "code": "GT",
    "name": "危地马拉",
    "isLast": true
  }, {
    "code": "VE",
    "name": "委内瑞拉",
    "isLast": true
  }, {
    "code": "BN",
    "name": "文莱",
    "isLast": true
  }, {
    "code": "WF",
    "name": "瓦利斯和富图纳",
    "isLast": true
  }, {
    "code": "VU",
    "name": "瓦努阿图",
    "isLast": true
  }]
}, {
  "initial": "X",
  "areaCodeInitialVOS": [{
    "code": "HU",
    "name": "匈牙利",
    "isLast": true
  }, {
    "code": "SY",
    "name": "叙利亚",
    "isLast": true
  }, {
    "code": "GR",
    "name": "希腊",
    "isLast": true
  }, {
    "code": "SG",
    "name": "新加坡",
    "isLast": true
  }, {
    "code": "NC",
    "name": "新喀里多尼亚",
    "isLast": true
  }, {
    "code": "NZ",
    "name": "新西兰",
    "isLast": true
  }, {
    "code": "EH",
    "name": "西撒哈拉",
    "isLast": true
  }, {
    "code": "ES",
    "name": "西班牙",
    "isLast": true
  }]
}, {
  "initial": "Y",
  "areaCodeInitialVOS": [{
    "code": "YE",
    "name": "也门",
    "isLast": true
  }, {
    "code": "AM",
    "name": "亚美尼亚",
    "isLast": true
  }, {
    "code": "IL",
    "name": "以色列",
    "isLast": true
  }, {
    "code": "IQ",
    "name": "伊拉克",
    "isLast": true
  }, {
    "code": "IR",
    "name": "伊朗",
    "isLast": true
  }, {
    "code": "IN",
    "name": "印度",
    "isLast": true
  }, {
    "code": "ID",
    "name": "印度尼西亚",
    "isLast": true
  }, {
    "code": "IT",
    "name": "意大利",
    "isLast": true
  }, {
    "code": "JM",
    "name": "牙买加",
    "isLast": true
  }, {
    "code": "JO",
    "name": "约旦",
    "isLast": true
  }, {
    "code": "GB",
    "name": "英国",
    "isLast": true
  }, {
    "code": "IM",
    "name": "英国属地曼岛",
    "isLast": true
  }, {
    "code": "IO",
    "name": "英属印度洋领地",
    "isLast": true
  }, {
    "code": "VG",
    "name": "英属维尔京群岛",
    "isLast": true
  }, {
    "code": "VN",
    "name": "越南",
    "isLast": true
  }]
}, {
  "initial": "Z",
  "areaCodeInitialVOS": [{
    "code": "TW",
    "name": "中国台湾",
    "isLast": true
  }, {
    "code": "MO",
    "name": "中国澳门",
    "isLast": true
  }, {
    "code": "HK",
    "name": "中国香港",
    "isLast": true
  }, {
    "code": "CF",
    "name": "中非",
    "isLast": true
  }, {
    "code": "TD",
    "name": "乍得",
    "isLast": true
  }, {
    "code": "CL",
    "name": "智利",
    "isLast": true
  }, {
    "code": "JE",
    "name": "泽西岛",
    "isLast": true
  }, {
    "code": "GI",
    "name": "直布罗陀",
    "isLast": true
  }, {
    "code": "ZM",
    "name": "赞比亚",
    "isLast": true
  }]
}];
var _default = exports.default = areaOtherList;