"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _artai = require("@/api/artai");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      visible: false,
      iframeUrl: '',
      posterId: '',
      // ipUrl: 'http://127.0.0.1:5173',
      ipUrl: 'http://poster-design.yunzongbu.cn'
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  beforeDestroy: function beforeDestroy() {
    this.iframeUrl = '';
    // 移除事件监听器
    window.removeEventListener('message', this.handleMessageFromIframe);
  },
  methods: {
    handleMessageFromIframe: function handleMessageFromIframe(event) {
      //检查消息来源是否可信
      if (event.origin !== this.ipUrl) {
        return;
      }

      // 处理接收到的消息
      switch (event.data.type) {
        case 'SOME_EVENT':
          // 处理特定事件
          console.log('通信:');
          this.$emit('result', this.posterId);
          break;
        default:
          // 处理其他事件
          break;
      }
    },
    openPoster: function openPoster(id, poster_record_id) {
      this.visible = true;
      this.posterId = id;
      this.iframeUrl = "".concat(this.ipUrl, "/amend?poster_record_id=").concat(poster_record_id);
      window.addEventListener('message', this.handleMessageFromIframe);
    },
    onClose: function onClose() {
      // this.formValidate.is_pay = 1;
      // this.onSubmit();
      this.visible = false;
      this.iframeUrl = '';
      window.removeEventListener('message', this.handleMessageFromIframe);
    }
  }
};