"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _authenticate = require("@/api/authenticate");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var selectType = {
  '1': 'Expert',
  '3': 'PraisalReport',
  '4': 'PraisalCategory',
  '5': 'PraisalSetting',
  '2': 'Appraisal',
  '6': 'AppraisalComplaint'
};
var _default = exports.default = {
  name: 'PraisalSetting',
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      activeMenu: '5',
      loading: true,
      oldData: {
        expert_withdraw_ratio: null,
        answer_cycle: null,
        fast_question_fee: null,
        expert_apply_condition: '',
        use_specification: '',
        need_pay_look_answer: 0,
        pay_look_answer_percent: null,
        answer_profit_sharing_percent: null,
        pay_look_answer_type: 1,
        question_choose_expert: 0,
        user_can_auction: 0,
        expert_can_auction: 0,
        identification_rule: '',
        is_can_ask: 0,
        is_show_wait_distributive: 0,
        auction_type: 0,
        category_level: 0
      }
    };
  },
  created: function created() {
    this.load();
  },
  methods: {
    onSelectTit: function onSelectTit(index) {
      var _this = this;
      this.activeMenu = index;
      this.$router.replace({
        name: selectType[index]
      });
      setTimeout(function () {
        _this.activeMenu = '5';
      });
    },
    load: function load() {
      var _this2 = this;
      (0, _authenticate._getSettingInfo)().then(function (res) {
        if (res.status === 200 && res.data) {
          _this2.oldData = res.data;
          _this2.oldData.fast_question_fee = res.data.fast_question_fee / 100;
          _this2.oldData.pay_look_answer_money = res.data.pay_look_answer_money / 100;
          if (res.data.expert_apply_condition) {
            _this2.$refs['editor'].setContent(res.data.expert_apply_condition);
          }
          if (res.data.use_specification) {
            _this2.$refs['editorRule'].setContent(res.data.use_specification);
          }
        }
        _this2.loading = false;
      });
    },
    saveAction: function saveAction() {
      var _this3 = this;
      var data = Object.assign({}, this.oldData);
      if (!data.expert_withdraw_ratio) {
        this.$message.error('请输入行家分佣比例');
        return false;
      }
      if (!data.answer_cycle) {
        this.$message.error('请输入行家回答限时');
        return false;
      }
      if (!data.fast_question_fee) {
        this.$message.error('请输入快速提问酬金');
        return false;
      }
      if (!data.expert_apply_condition) {
        this.$message.error('请输入专家申请说明');
        return false;
      }
      if (!data.use_specification) {
        this.$message.error('请输入答题言行规范');
        return false;
      }
      if (data.need_pay_look_answer === 1) {
        if (!data.pay_look_answer_percent) {
          this.$message.error('请输入偷学支付比例');
          return false;
        }
      }
      data.fast_question_fee = this.oldData.fast_question_fee * 100;
      data.pay_look_answer_money = this.oldData.pay_look_answer_money * 100;
      (0, _authenticate._setSettingInfo)(data).then(function (res) {
        if (res.status === 200) {
          _this3.$message.success('设置成功！');
          _this3.load();
        }
      });
      return false;
    }
  }
};