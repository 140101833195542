"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    colour: _colour.default
  },
  data: function data() {
    return {
      // background_color: "#2e3b58",
      // background_image: "",
      main_color: "#41434F",
      word_color: "rgba(66, 69, 77, 1)",
      gap: "3",
      transition: "1",
      showvideo: false,
      selectall: [],
      showmodel: "3",
      second: "3",
      origin: "1",
      main_image: "https://saas.cdn.yunzongbu.cn/merchant/23/20241022/image/1729576953994476085.png",
      main_width: 40,
      main_continue: true,
      main_start_front: false,
      auction_color: "#E0E0E7",
      auction_image: "https://saas.cdn.yunzongbu.cn/merchant/23/20241022/image/1729576953992497295.png",
      auction_width: 80,
      auction_lr_padding: 12,
      auction_top_padding: 7,
      auction_start_showall: false,
      album_font_size: "3.5",
      auction_font_size: "5",
      price_font_size: "5",
      image_size: "40",
      image_border_color: "rgba(66, 69, 77, 1)",
      auction_bottom_margin: "1",
      album_bottom_margin: "2",
      price_bottom_margin: "1",
      auction_attr_font_size: "2.5",
      auction_attr_margin: "7",
      price_all_margin: "6",
      slideNum: 9,
      videoModelType: "1",
      viceslideNum: 9,
      vicevideoModelType: "1"
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _system.getConfigClassKeys)("screen_one_setting").then(function (res) {
      if (res.data.onescreen) {
        var leddata = JSON.parse(res.data.onescreen);
        _this.main_color = leddata.main_color;
        _this.main_image = leddata.main_image;
        _this.main_width = leddata.main_width;
        _this.main_continue = leddata.main_continue;
        _this.main_start_front = leddata.main_start_front;
        _this.auction_color = leddata.auction_color;
        _this.auction_image = leddata.auction_image;
        _this.auction_width = leddata.auction_width;
        _this.auction_lr_padding = leddata.auction_lr_padding;
        _this.auction_top_padding = leddata.auction_top_padding;
        _this.word_color = leddata.word_color;
        _this.origin = leddata.origin;
        _this.gap = leddata.gap;
        _this.transition = leddata.transition;
        _this.showvideo = leddata.showvideo;
        _this.showmodel = leddata.showmodel;
        _this.second = leddata.second;
        _this.album_font_size = leddata.album_font_size;
        _this.auction_font_size = leddata.auction_font_size;
        _this.price_font_size = leddata.price_font_size;
        _this.image_size = leddata.image_size;
        _this.image_border_color = leddata.image_border_color;
        _this.auction_bottom_margin = leddata.auction_bottom_margin;
        _this.album_bottom_margin = leddata.album_bottom_margin;
        _this.price_bottom_margin = leddata.price_bottom_margin;
        _this.auction_attr_font_size = leddata.auction_attr_font_size;
        _this.auction_attr_margin = leddata.auction_attr_margin;
        _this.price_all_margin = leddata.price_all_margin;
        _this.slideNum = leddata.slideNum;
        _this.videoModelType = leddata.videoModelType;
        _this.viceslideNum = leddata.viceslideNum;
        _this.vicevideoModelType = leddata.vicevideoModelType;
        _this.auction_start_showall = leddata.auction_start_showall || false;
      }
    });
  },
  methods: {
    save: function save() {
      var _this2 = this;
      var configjson = {
        main_color: this.main_color,
        main_image: this.main_image,
        main_width: this.main_width,
        main_continue: this.main_continue,
        main_start_front: this.main_start_front,
        auction_color: this.auction_color,
        auction_image: this.auction_image,
        auction_width: this.auction_width,
        auction_lr_padding: this.auction_lr_padding,
        auction_top_padding: this.auction_top_padding,
        word_color: this.word_color,
        origin: this.origin,
        gap: this.gap,
        transition: this.transition,
        showvideo: this.showvideo,
        showmodel: this.showmodel,
        second: this.second,
        album_font_size: this.album_font_size,
        auction_font_size: this.auction_font_size,
        price_font_size: this.price_font_size,
        image_size: this.image_size,
        auction_bottom_margin: this.auction_bottom_margin,
        album_bottom_margin: this.album_bottom_margin,
        price_bottom_margin: this.price_bottom_margin,
        auction_attr_font_size: this.auction_attr_font_size,
        auction_attr_margin: this.auction_attr_margin,
        price_all_margin: this.price_all_margin,
        slideNum: this.slideNum,
        videoModelType: this.videoModelType,
        viceslideNum: this.viceslideNum,
        vicevideoModelType: this.vicevideoModelType,
        image_border_color: this.image_border_color,
        auction_start_showall: this.auction_start_showall
        // selectall: this.selectall,
      };
      (0, _system.configClassKeysSave)("screen_one_setting", {
        onescreen: JSON.stringify(configjson)
      }).then(function () {
        _this2.$message.success("保存成功");
      }).catch(function () {});
    },
    refresh: function refresh() {
      // this.background_color = "#2e3b58";
      this.main_color = "#ffffff";
      this.word_color = "#ffffff";
      this.gap = "3";
      this.transition = "0";
      this.showvideo = false;
      this.selectall = [];
      this.showmodel = "1";
      this.second = "3";
      this.origin = "1";
      // this.background_image = "";
      this.main_image = "";
    },
    delImg: function delImg(key) {
      this[key] = "";
    },
    // 上传图片
    modalPicTap: function modalPicTap(tit, key, key1) {
      var _this3 = this;
      this.$modalUpload(function (img) {
        if (key1) {
          _this3[key] = img[0];
        } else {
          _this3[key] = img[0];
        }
      }, tit);
    }
  }
};