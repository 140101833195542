var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "screen-box",
      style: {
        "background-color": _vm.background_color,
        color: _vm.word_color,
        "background-image": "url(" + _vm.background_image + ")",
      },
    },
    [
      _c(
        "div",
        { staticClass: "screen-goods-dis p-r70" },
        [
          _c("div", { staticClass: "screen-goods-sign" }, [
            _c("div", [
              _vm._v("已拍 " + _vm._s(_vm.auctionData.order_num - 1)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ml20" }, [
              _vm._v(
                "剩余 " +
                  _vm._s(_vm.albumData.auction_num - _vm.auctionData.order_num)
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "screen-goods-title f-500 line1" },
            [
              _vm.albumData.album_name
                ? [
                    _vm.albumData.album_name.length < 17
                      ? _c("div", { staticClass: "f-30 line1" }, [
                          _vm._v(_vm._s(_vm.albumData.album_name)),
                        ])
                      : _vm.albumData.album_name.length < 22
                      ? _c("div", { staticClass: "f-24 line1" }, [
                          _vm._v(_vm._s(_vm.albumData.album_name)),
                        ])
                      : _c("div", { staticClass: "f-20 line1" }, [
                          _vm._v(_vm._s(_vm.albumData.album_name)),
                        ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "screen-goods-img-box item-flex-center" }, [
            _vm.auctionData.product
              ? _c("img", {
                  attrs: {
                    src: _vm.auctionData.product.image + "!m640",
                    alt: "",
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "screen-goods-name f-500" }, [
            _vm._v(
              "\n      LOT " +
                _vm._s(_vm.auctionData.lot) +
                "\n      " +
                _vm._s(
                  _vm.auctionData.product
                    ? _vm.auctionData.product.store_name
                    : ""
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _vm.albumData &&
          _vm.albumData.feeTypeInfo &&
          _vm.albumData.feeTypeInfo.type
            ? [
                _c("div", { staticClass: "screen-goods-list" }, [
                  _c("div", { staticClass: "screen-goods-price" }, [
                    _vm._v(
                      _vm._s(
                        _vm.albumData.feeTypeInfo.type == "CNY"
                          ? "RMB"
                          : _vm.albumData.feeTypeInfo.type
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "screen-goods-num" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatPriceNumber(_vm.auctionData.now_price || 0, 2)
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.albumData.feeTypeInfo.type !=
                _vm.albumData.feeTypeInfo.mer_fee_type
                  ? _c("div", { staticClass: "screen-goods-list" }, [
                      _c("div", { staticClass: "screen-goods-price" }, [
                        _vm._v(
                          _vm._s(
                            _vm.albumData.feeTypeInfo.mer_fee_type == "CNY"
                              ? "RMB"
                              : _vm.albumData.feeTypeInfo.mer_fee_type
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "screen-goods-num" }, [
                        _vm._v(
                          _vm._s(
                            _vm.formatPriceNumber(
                              (
                                (_vm.auctionData.now_price || 0) /
                                _vm.albumData.feeTypeInfo.rate
                              ).toFixed(2),
                              2
                            )
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.abbreviationList, function (m, i) {
            return [
              _vm.albumData &&
              _vm.albumData.feeTypeInfo &&
              m.type != _vm.albumData.feeTypeInfo.type
                ? _c(
                    "div",
                    { key: "left" + i, staticClass: "screen-goods-list" },
                    [
                      _c("div", { staticClass: "screen-goods-price" }, [
                        _vm._v(_vm._s(m.type)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "screen-goods-num" }, [
                        _vm._v(
                          _vm._s(
                            _vm.moneyAdd(
                              _vm.auctionData.now_price || 0,
                              m.set_rate || m.rate,
                              2
                            )
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "screen-center", attrs: { id: "screen-center" } },
        [
          _c(
            "div",
            {
              staticClass: "screen-center-box",
              style: {
                "background-color": _vm.main_color,
                "background-image": "url(" + _vm.main_image + ")",
              },
            },
            [
              _vm.origin == 1
                ? _c(
                    "div",
                    {
                      staticClass: "screen-center-slide",
                      style: {
                        "background-color": _vm.main_color,
                        "background-image": "url(" + _vm.main_image + ")",
                      },
                    },
                    [
                      _vm.auctionData.product &&
                      _vm.auctionData.product.video_link &&
                      _vm.showvideo
                        ? [
                            _c("video", {
                              attrs: {
                                muted: "",
                                src: _vm.auctionData.product.video_link,
                                id: "video",
                              },
                              domProps: { muted: true },
                              on: { loadedmetadata: _vm.onVideoLoadedMetadata },
                            }),
                          ]
                        : _vm.showmodel == 3
                        ? [
                            _c("div", { staticClass: "img-box" }, [
                              _vm.auctionData.product
                                ? _c("img", {
                                    staticClass: "solidimg",
                                    style:
                                      (_vm.imgdisplat[0] == "shu"
                                        ? "height: auto;"
                                        : "") +
                                      (_vm.imgdisplat[0] == "heng"
                                        ? "width: auto;"
                                        : "") +
                                      _vm.scrollimgcss,
                                    attrs: {
                                      src:
                                        _vm.auctionData.product.image +
                                        "!m1080",
                                      alt: "",
                                    },
                                    on: { load: _vm.animateload },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        : [
                            _vm.auctionData.product
                              ? _c("img", {
                                  style:
                                    "" +
                                    (_vm.showmodel == 1
                                      ? "object-fit: contain;"
                                      : "object-fit: cover"),
                                  attrs: {
                                    src:
                                      _vm.auctionData.product.image + "!m1080",
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.origin == 2 && _vm.auctionData.product
                ? _vm._l(
                    _vm.auctionData.product.slider_image,
                    function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "screen-center-slide",
                          style:
                            "background-color:" +
                            _vm.main_color +
                            ";background-image:url(" +
                            _vm.main_image +
                            ");z-index:" +
                            (index + 1) +
                            ";" +
                            _vm.duration +
                            (_vm.animateindex < index ? _vm.csstext : ""),
                        },
                        [
                          _vm.showmodel == 3
                            ? [
                                _c("div", { staticClass: "img-box" }, [
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          !_vm.playvideo &&
                                          _vm.imgdisplat[index],
                                        expression:
                                          "!playvideo && imgdisplat[index]",
                                      },
                                    ],
                                    staticClass: "solidimg",
                                    style:
                                      (_vm.imgdisplat[index] == "shu"
                                        ? "height: auto;"
                                        : "") +
                                      (_vm.imgdisplat[index] == "heng"
                                        ? "width: auto;"
                                        : "") +
                                      (_vm.animateindex == index
                                        ? _vm.scrollimgcss
                                        : ""),
                                    attrs: { src: item + "!m1080", alt: "" },
                                    on: {
                                      load: function ($event) {
                                        return _vm.animatesolid(item, index)
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            : [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.animateindex == index,
                                      expression: "animateindex == index",
                                    },
                                  ],
                                  style:
                                    "" +
                                    (_vm.showmodel == 1
                                      ? "object-fit: contain;"
                                      : "object-fit: cover"),
                                  attrs: { src: item + "!m1080", alt: "" },
                                }),
                              ],
                        ],
                        2
                      )
                    }
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "screen-goods-dis p-l70" },
        [
          _c("div", { staticClass: "screen-goods-sign" }, [
            _c("div", [
              _vm._v("已拍 " + _vm._s(_vm.auctionData.order_num - 1)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ml20" }, [
              _vm._v(
                "剩余 " +
                  _vm._s(_vm.albumData.auction_num - _vm.auctionData.order_num)
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "screen-goods-title f-500 line1" },
            [
              _vm.albumData.album_name
                ? [
                    _vm.albumData.album_name.length < 17
                      ? _c("div", { staticClass: "f-30 line1" }, [
                          _vm._v(_vm._s(_vm.albumData.album_name)),
                        ])
                      : _vm.albumData.album_name.length < 22
                      ? _c("div", { staticClass: "f-24 line1" }, [
                          _vm._v(_vm._s(_vm.albumData.album_name)),
                        ])
                      : _c("div", { staticClass: "f-20 line1" }, [
                          _vm._v(_vm._s(_vm.albumData.album_name)),
                        ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "screen-goods-img-box item-flex-center" }, [
            _vm.auctionData.product
              ? _c("img", {
                  attrs: {
                    src: _vm.auctionData.product.image + "!m640",
                    alt: "",
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "screen-goods-name f-500" }, [
            _c("span", [_vm._v("LOT " + _vm._s(_vm.auctionData.lot) + " ")]),
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.auctionData.product
                    ? _vm.auctionData.product.store_name
                    : ""
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _vm.albumData &&
          _vm.albumData.feeTypeInfo &&
          _vm.albumData.feeTypeInfo.type
            ? [
                _c("div", { staticClass: "screen-goods-list" }, [
                  _c("div", { staticClass: "screen-goods-price" }, [
                    _vm._v(
                      _vm._s(
                        _vm.albumData.feeTypeInfo.type == "CNY"
                          ? "RMB"
                          : _vm.albumData.feeTypeInfo.type
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "screen-goods-num" }, [
                    _vm._v(
                      _vm._s(
                        _vm.formatPriceNumber(_vm.auctionData.now_price || 0, 2)
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.albumData.feeTypeInfo.type !=
                _vm.albumData.feeTypeInfo.mer_fee_type
                  ? _c("div", { staticClass: "screen-goods-list" }, [
                      _c("div", { staticClass: "screen-goods-price" }, [
                        _vm._v(
                          _vm._s(
                            _vm.albumData.feeTypeInfo.mer_fee_type == "CNY"
                              ? "RMB"
                              : _vm.albumData.feeTypeInfo.mer_fee_type
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "screen-goods-num" }, [
                        _vm._v(
                          _vm._s(
                            _vm.formatPriceNumber(
                              (
                                (_vm.auctionData.now_price || 0) /
                                _vm.albumData.feeTypeInfo.rate
                              ).toFixed(2),
                              2
                            )
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.abbreviationList, function (m, i) {
            return [
              _vm.albumData &&
              _vm.albumData.feeTypeInfo &&
              m.type != _vm.albumData.feeTypeInfo.type
                ? _c(
                    "div",
                    { key: "left" + i, staticClass: "screen-goods-list" },
                    [
                      _c("div", { staticClass: "screen-goods-price" }, [
                        _vm._v(_vm._s(m.type)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "screen-goods-num" }, [
                        _vm._v(
                          _vm._s(
                            _vm.moneyAdd(
                              _vm.auctionData.now_price || 0,
                              m.set_rate || m.rate,
                              2
                            )
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }