var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "配置", hasBack: true, isFixed: true },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo default-tabs ",
              attrs: {
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "default-active": _vm.activeMenu,
              },
              on: { select: _vm.onSelectTit },
            },
            [
              _c("el-menu-item", { attrs: { index: "5" } }, [
                _vm._v("规则配置"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "4" } }, [
                _vm._v("分类设置"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "default-setting mt-20" },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "auto" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "专家分佣比例：" } },
                    [
                      _c("el-input-number", {
                        staticClass: "w200",
                        attrs: { controls: false },
                        model: {
                          value: _vm.oldData.expert_withdraw_ratio,
                          callback: function ($$v) {
                            _vm.$set(_vm.oldData, "expert_withdraw_ratio", $$v)
                          },
                          expression: "oldData.expert_withdraw_ratio",
                        },
                      }),
                      _c("span", { staticClass: "ml20" }, [_vm._v("%")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "专家回答时限：" } },
                    [
                      _c("el-input-number", {
                        staticClass: "w200",
                        attrs: { controls: false },
                        model: {
                          value: _vm.oldData.answer_cycle,
                          callback: function ($$v) {
                            _vm.$set(_vm.oldData, "answer_cycle", $$v)
                          },
                          expression: "oldData.answer_cycle",
                        },
                      }),
                      _c("span", { staticClass: "ml20" }, [_vm._v("小时")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "快速提问酬金：" } },
                    [
                      _c("el-input-number", {
                        staticClass: "w200",
                        attrs: { controls: false },
                        model: {
                          value: _vm.oldData.fast_question_fee,
                          callback: function ($$v) {
                            _vm.$set(_vm.oldData, "fast_question_fee", $$v)
                          },
                          expression: "oldData.fast_question_fee",
                        },
                      }),
                      _c("span", { staticClass: "ml20" }, [_vm._v("元")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "快速提问可选专家：" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ccc",
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.oldData.question_choose_expert,
                          callback: function ($$v) {
                            _vm.$set(_vm.oldData, "question_choose_expert", $$v)
                          },
                          expression: "oldData.question_choose_expert",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "专家显示待分单：" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ccc",
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.oldData.is_show_wait_distributive,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.oldData,
                              "is_show_wait_distributive",
                              $$v
                            )
                          },
                          expression: "oldData.is_show_wait_distributive",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "追问功能：" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ccc",
                          "active-value": 0,
                          "inactive-value": 1,
                        },
                        model: {
                          value: _vm.oldData.is_can_ask,
                          callback: function ($$v) {
                            _vm.$set(_vm.oldData, "is_can_ask", $$v)
                          },
                          expression: "oldData.is_can_ask",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "专家推荐上拍：" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ccc",
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.oldData.expert_can_auction,
                          callback: function ($$v) {
                            _vm.$set(_vm.oldData, "expert_can_auction", $$v)
                          },
                          expression: "oldData.expert_can_auction",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "偷学收费：" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ccc",
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.oldData.need_pay_look_answer,
                          callback: function ($$v) {
                            _vm.$set(_vm.oldData, "need_pay_look_answer", $$v)
                          },
                          expression: "oldData.need_pay_look_answer",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "专家选择上拍类型：" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ccc",
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.oldData.auction_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.oldData, "auction_type", $$v)
                          },
                          expression: "oldData.auction_type",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "专家选择藏品等级：" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ccc",
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        model: {
                          value: _vm.oldData.category_level,
                          callback: function ($$v) {
                            _vm.$set(_vm.oldData, "category_level", $$v)
                          },
                          expression: "oldData.category_level",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "偷学支付方式" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.oldData.pay_look_answer_type,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.oldData,
                                  "pay_look_answer_type",
                                  $$v
                                )
                              },
                              expression: "oldData.pay_look_answer_type",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("百分比"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("固定金额"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.oldData.need_pay_look_answer == 1 &&
                        _vm.oldData.pay_look_answer_type == 1
                          ? _c(
                              "div",
                              { staticClass: "ml20" },
                              [
                                _c("el-input-number", {
                                  staticClass: "w200",
                                  attrs: { controls: false, min: 1 },
                                  model: {
                                    value: _vm.oldData.pay_look_answer_percent,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.oldData,
                                        "pay_look_answer_percent",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "oldData.pay_look_answer_percent",
                                  },
                                }),
                                _c("span", { staticClass: "ml20" }, [
                                  _vm._v("%（比例按照提问金额计算）"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.oldData.need_pay_look_answer == 1 &&
                        _vm.oldData.pay_look_answer_type == 2
                          ? _c(
                              "div",
                              { staticClass: "ml20" },
                              [
                                _c("el-input-number", {
                                  staticClass: "w200",
                                  attrs: { controls: false, min: 1 },
                                  model: {
                                    value: _vm.oldData.pay_look_answer_money,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.oldData,
                                        "pay_look_answer_money",
                                        $$v
                                      )
                                    },
                                    expression: "oldData.pay_look_answer_money",
                                  },
                                }),
                                _c("span", { staticClass: "ml20" }, [
                                  _vm._v("元（固定金额）"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.oldData.need_pay_look_answer == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "偷学收益分给提问者：" } },
                        [
                          _c("el-input-number", {
                            staticClass: "w200",
                            attrs: { max: 30, min: 0 },
                            model: {
                              value: _vm.oldData.answer_profit_sharing_percent,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.oldData,
                                  "answer_profit_sharing_percent",
                                  $$v
                                )
                              },
                              expression:
                                "oldData.answer_profit_sharing_percent",
                            },
                          }),
                          _c("span", { staticClass: "ml20" }, [
                            _vm._v(
                              "%（偷学支付金额按照比例分给提问者，最大30%）"
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "专家申请说明：" } },
                    [
                      _c("Tinymce", {
                        ref: "editor",
                        attrs: { height: 400 },
                        model: {
                          value: _vm.oldData.expert_apply_condition,
                          callback: function ($$v) {
                            _vm.$set(_vm.oldData, "expert_apply_condition", $$v)
                          },
                          expression: "oldData.expert_apply_condition",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "答题言行规范：" } },
                    [
                      _c("Tinymce", {
                        ref: "editorRule",
                        attrs: { height: 400 },
                        model: {
                          value: _vm.oldData.use_specification,
                          callback: function ($$v) {
                            _vm.$set(_vm.oldData, "use_specification", $$v)
                          },
                          expression: "oldData.use_specification",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "60px" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "formFixedBox" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: { click: _vm.saveAction },
            },
            [_vm._v("确认提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }