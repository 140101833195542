"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _index = require("@/utils/index");
var _accounts = require("@/api/accounts");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _system = require("@/api/system");
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
var _income = _interopRequireDefault(require("../wallet/modules/income.vue"));
var _extractCash = _interopRequireDefault(require("../wallet/modules/extractCash.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default,
    extractCash: _extractCash.default,
    income: _income.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      status: '2',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20,
        extract_status: '',
        keywords: '',
        date: '',
        account_type: 3
      },
      timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      showRefund: false,
      refundData: {},
      showdetail: null,
      uid: '',
      openUserInfo: false,
      userStatus: '1',
      userData: {
        tableData: {
          data: [],
          total: 0
        },
        listLoading: false,
        tableFrom: {
          page: 1,
          limit: 20,
          uid: '',
          account_type: 2
        }
      },
      showImg: false,
      showImgUrl: '',
      showagree: false,
      remark: ''
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.date = tab;
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    agreeBe: function agreeBe(item) {
      var _this = this;
      //   this.showagree = true;
      this.agreeData = {
        type: 3,
        uid: item.uid,
        extract_id: item.extract_id,
        pay_evidence: ''
      };
      this.$prompt('', '输入备注', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputPlaceholder: '请输入备注',
        inputErrorMessage: '请输入备注',
        inputValue: ''
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _accounts.firstPayApi)({
          uid: item.uid,
          extract_id: item.extract_id,
          mark: value
        }).then(function (req) {
          _this.$message.success('操作成功');
          _this.getList();
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    refauseBe: function refauseBe(item) {
      var _this2 = this;
      this.refauseData = {
        type: 3,
        uid: item.uid,
        extract_id: item.extract_id,
        pay_evidence: ''
      };
      this.$prompt('', '输入拒绝理由', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputPlaceholder: '请输入理由',
        inputErrorMessage: '请输入理由',
        inputValue: ''
      }).then(function (_ref2) {
        var value = _ref2.value;
        (0, _accounts.refuseExtractApi)({
          uid: item.uid,
          extract_id: item.extract_id,
          fail_msg: value
        }).then(function (req) {
          _this2.$message.success('操作成功');
          _this2.getList();
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    showEvidenceAction: function showEvidenceAction(img) {
      if (img) {
        this.showImgUrl = img;
        this.showImg = true;
      } else {
        this.$message.error('此记录没有凭证');
      }
    },
    closeRefundAction: function closeRefundAction() {
      this.showRefund = false;
      this.refundData = {};
    },
    goRefund: function goRefund(item) {
      this.showRefund = true;
      this.refundData = {
        type: 2,
        uid: item.uid,
        extract_id: item.extract_id,
        pay_evidence: ''
      };
    },
    createdRefund: function createdRefund(item) {
      this.showRefund = true;
      this.refundData = {
        type: 1,
        uid: item.uid,
        max_refund: item.balance,
        refund_price: '',
        pay_evidence: ''
      };
    },
    goRefundAction: function goRefundAction() {
      var _this3 = this;
      if (this.refundData.type === 1) {
        if (!this.refundData.refund_price || isNaN(this.refundData.refund_price)) {
          this.$message.error('请输入正确的退款金额');
          return false;
        }
        if (this.refundData.refund_price > this.refundData.max_refund) {
          this.$message.error('最大可退款' + this.refundData.max_refund + '元');
          return false;
        }
      }
      var data = {
        uid: this.refundData.uid,
        refund_price: this.refundData.refund_price,
        pay_evidence: this.refundData.pay_evidence || ''
      };
      var action = _accounts.createEntrustExtractApi;
      if (this.refundData.type === 2) {
        data = {
          uid: this.refundData.uid,
          extract_id: this.refundData.extract_id,
          pay_evidence: this.refundData.pay_evidence || ''
        };
        action = _accounts.extractRefundApi;
      }
      this.$confirm('确认退款', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        action(data).then(function () {
          _this3.$message.success('操作成功');
          _this3.closeRefundAction();
          if (_this3.openUserInfo) {
            _this3.getUserList(_this3.userStatus);
          }
          _this3.getList();
        }).catch(function () {});
      }).catch(function (err) {
        console.error(err);
      });
    },
    changeStatusAction: function changeStatusAction(val) {
      this.tableFrom.keywords = '';
      this.tableData.total = 0;
      this.tableData.data = [];
      this.getList(1);
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      var action = _accounts.userWalletList;
      switch (this.status) {
        case '2':
          action = _accounts.userExtractList;
          break;
        default:
          action = _accounts.userWalletList;
          break;
      }
      action(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.listLoading = false;
      });
    },
    changeImg: function changeImg() {
      var _this5 = this;
      this.$modalUpload(function (img) {
        _this5.refundData.pay_evidence = img[0];
      }, 1, '1');
    },
    onDetails: function onDetails(uid) {
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = null;
    },
    openUserAction: function openUserAction(item, status) {
      this.userStatus = status;
      this.userData = {
        tableData: {
          data: [],
          total: 0
        },
        listLoading: false,
        tableFrom: {
          page: 1,
          limit: 20,
          account_type: 2,
          uid: item.uid
        }
      };
      this.openUserInfo = true;
      this.getUserList(status);
    },
    changeUserStatusAction: function changeUserStatusAction() {
      this.userData.tableData = {
        data: [],
        total: 0
      };
      this.getUserList(this.userStatus, 1);
    },
    getUserList: function getUserList(status, num) {
      var _this6 = this;
      this.userData.listLoading = true;
      this.userData.tableFrom.page = num || this.userData.tableFrom.page;
      var action = _accounts.userWalletHistoryList;
      switch (status) {
        case '1':
          action = _accounts.userExtractList;
          break;
        default:
          this.userData.tableFrom.type = 'pay';
          action = _accounts.userWalletHistoryList;
          break;
      }
      action(this.userData.tableFrom).then(function (res) {
        _this6.userData.tableData.data = res.data.list;
        _this6.userData.tableData.total = res.data.count;
        _this6.userData.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this6.userData.listLoading = false;
      });
    },
    pageChangeTwo: function pageChangeTwo(page) {
      this.userData.tableFrom.page = page;
      this.getUserList('');
    },
    handleSizeChangeTwo: function handleSizeChangeTwo(val) {
      this.userData.tableFrom.limit = val;
      this.getUserList('');
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this7 = this;
      var data = (0, _objectSpread2.default)({
        export_type: 'userWalletExtractRecord'
      }, this.tableFrom);
      (0, _system.exportexcel)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this7.$createElement;
        _this7.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this7.$msgbox.close();
                _this7.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      var type = 'userWalletExtractRecord';
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(type);
    }
  }
};