var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "960px",
        visible: _vm.visible,
        fullscreen: true,
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "poster-dialog" }, [
          _c("img", {
            staticClass: "posterImg",
            attrs: {
              src: require("@/assets/images/poster/close.png"),
              alt: "",
            },
            on: { click: _vm.onClose },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "99vh" } }, [
        _c("iframe", {
          attrs: {
            src: _vm.iframeUrl,
            width: "100%",
            height: "100%",
            frameborder: "0",
            allowfullscreen: "",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }