var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _vm.loadEnd
      ? _c("div", { staticClass: "seeting-content flex" }, [
          _c("div", { staticClass: "seeting-show" }, [
            _c("div", { staticClass: "content" }, [
              _c(
                "div",
                {
                  staticClass: "main",
                  style: "width: " + _vm.w + "px;height: " + _vm.h + "px;",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "top",
                      style:
                        "background: " +
                        _vm.title_style_background_color +
                        ";color: " +
                        _vm.title_style_font_color +
                        ";" +
                        (_vm.title_style_height && _vm.title_style_height != 0
                          ? "height:" + _vm.title_style_height + "px"
                          : "") +
                        ";box-shadow: 0px 1px 0px 0px " +
                        _vm.title_style_shadow_color +
                        ";",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "top-view",
                          style:
                            "" +
                            (_vm.title_style_height &&
                            _vm.title_style_height != 0
                              ? "height:" + _vm.title_style_height + "px"
                              : ""),
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "top-left",
                              style:
                                "padding-left: " +
                                parseInt(_vm.fix * 64) +
                                "px",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "tltop-name",
                                  style:
                                    "font-size: " +
                                    _vm.title_font_size_venue +
                                    "px;margin-top: " +
                                    parseInt(_vm.fix * 46) +
                                    "px;",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.albumData.venue
                                        ? _vm.albumData.venue.title
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "tlbottom" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "tlbl",
                                    style:
                                      "font-size: " +
                                      _vm.title_font_size_befor +
                                      "px;color: " +
                                      _vm.title_style_background_color_next +
                                      ";",
                                  },
                                  [
                                    _vm.lastEndAuctionInfo
                                      ? [
                                          _c("span", { staticClass: "mr10" }, [
                                            _vm._v("上一件"),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              "LOT " +
                                                _vm._s(
                                                  _vm.lastEndAuctionInfo.lot
                                                )
                                            ),
                                          ]),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tlbc",
                                    style:
                                      "font-size: " +
                                      _vm.title_font_size_befor +
                                      "px;color: " +
                                      _vm.title_style_background_color_over +
                                      ";",
                                  },
                                  [
                                    _vm.lastEndAuctionInfo &&
                                    _vm.lastEndAuctionInfo.now_price
                                      ? _c("span", [
                                          _vm._v(
                                            "落槌价：" +
                                              _vm._s(
                                                _vm.formatPriceNumber(
                                                  _vm.lastEndAuctionInfo
                                                    .now_price,
                                                  2
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tlbr",
                                    style:
                                      "font-size: " +
                                      _vm.title_font_size_befor +
                                      "px;color: " +
                                      _vm.title_style_background_color_auction_num +
                                      ";",
                                  },
                                  [
                                    _vm.albumData && _vm.auctionData
                                      ? [
                                          _c("img", {
                                            style:
                                              "width: " +
                                              _vm.title_font_size_befor +
                                              "px;height: " +
                                              _vm.title_font_size_befor +
                                              "px;",
                                            attrs: {
                                              src: "https://saas.cdn.yunzongbu.cn/image/20210724/e719a91f4113853e64c5df8ebe0dc253.png",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              "待拍卖" +
                                                _vm._s(
                                                  _vm.albumData.auction_num -
                                                    _vm.auctionData.order_num
                                                ) +
                                                "件"
                                            ),
                                          ]),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "top-right",
                              style:
                                "font-size: " +
                                _vm.title_font_size_company_name +
                                "px;" +
                                (_vm.title_style_height &&
                                _vm.title_style_height != 0
                                  ? "height:" + _vm.title_style_height + "px"
                                  : ""),
                            },
                            [
                              _vm.compImg || _vm.compName
                                ? _c("div", { staticClass: "top-right-view" }, [
                                    _vm.compImg
                                      ? _c("img", {
                                          style:
                                            "width:" +
                                            _vm.compWidth +
                                            "px;height:" +
                                            _vm.compHeight +
                                            "px;",
                                          attrs: {
                                            src: _vm.compImg + "!120a",
                                            alt: "",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.compName
                                      ? _c("span", [
                                          _vm._v(_vm._s(_vm.compName)),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.compImg1 || _vm.compName1
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "top-right-view",
                                      staticStyle: { "margin-top": "10px" },
                                    },
                                    [
                                      _vm.compImg1
                                        ? _c("img", {
                                            style:
                                              "width:" +
                                              _vm.compWidth1 +
                                              "px;height:" +
                                              _vm.compHeight1 +
                                              "px;",
                                            attrs: {
                                              src: _vm.compImg1 + "!120a",
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.compName1
                                        ? _c("span", [
                                            _vm._v(_vm._s(_vm.compName1)),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "bottom zIndex",
                      class: _vm.shu == 0 ? "heng" : "shu",
                      style:
                        _vm.title_style_height && _vm.title_style_height != 0
                          ? "top:" + (Number(_vm.title_style_height) + 1) + "px"
                          : "",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "bottom-left",
                          style:
                            "background: " +
                            _vm.image_background_color +
                            ";" +
                            (_vm.shu == 0
                              ? "width: " + _vm.image_style_width + "%;"
                              : "") +
                            (_vm.shu == 1
                              ? "height:" + _vm.image_style_height + "%"
                              : ""),
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.shu == 0,
                                  expression: "shu == 0",
                                },
                              ],
                              staticClass: "bottom-left-img",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "img",
                                  style:
                                    "width:" +
                                    _vm.img_width +
                                    "px;height:" +
                                    _vm.img_height +
                                    "px;box-shadow: 0px 0px 20px 0px " +
                                    _vm.img_shadow +
                                    ";background:" +
                                    _vm.img_bg +
                                    ";",
                                },
                                [
                                  _vm.auctionData.product
                                    ? _c("img", {
                                        attrs: {
                                          src:
                                            _vm.auctionData.product.image +
                                            "!m1080",
                                        },
                                        on: {
                                          load: function (e) {
                                            return _vm.isLoadImgAction(
                                              e,
                                              _vm.auctionData.id,
                                              1
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.shu == 1,
                                  expression: "shu == 1",
                                },
                              ],
                              staticClass: "bottom-left-img",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "img",
                                  style:
                                    "padding: " +
                                    parseInt(62 * _vm.fix) +
                                    "px;height: 100%;width: 100%;",
                                },
                                [
                                  _vm.auctionData.product
                                    ? _c("img", {
                                        attrs: {
                                          src:
                                            _vm.auctionData.product.image +
                                            "!m1080",
                                        },
                                        on: {
                                          load: function (e) {
                                            return _vm.isLoadImgAction(
                                              e,
                                              _vm.auctionData.id,
                                              1
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.shu == 0
                        ? _c(
                            "div",
                            {
                              staticClass: "bottom-right",
                              style:
                                "background: " +
                                _vm.auction_style_background_color +
                                ";",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "bottom-r-t",
                                  style:
                                    "height: " +
                                    _vm.auction_style_height +
                                    "%;background: " +
                                    _vm.auction_style_background_color +
                                    ";padding: " +
                                    parseInt(58 * _vm.fix) +
                                    "px " +
                                    parseInt(80 * _vm.fix) +
                                    "px 0 " +
                                    parseInt(91 * _vm.fix) +
                                    "px;",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "lot",
                                      style:
                                        (_vm.auction_font_size_lot &&
                                        _vm.auction_font_size_lot != 0
                                          ? "font-size:" +
                                            _vm.auction_font_size_lot +
                                            "px"
                                          : "") +
                                        ";color: " +
                                        _vm.auction_style_font_color +
                                        ";",
                                    },
                                    [
                                      _vm._v(
                                        "LOT " + _vm._s(_vm.auctionData.lot)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "name",
                                      style:
                                        (_vm.auction_font_size_name &&
                                        _vm.auction_font_size_name != 0
                                          ? "font-size:" +
                                            _vm.auction_font_size_name +
                                            "px"
                                          : "") +
                                        ";color: " +
                                        _vm.auction_style_name +
                                        ";margin-top: " +
                                        parseInt(16 * _vm.fix) +
                                        "px;height: " +
                                        parseInt(134 * _vm.fix) +
                                        "px;line-height: " +
                                        parseInt(67 * _vm.fix) +
                                        "px;",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.auctionData.product
                                            ? _vm.auctionData.product.store_name
                                            : ""
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "desc",
                                      style:
                                        (_vm.auction_font_size_intro &&
                                        _vm.auction_font_size_intro != 0
                                          ? "font-size:" +
                                            _vm.auction_font_size_intro +
                                            "px"
                                          : "") +
                                        ";color: " +
                                        _vm.auction_style_desc +
                                        ";margin-top: " +
                                        parseInt(16 * _vm.fix) +
                                        "px;",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.auctionData.product
                                            ? _vm.auctionData.product.store_info
                                            : ""
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.show_start_price
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "attr",
                                          style:
                                            (_vm.auction_font_size_intro &&
                                            _vm.auction_font_size_price != 0
                                              ? "font-size:" +
                                                _vm.auction_font_size_price +
                                                "px"
                                              : "") +
                                            ";color: " +
                                            _vm.auction_style_price +
                                            ";margin-top: " +
                                            parseInt(16 * _vm.fix) +
                                            "px;",
                                        },
                                        [
                                          _vm._v(
                                            "起拍价：" +
                                              _vm._s(
                                                !_vm.albumData.feeTypeInfo ||
                                                  _vm.albumData.feeTypeInfo
                                                    .type == "CNY"
                                                  ? "RMB"
                                                  : _vm.albumData.feeTypeInfo
                                                      .type
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.formatPriceNumber(
                                                  _vm.auctionData.start_price ||
                                                    0,
                                                  2
                                                )
                                              )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "bottom-r-b",
                                  style:
                                    "height: " +
                                    _vm.price_style_height +
                                    "%;background: " +
                                    _vm.price_style_background_color +
                                    ";padding-top: " +
                                    parseInt(24 * _vm.fix) +
                                    "px;margin: 0 " +
                                    parseInt(80 * _vm.fix) +
                                    "px 0 " +
                                    parseInt(91 * _vm.fix) +
                                    "px;",
                                },
                                [
                                  _vm.albumData &&
                                  _vm.albumData.feeTypeInfo &&
                                  _vm.albumData.feeTypeInfo.type
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "list item-flex-center",
                                            style:
                                              "margin-top: " +
                                              parseInt(16 * _vm.fix) +
                                              "px;",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "l",
                                                style:
                                                  "font-size:" +
                                                  _vm.price_font_size_currency +
                                                  "px;color: " +
                                                  _vm.price_style_font_color_unit,
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.albumData.feeTypeInfo
                                                      .type == "CNY"
                                                      ? "RMB"
                                                      : _vm.albumData
                                                          .feeTypeInfo.type
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "r flex-one align-r",
                                                style:
                                                  (_vm.price_font_size_money &&
                                                  _vm.price_font_size_money != 0
                                                    ? "font-size:" +
                                                      _vm.price_font_size_money +
                                                      "px"
                                                    : "") +
                                                  ";color: " +
                                                  _vm.price_style_font_color,
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatPriceNumber(
                                                      _vm.auctionData
                                                        .now_price || 0,
                                                      2
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.albumData.feeTypeInfo.type !=
                                        _vm.albumData.feeTypeInfo.mer_fee_type
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "list item-flex-center",
                                                style:
                                                  "margin-top: " +
                                                  parseInt(16 * _vm.fix) +
                                                  "px;",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "l",
                                                    style:
                                                      "font-size:" +
                                                      _vm.price_font_size_currency +
                                                      "px;color: " +
                                                      _vm.price_style_font_color_unit,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.albumData
                                                          .feeTypeInfo
                                                          .mer_fee_type == "CNY"
                                                          ? "RMB"
                                                          : _vm.albumData
                                                              .feeTypeInfo
                                                              .mer_fee_type
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "r flex-one align-r",
                                                    style:
                                                      (_vm.price_font_size_money &&
                                                      _vm.price_font_size_money !=
                                                        0
                                                        ? "font-size:" +
                                                          _vm.price_font_size_money +
                                                          "px"
                                                        : "") +
                                                      ";color: " +
                                                      _vm.price_style_font_color,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatPriceNumber(
                                                          (
                                                            (_vm.auctionData
                                                              .now_price || 0) /
                                                            _vm.albumData
                                                              .feeTypeInfo.rate
                                                          ).toFixed(2),
                                                          2
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.abbreviationList, function (m, i) {
                                    return [
                                      _vm.albumData &&
                                      _vm.albumData.feeTypeInfo &&
                                      m.type != _vm.albumData.feeTypeInfo.type
                                        ? _c(
                                            "div",
                                            {
                                              key: "abbreviationList" + i,
                                              staticClass:
                                                "list item-flex-center",
                                              style:
                                                "margin-top: " +
                                                parseInt(16 * _vm.fix) +
                                                "px;",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "l",
                                                  style:
                                                    "font-size:" +
                                                    _vm.price_font_size_currency +
                                                    "px;color: " +
                                                    _vm.price_style_font_color_unit,
                                                },
                                                [_vm._v(_vm._s(m.type))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "r flex-one align-r",
                                                  style:
                                                    (_vm.price_font_size_money &&
                                                    _vm.price_font_size_money !=
                                                      0
                                                      ? "font-size:" +
                                                        _vm.price_font_size_money +
                                                        "px"
                                                      : "") +
                                                    ";color: " +
                                                    _vm.price_style_font_color,
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.moneyAdd(
                                                        _vm.auctionData
                                                          .now_price || 0,
                                                        m.set_rate || m.rate,
                                                        2
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.shu == 1
                        ? _c("div", { staticClass: "bottom-right" }, [
                            _c(
                              "div",
                              {
                                staticClass: "bottom-r-t",
                                style:
                                  "width: " +
                                  _vm.auction_style_width +
                                  "%;background: " +
                                  _vm.auction_style_background_color +
                                  ";padding: " +
                                  parseInt(30 * _vm.fix) +
                                  "px " +
                                  parseInt(60 * _vm.fix) +
                                  "px 0 " +
                                  parseInt(60 * _vm.fix) +
                                  "px;",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "lot",
                                    style:
                                      (_vm.auction_font_size_lot_horizontal &&
                                      _vm.auction_font_size_lot_horizontal != 0
                                        ? "font-size:" +
                                          _vm.auction_font_size_lot_horizontal +
                                          "px"
                                        : "") +
                                      ";color: " +
                                      _vm.auction_style_font_color +
                                      ";margin-top: " +
                                      parseInt(10 * _vm.fix) +
                                      "px;",
                                  },
                                  [_vm._v("LOT " + _vm._s(_vm.auctionData.lot))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "name name1",
                                    style:
                                      (_vm.auction_font_size_name_horizontal &&
                                      _vm.auction_font_size_name_horizontal != 0
                                        ? "font-size:" +
                                          _vm.auction_font_size_name_horizontal +
                                          "px"
                                        : "") +
                                      ";color: " +
                                      _vm.auction_style_name +
                                      ";margin-top: " +
                                      parseInt(10 * _vm.fix) +
                                      "px;height: " +
                                      parseInt(50 * _vm.fix) +
                                      "px;line-height: " +
                                      parseInt(50 * _vm.fix) +
                                      "px;",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.auctionData.product
                                          ? _vm.auctionData.product.store_name
                                          : ""
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "item-align-center",
                                    style:
                                      "margin-top: " +
                                      parseInt(10 * _vm.fix) +
                                      "px;",
                                  },
                                  [
                                    _vm.show_start_price
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "attr",
                                            style:
                                              (_vm.auction_font_size_intro_horizontal &&
                                              _vm.auction_font_size_price_horizontal !=
                                                0
                                                ? "font-size:" +
                                                  _vm.auction_font_size_price_horizontal +
                                                  "px"
                                                : "") +
                                              ";color: " +
                                              _vm.auction_style_price +
                                              ";margin-top: 0;padding-right: " +
                                              parseInt(20 * _vm.fix) +
                                              "px;",
                                          },
                                          [
                                            _vm._v(
                                              "起拍价：" +
                                                _vm._s(
                                                  _vm.formatPriceNumber(
                                                    _vm.auctionData
                                                      .start_price || 0,
                                                    2
                                                  )
                                                )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "desc",
                                        style:
                                          (_vm.auction_font_size_intro_horizontal &&
                                          _vm.auction_font_size_intro_horizontal !=
                                            0
                                            ? "font-size:" +
                                              _vm.auction_font_size_intro_horizontal +
                                              "px"
                                            : "") +
                                          ";color: " +
                                          _vm.auction_style_desc +
                                          ";flex: 1;margin-top: 0;",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.auctionData.product
                                              ? _vm.auctionData.product
                                                  .store_info
                                              : ""
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bottom-r-b display-flex flex-wrap",
                                style:
                                  "width: " +
                                  _vm.price_style_width +
                                  "%;background: " +
                                  _vm.price_style_background_color +
                                  ";padding: " +
                                  parseInt(56 * _vm.fix) +
                                  "px 0;margin: 0;justify-content:space-between;padding-right: " +
                                  parseInt(70 * _vm.fix) +
                                  "px;",
                              },
                              [
                                _vm.albumData &&
                                _vm.albumData.feeTypeInfo &&
                                _vm.albumData.feeTypeInfo.type
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "list item-flex-center",
                                          style:
                                            "width: " +
                                            parseInt(420 * _vm.fix) +
                                            "px;margin-top: " +
                                            parseInt(10 * _vm.fix) +
                                            "px;",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "l",
                                              style:
                                                (_vm.price_font_size_currency_horizontal &&
                                                _vm.price_font_size_currency_horizontal !=
                                                  0
                                                  ? "font-size:" +
                                                    _vm.price_font_size_currency_horizontal +
                                                    "px"
                                                  : "") +
                                                ";color: " +
                                                _vm.price_style_font_color_unit,
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.albumData.feeTypeInfo
                                                    .type == "CNY"
                                                    ? "RMB"
                                                    : _vm.albumData.feeTypeInfo
                                                        .type
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "r flex-one align-r",
                                              style:
                                                (_vm.price_font_size_money_horizontal &&
                                                _vm.price_font_size_money_horizontal !=
                                                  0
                                                  ? "font-size:" +
                                                    _vm.price_font_size_money_horizontal +
                                                    "px"
                                                  : "") +
                                                ";color: " +
                                                _vm.price_style_font_color,
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatPriceNumber(
                                                    _vm.auctionData.now_price ||
                                                      0,
                                                    2
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.albumData.feeTypeInfo.type !=
                                      _vm.albumData.feeTypeInfo.mer_fee_type
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "list item-flex-center",
                                              style:
                                                "width: " +
                                                parseInt(420 * _vm.fix) +
                                                "px;margin-top: " +
                                                parseInt(10 * _vm.fix) +
                                                "px;",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "l",
                                                  style:
                                                    (_vm.price_font_size_currency_horizontal &&
                                                    _vm.price_font_size_currency_horizontal !=
                                                      0
                                                      ? "font-size:" +
                                                        _vm.price_font_size_currency_horizontal +
                                                        "px"
                                                      : "") +
                                                    ";color: " +
                                                    _vm.price_style_font_color_unit,
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.albumData.feeTypeInfo
                                                        .mer_fee_type == "CNY"
                                                        ? "RMB"
                                                        : _vm.albumData
                                                            .feeTypeInfo
                                                            .mer_fee_type
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "r flex-one align-r",
                                                  style:
                                                    (_vm.price_font_size_money_horizontal &&
                                                    _vm.price_font_size_money_horizontal !=
                                                      0
                                                      ? "font-size:" +
                                                        _vm.price_font_size_money_horizontal +
                                                        "px"
                                                      : "") +
                                                    ";color: " +
                                                    _vm.price_style_font_color,
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatPriceNumber(
                                                        (
                                                          (_vm.auctionData
                                                            .now_price || 0) /
                                                          _vm.albumData
                                                            .feeTypeInfo.rate
                                                        ).toFixed(2),
                                                        2
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.abbreviationList, function (m, i) {
                                  return [
                                    _vm.albumData &&
                                    _vm.albumData.feeTypeInfo &&
                                    m.type != _vm.albumData.feeTypeInfo.type
                                      ? _c(
                                          "div",
                                          {
                                            key: "abbreviationList" + i,
                                            staticClass:
                                              "list item-flex-center",
                                            style:
                                              "width: " +
                                              parseInt(420 * _vm.fix) +
                                              "px;margin-top: " +
                                              parseInt(10 * _vm.fix) +
                                              "px;",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "l",
                                                style:
                                                  (_vm.price_font_size_currency_horizontal &&
                                                  _vm.price_font_size_currency_horizontal !=
                                                    0
                                                    ? "font-size:" +
                                                      _vm.price_font_size_currency_horizontal +
                                                      "px"
                                                    : "") +
                                                  ";color: " +
                                                  _vm.price_style_font_color_unit,
                                              },
                                              [_vm._v(_vm._s(m.type))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "r flex-one align-r",
                                                style:
                                                  (_vm.price_font_size_money_horizontal &&
                                                  _vm.price_font_size_money_horizontal !=
                                                    0
                                                    ? "font-size:" +
                                                      _vm.price_font_size_money_horizontal +
                                                      "px"
                                                    : "") +
                                                  ";color: " +
                                                  _vm.price_style_font_color,
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.moneyAdd(
                                                      _vm.auctionData
                                                        .now_price || 0,
                                                      m.set_rate || m.rate,
                                                      2
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.end
      ? _c("div", { staticClass: "is-end item-flex-center" }, [
          _c("div", { staticClass: "end-view" }, [
            _c("div", { staticClass: "top-name" }, [
              _vm._v(_vm._s(_vm.albumData.album_name)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "top1" }, [
              _vm._v(
                _vm._s(
                  _vm.parseTime(
                    _vm.albumEndData.end_time,
                    "{y}年{m}月{d}日 {h}:{i}"
                  )
                ) + " 结拍"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "center1" }, [
              _vm._v(
                "本场" +
                  _vm._s(_vm.albumEndData.total_num) +
                  "件/成交" +
                  _vm._s(_vm.albumEndData.deal_num) +
                  "件/成交率" +
                  _vm._s(_vm.albumEndData.deal_rate) +
                  "%"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "center2" }, [
              _vm._v("本场成交额"),
              _vm.albumData && _vm.albumData.feeTypeInfo
                ? _c("span", { staticClass: "ml5 mr5" }, [
                    _vm._v(
                      _vm._s(
                        _vm.albumData.feeTypeInfo.type == "CNY"
                          ? "RMB"
                          : _vm.albumData.feeTypeInfo.type
                      )
                    ),
                  ])
                : _vm._e(),
              _vm._v(
                _vm._s(
                  _vm.formatPriceNumber(Number(_vm.albumEndData.deal_price), 2)
                )
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bottom-copyright" }, [
              _vm._v("艺天下提供技术支持"),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }