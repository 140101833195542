var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "",
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _c("div", { staticClass: "all-h-print" }, [
        _vm.collectMoney.show
          ? _c("div", { attrs: { id: "printorder" } }, [
              _c("div", { staticClass: "detail-box" }, [
                _c("div", { staticClass: "flex" }, [
                  _c("div", { staticClass: "detail-left" }, [
                    _c("div", { staticClass: "detail-left-title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.collectMoney.data.merConfig.company_name) +
                          " | " +
                          _vm._s(_vm.switchLang("dhwtjtsqs")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-left-desc",
                        staticStyle: {
                          "font-size": "14px",
                          "font-family": "'printEN'",
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.collectMoney.data.merConfig
                                .company_english_name
                            ) +
                            "  ,Telephone Entrustment Authorization\n          "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "qrcodeBox" }, [
                  _vm.merData.config && _vm.merData.config.mer_print_logo
                    ? _c("img", {
                        staticStyle: { height: "70px" },
                        attrs: {
                          src: _vm.merData.config.mer_print_logo + "!120a",
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-left-title",
                  staticStyle: { margin: "30px 0" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.collectMoney.data.venueInfo.title) + " "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "5px" },
                },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.switchLang("jtrxm")) +
                          " BUYER'S NAME\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          "font-size": "16px",
                        },
                      },
                      [
                        _vm._v(
                          "  " +
                            _vm._s(_vm.collectMoney.data.entrustUser.real_name)
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.switchLang("jthp")) +
                          " PADDLE NO.  \n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          "font-size": "16px",
                        },
                      },
                      [
                        _vm._v(
                          "  " +
                            _vm._s(
                              _vm.collectMoney.data.number
                                ? _vm.collectMoney.data.number.number
                                : ""
                            )
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.switchLang("bprq")) +
                          " DATE OF SALE  \n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          "font-size": "16px",
                        },
                      },
                      [
                        _vm._v(
                          "  " +
                            _vm._s(
                              _vm.collectMoney.data.number
                                ? _vm.collectMoney.data.number.create_time
                                : ""
                            )
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "5px" },
                },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.switchLang("zjlx")) +
                          " ID TYPE \n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          "font-size": "16px",
                        },
                      },
                      [
                        _vm._v(
                          "  " +
                            _vm._s(
                              _vm.collectMoney.data.entrustUser.userVerify
                                ? ["中国大陆", "香港", "澳门", "台湾", "其他"][
                                    _vm.collectMoney.data.entrustUser.userVerify
                                      .idcard_type - 1
                                  ]
                                : ""
                            )
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.switchLang("zjhm")) +
                          " NO. \n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          "font-size": "16px",
                        },
                      },
                      [
                        _vm._v(
                          "  " +
                            _vm._s(
                              _vm.collectMoney.data.entrustUser.userVerify
                                ? _vm.collectMoney.data.entrustUser.userVerify
                                    .idcard
                                : ""
                            )
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.switchLang("sj")) +
                          " MOBILE PHONE \n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          "font-size": "16px",
                        },
                      },
                      [
                        _vm._v(
                          "  " +
                            _vm._s(
                              _vm.collectMoney.data.entrustUser.originPhone
                            )
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "5px" },
                },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c("div", [_vm._v(_vm._s(_vm.switchLang("dz")))]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          "font-size": "16px",
                        },
                      },
                      [
                        _vm._v(
                          "  " +
                            _vm._s(
                              _vm.collectMoney.data.entrustUser.userVerify
                                ? _vm.collectMoney.data.entrustUser.userVerify
                                    .address
                                : ""
                            )
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex", staticStyle: { "margin-top": "30px" } },
                [
                  _c("div", [
                    _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(_vm._s(_vm.switchLang("brzx"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "margin-bottom": "5px" },
                      },
                      [
                        _c("span", [_vm._v("· ")]),
                        _vm._v(_vm._s(_vm.switchLang("pmrdpm"))),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "margin-bottom": "5px" },
                      },
                      [
                        _c("span", [_vm._v("· ")]),
                        _vm._v(_vm._s(_vm.switchLang("ktgpmr"))),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "margin-bottom": "5px" },
                      },
                      [
                        _c("span", [_vm._v("· ")]),
                        _vm._v(_vm._s(_vm.switchLang("bryzbwt"))),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "margin-bottom": "5px" },
                      },
                      [
                        _c("span", [_vm._v("· ")]),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.switchLang("rbrwttr")) +
                            " \n          " +
                            _vm._s(
                              _vm.collectMoney.data.merConfig.company_name
                            ) +
                            " \n          " +
                            _vm._s(_vm.switchLang("rbrwttr2")) +
                            "\n          " +
                            _vm._s(
                              _vm.collectMoney.data.merConfig.mer_pc_website
                            ) +
                            " \n          " +
                            _vm._s(_vm.switchLang("rbrwttr3")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: {
                          "margin-bottom": "5px",
                          "font-weight": "500",
                          color: "#000",
                        },
                      },
                      [
                        _c("span", [_vm._v("· ")]),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", [
                            _vm._v(_vm._s(_vm.switchLang("fpkjzy")) + ":"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.switchLang("msrrx")) +
                              "\n          "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: {
                          "margin-bottom": "5px",
                          "font-weight": "500",
                          color: "#000",
                        },
                      },
                      [
                        _c("span", [_vm._v("· ")]),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", [
                            _vm._v(_vm._s(_vm.switchLang("crjzy")) + ":"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.switchLang("gjwwzg")) +
                              "\t\t\t\n          "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { margin: "15px 0" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.switchLang("zsqbw")) +
                          "\n          " +
                          _vm._s(_vm.collectMoney.data.merConfig.company_name) +
                          " \n          " +
                          _vm._s(_vm.switchLang("zsqbw2")) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "margin-bottom": "5px" },
                      },
                      [
                        _c("div", [_vm._v("一、")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.switchLang("ytgsm")))]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "margin-bottom": "5px" },
                      },
                      [
                        _c("div", [_vm._v("二、")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.switchLang("zwtjtz")))]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "margin-bottom": "5px" },
                      },
                      [
                        _c("div", [_vm._v("三、")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.switchLang("pmrwt")))]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "margin-bottom": "5px" },
                      },
                      [
                        _c("div", [_vm._v("四、")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.switchLang("bzxss")))]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { "margin-bottom": "5px" },
                      },
                      [
                        _c("div", [_vm._v("五、")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.switchLang("zflcj")))]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _c("div", [_vm._v("六、")]),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.switchLang("dzxsfsd")))]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { width: "600px", "margin-left": "20px" } },
                    [
                      _c(
                        "div",
                        { staticClass: "ptd-box" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "ptd",
                              staticStyle: { "font-weight": "bold" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-title",
                                  staticStyle: { width: "100px" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.switchLang("BuyerTabletu"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                      },
                                    },
                                    [_vm._v("Cat. No.")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-title",
                                  staticStyle: { width: "250px" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.switchLang("BuyerTablepp"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                      },
                                    },
                                    [_vm._v("Lot Name")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.collectMoney.data.auctionData,
                            function (m, i) {
                              return _c(
                                "div",
                                { key: "order" + i, staticClass: "ptd" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      staticStyle: { width: "100px" },
                                    },
                                    [_vm._v(_vm._s(m.auction.lot))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      staticStyle: { width: "250px" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(m.auction.product.store_name)
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { "page-break-after": "always" } }),
              _vm._v(" "),
              _c("div", {
                staticClass: "print-line",
                staticStyle: { "margin-top": "30px" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "detail-sign flex" }, [
                _c("div", { staticClass: "flex" }, [
                  _vm._v(_vm._s(_vm.switchLang("jtrqz"))),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-family": "'printEN'",
                        width: "200px",
                      },
                    },
                    [_vm._v("  SIGNATURE ")]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { "margin-left": "180px" } }, [
                  _vm._v(_vm._s(_vm.switchLang("rq")) + " DATE"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "print-line" }),
              _vm._v(" "),
              _c("div", { staticClass: "detail-sign" }, [
                _vm._v(_vm._s(_vm.switchLang("zskhjl"))),
                _c("span", { staticStyle: { "font-family": "'printEN'" } }, [
                  _vm._v(
                    "  CUSTOMER MANAGER  " + _vm._s(_vm.switchLang("ygxm"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "print-line" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ptd-bottom", attrs: { id: "ptd-bottom" } },
                [
                  _c("div", { staticClass: "ptd-bottom-left" }, [
                    _c("div", { staticClass: "ptd-bottom-left-list" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.switchLang("CompanyAddress")) +
                          "：" +
                          _vm._s(_vm.merData.config.company_address) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ptd-bottom-left-list" }, [
                      _vm._v(
                        "\n          Company Address: " +
                          _vm._s(
                            _vm.merData.config.company_english_address || ""
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ptd-bottom-left-list" }, [
                      _vm._v(
                        _vm._s(_vm.switchLang("CompanyPhone")) +
                          "/TEL： " +
                          _vm._s(_vm.merData.config.contact_phone)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ptd-bottom-right" },
                    _vm._l(
                      _vm.merData.config.mer_qrcode_data,
                      function (item, index) {
                        return item.imgUrl
                          ? _c(
                              "div",
                              {
                                key: "rcimg" + index,
                                staticClass: "ptd-bottom-img-list",
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    crossorigin: "anonymous",
                                    src: item.imgUrl + "!120a",
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "ptd-bottom-img-list-title" },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                              ]
                            )
                          : _vm._e()
                      }
                    ),
                    0
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintorder },
            },
            [_vm._v("打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }