"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var orderRouter = {
  path: "".concat(_settings.roterPre, "/order"),
  name: 'order',
  meta: {
    icon: 'dashboard',
    title: '订单'
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: 'noRedirect',
  children: [{
    path: 'productOrder',
    name: 'ProductOrder',
    meta: {
      title: '订单管理'
    },
    children: [{
      path: 'list',
      name: 'OrderList',
      meta: {
        title: '订单管理'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/order/index'));
        });
      }
    }, {
      path: 'refund',
      name: 'OrderRefund',
      meta: {
        title: '退款单'
        // permissionKey: '/order/refund'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/order/orderRefund/index'));
        });
      }
    },
    // {
    //   path: 'invoice',
    //   name: 'OrderInvoice ',
    //   meta: {
    //     title: '发票管理'
    //   },
    //   component: () => import('@/views/order/orderInvoice/index')
    // },
    {
      path: 'cancellation',
      name: 'OrderCancellation',
      meta: {
        title: '核销订单'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/order/orderCancellate/index'));
        });
      }
    }],
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/productOrder'));
      });
    }
  }, {
    path: 'auctionOrder',
    name: 'AuctionOrder',
    meta: {
      title: '拍品订单'
    },
    children: [{
      path: 'list',
      name: 'OrderList',
      meta: {
        title: '订单管理'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/order/auction/index'));
        });
      }
    }, {
      path: 'refund',
      name: 'OrderRefund',
      meta: {
        title: '退款单',
        permissionKey: '/order/refund'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/order/orderRefund/index'));
        });
      }
    }],
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/auctionOrder'));
      });
    }
  }, {
    path: 'list',
    name: 'OrderIndex',
    meta: {
      title: '货品订单'
    },
    children: [{
      path: 'auctionMeeting',
      name: 'AuctionMeetingOrder',
      meta: {
        title: '订单管理',
        permissionKey: '/order/list/auctionMeeting'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/order/goodsOrder/auctionOrder'));
        });
      }
    }, {
      path: 'proxy',
      name: 'proxyOrder',
      meta: {
        title: '代拍订单',
        activeMenu: "".concat(_settings.roterPre, "/order/list/auctionMeeting")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/order/goodsOrder/auctionOrder'));
        });
      }
    }, {
      path: 'all',
      name: 'AllOrder',
      meta: {
        title: '订单管理',
        activeMenu: "".concat(_settings.roterPre, "/order/list/auctionMeeting")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/order/index'));
        });
      }
    }, {
      path: 'sellmanage',
      name: 'sellmanage',
      meta: {
        title: '销售管理',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/order/list/auctionMeeting"),
        permissionKey: '/order/list/sellmanage'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/basic/sellmanage'));
        });
      }
    }, {
      path: 'refund',
      name: 'OrderRefund',
      meta: {
        title: '退款单',
        activeMenu: "".concat(_settings.roterPre, "/order/list/auctionMeeting"),
        permissionKey: '/order/list/refund'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/order/orderRefund/index'));
        });
      }
    }],
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/goodsOrder/index'));
      });
    }
  }, {
    path: 'invoice',
    name: 'OrderInvoice',
    meta: {
      title: '发票管理',
      permissionKey: '/order/invoice'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/orderInvoice/index'));
      });
    }
  }, {
    path: 'cancellation',
    name: 'OrderCancellation',
    meta: {
      title: '核销订单'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/orderCancellate/index'));
      });
    }
  }]
};
var _default = exports.default = orderRouter;