"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _user = require("@/api/user.js");
var _system = require("@/api/system.js");
var _index = require("@/utils/index");
var _zhTW = _interopRequireDefault(require("@/utils/zh-TW.json"));
var _order = require("@/api/order");
var _printJs = _interopRequireDefault(require("print-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ids: '',
      uid: '',
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      },
      fee_type: '',
      feeTypeInfo: {
        mer_fee_type: ''
      },
      isOutbound: false,
      qrcodeImg: '',
      merData: {},
      detail: {},
      isNo: true,
      result: []
    };
  },
  computed: {
    feeTypeObj: function feeTypeObj() {
      return this.$store.getters.feeTypeInfo;
    },
    getForeWidth: function getForeWidth() {
      var w = 0;
      if (this.detail.tableColumn[2] && this.detail.tableColumn[2].open && this.detail.tableColumn[2].w) {
        w += this.detail.tableColumn[2].w;
      }
      if (this.detail.tableColumn[3] && this.detail.tableColumn[3].open && this.detail.tableColumn[3].w) {
        w += this.detail.tableColumn[3].w;
      }
      if (this.detail.tableColumn[4] && this.detail.tableColumn[4].open && this.detail.tableColumn[4].w) {
        w += this.detail.tableColumn[4].w;
      }
      if (this.detail.tableColumn[5] && this.detail.tableColumn[5].open && this.detail.tableColumn[5].w) {
        w += this.detail.tableColumn[5].w;
      }
      if (this.detail.tableColumn[6] && this.detail.tableColumn[6].open && this.detail.tableColumn[6].w) {
        w += this.detail.tableColumn[6].w;
      }
      if (this.fee_type != this.feeTypeInfo.mer_fee_type) {
        if (this.detail.tableColumn[2] && this.detail.tableColumn[2].open && this.detail.tableColumn[2].w) {
          w -= this.detail.tableColumn[2].w;
        }
        if (this.detail.tableColumn[6] && this.detail.tableColumn[6].open && this.detail.tableColumn[6].w) {
          w += this.detail.tableColumn[6].w;
        }
      }
      return "width: ".concat(w, "px;");
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this.merData = res.data;
    });
    (0, _system.getConfigClassKeys)('print_config').then(function (res) {
      if (res.data.print_auction_transaction_order_bill_info) {
        var d = JSON.parse(res.data.print_auction_transaction_order_bill_info);
        if (!d.title) {
          d.title = {
            china: _this.switchLang('BuyerTitle'),
            english: "Buyer's Transaction Details"
          };
        }
        if (!d.signColumnTab) d.signColumnTab = 'system';
        _this.detail = d;
      } else {
        _this.detail = {
          title: {
            china: _this.switchLang('BuyerTitle'),
            english: "Buyer's Transaction Details"
          },
          buyerCompany: {
            china: _this.switchLang('BuyerCompany'),
            english: "Thank you for your support. We're pleased to inform you that the following items have been delivered. We appreciate your kindness and understanding, and we look forward to your continued partnership!"
          },
          tableColumn: [{
            open: true,
            name: '专场名称',
            showName: _this.switchLang("BuyerTablezc"),
            english: 'SESSION NAME'
          }, {
            open: true,
            name: '拍品名称',
            showName: _this.switchLang("BuyerTablepp"),
            english: 'DESCRIPTION'
          }, {
            open: true,
            name: '图录号',
            showName: _this.switchLang("BuyerTabletu"),
            english: 'LOT NO.',
            w: 86
          }, {
            open: true,
            name: '落槌价',
            showName: _this.switchLang("BuyerTablelc"),
            english: 'HAMMER PRICE',
            w: 130
          }, {
            open: true,
            name: '佣金',
            showName: _this.switchLang("BuyerTableyj"),
            english: 'COMMISSION',
            w: 130
          }, {
            open: true,
            name: '其他',
            showName: _this.switchLang("BuyerTableqt"),
            english: 'OTHER',
            w: 130
          }, {
            open: true,
            name: '金额',
            showName: _this.switchLang("Amount"),
            english: 'SUBTOTAL',
            w: 130
          }],
          settleText: {
            china: "* ".concat(_this.switchLang('SettleText'), "\u3002"),
            english: 'I confirm that all the items listed above are in good condition after inspection, and I hereby accept delivery of the items.'
          },
          signColumnTab: 'system',
          signColumn: {
            accountName: {
              open: true,
              name: '开户名称',
              showName: '',
              w: '100'
            },
            accountBank: {
              open: true,
              name: '开户行',
              showName: '',
              w: '100'
            },
            accountNumber: {
              open: true,
              name: '帐号',
              showName: '',
              w: '100'
            },
            accountAddress: {
              open: true,
              name: '公司地址',
              showName: '',
              w: '100'
            }
          }
        };
      }
    });
  },
  methods: {
    switchLang: function switchLang(filed) {
      if (this.feeTypeObj.mer_fee_type == 'HKD') {
        return _zhTW.default.TW[filed];
      } else return _zhTW.default.CN[filed];
    },
    onPrintOut: function onPrintOut() {
      var _this2 = this;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _order.orderProductCreateOut)({
        order_ids: this.ids,
        uid: this.uid
      }).then(function (res) {
        console.log(res);
        _this2.qrcodeImg = res.data.bar_code_image;
        setTimeout(function () {
          _this2.bindPrintorder();
          loading.close();
        }, 500);
      }).catch(function (err) {
        return loading.close();
      });
    },
    bindPrintorder: function bindPrintorder() {
      // console.log("是不是修改的这里啊");
      var that = this;
      setTimeout(function () {
        (0, _printJs.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
          printable: 'printorder',
          type: 'html',
          header: null,
          targetStyles: ['*'],
          scanStyles: true,
          maxWidth: 1000,
          font_size: 'auto',
          style: "@page { margin: 5mm 5mm 12mm;size: auto; } .paging{page-break-after: always;}",
          onPrintDialogClose: function onPrintDialogClose() {
            console.log('关闭');
          }
        }, "onPrintDialogClose", function onPrintDialogClose() {
          console.log('pdf');
          // batchCreate({ order_ids: that.collectMoney.order_ids, order_type: 'order' });
          // that.collectMoney.show = false;
          // that.$emit('load');
        }), "onPdfOpen", function onPdfOpen() {
          console.log('pdf1');
        }));
      }, 500);
    },
    getNewArray: function getNewArray(array) {
      var num = 0;
      var heightAll = 0;
      var dom = document.getElementsByClassName('calcul');
      var printTopDom = document.getElementsByClassName('all-print-main-el');
      var h = 1300 - printTopDom[0].offsetTop;
      var arr = [];
      for (var i = 0; i < dom.length; i++) {
        heightAll += Number(window.getComputedStyle(dom[i]).height.replace('px', ''));
        if (num === 0 && heightAll > h) {
          arr.push(i);
          num += 1;
        } else if (num > 0 && heightAll > 1200 * num + h) {
          arr.push(i);
          num += 1;
        }
      }
      this.result = this.sliceArrayIntoChunks(this.collectMoney.data.orderData, arr);
      this.isNo = false;
      // const newArray = [];
      // if (array.length > 20) {
      //   newArray.push(array.slice(0, 20));
      //   const ffarr = array.slice(20, array.length);
      //   const aaa = this.getSplArray(ffarr, 26);
      //   // console.log(aaa);
      //   aaa.forEach(item => {
      //     newArray.push(item);
      //   });
      // } else newArray.push(array);
      // return newArray;
    },
    sliceArrayIntoChunks: function sliceArrayIntoChunks(array, indexes) {
      var arr = [];
      var len = array.length;
      var ind = 0;
      indexes.forEach(function (item, index) {
        arr.push(array.slice(ind, item));
        ind = item;
      });
      arr.push(array.slice(indexes[indexes.length - 1], len));
      return arr;
    },
    getSplArray: function getSplArray(array, subGroupLength) {
      // array 代表一个数组
      // subGroupLength 代表数组里面的数组的length值
      var index = 0;
      var newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    },
    loadData: function loadData(ids, uid) {
      var _this3 = this;
      this.isNo = true;
      this.ids = ids;
      this.uid = uid;
      (0, _order.getAuctionTransactionOrderBillInfo)({
        order_ids: ids,
        uid: uid
      }).then(function (res) {
        console.log('1', res);
        if (res.data.orderData.length > 0) {
          _this3.fee_type = res.data.orderData[0].album_info.fee_type;
          _this3.feeTypeInfo = res.data.orderData[0].feeTypeInfo;
        }
        var orderLength = res.data.orderData.length;
        // res.data.orderData = this.getNewArray(res.data.orderData);

        // this.total_price = total_price;
        _this3.collectMoney = {
          order_ids: ids,
          data: res.data,
          show: true,
          orderLength: orderLength
        };
        _this3.$nextTick(function () {
          // 赋值后马上更新
          _this3.getNewArray(res.data.orderData);
        });
      }).catch(function () {});
    }
  }
};