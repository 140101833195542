var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", { attrs: { name: "用户留言" } }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "order-table fk",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "ID", align: "center", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.feedback_id))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "联系人", align: "left", width: "220" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.user && row.user.uid
                          ? [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "5px",
                                    "margin-bottom": "5px",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#6699dd",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDetails(row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            row.user.real_name ||
                                              row.user.nickname
                                          ) +
                                          " "
                                      ),
                                      row.user.mark
                                        ? _c("span", [
                                            _vm._v(
                                              "(" + _vm._s(row.user.mark) + ")"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v("联系人：" + _vm._s(row.realname)),
                                  ]),
                                  _vm._v(" "),
                                  row.contact
                                    ? _c("div", [
                                        _vm._v(
                                          "联系手机：" + _vm._s(row.contact)
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.email
                                    ? _c("div", [
                                        _vm._v("Email：" + _vm._s(row.email)),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图片", width: "50", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          { staticClass: "flex item-flex-center" },
                          [
                            row.images && row.images[0]
                              ? _c("el-image", {
                                  staticStyle: {
                                    width: "37px",
                                    height: "37px",
                                  },
                                  attrs: {
                                    src: row.images[0] + "!120a",
                                    "preview-src-list": row.images,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "反馈内容", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.content))])]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-size": _vm.queryParams.limit,
                  "current-page": _vm.queryParams.page,
                  layout: "total, prev, pager, next, sizes",
                  total: _vm.tableData.total,
                },
                on: { "current-change": _vm.getList },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { editMark: _vm.getList, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "反馈内容",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [_c("span", [_vm._v(_vm._s(_vm.content))])]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }