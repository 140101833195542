"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _setting = require("@/api/setting");
var _product = require("@/api/product");
var _basic = require("@/api/basic");
var _warehouse = require("@/api/warehouse");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      id: this.$route.query.id || "",
      formValidate: {
        out_warehouse_id: "",
        push_warehouse_id: "",
        out_type: "out_warehouse_transfers",
        manage_admin_id: "",
        remark: "",
        goods: []
      },
      adminarr: [],
      rules: {
        out_warehouse_id: [{
          required: true,
          message: "请选择仓库"
        }],
        push_warehouse_id: [{
          required: true,
          message: "请选择仓库"
        }],
        out_type: [{
          required: true,
          message: "请选择出库类型"
        }],
        manage_admin_id: [{
          required: false,
          message: "请选择业务负责人",
          trigger: "change"
        }]
      },
      storeArr: [],
      loading: false,
      multipleSelection: [],
      goodsArr: [],
      tableData: {
        data: [],
        total: 0
      },
      selectFlag: false,
      tableFrom: {
        product_name: "",
        id: "",
        page: 1,
        limit: 20
      },
      goodsIdArr: [],
      searchQuery: {},
      userList: [],
      removeList: [],
      reductjson: {
        erp_v2_transfers_warehouse: '0',
        is_approval_admin: 0,
        adminData: []
      },
      reviewAduitId: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.getadminList();
    this.getstore();
    (0, _setting.adminListApi)().then(function (res) {
      // console.log(res);
      _this.userList = res.data.list.map(function (item) {
        return {
          label: item.real_name,
          value: item.merchant_admin_id
        };
      });
    });
    (0, _setting.getApprovalApi)({
      type: 'erp_v2_transfers_warehouse'
    }).then(function (res) {
      console.log(res);
      _this.reductjson = res.data;
    });
  },
  methods: {
    getInfoAction: function getInfoAction() {
      var _this2 = this;
      (0, _warehouse.getwarehouseOrderDetailApiV2)(this.id).then(function (res) {
        console.log('1----0', res);
        _this2.$set(_this2.formValidate, 'out_warehouse_id', res.data.outWarehouse.warehouse_id);
        _this2.$set(_this2.formValidate, 'push_warehouse_id', res.data.enterWarehouse.warehouse_id);
        _this2.getList();
        res.data.warehouseOrderProduct.forEach(function (m) {
          m = (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, m.erpProduct), m), {}, {
            id: m.erpProduct.id,
            label_ids: m.erpProduct.label_ids ? m.erpProduct.label_ids.split(',').map(function (item) {
              return Number(item);
            }) : []
          });
          m.edit = true;
          _this2.saleGoods(m, "goods", "");
        });
      });
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    close: function close() {
      this.$router.back();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this3.formValidate.push_warehouse_id == _this3.formValidate.out_warehouse_id) {
            _this3.$message.error("不能选择同一仓库");
            return;
          }
          _this3.loading = true;
          var json = {};
          json.out_id = _this3.formValidate.out_warehouse_id;
          json.warehouseProduct = _this3.goodsArr.map(function (item) {
            return {
              erp_product_id: item.id,
              product_no: item.product_no,
              shelf_id: item.shelf_id,
              image: item.image,
              product_name: item.product_name
            };
          });
          json.enter_id = _this3.formValidate.push_warehouse_id;
          json.out_type = _this3.formValidate.out_type;
          if (_this3.reductjson.erp_v2_transfers_warehouse == 1) {
            if (!_this3.reviewAduitId) {
              _this3.$message.error('请选择审批人');
              return;
            }
            json.audit_admin_id = _this3.reviewAduitId;
          }
          if (_this3.id) {
            (0, _warehouse.updateTransfersApi)(_this3.id, json).then(function (res) {
              if (res.status == 200) {
                _this3.$router.back();
              }
            }).catch(function (err) {
              _this3.$message.error(err.message);
            });
          } else {
            (0, _warehouse.createTransferOrderApiV2)(json).then(function (res) {
              if (res.status == 200) {
                _this3.$router.back();
              }
            }).catch(function (err) {
              _this3.$message.error(err.message);
            });
          }
        } else {
          return false;
        }
      });
    },
    selectoutp: function selectoutp() {
      this.selectActiveGoods();
    },
    getadminList: function getadminList() {
      var _this4 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this4.adminarr = res.data.list;
      });
    },
    getstore: function getstore() {
      var _this5 = this;
      (0, _product.getStoreList)().then(function (res) {
        _this5.storeArr = res.data.list;
        if (_this5.$route.query.id) {
          _this5.getInfoAction();
        } else if (res.data.list.length) {
          _this5.formValidate.out_warehouse_id = res.data.list[0].warehouse_id;
          _this5.getList();
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectActiveGoods: function selectActiveGoods() {
      this.selectFlag = true;
      this.getList();
    },
    getList: function getList() {
      var _this6 = this;
      this.tableFrom.warehouse_id = this.formValidate.out_warehouse_id;
      this.tableFrom.product_warehouse_status = 2;
      (0, _basic.getgoodsListV2Api)(this.tableFrom).then(function (res) {
        console.log(res);
        res.data.list.forEach(function (item) {
          item.selected = false;
        });
        if (_this6.tableFrom.page == 1 && _this6.removeList.length > 0) {
          _this6.tableData.data = [].concat((0, _toConsumableArray2.default)(_this6.removeList), (0, _toConsumableArray2.default)(res.data.list));
        } else _this6.tableData.data = res.data.list;
        _this6.tableData.total = res.data.count;
        _this6.listLoading = false;
      });
    },
    closeSelect: function closeSelect() {
      this.selectFlag = false;
    },
    saleGoods: function saleGoods(row, index) {
      if (this.goodsIdArr.indexOf(row.id) > -1) return false;
      this.row = row;
      this.sindex = index;
      this.row.selected = true;
      var newrow = JSON.parse(JSON.stringify(row));
      this.goodsArr.push(newrow);
      this.goodsIdArr.push(row.id);
    },
    saleGoodsAll: function saleGoodsAll() {
      var _this7 = this;
      this.tableData.data.forEach(function (m, i) {
        _this7.saleGoods(m, i);
      });
    },
    delGoods: function delGoods(index, row) {
      if (row.edit) {
        this.tableData.data.unshift(row);
        this.removeList.push(row);
      }
      var nindex = this.goodsIdArr.indexOf(row.id);
      this.goodsIdArr.splice(nindex, 1);
      this.goodsArr.splice(nindex, 1);
    },
    cancelSelect: function cancelSelect(index, row) {
      row.selected = false;
      var nindex = this.goodsIdArr.indexOf(row.id);
      this.goodsIdArr.splice(nindex, 1);
      this.goodsArr.splice(nindex, 1);
    }
  }
};