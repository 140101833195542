var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", { attrs: { name: "概况" } }),
      _vm._v(" "),
      _c("el-card", { staticClass: "relative" }, [
        _c("div", { staticClass: "dashTit flex align-items-c" }, [
          _vm._v("数据增长\n      "),
          _c(
            "div",
            { staticClass: "daSpanText" },
            [
              _c(
                "span",
                {
                  staticClass: "cur",
                  class: { active: _vm.date == "today" },
                  on: {
                    click: function ($event) {
                      return _vm.onTabChange("today")
                    },
                  },
                },
                [_vm._v("当日")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "cur",
                  class: { active: _vm.date == "yesterday" },
                  on: {
                    click: function ($event) {
                      return _vm.onTabChange("yesterday")
                    },
                  },
                },
                [_vm._v("昨日")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "cur",
                  class: { active: _vm.date == "lately7" },
                  on: {
                    click: function ($event) {
                      return _vm.onTabChange("lately7")
                    },
                  },
                },
                [_vm._v("最近7天")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "cur",
                  class: { active: _vm.date == "lately30" },
                  on: {
                    click: function ($event) {
                      return _vm.onTabChange("lately30")
                    },
                  },
                },
                [_vm._v("最近30天")]
              ),
              _vm._v(" "),
              _c("el-date-picker", {
                staticStyle: { width: "250px" },
                attrs: {
                  "value-format": "yyyy/MM/dd",
                  format: "yyyy/MM/dd",
                  size: "small",
                  type: "daterange",
                  placement: "bottom-start",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                on: { change: _vm.onchangeTime },
                model: {
                  value: _vm.timeValue,
                  callback: function ($$v) {
                    _vm.timeValue = $$v
                  },
                  expression: "timeValue",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flexBox" }, [
          _c("div", { staticClass: "fleItemOne" }, [
            _vm._v("\n        订单数量 \n        "),
            _vm._v(" "),
            _c("span", { staticClass: "flexdesc" }, [
              _vm._v("自营: " + _vm._s(_vm.orderTotal.orderCount)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "flexdesc" }, [
              _vm._v("代拍：" + _vm._s(_vm.orderTotal.orderProxyCount)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "fleItemOne" }, [
            _vm._v("\n        订单金额 \n        "),
            _vm._v(" "),
            _c("span", { staticClass: "flexdesc" }, [
              _vm._v(
                "自营: " +
                  _vm._s(
                    _vm.formatPriceNumber(
                      Number(_vm.orderTotal.orderTotalPrice)
                    )
                  )
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "flexdesc" }, [
              _vm._v(
                "代拍：" +
                  _vm._s(
                    Number(_vm.orderTotal.orderProxyTotalPrice) > 0 ? "约" : ""
                  ) +
                  _vm._s(
                    _vm.formatPriceNumber(
                      Number(_vm.orderTotal.orderProxyTotalPrice)
                    )
                  )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "fleItemOne" }, [
            _vm._v("\n        收款金额 \n        "),
            _vm._v(" "),
            _c("span", { staticClass: "flexdesc" }, [
              _vm._v(
                "自营: " +
                  _vm._s(
                    _vm.formatPriceNumber(Number(_vm.orderTotal.orderPayPrice))
                  )
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "flexdesc" }, [
              _vm._v(
                "代拍：" +
                  _vm._s(
                    Number(_vm.orderTotal.orderProxyTotalPrice) > 0 ? "约" : ""
                  ) +
                  _vm._s(
                    _vm.formatPriceNumber(
                      Number(_vm.orderTotal.orderProxyPayPrice)
                    )
                  )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "fleItemOne" }, [
            _vm._v("注册用户 "),
            _c("span", {}, [_vm._v(_vm._s(_vm.orderTotal.newUserNum))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "fleItemOne" }, [
            _vm._v("新客户 "),
            _c("span", {}, [_vm._v(_vm._s(_vm.orderTotal.orderPayUserCount))]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItemOne",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.$router.push("/merchant/user/feedback")
                },
              },
            },
            [
              _vm._v("用户留言 "),
              _c("span", {}, [_vm._v(_vm._s(_vm.orderTotal.feedbackCount))]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "dashTit" }, [_vm._v("审批任务")]),
        _vm._v(" "),
        _c("div", { staticClass: "flexBox" }, [
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl(
                    "/merchant/reviews/review?type=erp_v2_enter_warehouse"
                  )
                },
              },
            },
            [
              _vm._v("入库单 "),
              _c("span", { staticClass: "redText" }, [
                _vm._v(
                  _vm._s(_vm.statisTotal.approvalStatistics.enterWarehouseCount)
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl(
                    "/merchant/reviews/review?type=erp_v2_out_warehouse"
                  )
                },
              },
            },
            [
              _vm._v("出库单 "),
              _c("span", {}, [
                _vm._v(
                  _vm._s(_vm.statisTotal.approvalStatistics.outWarehouseCount)
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl(
                    "/merchant/reviews/review?type=erp_v2_transfers_order"
                  )
                },
              },
            },
            [
              _vm._v("调拨单 "),
              _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm.statisTotal.approvalStatistics.transfersWarehouseCount
                  )
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl("/merchant/reviews/review?type=")
                },
              },
            },
            [
              _vm._v("盘点单 "),
              _c("span", { staticClass: "redText" }, [
                _vm._v(
                  _vm._s(_vm.statisTotal.approvalStatistics.stockTakeCount)
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl("/merchant/reviews/review?type=")
                },
              },
            },
            [
              _vm._v("对账单 "),
              _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm.statisTotal.approvalStatistics.enterCheckOrderCount
                  )
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl("/merchant/reviews/review?type=")
                },
              },
            },
            [
              _vm._v("付款单 "),
              _c("span", {}, [
                _vm._v(
                  _vm._s(_vm.statisTotal.approvalStatistics.payOrderCount)
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl(
                    "/merchant/reviews/review?type=erp_v2_contract"
                  )
                },
              },
            },
            [
              _vm._v("合同审批 "),
              _c("span", {}, [
                _vm._v(
                  _vm._s(_vm.statisTotal.approvalStatistics.contractCount)
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl(
                    "/merchant/reviews/review?type=market_media_make"
                  )
                },
              },
            },
            [
              _vm._v("营销 "),
              _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm.statisTotal.approvalStatistics.marketMediaMakeCount
                  )
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "dashTit" }, [_vm._v("订单处理")]),
        _vm._v(" "),
        _c("div", { staticClass: "flexBox" }, [
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl(
                    "/merchant/order/list/auctionMeeting?status=8"
                  )
                },
              },
            },
            [
              _vm._v("待确认 "),
              _c("span", { staticClass: "redText" }, [
                _vm._v(_vm._s(_vm.statisTotal.orderStatistics.waitConfirm)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl(
                    "/merchant/order/list/auctionMeeting?status=1"
                  )
                },
              },
            },
            [
              _vm._v("待付款 "),
              _c("span", {}, [
                _vm._v(_vm._s(_vm.statisTotal.orderStatistics.unpaid)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl(
                    "/merchant/order/list/auctionMeeting?status=2"
                  )
                },
              },
            },
            [
              _vm._v("待发货 "),
              _c("span", {}, [
                _vm._v(_vm._s(_vm.statisTotal.orderStatistics.unshipped)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl(
                    "/merchant/order/list/auctionMeeting?status=3"
                  )
                },
              },
            },
            [
              _vm._v("待提货 "),
              _c("span", {}, [
                _vm._v(_vm._s(_vm.statisTotal.orderStatistics.untake)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl(
                    "/merchant/order/list/auctionMeeting?status=6"
                  )
                },
              },
            },
            [
              _vm._v("待退款 "),
              _c("span", { staticClass: "redText" }, [
                _vm._v(_vm._s(_vm.statisTotal.orderStatistics.refunding)),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "dashTit" }, [_vm._v("财务处理")]),
        _vm._v(" "),
        _c("div", { staticClass: "flexBox" }, [
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl("/merchant/accounts/wallet/reconciliation")
                },
              },
            },
            [
              _vm._v("待对账 "),
              _c("span", { staticClass: "redText" }, [
                _vm._v(
                  _vm._s(
                    _vm.statisTotal.financialStatistics.waitReconciliationCount
                  )
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl(
                    "/merchant/accounts/wallet/payment?status=1"
                  )
                },
              },
            },
            [
              _vm._v("待付款 "),
              _c("span", {}, [
                _vm._v(
                  _vm._s(_vm.statisTotal.financialStatistics.waitPaymentCount)
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl("/merchant/accounts/wallet/receipt")
                },
              },
            },
            [
              _vm._v("转账审核 "),
              _c("span", {}, [
                _vm._v(
                  _vm._s(_vm.statisTotal.financialStatistics.waitTransferCount)
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl("/merchant/accounts/wallet/invoice")
                },
              },
            },
            [
              _vm._v("待开票 "),
              _c("span", { staticClass: "redText" }, [
                _vm._v(
                  _vm._s(_vm.statisTotal.financialStatistics.waitReceiptCount)
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "dashTit" }, [_vm._v("业务处理")]),
        _vm._v(" "),
        _c("div", { staticClass: "flexBox" }, [
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl("/merchant/contract/manage/letterfinnance")
                },
              },
            },
            [
              _vm._v("委托对账 "),
              _c("span", { staticClass: "redText" }, [
                _vm._v(
                  _vm._s(
                    _vm.statisTotal.businessStatistics.entrustCheckAuctionCount
                  )
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl("/merchant/warehouse/manage/check")
                },
              },
            },
            [
              _vm._v("库存盘点 "),
              _c("span", {}, [
                _vm._v(
                  _vm._s(_vm.statisTotal.businessStatistics.erpStockTaskCount)
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl("/merchant/user/list/verify")
                },
              },
            },
            [
              _vm._v("实名认证 "),
              _c("span", {}, [
                _vm._v(
                  _vm._s(_vm.statisTotal.businessStatistics.userVerifyCount)
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fleItem",
              on: {
                click: function ($event) {
                  return _vm.onGoUrl("/merchant/user/userConfig/cancellation")
                },
              },
            },
            [
              _vm._v("注销待审核 "),
              _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm.statisTotal.businessStatistics.userLogoutWaitAppleCount
                      ? _vm.statisTotal.businessStatistics
                          .userLogoutWaitAppleCount
                      : 0
                  )
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }