var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: {
          name: _vm.$route.query.vname + "-买家分析",
          hasBack: true,
          isFixed: true,
        },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.exportOrder },
            },
            [_vm._v("导出")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "text" },
              on: { click: _vm.getExportFileList },
            },
            [_vm._v("导出记录")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "mt20 ft12",
              attrs: {
                border: "",
                data: _vm.list,
                "element-loading-text": "Loading",
                fit: "",
                "highlight-current-row": "",
              },
              on: { "expand-change": _vm.changeExpandAction },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "expand", width: "30" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: props.row.tableLoading,
                                expression: "props.row.tableLoading",
                              },
                            ],
                            staticClass: "ft12",
                            attrs: {
                              data: props.row.list,
                              "element-loading-text": "Loading",
                              fit: "",
                              "highlight-current-row": "",
                            },
                          },
                          [
                            _c("template", { slot: "empty" }, [
                              _c("div", { staticClass: "tableEmpty" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "tableDiv" }, [
                                  _vm._v("暂无数据"),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "拍品", "min-width": "150" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", { staticClass: "flex" }, [
                                          _c("img", {
                                            staticClass: "user-avatar",
                                            attrs: {
                                              src:
                                                scope.row.product.image +
                                                "!120a",
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-one ml10" },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  "LOT" +
                                                    _vm._s(scope.row.lot) +
                                                    " " +
                                                    _vm._s(
                                                      scope.row.product
                                                        .store_name
                                                    )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "冻结保证金",
                                "min-width": "100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.frozen_price
                                          ? _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatPriceNumber(
                                                    scope.row.frozen_price,
                                                    2
                                                  )
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "出价", "min-width": "100" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.last_bid_record
                                          ? _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ft14 fWeight500",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatPriceNumber(
                                                        scope.row
                                                          .last_bid_record
                                                          .bid_price,
                                                        2
                                                      )
                                                    ) + "元"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              scope.row.last_bid_record
                                                .bid_price ===
                                              scope.row.now_price
                                                ? _c(
                                                    "span",
                                                    { staticClass: "lv ml10" },
                                                    [_vm._v("领先")]
                                                  )
                                                : _c(
                                                    "span",
                                                    { staticClass: "ml10" },
                                                    [_vm._v("出局")]
                                                  ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "代理出价", "min-width": "100" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.proxy_info
                                          ? _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ft14 fWeight500",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatPriceNumber(
                                                        Number(
                                                          scope.row.proxy_info
                                                            .proxy_price
                                                        ),
                                                        2
                                                      )
                                                    ) + "元"
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "拍品状态", "min-width": "100" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.auction_status === 1
                                          ? _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "lv" },
                                                [_vm._v("进行中")]
                                              ),
                                            ])
                                          : scope.row.auction_status === 2
                                          ? _c("div", [
                                              _c("span", [_vm._v("预展中")]),
                                            ])
                                          : _c("div", [
                                              _c("span", [_vm._v("已结束")]),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "买家",
                  "min-width": "160",
                  prop: "",
                  "search-key": "keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.user
                          ? _c("div", { staticClass: "flex" }, [
                              _c("img", {
                                staticClass: "user-avatar",
                                attrs: { src: scope.row.user.avatar, alt: "" },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex-one ml10" }, [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onDetails(
                                              scope.row.user.uid
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.user.real_name ||
                                              scope.row.user.nickname
                                          )
                                        ),
                                        scope.row.user.real_name
                                          ? _c("span", [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(
                                                    scope.row.user.nickname
                                                  ) +
                                                  ")"
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(_vm._s(scope.row.user.phone)),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "号牌",
                  "min-width": "100",
                  prop: "",
                  "search-key": "number",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "key-point" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(scope.row.number ? scope.row.number : "") +
                              "\n                  "
                          ),
                          scope.row.number_type == "特殊号牌"
                            ? _c(
                                "span",
                                {
                                  staticClass: "ts",
                                  staticStyle: { background: "#fa4f4f" },
                                },
                                [_vm._v("特")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.number_type == "免保号牌"
                            ? _c(
                                "span",
                                {
                                  staticClass: "ts",
                                  staticStyle: { background: "#ddb75b" },
                                },
                                [_vm._v("免")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.number_type == "现场号牌"
                            ? _c(
                                "span",
                                {
                                  staticClass: "ts",
                                  staticStyle: { background: "#000000" },
                                },
                                [_vm._v("现")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "渠道",
                  "min-width": "80",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.user_type))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "注册时间",
                  "min-width": "160",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.user
                          ? _c("div", [
                              _vm._v(_vm._s(scope.row.user.create_time)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "钱包余额",
                  "min-width": "90",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.userAccount
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    scope.row.userAccount.balance,
                                    2
                                  )
                                ) + "元"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "想买",
                  "min-width": "60",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.want_num) + "件")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "出价",
                  "min-width": "60",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.bid_num) + "次")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "买家历史",
                  "min-width": "200",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            "成交" +
                              _vm._s(scope.row.auction_pay_order_num) +
                              "件"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "付款" +
                              _vm._s(
                                _vm.formatPriceNumber(
                                  scope.row.auction_pay_price || 0,
                                  2
                                )
                              ) +
                              "元"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "违约" +
                              _vm._s(scope.row.auction_overdue_num) +
                              "次"
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.queryParams.limit,
              "current-page": _vm.queryParams.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.total,
            },
            on: { "current-change": _vm.pageChangeNew },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人工录入",
            visible: _vm.showUserSearch,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUserSearch = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入客户真实姓名" },
            model: {
              value: _vm.uname,
              callback: function ($$v) {
                _vm.uname = $$v
              },
              expression: "uname",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c justify-c mt40" },
            [
              _c("el-button", [_vm._v("取消")]),
              _vm._v(" "),
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v("\n        确认\n      "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: (_vm.showAddNumber.user ? "修改" : "添加") + "买家号牌",
            visible: _vm.showAddNumber.show,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showAddNumber, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-item-center mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("客户类型：")]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.showAddNumber.number_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.showAddNumber, "number_type", $$v)
                    },
                    expression: "showAddNumber.number_type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "3" } }, [
                    _vm._v("免保号牌"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "2" } }, [
                    _vm._v("特殊号牌"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("客户名称：")]),
              _vm._v(" "),
              _vm.showAddNumber.user
                ? _c("div", [_vm._v(_vm._s(_vm.showAddNumber.user.nickname))])
                : _c(
                    "el-select",
                    {
                      staticClass: "w200",
                      attrs: {
                        placeholder: "搜索客户昵称/手机号/真实姓名",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchUserAction,
                        loading: _vm.searchLoading,
                      },
                      model: {
                        value: _vm.showAddNumber.uid,
                        callback: function ($$v) {
                          _vm.$set(_vm.showAddNumber, "uid", $$v)
                        },
                        expression: "showAddNumber.uid",
                      },
                    },
                    _vm._l(_vm.userList, function (m, i) {
                      return _c(
                        "el-option",
                        {
                          key: "user" + i,
                          attrs: { value: m.uid, label: m.nickname },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avatar flex align-items-c" },
                            [
                              _c("img", { attrs: { src: m.avatar, alt: "" } }),
                              _vm._v(" "),
                              _c("div", { staticClass: "user-r flex-one" }, [
                                _c(
                                  "div",
                                  { staticClass: "line1" },
                                  [
                                    m.real_name
                                      ? [_vm._v(_vm._s(m.real_name) + " | ")]
                                      : _vm._e(),
                                    _vm._v(_vm._s(m.nickname)),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("div", [_vm._v(_vm._s(m.phone))]),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
              _vm._v(" "),
              [
                _c("span", { staticClass: "w100 ml20" }, [
                  _vm._v("设置号牌："),
                ]),
                _vm._v(" "),
                _c("el-input-number", {
                  staticClass: "w100",
                  attrs: {
                    type: "number",
                    controls: false,
                    placeholder: "输入号码",
                  },
                  model: {
                    value: _vm.showAddNumber.number,
                    callback: function ($$v) {
                      _vm.$set(_vm.showAddNumber, "number", $$v)
                    },
                    expression: "showAddNumber.number",
                  },
                }),
              ],
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "album-filter flex align-items-c mt10" }, [
            _c("span", { staticClass: "w100 mt10" }, [_vm._v("参拍专场：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.showAddNumber.change_album_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.showAddNumber, "change_album_type", $$v)
                      },
                      expression: "showAddNumber.change_album_type",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: 0 } }, [
                      _vm._v("\n            全部\n          "),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: 1 } }, [
                      _vm._v("\n            选择专场\n          "),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.showAddNumber.change_album_type === 1
            ? _c(
                "div",
                { staticClass: "album-filter flex align-items-c mt10" },
                [
                  _c("span", { staticClass: "w100 mt10" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-one" },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.showAddNumber.number_plate_rule,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.showAddNumber,
                                "number_plate_rule",
                                $$v
                              )
                            },
                            expression: "showAddNumber.number_plate_rule",
                          },
                        },
                        _vm._l(_vm.albumList, function (m, i) {
                          return _c(
                            "el-checkbox-button",
                            { key: i, attrs: { label: m.album_id } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(m.album_name) +
                                  " " +
                                  _vm._s(m.auction_num) +
                                  "件\n          "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "album-filter flex align-items-c mt10" },
            [
              _c("span", { staticClass: "w100 mt10" }, [_vm._v("备注:")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w200",
                attrs: {
                  type: "textarea",
                  rows: 2,
                  placeholder: "请输入备注内容",
                },
                model: {
                  value: _vm.showAddNumber.remarks,
                  callback: function ($$v) {
                    _vm.$set(_vm.showAddNumber, "remarks", $$v)
                  },
                  expression: "showAddNumber.remarks",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.showAddNumber.btnLoading,
                  },
                  on: { click: _vm.saveAddNumberAction },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  on: {
                    click: function ($event) {
                      _vm.showAddNumber.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加号牌组",
            visible: _vm.showAddNumberGroup.show,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showAddNumberGroup, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("号牌组名称：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w200",
                attrs: { placeholder: "输入名称" },
                model: {
                  value: _vm.showAddNumberGroup.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.showAddNumberGroup, "name", $$v)
                  },
                  expression: "showAddNumberGroup.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.showAddNumberGroup.btnLoading,
                  },
                  on: { click: _vm.saveNumberGroupAction },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { staticClass: "ml20" }, [_vm._v("取消")]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加号牌",
            visible: _vm.showBatchAddNumber.show,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showBatchAddNumber, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("输入号段：")]),
              _vm._v(" "),
              _c("el-input-number", {
                staticClass: "w100",
                attrs: { min: 0, controls: false, placeholder: "最小号段" },
                model: {
                  value: _vm.showBatchAddNumber.min,
                  callback: function ($$v) {
                    _vm.$set(_vm.showBatchAddNumber, "min", $$v)
                  },
                  expression: "showBatchAddNumber.min",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml10 mr10" }, [_vm._v("至")]),
              _vm._v(" "),
              _c("el-input-number", {
                staticClass: "w100",
                attrs: { min: 0, controls: false, placeholder: "最大号段" },
                model: {
                  value: _vm.showBatchAddNumber.max,
                  callback: function ($$v) {
                    _vm.$set(_vm.showBatchAddNumber, "max", $$v)
                  },
                  expression: "showBatchAddNumber.max",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("排除数字：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w300",
                attrs: { placeholder: "输入数字(多个,分隔 如：4,7)" },
                model: {
                  value: _vm.showBatchAddNumber.filter_number,
                  callback: function ($$v) {
                    _vm.$set(_vm.showBatchAddNumber, "filter_number", $$v)
                  },
                  expression: "showBatchAddNumber.filter_number",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.showBatchAddNumber.btnLoading,
                  },
                  on: { click: _vm.batchAddNumberAction },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { staticClass: "ml20" }, [_vm._v("取消")]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看号牌",
            visible: _vm.numberList.show,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.numberList, "show", $event)
            },
          },
        },
        [
          _vm.numberList.query
            ? _c(
                "div",
                { staticClass: "list" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.numberList.listLoading,
                          expression: "numberList.listLoading",
                        },
                      ],
                      staticClass: "mt20 ft12",
                      attrs: {
                        data: _vm.numberList.list,
                        "element-loading-text": "Loading",
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("template", { slot: "empty" }, [
                        _c("div", { staticClass: "tableEmpty" }, [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "tableDiv" }, [
                            _vm._v("暂无数据"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-table-column", {
                        key: "1",
                        attrs: { label: "号牌", prop: "name" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row.num) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3619475943
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        key: "2",
                        attrs: { label: "号牌类型" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          scope.row.type == 1
                                            ? "普通号牌"
                                            : "特殊号牌"
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3036510842
                        ),
                      }),
                      _vm._v(" "),
                      [
                        _c("el-table-column", {
                          key: "3",
                          attrs: { label: "创建时间" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(scope.row.create_time) +
                                          "\n              "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2005021215
                          ),
                        }),
                      ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt20 flex justify-e" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "page-size": _vm.numberList.query.limit,
                          "current-page": _vm.numberList.query.page,
                          layout: "total, prev, pager, next, jumper",
                          total: _vm.numberList.total,
                        },
                        on: { "current-change": _vm.pageChangeNumber },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { editMark: _vm.load, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            center: "",
            title: "查看图片",
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl, alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }