"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _authenticate = require("@/api/authenticate");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _system = require("@/api/system");
var _utils = require("@/utils");
var _components = require("echarts/components");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var echarts = require('echarts/lib/echarts');
require('echarts/lib/chart/line');
require('echarts/lib/chart/bar');
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');
require('echarts/lib/component/legend');
require('echarts/lib/component/legendScroll');
// require("echarts/lib/chart/pie");

echarts.use([_components.GridComponent]);
var SelectType = {
  '1': 'Expert',
  '3': 'PraisalReport',
  '4': 'PraisalCategory',
  '5': 'PraisalSetting',
  '2': 'Appraisal',
  '6': 'AppraisalComplaint'
};
var _default = exports.default = {
  name: 'PraisalReport',
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      formatPriceNumber: _utils.formatPriceNumber,
      activeMenu: '3',
      chartDate: '',
      status: 0,
      uid: '',
      date: '',
      stime: '',
      etime: [],
      startDateOption: {
        disabledDate: this.setStartDate
      },
      endDateOption: {
        disabledDate: this.setEndDate
      },
      searchLoading: false,
      searchKey: '',
      announcerList: [],
      selectType: 1,
      isStudy: 1,
      alldata: {},
      loading: false,
      estime: '',
      eetime: '',
      token: localStorage.getItem('token'),
      resultData: [],
      total: 0,
      queryParams: {
        page: 1,
        limit: 10,
        select_date: 'day'
      },
      colorArr: ['red', 'orange', 'blue'],
      hasCategory: false,
      suid: '',
      orderDay: 'today',
      payDay: 'today',
      loadLoading: true
    };
  },
  mounted: function mounted() {
    this.getAllData();
    this.setEchars();
    this.load('today');
    // this.searchPost("");
    this.loadTableData();
    // this.loadCategoryData();
  },
  methods: {
    setEchars: function setEchars() {
      this.orderEchart = echarts.init(document.getElementById('order'));
      this.moneyEchart = echarts.init(document.getElementById('money'));
      var option = {
        xAxis: {
          type: 'category',
          data: [''],
          name: '日期'
        },
        yAxis: {
          type: 'value',
          name: '个数'
        },
        series: [{
          data: [''],
          type: 'line'
        }]
      };
      var option1 = {
        xAxis: {
          type: 'category',
          data: [''],
          name: '日期'
        },
        yAxis: {
          type: 'value',
          name: '金额'
        },
        series: [{
          data: [''],
          type: 'line'
        }]
      };
      this.orderEchart.setOption(option);
      this.moneyEchart.setOption(option1);
    },
    onSelectTit: function onSelectTit(index) {
      var _this = this;
      this.activeMenu = index;
      this.$router.push({
        name: SelectType[index]
      });
      setTimeout(function () {
        _this.activeMenu = '3';
      });
    },
    formSubmit: function formSubmit(e) {
      if (this.cz) {
        this.cz = false;
        return false;
      }
      e.preventDefault();
      return false;
    },
    searchPost: function searchPost(data) {
      var _this2 = this;
      var params = {
        uname: data,
        page: 1,
        limit: 20
      };
      this.searchLoading = true;
      (0, _authenticate._getExpertList)(params).then(function (res) {
        if (res.status === 200) {
          _this2.announcerList = res.data.list;
        }
        _this2.searchLoading = false;
      }).catch(function (err) {
        console.log(err);
      });
    },
    changeType: function changeType(type) {
      this.selectType = type;
      this.load();
      this.loadCategoryData();
    },
    getAllData: function getAllData() {
      var _this3 = this;
      (0, _authenticate._getQuestionData)().then(function (res) {
        if (res.status === 200) {
          _this3.alldata = res.data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    loadPeopleData: function loadPeopleData() {
      var _this4 = this;
      this.loadLoading = true;
      var params = Object.assign({}, this.queryParams);
      // if (this.stime) {
      //   params.stime = new Date(this.stime).getTime() / 1000;
      // }
      if (this.etime && this.etime.length) {
        params.date = this.etime.join('-');
      }
      if (this.suid) {
        params.expert_uid = this.suid;
      }
      (0, _authenticate._getExpertTable)(params).then(function (res) {
        if (res.status === 200) {
          _this4.total = res.data.count;
          _this4.resultData = res.data.list;
          _this4.loadLoading = false;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    selectPeopleOrder: function selectPeopleOrder() {
      this.queryParams.page = 1;
      this.loadPeopleData();
    },
    loadTableData: function loadTableData() {
      var _this5 = this;
      var params = this.queryParams;
      (0, _authenticate._getQuestionTable)(params).then(function (res) {
        if (res.status === 200) {
          _this5.total = res.data.count;
          _this5.resultData = res.data.list;
          _this5.loadLoading = false;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    loadStudyTableData: function loadStudyTableData() {
      var _this6 = this;
      var params = this.queryParams;
      params.limit = this.queryParams.limit;
      (0, _authenticate._getQuestionStudyTable)(params).then(function (res) {
        if (res.status === 200) {
          _this6.total = res.data.count;
          _this6.resultData = res.data.list;
          _this6.loadLoading = false;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    changeTableType: function changeTableType(type) {
      this.loadLoading = true;
      this.queryParams.select_date = type;
      this.queryParams.page = 1;
      if (this.status === 2) {
        this.loadStudyTableData();
      } else if (this.status === 1) {
        this.loadPeopleData();
      } else {
        this.loadTableData();
      }
    },
    changeTableStatus: function changeTableStatus() {
      this.loadLoading = true;
      this.resultData = [];
      this.total = 0;
      this.queryParams.page = 1;
      if (this.status === 2) {
        this.loadStudyTableData();
      } else if (this.status === 1) {
        this.loadPeopleData();
      } else {
        this.loadTableData();
      }
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.limit = size;
      this.queryParams.page = 1;
      if (this.status === 2) {
        this.loadStudyTableData();
      } else if (this.status === 1) {
        this.loadPeopleData();
      } else {
        this.loadTableData();
      }
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      var newPage = page;
      this.$router.push({
        path: this.$route.path,
        query: {
          page: newPage
        }
      });
      if (this.status === 2) {
        this.loadStudyTableData();
      } else if (this.status === 1) {
        this.loadPeopleData();
      } else {
        this.loadTableData();
      }
    },
    onChangeDate: function onChangeDate(e) {
      this.date = e;
    },
    getExportFileList: function getExportFileList(type) {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(type);
    },
    exportAction: function exportAction(type) {
      var _this7 = this;
      var params = JSON.parse(JSON.stringify(this.queryParams));
      params.limit = this.queryParams.limit;
      if (this.stime) {
        params.stime = new Date(this.stime).getTime() / 1000;
      }
      if (this.etime) {
        params.etime = new Date(this.etime).getTime() / 1000;
      }
      if (this.suid && type == 'identificationExpertReport') {
        params.expert_uid = this.suid;
      }
      // 导出
      (0, _system.exportexcel)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
        export_type: type
      })).then(function (res) {
        var h = _this7.$createElement;
        _this7.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this7.$msgbox.close();
                _this7.getExportFileList(type);
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    load: function load(d, source) {
      var _this8 = this;
      var params = {
        date: d
      };
      (0, _authenticate._getQuestionOrder)(params).then(function (res) {
        if (res.status === 200) {
          if (!_this8.orderEchart) _this8.orderEchart = echarts.init(document.getElementById('order'));
          if (!_this8.moneyEchart) _this8.moneyEchart = echarts.init(document.getElementById('money'));
          if (res.data) {
            if (source == 'count' || !source) {
              var option = {
                xAxis: {
                  type: 'category',
                  data: res.data.times
                },
                color: _this8.colorArr,
                tooltip: {
                  trigger: 'axis'
                },
                legend: {
                  data: [{
                    name: '鉴定订单'
                  }, {
                    name: '偷学订单'
                  }, {
                    name: '退款订单'
                  }]
                },
                yAxis: {
                  type: 'value'
                },
                series: [{
                  data: res.data.questionCountData,
                  type: 'line',
                  name: '鉴定订单',
                  stack: '总量',
                  smooth: true
                }, {
                  data: res.data.studyCountData,
                  type: 'line',
                  name: '偷学订单',
                  smooth: true
                }, {
                  data: res.data.refundCountData,
                  type: 'line',
                  name: '退款订单',
                  smooth: true
                }]
              };
              _this8.orderEchart.setOption(option);
            }
            if (source == 'price' || !source) {
              var option1 = {
                xAxis: {
                  type: 'category',
                  data: res.data.times
                },
                color: _this8.colorArr,
                tooltip: {
                  trigger: 'axis'
                },
                legend: {
                  data: [{
                    name: '鉴定总金额'
                  }, {
                    name: '偷学总金额'
                  }, {
                    name: '退款金额'
                  }]
                },
                yAxis: {
                  type: 'value'
                },
                series: [{
                  data: res.data.questionPriceData,
                  type: 'line',
                  name: '鉴定总金额',
                  stack: '总量',
                  smooth: true
                }, {
                  data: res.data.studyPriceData,
                  type: 'line',
                  name: '偷学总金额',
                  smooth: true
                }, {
                  data: res.data.refundPriceData,
                  type: 'line',
                  name: '退款金额',
                  smooth: true
                }]
              };
              _this8.moneyEchart.setOption(option1);
            }
          } else {
            var _option = {
              xAxis: {
                type: 'category',
                data: [''],
                name: '日期'
              },
              yAxis: {
                type: 'value',
                name: '个数'
              },
              series: [{
                data: [''],
                type: 'line'
              }]
            };
            var _option2 = {
              xAxis: {
                type: 'category',
                data: [''],
                name: '日期'
              },
              yAxis: {
                type: 'value',
                name: '金额'
              },
              series: [{
                data: [''],
                type: 'line'
              }]
            };
            _this8.orderEchart.setOption(_option);
            _this8.moneyEchart.setOption(_option2);
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    changeStateEvent: function changeStateEvent(status) {
      this.status = status;
      if (status === 2) {
        this.isStudy = 2;
        this.queryParams.page = 1;
        this.loadStudyTableData();
      } else {
        //  2为偷学  1为未偷学
        this.isStudy = 1;
        if (status === 1) {
          this.queryParams.page = 1;
          this.loadPeopleData();
        } else {
          this.queryParams.page = 1;
          this.loadTableData();
        }
      }
      this.load();
    },
    formatMoney: function formatMoney(money, decimal) {
      var decimal1 = decimal > 0 && decimal <= 20 ? decimal : 2;
      var money2 = money;
      var money1 = "".concat(parseFloat("".concat(money2).replace(/[^\d-]/g, '')).toFixed(decimal1));
      var d = money1.split('.')[0].split('').reverse();
      //   const y = `.${money1.split('.')[1]}`;
      var q = '';
      for (var i = 0; i < d.length; i += 1) {
        q += d[i] + ((i + 1) % 3 === 0 && i + 1 !== d.length ? ',' : '');
      }
      return "".concat(q.split('').reverse().join(''));
    }
  }
};