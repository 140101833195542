var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "",
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _c("div", { staticClass: "all-h-print" }, [
        _vm.collectMoney.show
          ? _c(
              "div",
              { attrs: { id: "printorder" } },
              [
                _c("div", { staticClass: "detail-box" }, [
                  _c("div", { staticClass: "flex" }, [
                    _vm.merData.config && _vm.merData.config.mer_print_logo
                      ? _c("img", {
                          staticStyle: {
                            height: "70px",
                            "margin-right": "20px",
                          },
                          attrs: {
                            src: _vm.merData.config.mer_print_logo + "!120a",
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "detail-left" }, [
                      _c("div", { staticClass: "detail-left-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.collectMoney.data.merConfig.company_name
                            ) +
                            " | " +
                            _vm._s(_vm.switchLang("AccountRecon")) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "detail-left-desc",
                          staticStyle: {
                            "font-size": "14px",
                            "font-family": "'printEN'",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.collectMoney.data.merConfig
                                  .company_english_name
                              ) +
                              " | Reconciliation Bill\n        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "secTit" }, [
                  _vm._v(_vm._s(_vm.switchLang("BuyerCompany"))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-left-desc",
                    staticStyle: {
                      "font-size": "13px",
                      "font-family": "'printEN'",
                      "font-weight": "500",
                    },
                  },
                  [_vm._v(_vm._s(_vm.switchLang("BuyerCompany_en")))]
                ),
                _vm._v(" "),
                _vm.collectMoney.data.venueInfo
                  ? _c(
                      "div",
                      {
                        staticClass: "detail-left-title",
                        staticStyle: { "margin-top": "40px" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.collectMoney.data.venueInfo.title) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-list",
                    staticStyle: { "margin-top": "5px" },
                  },
                  [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.switchLang("SellerName")) + " SELLER'S NAME "
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-weight": "500",
                            "font-size": "16px",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.collectMoney.data.entrustUser.real_name
                                ? _vm.collectMoney.data.entrustUser.real_name
                                : _vm.collectMoney.data.entrustUser.nickname
                            )
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.collectMoney.data.contractCount < 2
                      ? _c("div", [
                          _vm._v(
                            _vm._s(_vm.switchLang("ContractNo")) +
                              " CONTRACT NO. "
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "500",
                                "font-size": "16px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.collectMoney.data.contractInfo
                                    ? _vm.collectMoney.data.contractInfo
                                        .contract_no
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("交易日期 DATE OF SALE "),
                      _vm.collectMoney.data.createTimeArea.min !=
                      _vm.collectMoney.data.createTimeArea.max
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "500",
                                "font-size": "16px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.collectMoney.data.createTimeArea.min
                                ) +
                                  " - " +
                                  _vm._s(
                                    _vm.collectMoney.data.createTimeArea.max
                                  )
                              ),
                            ]
                          )
                        : _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "500",
                                "font-size": "16px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.collectMoney.data.createTimeArea.min)
                              ),
                            ]
                          ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.isNo
                  ? _c(
                      "div",
                      { staticClass: "ptd-box" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ptd",
                            staticStyle: { "font-weight": "bold" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "ptd-title",
                                staticStyle: { flex: "1" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.switchLang("BuyerTabletu"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "font-family": "'printEN'" },
                                  },
                                  [_vm._v("LOT NO.")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.collectMoney.data.contractCount > 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "ptd-title",
                                    staticStyle: { flex: "2" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.switchLang("KtNo"))),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-family": "'printEN'",
                                        },
                                      },
                                      [_vm._v("CONTRACT NO.")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-title",
                                staticStyle: { flex: "2" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.switchLang("BuyerTablepp"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "font-family": "'printEN'" },
                                  },
                                  [_vm._v("DESCRIPTION")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-title",
                                staticStyle: { flex: "2" },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.switchLang("SALESSTATUS"))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "font-family": "'printEN'" },
                                  },
                                  [_vm._v("SALES STATUS")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-title",
                                staticStyle: { flex: "2" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.switchLang("BuyerTablelc"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "font-family": "'printEN'" },
                                  },
                                  [_vm._v("HAMMER PRICE")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-title",
                                staticStyle: { flex: "4" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.switchLang("REMUNERATION"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "font-family": "'printEN'" },
                                  },
                                  [_vm._v("COMMISSION")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-title",
                                staticStyle: { flex: "2" },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.switchLang("Amount"))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "font-family": "'printEN'" },
                                  },
                                  [_vm._v("SUBTOTAL")]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(
                          _vm.collectMoney.data.checkAuctionData,
                          function (m, i) {
                            return _c(
                              "div",
                              { key: "order" + i, staticClass: "ptd calcul" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { flex: "1" },
                                  },
                                  [_vm._v(_vm._s(m.pmAuction.lot))]
                                ),
                                _vm._v(" "),
                                _vm.collectMoney.data.contractCount > 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        staticStyle: { flex: "2" },
                                      },
                                      [_vm._v(_vm._s(m.contract.contract_no))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { flex: "2" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(m.pmAuction.product.store_name)
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { flex: "2" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        m.auctionStatusText == "交割失败"
                                          ? _vm.switchLang("NoPay")
                                          : m.auctionStatusText
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { flex: "2" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        m.auction_status == 5 ||
                                          m.auction_status == 6
                                          ? _vm.formatPriceNumber(
                                              m.pmAuction.now_price,
                                              2
                                            )
                                          : 0
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: {
                                      flex: "4",
                                      "flex-direction": "column",
                                    },
                                  },
                                  [
                                    m.auction_status <= 4
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              width: "100%",
                                              "justify-content": "space-around",
                                              "flex-wrap": "wrap",
                                            },
                                          },
                                          _vm._l(
                                            m.price_data[1],
                                            function (e, ind) {
                                              return _c(
                                                "div",
                                                {
                                                  key: "1" + ind,
                                                  staticStyle: {
                                                    "line-height": "20px",
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                  },
                                                },
                                                [
                                                  e.name &&
                                                  Number(e.deduction_amount) !=
                                                    0
                                                    ? [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(e.name) +
                                                              _vm._s(
                                                                e.unit == 1
                                                                  ? ""
                                                                  : e.number
                                                              ) +
                                                              _vm._s(
                                                                e.unit == 1
                                                                  ? ""
                                                                  : "%"
                                                              )
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        e.deduction_amount
                                                          ? _c("div", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formatPriceNumber(
                                                                    e.deduction_amount,
                                                                    2
                                                                  )
                                                                ) + "元"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              width: "100%",
                                              "justify-content": "space-around",
                                            },
                                          },
                                          _vm._l(
                                            m.price_data[0],
                                            function (e, ind) {
                                              return _c(
                                                "div",
                                                {
                                                  key: "0" + ind,
                                                  staticStyle: {
                                                    "line-height": "20px",
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                  },
                                                },
                                                [
                                                  e.name &&
                                                  Number(e.deduction_amount) !=
                                                    0
                                                    ? [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(e.name) +
                                                              _vm._s(
                                                                e.unit == 1
                                                                  ? ""
                                                                  : e.number
                                                              ) +
                                                              _vm._s(
                                                                e.unit == 1
                                                                  ? ""
                                                                  : "%"
                                                              )
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        e.deduction_amount
                                                          ? _c("div", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formatPriceNumber(
                                                                    e.deduction_amount,
                                                                    2
                                                                  )
                                                                ) + "元"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          width: "100%",
                                          "justify-content": "space-around",
                                        },
                                      },
                                      _vm._l(
                                        m.price_data[2],
                                        function (e, ind) {
                                          return _c(
                                            "div",
                                            {
                                              key: "2" + ind,
                                              staticStyle: {
                                                "line-height": "20px",
                                                display: "flex",
                                                "flex-direction": "column",
                                              },
                                            },
                                            [
                                              e.name &&
                                              Number(e.deduction_amount) != 0
                                                ? [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(e.name) +
                                                          _vm._s(
                                                            e.unit == 1
                                                              ? ""
                                                              : e.number
                                                          ) +
                                                          _vm._s(
                                                            e.unit == 1
                                                              ? ""
                                                              : "%"
                                                          )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    e.deduction_amount
                                                      ? _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatPriceNumber(
                                                                e.deduction_amount,
                                                                2
                                                              )
                                                            ) + "元"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { flex: "2" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatPriceNumber(
                                          m.settlement_amount,
                                          2
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ptd" }, [
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: {
                                flex: "1",
                                "flex-direction": "column",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.switchLang("Total"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("TOTAL")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.collectMoney.data.contractCount > 1
                            ? _c("div", {
                                staticClass: "ptd-body",
                                staticStyle: {
                                  flex: "2",
                                  "flex-direction": "column",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.collectMoney.data.checkAuctionData
                                      .length
                                  ) + "件"
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "ptd-body",
                            staticStyle: { flex: "2" },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    _vm.collectMoney.data.nowTotal,
                                    2
                                  )
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: {
                                flex: "4",
                                "flex-direction": "column",
                              },
                            },
                            [
                              _vm.collectMoney.data.obj.err > 0
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        width: "100%",
                                        "justify-content": "space-around",
                                      },
                                    },
                                    _vm._l(
                                      _vm.collectMoney.data.totalPriceData[1],
                                      function (e, ind) {
                                        return _c(
                                          "div",
                                          {
                                            key: "1" + ind,
                                            staticStyle: {
                                              "line-height": "20px",
                                              display: "flex",
                                              "flex-direction": "column",
                                            },
                                          },
                                          [
                                            e.name && Number(e.price) != 0
                                              ? [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(e.name) +
                                                        _vm._s(
                                                          e.unit == 1
                                                            ? ""
                                                            : e.number
                                                        ) +
                                                        _vm._s(
                                                          e.unit == 1 ? "" : "%"
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  e.price
                                                    ? _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatPriceNumber(
                                                              e.price,
                                                              2
                                                            )
                                                          ) + "元"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.collectMoney.data.obj.succ > 0
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        width: "100%",
                                        "justify-content": "space-around",
                                      },
                                    },
                                    _vm._l(
                                      _vm.collectMoney.data.totalPriceData[0],
                                      function (e, ind) {
                                        return _c(
                                          "div",
                                          {
                                            key: "0" + ind,
                                            staticStyle: {
                                              "line-height": "20px",
                                              display: "flex",
                                              "flex-direction": "column",
                                            },
                                          },
                                          [
                                            e.name && Number(e.price) != 0
                                              ? [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(e.name) +
                                                        _vm._s(
                                                          e.unit == 1
                                                            ? ""
                                                            : e.number
                                                        ) +
                                                        _vm._s(
                                                          e.unit == 1 ? "" : "%"
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  e.price
                                                    ? _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatPriceNumber(
                                                              e.price,
                                                              2
                                                            )
                                                          ) + "元"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    width: "100%",
                                    "justify-content": "space-around",
                                  },
                                },
                                _vm._l(
                                  _vm.collectMoney.data.totalPriceData[2],
                                  function (e, ind) {
                                    return _c(
                                      "div",
                                      {
                                        key: "2" + ind,
                                        staticStyle: {
                                          "line-height": "20px",
                                          display: "flex",
                                          "flex-direction": "column",
                                        },
                                      },
                                      [
                                        e.name && Number(e.price) != 0
                                          ? [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(e.name) +
                                                    _vm._s(
                                                      e.unit == 1
                                                        ? ""
                                                        : e.number
                                                    ) +
                                                    _vm._s(
                                                      e.unit == 1 ? "" : "%"
                                                    )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              e.price
                                                ? _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatPriceNumber(
                                                          e.price,
                                                          2
                                                        )
                                                      ) + "元"
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    _vm.collectMoney.data.totalSettlementAmount,
                                    2
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ptd" }, [
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body blb",
                              staticStyle: { "flex-direction": "column" },
                              style:
                                _vm.collectMoney.data.contractCount > 1
                                  ? "flex: 5;padding-right: 52px;"
                                  : "flex: 3;",
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.switchLang("AdditionalAmount"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-family": "'printEN'",
                                    "text-align": "left",
                                  },
                                },
                                [_vm._v("ADDITIONAL AMOUNT")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body blb",
                              staticStyle: { flex: "4" },
                            },
                            [
                              _vm._v(
                                "+" +
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data
                                        .totalExtraSettlementAmount,
                                      2
                                    )
                                  )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: {
                                flex: "4",
                                "flex-direction": "column",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "text-align": "left" } },
                                [_vm._v(_vm._s(_vm.switchLang("PaidAmount")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-family": "'printEN'",
                                    "text-align": "left",
                                  },
                                },
                                [_vm._v("ACTUAL PAYMENT AMOUNT")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    _vm.collectMoney.data
                                      .totalPaySettlementAmount,
                                    2
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd blb",
                            style:
                              _vm.collectMoney.data.contractCount > 1
                                ? "padding: 0;"
                                : "padding: 0;",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { "flex-direction": "column" },
                                style:
                                  _vm.collectMoney.data.contractCount > 1
                                    ? "flex: 5;padding-right: 15px;"
                                    : "flex: 3;padding-right: 8px;",
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.switchLang("feeTypeText"))
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-family": "'printEN'",
                                      "text-align": "left",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "CAPITAL IN " +
                                        _vm._s(
                                          _vm.feeTypeObj.type == "CNY"
                                            ? "RMB"
                                            : _vm.feeTypeObj.type
                                        )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                style:
                                  _vm.collectMoney.data.contractCount > 1
                                    ? "flex: 10;border-left: 2px solid transparent;padding-right: 0;"
                                    : "flex: 10;border-left: 2px solid transparent;padding-right: 0;",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.collectMoney.data
                                      .totalPaySettlementAmountRmb
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      2
                    )
                  : [
                      _vm._l(_vm.result, function (item, ind) {
                        return _c(
                          "div",
                          {
                            key: ind,
                            staticClass: "ptd-box",
                            style:
                              ind < _vm.result.length - 1
                                ? "page-break-after: always;"
                                : "",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "ptd",
                                staticStyle: { "font-weight": "bold" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-title",
                                    staticStyle: { flex: "1" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.switchLang("BuyerTabletu"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-family": "'printEN'",
                                        },
                                      },
                                      [_vm._v("LOT NO.")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.collectMoney.data.contractCount > 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-title",
                                        staticStyle: { flex: "2" },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.switchLang("KtNo"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-family": "'printEN'",
                                            },
                                          },
                                          [_vm._v("CONTRACT NO.")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-title",
                                    staticStyle: { flex: "2" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.switchLang("BuyerTablepp"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-family": "'printEN'",
                                        },
                                      },
                                      [_vm._v("DESCRIPTION")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-title",
                                    staticStyle: { flex: "2" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.switchLang("SALESSTATUS"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-family": "'printEN'",
                                        },
                                      },
                                      [_vm._v("SALES STATUS")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-title",
                                    staticStyle: { flex: "2" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.switchLang("BuyerTablelc"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-family": "'printEN'",
                                        },
                                      },
                                      [_vm._v("HAMMER PRICE")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-title",
                                    staticStyle: { flex: "4" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.switchLang("REMUNERATION"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-family": "'printEN'",
                                        },
                                      },
                                      [_vm._v("COMMISSION")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-title",
                                    staticStyle: { flex: "2" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.switchLang("Amount"))),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-family": "'printEN'",
                                        },
                                      },
                                      [_vm._v("SUBTOTAL")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(item, function (m, i) {
                              return _c(
                                "div",
                                { key: "order" + i, staticClass: "ptd" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      staticStyle: { flex: "1" },
                                    },
                                    [_vm._v(_vm._s(m.pmAuction.lot))]
                                  ),
                                  _vm._v(" "),
                                  _vm.collectMoney.data.contractCount > 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          staticStyle: { flex: "2" },
                                        },
                                        [_vm._v(_vm._s(m.contract.contract_no))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      staticStyle: { flex: "2" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(m.pmAuction.product.store_name)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      staticStyle: { flex: "2" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          m.auctionStatusText == "交割失败"
                                            ? _vm.switchLang("NoPay")
                                            : m.auctionStatusText
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      staticStyle: { flex: "2" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          m.auction_status == 5 ||
                                            m.auction_status == 6
                                            ? _vm.formatPriceNumber(
                                                m.pmAuction.now_price,
                                                2
                                              )
                                            : 0
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      staticStyle: {
                                        flex: "4",
                                        "flex-direction": "column",
                                      },
                                    },
                                    [
                                      m.auction_status <= 4
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                width: "100%",
                                                "justify-content":
                                                  "space-around",
                                              },
                                            },
                                            _vm._l(
                                              m.price_data[1],
                                              function (e, ind) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: "1" + ind,
                                                    staticStyle: {
                                                      "line-height": "20px",
                                                      display: "flex",
                                                      "flex-direction":
                                                        "column",
                                                    },
                                                  },
                                                  [
                                                    e.name &&
                                                    Number(
                                                      e.deduction_amount
                                                    ) != 0
                                                      ? [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(e.name) +
                                                                _vm._s(
                                                                  e.unit == 1
                                                                    ? ""
                                                                    : e.number
                                                                ) +
                                                                _vm._s(
                                                                  e.unit == 1
                                                                    ? ""
                                                                    : "%"
                                                                )
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          e.deduction_amount
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.formatPriceNumber(
                                                                      e.deduction_amount,
                                                                      2
                                                                    )
                                                                  ) + "元"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                width: "100%",
                                                "justify-content":
                                                  "space-around",
                                              },
                                            },
                                            _vm._l(
                                              m.price_data[0],
                                              function (e, ind) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: "0" + ind,
                                                    staticStyle: {
                                                      "line-height": "20px",
                                                      display: "flex",
                                                      "flex-direction":
                                                        "column",
                                                    },
                                                  },
                                                  [
                                                    e.name &&
                                                    Number(
                                                      e.deduction_amount
                                                    ) != 0
                                                      ? [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(e.name) +
                                                                _vm._s(
                                                                  e.unit == 1
                                                                    ? ""
                                                                    : e.number
                                                                ) +
                                                                _vm._s(
                                                                  e.unit == 1
                                                                    ? ""
                                                                    : "%"
                                                                )
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          e.deduction_amount
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.formatPriceNumber(
                                                                      e.deduction_amount,
                                                                      2
                                                                    )
                                                                  ) + "元"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            width: "100%",
                                            "justify-content": "space-around",
                                          },
                                        },
                                        _vm._l(
                                          m.price_data[2],
                                          function (e, ind) {
                                            return _c(
                                              "div",
                                              {
                                                key: "2" + ind,
                                                staticStyle: {
                                                  "line-height": "20px",
                                                  display: "flex",
                                                  "flex-direction": "column",
                                                },
                                              },
                                              [
                                                e.name &&
                                                Number(e.deduction_amount) != 0
                                                  ? [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(e.name) +
                                                            _vm._s(
                                                              e.unit == 1
                                                                ? ""
                                                                : e.number
                                                            ) +
                                                            _vm._s(
                                                              e.unit == 1
                                                                ? ""
                                                                : "%"
                                                            )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      e.deduction_amount
                                                        ? _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatPriceNumber(
                                                                  e.deduction_amount,
                                                                  2
                                                                )
                                                              ) + "元"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      staticStyle: { flex: "2" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatPriceNumber(
                                            m.settlement_amount,
                                            2
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-box",
                          staticStyle: {
                            "margin-top": "0",
                            "border-top": "none",
                          },
                        },
                        [
                          _c("div", { staticClass: "ptd" }, [
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: {
                                  flex: "1",
                                  "flex-direction": "column",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.switchLang("Total"))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "font-family": "'printEN'" },
                                  },
                                  [_vm._v("TOTAL")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.collectMoney.data.contractCount > 1
                              ? _c("div", {
                                  staticClass: "ptd-body",
                                  staticStyle: {
                                    flex: "2",
                                    "flex-direction": "column",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "2" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.collectMoney.data.checkAuctionData
                                        .length
                                    ) + "件"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "2" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data.nowTotal,
                                      2
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: {
                                  flex: "4",
                                  "flex-direction": "column",
                                },
                              },
                              [
                                _vm.collectMoney.data.obj.err > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          width: "100%",
                                          "justify-content": "space-around",
                                        },
                                      },
                                      _vm._l(
                                        _vm.collectMoney.data.totalPriceData[1],
                                        function (e, ind) {
                                          return _c(
                                            "div",
                                            {
                                              key: "1" + ind,
                                              staticStyle: {
                                                "line-height": "20px",
                                                display: "flex",
                                                "flex-direction": "column",
                                              },
                                            },
                                            [
                                              e.name && Number(e.price) != 0
                                                ? [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(e.name) +
                                                          _vm._s(
                                                            e.unit == 1
                                                              ? ""
                                                              : e.number
                                                          ) +
                                                          _vm._s(
                                                            e.unit == 1
                                                              ? ""
                                                              : "%"
                                                          )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    e.price
                                                      ? _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatPriceNumber(
                                                                e.price,
                                                                2
                                                              )
                                                            ) + "元"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.collectMoney.data.obj.succ > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          width: "100%",
                                          "justify-content": "space-around",
                                        },
                                      },
                                      _vm._l(
                                        _vm.collectMoney.data.totalPriceData[0],
                                        function (e, ind) {
                                          return _c(
                                            "div",
                                            {
                                              key: "0" + ind,
                                              staticStyle: {
                                                "line-height": "20px",
                                                display: "flex",
                                                "flex-direction": "column",
                                              },
                                            },
                                            [
                                              e.name && Number(e.price) != 0
                                                ? [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(e.name) +
                                                          _vm._s(
                                                            e.unit == 1
                                                              ? ""
                                                              : e.number
                                                          ) +
                                                          _vm._s(
                                                            e.unit == 1
                                                              ? ""
                                                              : "%"
                                                          )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    e.price
                                                      ? _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatPriceNumber(
                                                                e.price,
                                                                2
                                                              )
                                                            ) + "元"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      width: "100%",
                                      "justify-content": "space-around",
                                    },
                                  },
                                  _vm._l(
                                    _vm.collectMoney.data.totalPriceData[2],
                                    function (e, ind) {
                                      return _c(
                                        "div",
                                        {
                                          key: "2" + ind,
                                          staticStyle: {
                                            "line-height": "20px",
                                            display: "flex",
                                            "flex-direction": "column",
                                          },
                                        },
                                        [
                                          e.name && Number(e.price) != 0
                                            ? [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(e.name) +
                                                      _vm._s(
                                                        e.unit == 1
                                                          ? ""
                                                          : e.number
                                                      ) +
                                                      _vm._s(
                                                        e.unit == 1 ? "" : "%"
                                                      )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                e.price
                                                  ? _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatPriceNumber(
                                                            e.price,
                                                            2
                                                          )
                                                        ) + "元"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "2" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data
                                        .totalSettlementAmount,
                                      2
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "ptd" }, [
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body blb",
                                staticStyle: { "flex-direction": "column" },
                                style:
                                  _vm.collectMoney.data.contractCount > 1
                                    ? "flex: 5;padding-right: 52px;"
                                    : "flex: 3;",
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.switchLang("AdditionalAmount"))
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-family": "'printEN'",
                                      "text-align": "left",
                                    },
                                  },
                                  [_vm._v("ADDITIONAL AMOUNT")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body blb",
                                staticStyle: { flex: "4" },
                              },
                              [
                                _vm._v(
                                  "+" +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        _vm.collectMoney.data
                                          .totalExtraSettlementAmount,
                                        2
                                      )
                                    )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: {
                                  flex: "4",
                                  "flex-direction": "column",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [_vm._v(_vm._s(_vm.switchLang("PaidAmount")))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-family": "'printEN'",
                                      "text-align": "left",
                                    },
                                  },
                                  [_vm._v("ACTUAL PAYMENT AMOUNT")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "2" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data
                                        .totalPaySettlementAmount,
                                      2
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd blb",
                              style:
                                _vm.collectMoney.data.contractCount > 1
                                  ? "padding: 0;"
                                  : "padding: 0;",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  staticStyle: { "flex-direction": "column" },
                                  style:
                                    _vm.collectMoney.data.contractCount > 1
                                      ? "flex: 5;padding-right: 15px;"
                                      : "flex: 3;padding-right: 8px;",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "text-align": "left" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.switchLang("feeTypeText"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                        "text-align": "left",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "CAPITAL IN " +
                                          _vm._s(
                                            _vm.feeTypeObj.type == "CNY"
                                              ? "RMB"
                                              : _vm.feeTypeObj.type
                                          )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  style:
                                    _vm.collectMoney.data.contractCount > 1
                                      ? "flex: 10;border-left: 2px solid transparent;padding-right: 0;"
                                      : "flex: 10;border-left: 2px solid transparent;padding-right: 0;",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.collectMoney.data
                                        .totalPaySettlementAmountRmb
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "secTit",
                    staticStyle: {
                      "margin-top": "30px",
                      "margin-bottom": "10px",
                    },
                  },
                  [_vm._v(_vm._s(_vm.switchLang("AccountText")) + "。")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-left-desc",
                    staticStyle: {
                      "font-size": "13px",
                      "font-family": "'printEN'",
                      "font-weight": "500",
                      "word-wrap": "break-word",
                      "word-break": "normal",
                    },
                  },
                  [
                    _vm._v(
                      "Dear client, thank you very much for your support and assistance to our company. We are now submitting the transaction results of the works you entrusted our company for auction. Please check and verify them. Please continue to support our company as always. Thank you."
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "print-line",
                  staticStyle: { "margin-top": "30px" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "detail-sign" }, [
                  _vm._v(_vm._s(_vm.switchLang("SellerSign"))),
                  _c("span", { staticStyle: { "font-family": "'printEN'" } }, [
                    _vm._v("（SIGNATURE）"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "print-line" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-list",
                    staticStyle: { "margin-top": "30px" },
                  },
                  [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.switchLang("OrderMak")) +
                          "：" +
                          _vm._s(_vm.collectMoney.data.adminInfo.real_name)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(_vm._s(_vm.switchLang("UnitSeal")) + "："),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(_vm._s(_vm.switchLang("CollectionPeo")) + "："),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.switchLang("DocumentNumber")) +
                          "：" +
                          _vm._s(_vm.no)
                      ),
                    ]),
                  ]
                ),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintorder },
            },
            [_vm._v("打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }