var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: "merchantUserCreateVerify",
                    expression: "'merchantUserCreateVerify'",
                  },
                ],
                staticStyle: { "margin-bottom": "20px" },
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.addAuthAction },
              },
              [_vm._v("新增实名认证")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 170px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "userVerRef",
              staticStyle: { width: "100%" },
              attrs: {
                height: "100%",
                data: _vm.tableData.data,
                border: "",
                size: "small",
              },
            },
            [
              _c("ytx-table-column", {
                attrs: {
                  label: "ID",
                  width: "60",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [[_vm._v(_vm._s(row.uid))]]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "头像",
                  width: "50",
                  prop: "",
                  align: "center",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.avatar
                          ? _c("img", {
                              staticStyle: {
                                width: "25px",
                                height: "25px",
                                cursor: "pointer",
                                "margin-top": "5px",
                              },
                              attrs: {
                                src: scope.row.avatar
                                  ? scope.row.avatar
                                  : _vm.moren,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onDetails(scope.row)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "用户昵称",
                  "min-width": "180",
                  prop: "",
                  "search-key": "user_nickname",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "tabHead" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#6699dd",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onDetails(row)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(row.user.nickname))]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "真实姓名",
                  "min-width": "120",
                  prop: "",
                  "search-key": "keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " + _vm._s(row.real_name) + "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "手机号",
                  "min-width": "100",
                  prop: "",
                  "search-key": "user_phone",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " + _vm._s(row.user.phone) + "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "身份证",
                  "min-width": "120",
                  prop: "",
                  permission: "merchantUserVerifyList",
                  "permission-column": "idcard",
                  "search-key": "keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            row.idcard_just_photo
                              ? _c("el-image", {
                                  staticStyle: {
                                    width: "25px",
                                    height: "25px",
                                    cursor: "pointer",
                                    "margin-top": "5px",
                                  },
                                  attrs: {
                                    src: row.idcard_just_photo
                                      ? row.idcard_just_photo
                                      : "",
                                    "preview-src-list": [
                                      row.idcard_just_photo,
                                      row.idcard_back_photo,
                                    ],
                                  },
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n            " +
                                _vm._s(row.idcard) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "认证时间",
                  "min-width": "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(row.create_time) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "审核状态",
                  width: "100",
                  prop: "",
                  "search-key": "verify_status",
                  "search-type": "radio",
                  options: [
                    { label: "待审核", value: "2" },
                    { label: "已认证", value: "1" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.verify_status == 1
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "info" } },
                              [_vm._v("已认证")]
                            )
                          : row.verify_status == 2
                          ? _c("el-tag", { attrs: { size: "small" } }, [
                              _vm._v("待审核"),
                            ])
                          : row.verify_status == -1
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "danger" } },
                              [_vm._v("已拒绝")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.verify_status == -1 && row.fail_msg
                          ? _c("div", [
                              _vm._v("拒绝理由：" + _vm._s(row.fail_msg)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "TableRightBtn",
                          [
                            scope.row.verify_status == 1
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: "merchantUserEditVerify",
                                        expression: "'merchantUserEditVerify'",
                                      },
                                    ],
                                    staticStyle: { "font-size": "14px" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editAuthAction(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "commonedit",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("修改"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "template",
                              { slot: "pop_btn" },
                              [
                                scope.row.verify_status == 2
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "merchantUserVerifyAudit",
                                            expression:
                                              "'merchantUserVerifyAudit'",
                                          },
                                        ],
                                        staticStyle: { "font-size": "14px" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showRealNameAuthentication(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "common-shenhe",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v("实名审核"),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-b orderFoot" },
        [
          _c(
            "div",
            { staticClass: "flex-one" },
            [
              _c(
                "el-dropdown",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantSystemExcelExport:merchantVerifyUser",
                      expression:
                        "'merchantSystemExcelExport:merchantVerifyUser'",
                    },
                  ],
                  attrs: { type: "primary", placement: "top" },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary", size: "small" },
                    },
                    [_vm._v("导出")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.exportOrder },
                            },
                            [_vm._v("导出表格")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.getExportFileList },
                            },
                            [_vm._v("导出记录")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.userFrom.limit,
              "current-page": _vm.userFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: {
                editMark: _vm.getList,
                close: function ($event) {
                  _vm.showdetail = false
                },
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.realNameAuthentication.show,
            title: "审核信息",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.realNameAuthentication, "show", $event)
            },
          },
        },
        [
          _c("div", [
            _vm._v("姓名：" + _vm._s(_vm.realNameAuthentication.real_name)),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("证件类型："),
            _vm.realNameAuthentication.idcard_type == 1
              ? _c("span", [_vm._v("大陆身份证")])
              : _vm.realNameAuthentication.idcard_type == 2
              ? _c("span", [_vm._v("香港身份证")])
              : _vm.realNameAuthentication.idcard_type == 3
              ? _c("span", [_vm._v("澳门身份证")])
              : _vm.realNameAuthentication.idcard_type == 4
              ? _c("span", [_vm._v("台湾身份证")])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("证件号码：" + _vm._s(_vm.realNameAuthentication.idcard)),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("证件照片：")]),
          _vm._v(" "),
          _c("div", { staticClass: "flex align-items-c mt10" }, [
            _c("div", { staticClass: "l flex-one" }, [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.realNameAuthentication.idcard_just_photo },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "r flex-one ml20" }, [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.realNameAuthentication.idcard_back_photo },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt10" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.realNameAuthentication.result,
                    callback: function ($$v) {
                      _vm.$set(_vm.realNameAuthentication, "result", $$v)
                    },
                    expression: "realNameAuthentication.result",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("同意")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: -1 } }, [_vm._v("拒绝")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-one ml20" },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.realNameAuthentication.result == 1,
                      placeholder: "请输入拒绝理由",
                    },
                    model: {
                      value: _vm.realNameAuthentication.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.realNameAuthentication, "reason", $$v)
                      },
                      expression: "realNameAuthentication.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex align-items-c mt20",
              staticStyle: { "padding-bottom": "20px" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveVerifyAction },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.closeAction } }, [
                _vm._v("取消"),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.authenticationData.show,
            title: "实名认证",
            "close-on-click-modal": false,
            width: "650px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.authenticationData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("绑定用户：")]),
              _vm._v(" "),
              _vm.authenticationData.user
                ? _c("div", [
                    _vm._v(
                      _vm._s(_vm.authenticationData.user.nickname) +
                        " | " +
                        _vm._s(_vm.authenticationData.user.real_name)
                    ),
                  ])
                : _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: {
                        placeholder: "搜索客户昵称/手机号/真实姓名",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchUserAction,
                        loading: _vm.searchLoading,
                      },
                      on: { change: _vm.changeUserAction },
                      model: {
                        value: _vm.authenticationData.uid,
                        callback: function ($$v) {
                          _vm.$set(_vm.authenticationData, "uid", $$v)
                        },
                        expression: "authenticationData.uid",
                      },
                    },
                    _vm._l(_vm.userList, function (m, i) {
                      return _c(
                        "el-option",
                        {
                          key: "user" + i,
                          attrs: { value: m.uid, label: m.nickname },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avatar flex align-items-c" },
                            [
                              _c("img", { attrs: { src: m.avatar, alt: "" } }),
                              _vm._v(" "),
                              _c("div", { staticClass: "user-r flex-one" }, [
                                _c(
                                  "div",
                                  { staticClass: "line1" },
                                  [
                                    m.real_name
                                      ? [_vm._v(_vm._s(m.real_name) + " | ")]
                                      : _vm._e(),
                                    _vm._v(_vm._s(m.nickname)),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("div", [_vm._v(_vm._s(m.phone))]),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "edit flex align-items-c mt20" }, [
            _c("div", { staticClass: "l" }, [_vm._v("身份证类型：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "r" },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.authenticationData.idcard_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.authenticationData, "idcard_type", $$v)
                      },
                      expression: "authenticationData.idcard_type",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("大陆身份证"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 2 } }, [
                      _vm._v("香港身份证"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 3 } }, [
                      _vm._v("澳门身份证"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 4 } }, [
                      _vm._v("台湾身份证"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "id-img flex align-items-c mt20" }, [
            _c("div", { staticClass: "flex align-items-c flex-one" }, [
              _c("div", { staticClass: "img-title" }, [
                _c("div", [_vm._v("身份证正面：")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center" },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "el-upload-view",
                      attrs: {
                        action: "/",
                        "show-file-list": false,
                        "http-request": function (file) {
                          return _vm.uploadImg(file, "idcard_just_photo")
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "item-flex-center",
                          staticStyle: { cursor: "pointer" },
                        },
                        [
                          _vm.authenticationData.idcard_just_photo
                            ? _c("img", {
                                attrs: {
                                  src: _vm.authenticationData.idcard_just_photo,
                                },
                              })
                            : !_vm.frontImgLoading
                            ? _c("img", {
                                staticStyle: { width: "20px" },
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20220401/dd73557f682fc5c3eff8f284da5445fa.png",
                                },
                              })
                            : _c("img", {
                                staticStyle: { width: "20px" },
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20220401/a0a8a73f525c43c1035781e73fdd7c22.gif",
                                },
                              }),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex align-items-c flex-one" }, [
              _c("div", { staticClass: "img-title" }, [
                _c("div", [_vm._v("身份证反面：")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center" },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "el-upload-view",
                      attrs: {
                        action: "/",
                        "show-file-list": false,
                        "http-request": function (file) {
                          return _vm.uploadImg(file, "idcard_back_photo")
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "item-flex-center",
                          staticStyle: { cursor: "pointer" },
                        },
                        [
                          _vm.authenticationData.idcard_back_photo
                            ? _c("img", {
                                attrs: {
                                  src: _vm.authenticationData.idcard_back_photo,
                                },
                              })
                            : !_vm.backImgLoading
                            ? _c("img", {
                                staticStyle: { width: "20px" },
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20220401/dd73557f682fc5c3eff8f284da5445fa.png",
                                },
                              })
                            : _c("img", {
                                staticStyle: { width: "20px" },
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20220401/a0a8a73f525c43c1035781e73fdd7c22.gif",
                                },
                              }),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit flex align-items-c mt20" }, [
            _c("div", { staticClass: "l" }, [_vm._v("真实姓名：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "r" },
              [
                _c("el-input", {
                  model: {
                    value: _vm.authenticationData.real_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.authenticationData, "real_name", $$v)
                    },
                    expression: "authenticationData.real_name",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit flex align-items-c mt20" }, [
            _c("div", { staticClass: "l" }, [_vm._v("身份证：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "r" },
              [
                _c("el-input", {
                  model: {
                    value: _vm.authenticationData.idcard_no,
                    callback: function ($$v) {
                      _vm.$set(_vm.authenticationData, "idcard_no", $$v)
                    },
                    expression: "authenticationData.idcard_no",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit flex align-items-c mt20" }, [
            _c("div", { staticClass: "l" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "r" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.authenticationData.show = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.saveAuthAction },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }