"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auction = require("@/api/auction");
var _user = require("@/api/user");
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'num',
  components: {
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      listLoading: false,
      grouplist: [{
        name: '特殊号牌',
        number_num: 0,
        type: 2
      }, {
        name: '现场号牌',
        number_num: 0,
        type: 1
      }],
      showBatchAddNumber: {
        show: false,
        btnLoading: false,
        min: undefined,
        max: undefined,
        id: '',
        number_type: 1,
        filter_number: ''
      },
      // 批量添加号牌 数据

      numberList: {},
      // 号牌列表

      list: [],
      total: 0,
      queryParams: {
        page: 1,
        limit: 20
        // limit: 1,
      },
      type: 1,
      addUserData: {
        show: false,
        uid: '',
        remarks: '',
        btnLoading: false,
        expire_time: ''
      },
      searchLoading: false,
      userList: [],
      uid: '',
      showdetail: false,
      multipleSelection: [],
      user_keyword: '',
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      }
    };
  },
  computed: {
    fileUrl: function fileUrl() {
      return _settingMer.default.https + "/excel/import/import_pm_number_plate";
    }
  },
  mounted: function mounted() {
    this.loadNumberGroup();
    this.load();
  },
  methods: {
    handleSuccess: function handleSuccess(response) {
      if (response.status === 200) {
        this.$message.success('上传成功');
        this.loadNumberGroup();
      } else {
        this.$message.error(response.message);
      }
      this.$refs.uploadImg.clearFiles();
    },
    downloadAction: function downloadAction() {
      window.open(_settingMer.default.https + "/excel/templateDownload?type=import_pm_number_plate&token=" + (0, _auth.getToken)());
    },
    addUserAction: function addUserAction() {
      this.addUserData = {
        show: true,
        id: '',
        uid: '',
        remarks: '',
        btnLoading: false,
        expire_time: '',
        type: this.type
      };
    },
    editAction: function editAction(m) {
      var _this = this;
      var d = new Date(m.expire_time_format);
      this.userList = [m.user];
      setTimeout(function () {
        _this.addUserData = {
          id: m.bond_member_id,
          show: true,
          uid: m.uid,
          remarks: m.remarks,
          btnLoading: false,
          expire_time: Date.parse(d),
          type: m.type,
          number: m.number
        };
      }, 200);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    searchUserAction: function searchUserAction(data) {
      var _this2 = this;
      var params = {
        keyword: data,
        page: 1,
        limit: 20
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this2.userList = res.data.list;
      });
    },
    saveAction: function saveAction() {
      var _this3 = this;
      this.addUserData.btnLoading = true;
      var data = {
        uid: this.addUserData.uid,
        remarks: this.addUserData.remarks,
        expire_time: this.addUserData.expire_time / 1000,
        type: this.addUserData.type,
        number: this.addUserData.number
      };
      if (!data.uid) {
        this.$message.error('请选择客户');
        this.addUserData.btnLoading = false;
        return false;
      }
      if (!data.expire_time) {
        this.$message.error('请选择有效期');
        this.addUserData.btnLoading = false;
        return false;
      }
      this.$confirm("\u786E\u8BA4".concat(this.addUserData.id ? '编辑' : '新增').concat(this.addUserData.type === 1 ? '免保' : '特殊', "\u7528\u6237?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.bondMemberUserSetApi)(data).then(function (res) {
          _this3.$message.success("\u64CD\u4F5C\u6210\u529F");
          _this3.load();
          _this3.addUserData.btnLoading = false;
          _this3.addUserData.show = false;
        }).catch(function (message) {
          _this3.addUserData.btnLoading = false;
        });
      }).catch(function () {
        _this3.addUserData.btnLoading = false;
      });
    },
    changeTypeAction: function changeTypeAction(val) {
      this.type = val;
      this.list = [];
      this.pageChange(1);
    },
    load: function load() {
      var _this4 = this;
      this.listLoading = true;
      this.queryParams.type = this.type;
      this.queryParams.user_keyword = this.user_keyword;
      (0, _auction.bondMemberUserListApi)(this.queryParams).then(function (response) {
        _this4.total = response.data.count;
        _this4.list = response.data.list;
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      // this.setTypeAction();
      this.load();
    },
    loadNumberGroup: function loadNumberGroup() {
      var _this5 = this;
      this.listLoading = true;
      (0, _auction.getNumberPlateNumApi)().then(function (res) {
        _this5.grouplist[1].number_num = res.data.scene;
        _this5.grouplist[0].number_num = res.data.special;
      }).catch(function () {});
    },
    showBatchAddNumberAction: function showBatchAddNumberAction(m) {
      this.showBatchAddNumber = {
        show: true,
        btnLoading: false,
        min: undefined,
        max: undefined,
        number_type: m.type,
        filter_number: ''
      };
    },
    batchAddNumberAction: function batchAddNumberAction() {
      var _this6 = this;
      if (!this.showBatchAddNumber.min || !this.showBatchAddNumber.max) {
        this.$message.error('请设置正确的号段');
        return false;
      }
      if (this.showBatchAddNumber.min > this.showBatchAddNumber.max) {
        this.$message.error('请设置正确的号段');
        return false;
      }
      this.showBatchAddNumber.btnLoading = true;
      (0, _auction.batchAddGroupNumber)({
        number_start: this.showBatchAddNumber.min,
        number_end: this.showBatchAddNumber.max,
        number_type: this.showBatchAddNumber.number_type,
        filter_number: this.showBatchAddNumber.filter_number
      }).then(function (res) {
        _this6.$message.success("\u6210\u529F\u6DFB\u52A0".concat(res.data.success_number_count, "\u4E2A\u53F7\u724C"));
        _this6.loadNumberGroup();
        _this6.showBatchAddNumber.show = false;
      }).catch(function (message) {
        _this6.showBatchAddNumber.btnLoading = false;
      });
    },
    showNumberListAction: function showNumberListAction(m) {
      this.numberList = {
        show: true,
        list: [],
        total: 0,
        listLoading: true,
        query: {
          page: 1,
          limit: 20,
          type: m.type
        }
      };
      this.loadNumberListAction();
    },
    clearNumberListAction: function clearNumberListAction(m) {
      var _this7 = this;
      this.btnLoading = true;
      this.$confirm("\u786E\u8BA4\u6E05\u7A7A\u8BE5\u53F7\u724C\u7EC4\u4E0B\u7684\u6240\u6709\u53F7\u724C?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.clearGroupNumberApi)({
          number_type: m.type
        }).then(function (res) {
          _this7.$message.success("\u5220\u9664\u6210\u529F");
          _this7.loadNumberGroup();
          _this7.btnLoading = false;
        }).catch(function (message) {
          _this7.btnLoading = false;
        });
      }).catch(function () {
        _this7.btnLoading = false;
      });
    },
    searchNumberListAction: function searchNumberListAction() {
      this.numberList.query.page = 1;
      this.loadNumberListAction();
    },
    loadNumberListAction: function loadNumberListAction() {
      var _this8 = this;
      (0, _auction.numberList)(this.numberList.query).then(function (res) {
        _this8.numberList.total = res.data.count;
        _this8.numberList.list = res.data.list;
        _this8.numberList.listLoading = false;
      }).catch(function () {});
    },
    pageChangeNumber: function pageChangeNumber(page) {
      console.log(page);
      this.numberList.query.page = page;
      this.loadNumberListAction();
    },
    // 详情
    onDetails: function onDetails(row) {
      console.log(row);
      this.uid = row.uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    delAction: function delAction(m) {
      var _this9 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u514D\u4FDD\u7528\u6237?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.bondMemberUserDelApi)({
          bond_member_id: m.bond_member_id
        }).then(function (res) {
          _this9.$message.success("\u64CD\u4F5C\u6210\u529F");
          _this9.load();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    editStatusAction: function editStatusAction(m) {
      var _this10 = this;
      this.$confirm("\u786E\u8BA4".concat(m.status === 1 ? '禁用' : '启用', "\u8BE5\u514D\u4FDD\u7528\u6237?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.bondMemberUserEditStatusApi)({
          bond_member_ids: m.bond_member_id,
          status: m.status === 1 ? 0 : 1
        }).then(function (res) {
          _this10.$message.success("\u64CD\u4F5C\u6210\u529F");
          _this10.load();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    editStatusAllAction: function editStatusAllAction(status) {
      var _this11 = this;
      if (this.multipleSelection.length <= 0) {
        this.$message.error('请先选择客户');
        return false;
      }
      this.$confirm("\u786E\u8BA4\u6279\u91CF".concat(status === 0 ? '禁用' : '启用', "\u514D\u4FDD\u7528\u6237?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.bondMemberUserEditStatusApi)({
          bond_member_ids: _this11.multipleSelection.map(function (m) {
            return m.bond_member_id;
          }).join(','),
          status: status
        }).then(function (res) {
          _this11.$message.success("\u64CD\u4F5C\u6210\u529F");
          _this11.load();
        }).catch(function (message) {});
      }).catch(function () {});
    }
  }
};