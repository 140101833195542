"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _index = require("@/utils/index");
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
var _auction = _interopRequireDefault(require("@/components/customChildren/auction"));
var _goods = _interopRequireDefault(require("@/components/customChildren/goods"));
var _live = require("@/api/live");
var _request = _interopRequireDefault(require("@/api/request"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { TcPlayer } from '@/assets/js/TcPlayer-module-2.4.1';
var _default = exports.default = {
  components: {
    userdetail: _userdetail.default,
    auctionComponent: _auction.default,
    goodsComponent: _goods.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      status: '1',
      keywords: '',
      loadStatus: '1',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20,
        status: 0,
        live_status: '1',
        live_type: '',
        date: ''
      },
      showdetail: null,
      uid: '',
      showCreated: false,
      createdData: {
        price: '',
        use: 'wallet_recharge',
        pay_type: 'offline_pos',
        pay_evidence: '',
        mark: '',
        user: {}
      },
      pushData: {
        show: false,
        data: {}
      },
      timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      drawerData: {
        show: false,
        data: [],
        titleData: {},
        page: 1,
        limit: 20,
        total: 0,
        listLoading: false,
        key: '',
        live_id: ''
      },
      aboutProduct: {
        show: false,
        data: [],
        page: 1,
        limit: 20,
        total: 0,
        live_id: '',
        listLoading: false
      },
      showVideo: {
        show: false,
        src: ''
      },
      statueNum: {},
      showImg: false,
      // 查看图片 控制器
      imgUrl: '' // 放大查看的图片
    };
  },
  computed: {
    merId: function merId() {
      return this.$store.getters.mer_id;
    },
    domain_url: function domain_url() {
      return this.$store.getters.domain_url;
    }
  },
  mounted: function mounted() {
    var _this = this;
    // for (let key in this.$route.query) {
    //   if (key === 'page' || key === 'limit') {

    //   }
    // }
    (0, _live.getStatusFilterApi)().then(function (res) {
      _this.statueNum = res.data;
    });
    var queryData = JSON.parse(JSON.stringify(this.$route.query));
    if (Number(queryData.page)) {
      this.tableFrom = queryData;
      this.tableFrom.page = Number(this.tableFrom.page);
      this.tableFrom.limit = Number(this.tableFrom.limit);
    }
    this.getList(1);
  },
  methods: {
    showChangeProduct: function showChangeProduct(key) {
      this.$refs[key].changeshow();
    },
    selectMessage: function selectMessage(data) {
      var _this2 = this;
      (0, _live.liveProductCreateApi)({
        live_id: this.aboutProduct.live_id,
        product_id: data.data[0].id
      }).then(function (res) {
        _this2.loadAboutProduct();
      }).catch(function () {});
    },
    showAboutProductAction: function showAboutProductAction(row) {
      this.aboutProduct = {
        show: true,
        data: [],
        page: 1,
        limit: 20,
        total: 0,
        live_id: row.live_id,
        listLoading: false
      };
      this.loadAboutProduct();
    },
    loadAboutProduct: function loadAboutProduct() {
      var _this3 = this;
      var data = {
        live_id: this.aboutProduct.live_id,
        page: this.aboutProduct.page,
        limit: this.aboutProduct.limit
      };
      (0, _live.liveProductListApi)(data).then(function (res) {
        _this3.aboutProduct.total = res.data.count;
        _this3.aboutProduct.data = res.data.data;
        _this3.aboutProduct.listLoading = false;
      });
    },
    pageChangeAboutProduct: function pageChangeAboutProduct(page) {
      this.aboutProduct.page = page;
      this.loadAboutProduct();
    },
    editSortAction: function editSortAction(val, index) {
      var _this4 = this;
      (0, _live.liveSetProductSortApi)({
        product_id: this.aboutProduct.data[index].product_id,
        live_id: this.aboutProduct.live_id,
        sort: val
      }).then(function () {
        _this4.$message.success('修改排序成功');
      }).catch(function () {});
    },
    delLiveProductAction: function delLiveProductAction(id) {
      var _this5 = this;
      // delLiveApi
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u5173\u8054\u5546\u54C1/\u62CD\u54C1?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _live.liveProductDelApi)({
          live_id: _this5.aboutProduct.live_id,
          product_id: id
        }).then(function () {
          _this5.$message.success('操作成功');
          _this5.loadAboutProduct();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    LiveProductTopAction: function LiveProductTopAction(id, val) {
      var _this6 = this;
      // delLiveApi
      this.$confirm("\u786E\u8BA4".concat(val ? '取消' : '', "\u7F6E\u9876\u8BE5\u5173\u8054\u5546\u54C1/\u62CD\u54C1?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _live.liveSetProductTopApi)({
          live_id: _this6.aboutProduct.live_id,
          product_id: id,
          status: val
        }).then(function () {
          _this6.$message.success('操作成功');
          _this6.loadAboutProduct();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    getLivePushUrlAction: function getLivePushUrlAction(row, key) {
      var _this7 = this;
      (0, _live.getLivePushUrl)(row.live_id).then(function (res) {
        _this7.pushData = {
          show: true,
          data: res.data
        };
        if (res.data) {
          _this7.copyText = res.data.webrtc;
          if (key === 'LVB') {
            _this7.copyText = res.data.rtmp;
          }
          var input = document.createElement('input'); // 直接构建input
          input.value = _this7.copyText; // 设置内容
          console.log(input.value);
          document.body.appendChild(input); // 添加临时实例
          input.select(); // 选择实例内容
          document.execCommand('Copy'); // 执行复制
          document.body.removeChild(input); // 删除临时实例
          _this7.$message.success('复制成功');
        } else {
          _this7.$message.error('复制失败');
        }
      }).catch(function () {});
    },
    getLivePlayUrlAction: function getLivePlayUrlAction(row) {
      var _this8 = this;
      (0, _live.liveDetailApi)(row.live_id).then(function (res) {
        if (res.data.play_url && res.data.play_url.rtmp) {
          _this8.copyText = res.data.play_url.rtmp;
          var input = document.createElement('input'); // 直接构建input
          input.value = _this8.copyText; // 设置内容
          console.log(input.value);
          document.body.appendChild(input); // 添加临时实例
          input.select(); // 选择实例内容
          document.execCommand('Copy'); // 执行复制
          document.body.removeChild(input); // 删除临时实例
          _this8.$message.success('复制成功');
        } else {
          _this8.$message.error('复制失败');
        }
      });
    },
    createdAction: function createdAction() {
      this.$router.push({
        name: 'LiveDetail'
      });
    },
    editAction: function editAction(id) {
      this.$router.push({
        name: 'LiveDetail',
        query: {
          id: id
        }
      });
    },
    delAction: function delAction(id) {
      var _this9 = this;
      // delLiveApi
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u76F4\u64AD?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _live.delLiveApi)(id).then(function () {
          _this9.$message.success('操作成功');
          _this9.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    // 选择时间
    selectChange: function selectChange() {
      this.timeVal = [];
      this.tableData.data = [];
      this.tableData.total = 0;
      this.getList(1);
    },
    // 选择时间
    selectChangeData: function selectChangeData(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    getList: function getList(num) {
      var _this10 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      this.$router.replace({
        query: this.tableFrom
      });
      (0, _live.liveList)(this.tableFrom).then(function (res) {
        _this10.tableData.data = res.data.list;
        _this10.tableData.total = res.data.count;
        _this10.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this10.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    goObsConsole: function goObsConsole(item) {
      console.log(item);
      if (item.relation_id) {
        // if(localStorage.getItem('origin') == 'obs'){
        // window.open(`/merchant/obsconsole?id=${item.relation_id}&token=${localStorage.getItem('token')}`);
        this.$router.push({
          path: '/merchant/obsconsole',
          query: {
            id: item.relation_id
          }
        });
        // } else{
        //   window.open(`/merchant/obsconsole?id=${item.relation_id}`);
        // } 
      }
    },
    endLiveAction: function endLiveAction(id) {
      var _this11 = this;
      this.$confirm("\u786E\u8BA4\u8BE5\u76F4\u64AD\u5DF2\u7ED3\u675F?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _live.endLiveApi)({
          live_id: id
        }).then(function () {
          _this11.$message.success('操作成功');
          _this11.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    clearAction: function clearAction(id) {
      var _this12 = this;
      this.$confirm("\u786E\u8BA4\u53D6\u6D88\u8BE5\u76F4\u64AD?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _live.cancelDetailApi)(id).then(function () {
          _this12.$message.success('操作成功');
          _this12.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    showPeopleAction: function showPeopleAction(item, key) {
      this.drawerData.live_id = item.live_id;
      this.drawerData.key = key;
      this.drawerData.page = 1;
      this.drawerData.total = 0;
      this.drawerData.data = [];
      this.drawerData.show = true;
      this.keywords = '';
      this.loadUserList(key);
    },
    goSearchAction: function goSearchAction() {
      this.drawerData.page = 1;
      this.loadUserList(2);
    },
    loadUserList: function loadUserList(key) {
      var _this13 = this;
      this.drawerData.listLoading = true;
      var action;
      if (key === 1) {
        action = _live.userOnlineListApi;
      } else if (key === 2) {
        action = _live.userVisitListApi;
      } else if (key === 3) {
        action = _live.userLikeListApi;
      }
      action({
        page: this.drawerData.page,
        limit: this.drawerData.limit,
        live_id: this.drawerData.live_id,
        keyword: this.keywords
      }).then(function (res) {
        if (key === 1) {
          res.data.list.forEach(function (m) {
            m.user = {
              avatar: m.avatar,
              nickname: m.nickname,
              real_name: m.real_name,
              uid: m.uid
            };
          });
        }
        _this13.drawerData.total = res.data.count;
        _this13.drawerData.data = res.data.list;
        _this13.drawerData.listLoading = false;
      }).catch(function () {});
    },
    pageChangeDrawer: function pageChangeDrawer(p) {
      this.drawerData.page = p;
      this.loadUserList(this.drawerData.key);
    },
    onDetails: function onDetails(uid) {
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = null;
    },
    showVideoAction: function showVideoAction(m) {
      var _this14 = this;
      console.log(m);
      this.showVideo = {
        FileId: m.FileId,
        src: m.MediaUrl,
        show: true
      };
      this.$nextTick(function () {
        _this14.loadVideo();
      });
    },
    loadVideo: function loadVideo() {
      var w = 750;
      this.player = TCPlayer('id_test_video', {
        width: w,
        height: w / 16 * 9,
        autoplay: true
      });
      this.player.src(this.showVideo.src);
      // this.player = new TcPlayer('id_test_video', {
      //   m3u8: this.showVideo.src,
      //   autoplay: true, // iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
      //   width: w,
      //   height: (w / 16) * 9,
      //   live: false,
      //   controls: 'default',
      //   playbackRates: [0.5, 1, 1.25, 1.5, 2]
      // });
    },
    closeBeforeAction: function closeBeforeAction(done) {
      if (this.player) {
        this.player.dispose();
        this.player = null;
      }
      done();
    },
    copyUrlAction: function copyUrlAction(video) {
      if (!video) return false;
      var url = video.MediaUrl;
      var input = document.createElement('input'); // 直接构建input
      input.value = url; // 设置内容

      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制文件地址成功');
    },
    delLiveVideoRecordAction: function delLiveVideoRecordAction(data, index, i) {
      var _this15 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u56DE\u653E?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _live.delLiveVideoRecordApi)(data).then(function () {
          _this15.$message.success('操作成功');
          _this15.tableData.data[index].videoRecord.splice(i, 1);
          // this.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    copyXcxLink: function copyXcxLink(m, event) {
      var url = '/pages/Live/Live?id=' + m.live_id;
      var input = document.createElement('input'); // 直接构建input
      input.value = url; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    copyH5Link: function copyH5Link(m, event) {
      var url = '/pages/Live/Live?id=' + m.live_id;
      var url1 = "".concat(this.domain_url).concat(url);
      var input = document.createElement('input'); // 直接构建input
      input.value = url1; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    showImgAction: function showImgAction(val, m) {
      var _this16 = this;
      var action = process.env.VUE_APP_BASE_API + '/api/sharePoster';
      var mer_id = this.$store.getters.mer_id;
      switch (val) {
        case 1:
          action += '?return_format=2&poster_type=liveRoom&qrcode_type=1&Independent_qrcode=1&id=' + m.live_id + '&mer_id=' + mer_id;
          break;
        case 2:
          action += '?return_format=2&poster_type=liveRoom&qrcode_type=2&Independent_qrcode=1&id=' + m.live_id + '&mer_id=' + mer_id;
          break;
        case 3:
          action += '?return_format=2&poster_type=liveRoom&qrcode_type=1&Independent_qrcode=0&id=' + m.live_id + '&mer_id=' + mer_id;
          break;
        case 4:
          action += '?return_format=2&poster_type=liveRoom&qrcode_type=2&Independent_qrcode=0&id=' + m.live_id + '&mer_id=' + mer_id;
          break;
        default:
          action += '?return_format=2&poster_type=liveRoom&qrcode_type=1&Independent_qrcode=1&id=' + m.live_id + '&mer_id=' + mer_id;
          break;
      }
      // this.imgData.imgUrl = action;
      this.showImg = true;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      _request.default.get(action).then(function (res) {
        loading.close();
        _this16.imgUrl = res.data.base_image;
      });
    },
    beforeCloseImg: function beforeCloseImg(done) {
      this.imgUrl = '';
      done();
    },
    bannelAction: function bannelAction(item) {
      var _this17 = this;
      var action = _live.setBannedApi;
      if (item.isBanned === 1) {
        action = _live.delBannedApi;
      }
      this.$confirm(item.isBanned === 1 ? '确认解除该用户禁言？' : '是否禁言该用户？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        action({
          live_id: item.type_id,
          uid: item.uid
        }).then(function () {
          _this17.$message.success('操作成功');
          _this17.loadUserList(2);
        }).catch(function (message) {});
      }).catch(function () {});
    },
    allBannedAction: function allBannedAction(item) {
      var _this18 = this;
      var is_open_chat = item.extend_data.is_open_chat;
      this.$confirm(is_open_chat === 1 ? '确认全体禁言？' : '确认解除全体禁言？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _live.setLiveOpenChatApi)({
          live_id: item.live_id,
          is_open_chat: is_open_chat ? 0 : 1
        }).then(function () {
          _this18.$message.success('操作成功');
          _this18.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    editVirtualAction: function editVirtualAction(val, index) {
      var _this19 = this;
      (0, _live.editLiveVirtualNumApi)(this.tableData.data[index].live_id, {
        virtual_num: val || 0
      }).then(function (res) {
        if (!val) {
          _this19.tableData.data[index].extend_data.virtual_num = 0;
        }
        _this19.$message.success('设置成功');
      }).catch(function () {});
    }
  }
};