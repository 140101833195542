"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.auditDelete = auditDelete;
exports.auditSwitchStatus = auditSwitchStatus;
exports.batchCreate = batchCreate;
exports.batchRemoveApi = batchRemoveApi;
exports.batchRestoreeApi = batchRestoreeApi;
exports.batchUpdateField = batchUpdateField;
exports.contractConfig = contractConfig;
exports.createGoods = createGoods;
exports.createGoodsV2 = createGoodsV2;
exports.createinventoryOrder = createinventoryOrder;
exports.createsellOrder = createsellOrder;
exports.editcellgoods = editcellgoods;
exports.editcellgoodsV2 = editcellgoodsV2;
exports.editcontractConfig = editcontractConfig;
exports.editinventoryOrder = editinventoryOrder;
exports.editletterStatus = editletterStatus;
exports.erpDeleteProductApi = erpDeleteProductApi;
exports.erpUserBatchRestoreApi = erpUserBatchRestoreApi;
exports.erpUserDeleteApi = erpUserDeleteApi;
exports.fastcreateGoods = fastcreateGoods;
exports.getauctionErpList = getauctionErpList;
exports.getbasicgoodsList = getbasicgoodsList;
exports.getgoodsCateHandleLogV2 = getgoodsCateHandleLogV2;
exports.getgoodsDetailV2 = getgoodsDetailV2;
exports.getgoodsHandleLogV2 = getgoodsHandleLogV2;
exports.getgoodsImportTemplateApi = getgoodsImportTemplateApi;
exports.getgoodsListV2Api = getgoodsListV2Api;
exports.getidsgoods = getidsgoods;
exports.getinventoryOrderDetail = getinventoryOrderDetail;
exports.getinventoryOrderList = getinventoryOrderList;
exports.getinventorySwitchStatusApi = getinventorySwitchStatusApi;
exports.getletterDetail = getletterDetail;
exports.getpreviewsetting = getpreviewsetting;
exports.getproductcatelog = getproductcatelog;
exports.getproductlog = getproductlog;
exports.getpushinventoryOrderList = getpushinventoryOrderList;
exports.getsellOrderList = getsellOrderList;
exports.goodsCategoryAttr = goodsCategoryAttr;
exports.goodsDeleteApiV2 = goodsDeleteApiV2;
exports.goodsDetail = goodsDetail;
exports.goodsList = goodsList;
exports.goodsMoreDetail = goodsMoreDetail;
exports.goodswarehouseList = goodswarehouseList;
exports.labelDeleteApi = labelDeleteApi;
exports.labelEditApi = labelEditApi;
exports.labelFormApi = labelFormApi;
exports.labelLstApi = labelLstApi;
exports.letterCreateApi = letterCreateApi;
exports.letterList = letterList;
exports.letterUpdate = letterUpdate;
exports.setpreviewsetting = setpreviewsetting;
exports.shelfCreate = shelfCreate;
exports.shelfDel = shelfDel;
exports.shelfList = shelfList;
exports.shelfMoreCreate = shelfMoreCreate;
exports.shelfUpdate = shelfUpdate;
exports.updateGoods = updateGoods;
exports.updateGoodsV2 = updateGoodsV2;
exports.upgoods = upgoods;
var _request = _interopRequireDefault(require("./request"));
/**
 * 货架列表
 */
function shelfList(data) {
  return _request.default.get('store/product/shelf/list', data);
}
/**
 * 货架删除
 */
function erpDeleteProductApi(data) {
  return _request.default.post('erp/product/batchDelete', data);
}
/**
 * 货品恢复
 */
function batchRestoreeApi(data) {
  return _request.default.post('erp/v2/product/batchRecover', data);
}
/**
 * 货品彻底删除
 */
function batchRemoveApi(data) {
  return _request.default.post('erp/v2/product/batchDeleteForce', data);
}
/**
 * 合作方删除
 */
function erpUserDeleteApi(data) {
  return _request.default.post('erp/entrust/user/batchDelete', data);
}
/**
 * 合作方恢复
 */
function erpUserBatchRestoreApi(data) {
  return _request.default.post('erp/entrust/user/batchRestore', data);
}
/**
* 创建货架
*/
function shelfCreate(data) {
  return _request.default.post('store/product/shelf/create', data);
}
/**
* 批量创建货架
*/
function shelfMoreCreate(data) {
  return _request.default.post('store/product/shelf/batchCreate', data);
}
/**
* 修改货架
*/
function shelfUpdate(data) {
  return _request.default.post('store/product/shelf/update', data);
}
/**
* 删除货架
*/
function shelfDel(data) {
  return _request.default.post('store/product/shelf/del', data);
}
/**
* 创建货品
*/
function createGoods(data) {
  return _request.default.post('erp/product/create', data);
}
/**
* 快速创建货品
*/
function fastcreateGoods(data) {
  return _request.default.post('erp/product/fastCreate', data);
}
/**
* 更新货品
*/
function updateGoods(id, data) {
  return _request.default.post('erp/product/update/' + id, data);
}
/**
* 货品详情
*/
function goodsDetail(id) {
  return _request.default.get('erp/product/detail/' + id);
}

/**
* 货品详情
*/
function goodsMoreDetail(data) {
  return _request.default.get('erp/entrust/order/getCategoryProductLst', data);
}
/**
* 货品列表
*/
function goodsList(data) {
  return _request.default.get('erp/product/lst', data);
}
/**
* 仓库货品列表
*/
function goodswarehouseList(data) {
  return _request.default.get('erp/warehouse/product/lst', data);
}
/**
* 货品分类属性
*/
function goodsCategoryAttr(data) {
  return _request.default.get('store/category/attrList', data);
}
/**
 * @description 标签 -- 列表
 */
function labelLstApi(data) {
  return _request.default.get('store/label/lst', data);
}
/**
 * @description 标签 -- 添加表单
 */
function labelFormApi() {
  return _request.default.get('store/label/form');
}
/**
 * @description 标签 -- 编辑表单
 */
function labelEditApi(id) {
  return _request.default.get('store/label/form/' + id);
}
/**
 * @description 标签 -- 删除
 */
function labelDeleteApi(id) {
  return _request.default.delete("store/label/".concat(id));
}
/**
 * @description 创建委托人
 */
function letterCreateApi(data) {
  return _request.default.post('erp/entrust/user/create', data);
}
/**
 * @description 编辑委托人
 */
function letterUpdate(id, data) {
  return _request.default.post("erp/entrust/user/update/".concat(id), data);
}
/**
 * @description 委托列表
 */
function letterList(data) {
  return _request.default.get('erp/entrust/user/lst', data);
}
/**
 * @description 委托人详情
 */
function getletterDetail(id) {
  return _request.default.get("erp/entrust/user/detail/".concat(id));
}
/**
 * @description 委托人详情
 */
function editletterStatus(id, data) {
  return _request.default.post("erp/entrust/user/switchStatus/".concat(id), data);
}
/**
 * @description 合同详情配置
 */
function contractConfig() {
  return _request.default.get('erp/entrust/order/getFieldConfig');
}
/**
 * @description 修改合同详情配置
 */
function editcontractConfig(data) {
  return _request.default.post('erp/entrust/order/setFieldConfig', data);
}
/**
 * @description 创建委托单
 */
function createinventoryOrder(data) {
  return _request.default.post('erp/entrust/order/create', data);
}
/**
 * @description 修改委托单
 */
function editinventoryOrder(id, data) {
  return _request.default.post("erp/entrust/order/update/".concat(id), data);
}
/**
 * @description 委托单详情
 */
function getinventoryOrderDetail(id) {
  return _request.default.get("erp/entrust/order/detail/".concat(id));
}
/**
 * @description 委托单列表
 */
function getinventoryOrderList(data) {
  return _request.default.get("erp/entrust/order/lst", data);
}
/**
 * @description 委托单作废
 */
function getinventorySwitchStatusApi(id, data) {
  return _request.default.post("erp/entrust/order/switchStatus/".concat(id), data);
}
/**
 * @description 入库委托单列表
 */
function getpushinventoryOrderList(data) {
  return _request.default.get("erp/entrust/order/product/getWaitPushWarehouseLst", data);
}
/**
 * @description 销售单列表
 */
function getsellOrderList(data) {
  return _request.default.get("erp/salesOrder/lst", data);
}
/**
 * @description 创建销售单
 */
function createsellOrder(data) {
  return _request.default.post('erp/salesOrder/create', data);
}
/**
 * @description 货品操作日志
 */
function getproductlog(data) {
  return _request.default.get("erp/product/log/lst", data);
}
/**
 * @description 货品操作日志
 */
function getproductcatelog(data) {
  return _request.default.get("erp/product/log/categoryList", data);
}
/**
 * @description 获取配置
 */
function getpreviewsetting() {
  return _request.default.get("system/admin/audit/getAuditAdmin");
}
/**
 * @description 设置审批配置
 */
function setpreviewsetting(data) {
  return _request.default.post("system/admin/audit/setAuditAdmin", data);
}
/**
 * @description 上架到拍买
 */
function upgoods(data) {
  return _request.default.post("erp/product/batchProductToAuction", data);
}
// 单个编辑货品
function editcellgoods(id, data) {
  return _request.default.post("erp/product/updateField/".concat(id), data);
}
// 获取货品配置列表
function getbasicgoodsList(data) {
  return _request.default.get('erp/product/lst_v2', data);
}
// 通过id获取货品
function getidsgoods(data) {
  return _request.default.get('erp/product/idsByMainList', data);
}

// 货品列表v2接口
function getgoodsListV2Api(data) {
  return _request.default.get('erp/v2/product/list', data);
}

// 获取货品导入模板
function getgoodsImportTemplateApi(data) {
  return _request.default.get('erp/v2/product/downloadAuctionImportTemplate', data);
}
// 单个编辑货品信息
function editcellgoodsV2(id, data) {
  return _request.default.post("erp/v2/product/updateField/".concat(id), data);
}
// 货品档案详情
function getgoodsDetailV2(id) {
  return _request.default.get("erp/v2/product/detail/".concat(id));
}
// 批量修改字段
function batchUpdateField(data) {
  return _request.default.post("erp/v2/product/batchUpdateField", data);
}
// 货品档案操作日志
function getgoodsHandleLogV2(data) {
  return _request.default.get("erp/v2/product/log/list", data);
}
// 货品档案操作日志
function getgoodsCateHandleLogV2(data) {
  return _request.default.get("erp/v2/product/log/categoryList", data);
}
// 货品档案删除
function goodsDeleteApiV2(data) {
  return _request.default.post("erp/v2/product/batchDelete", data);
}
// 修改货品档案
function updateGoodsV2(id, data) {
  return _request.default.post("erp/v2/product/update/".concat(id), data);
}
// 新增货品档案
function createGoodsV2(data) {
  return _request.default.post("erp/v2/product/create", data);
}
// 获取拍卖会专场详情列表
function getauctionErpList(data) {
  return _request.default.get("auction/venue/erpGetList", data);
}
// 加入清单
function batchCreate(data) {
  return _request.default.post("erp/v2/product/audit/batchCreate", data);
}
// 移除清单
function auditDelete(id) {
  return _request.default.post("erp/v2/product/audit/delete/".concat(id), {});
}
// 修改审核状态
function auditSwitchStatus(id, data) {
  return _request.default.post("erp/v2/product/audit/switchStatus/".concat(id), data);
}