"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _createPoster = _interopRequireDefault(require("./createPoster.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    createPoster: _createPoster.default
  },
  data: function data() {
    return {};
  },
  // computed: {
  // },
  created: function created() {},
  methods: {
    onOpenModule: function onOpenModule() {
      this.$router.push({
        name: 'posterEnd'
      });
    },
    onOpenPoster: function onOpenPoster() {
      this.$refs.createPosterRef.openPoster();
    }
  }
};