"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var panels = {
  basics: {
    title: '通用组件',
    children: [{
      name: 'nav',
      // 标签name
      icon: 'https://saas.cdn.yunzongbu.cn/icon/navigation_icon.png',
      title: '图文导航',
      // 标签名称
      content: {
        style: 1,
        // 风格类型
        jump_type: 1,
        // 跳转方式
        data: [
        // 数据
        {
          url: '',
          title: '导航一',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }, {
          url: '',
          title: '导航二',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }, {
          url: '',
          title: '导航三',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }, {
          url: '',
          title: '导航四',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }]
      },
      // 外观
      facade: {
        nav_style: 1,
        // 展示方式 1 居左 居中
        fixed_top: false,
        // 页面跟随  false 不居顶  true 居顶
        background_color: '',
        // 背景颜色
        text_color: '#000000',
        // 文字颜色
        text_style: 400,
        // 文字风格 400常规  500  加粗
        text_size: 28,
        // 字号
        page_padding: 40,
        // 页面边距
        text_top: 0,
        // 文字距图标距离
        page_padding_left: 40,
        page_padding_right: 40,
        page_padding_top: 0,
        page_padding_bottom: 0,
        item_padding: 40,
        // 容器边距
        img_width: 120,
        // 图片宽度
        img_height: 120,
        // 图片高度
        is_hover: false,
        // 是否开启选中模式
        hover_index: 1,
        // 开启选中模式后   选中的下标索引
        hover_color: '#003D7C',
        // 开启选中模式后  选中的颜色
        hover_style: 500,
        // 选中的字重
        hover_size: 28,
        // 选中的字号
        line_color: '#003D7C',
        // 线条颜色
        line_height: 6,
        // 线条高度
        line_radius_tl: 4,
        // 线条圆角
        line_radius_tr: 4,
        line_radius_bl: 4,
        line_radius_br: 4,
        line_width: 0 // 线条宽度， 0/空 100%宽度
      }
    }, {
      name: 'tag',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/tab_icon.png',
      title: '选项卡',
      content: {
        data: [{
          name: '',
          title: '文字',
          link: {
            title: ''
          }
        }, {
          name: '',
          title: '文字',
          link: {
            title: ''
          }
        }, {
          name: '',
          title: '文字',
          link: {
            title: ''
          }
        }],
        defaultNum: 1,
        active: 0,
        loadMore: false // 无限加载
      },
      active: 0,
      // 外观
      facade: {
        nav_style: 1,
        // 展示方式 1 居左 居中
        fixed_top: false,
        // 页面跟随  false 不居顶  true 居顶
        background_color: '',
        // 背景颜色
        text_color: '#000000',
        // 文字颜色
        text_active_color: '#003D7c',
        // 文字选中颜色
        text_style: 400,
        // 文字风格 1常规  2  加粗
        text_size: 28,
        // 字号
        page_padding: 40,
        // 页面边距
        item_padding: 40,
        // 容器边距
        content_margin: 20,
        // 页面与内容距离
        nav_height: 96,
        // 选项卡高度
        text_margin_top: 20,
        // 文字距上距离
        lineHeight: 40,
        active_text_style: 500,
        active_text_size: 28
      }
    }, {
      name: 'banner',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/banner_icon.png',
      title: '轮播图',
      content: {
        style: 1,
        // 风格模板选择  1  单张滑动  2 左右露出  3 右侧露出
        view_width: 750,
        // 轮播图宽
        view_height: 750,
        // 轮播图高
        data: [{
          url: '',
          title: '',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }, {
          url: '',
          title: '',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }, {
          url: '',
          title: '',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }]
      },
      // 外观
      facade: {
        img_style: 2,
        // 显示方式 2 对其-完整图片 3 对其-截取正方形
        background_color: '',
        // 背景颜色
        img_radius: 0,
        // 图上圆角
        img_bottom_radius: 0,
        // 图下圆角
        itemTopLeftRadius: 0,
        // 左上角圆角
        itemTopRightRadius: 0,
        // 右上圆角
        itemBottomLeftRadius: 0,
        // 左下圆角
        itemBottomRightRadius: 0,
        // 右下圆角
        page_padding: 0,
        // 页面边距
        item_padding: 0,
        // 容器边距
        chamfer_style: 1,
        // 倒角样式
        indicator_style: 2,
        // 指示器样式  1 远点  2 长条   3 数字
        indicator_vertical_align: 'top',
        // 指示器上下位置
        indicator_align: 'center',
        // 指示器位置
        indicator_color: '#dd0000',
        // 指示器颜色
        show_name: false,
        name_bg: '',
        name_vertical_align: 'bottom',
        name_color: '#333333',
        name_fontsize: 26,
        name_fontweight: 400,
        name_padding_top: 12,
        name_padding_bottom: 12,
        name_padding_left: 0,
        name_padding_right: 0,
        name_margin_top: 0,
        name_margin_bottom: 0,
        name_margin_left: 0,
        name_margin_right: 0,
        name_border_top_left_radius: 0,
        name_border_top_right_radius: 0,
        name_border_bottom_left_radius: 0,
        name_border_bottom_right_radius: 0,
        border_shawdow_color: '',
        border_shawdow_size: 0,
        border_shawdow_x: 0,
        border_shawdow_y: 0
      }
    }, {
      name: 'title',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/title_icon.png',
      title: '标题栏',
      content: {
        style: 1,
        // 标题栏模板id
        title: '标题文字',
        // 标题栏主文字
        subtitle: '二级标题',
        // 标题栏附文字
        is_more: true,
        // 是否展示更多
        moretitle: '更多',
        // 更多的文字
        morelink: {
          link: '',
          name: '',
          name1: ''
        },
        // 更多的链接
        title_img: '' // 标题图片
      },
      // 外观
      facade: {
        title_color: '#000000',
        // 文字颜色
        title_font_size: 40,
        // 文字字号
        subtitle_color: '#999999',
        // 副标题文字颜色
        subtitle_font_size: 24,
        // 副标题字号
        more_color: '#000000',
        // 更多文字颜色
        more_font_size: 24,
        // 更多文字字号
        more_img: 1,
        // 更多箭头图标 1 黑色 2 白色
        background_color: '',
        // 背景颜色
        page_padding: 40,
        // 页面边距
        title_img_margin: 0
      }
    }, {
      name: 'search',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/search_icon.png',
      title: '搜索框',
      content: {
        style: 1,
        // 搜索栏风格模板id
        text: '搜索',
        front_icon: '',
        // 前图标
        front_link: {
          link: '',
          name: '',
          name1: ''
        },
        // 前图标跳转链接
        after_icon: '',
        // 后图标
        after_link: {
          link: '',
          name: '',
          name1: ''
        },
        // 后图标跳转链接
        data: [{
          url: '',
          title: '文字链接',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }, {
          url: '',
          title: '文字链接',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }, {
          url: '',
          title: '文字链接',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }] // 文字链接
      },
      // 外观
      facade: {
        page_padding: 16,
        // 页面边距
        background_color: '',
        // 背景颜色
        border_color: '#f5f5f5',
        // 输入框颜色
        text_color: '#999999',
        // 文字颜色
        text_style: 400,
        // 文字类型 300 加细  400正常  500 加粗
        text_size: 24,
        // 文字字号
        icon_align: 'right',
        // 搜索图标位置
        icon_color: '#999999',
        // icon颜色
        border_radius: 32,
        // 圆角角度
        high_style: 64,
        // 搜索框高度
        width_style: 560,
        // 搜索框宽度
        text_link_color: '#999999',
        // 文字链 文字颜色
        text_link_size: 24,
        // 文字字号
        text_link_style: 400 // 文字类型 300 加细  400正常  500 加粗
      }
    }, {
      name: 'blank',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/kongge_icon.png',
      title: '分割',
      content: {
        has_line: false // 是否开启线条模式
      },
      // 外观
      facade: {
        height: 20,
        // 分割高度
        background_color: '',
        // 分割的背景颜色
        line_width: 375,
        // 线的宽度
        line_height: 1,
        // 线的高度
        line_color: '#DDDDDD' // 线的颜色
      }
    }, {
      name: 'rubik',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/image_icon.png',
      title: '图片',
      content: {
        style: 1,
        // 风格 1  2  3  4  5  6
        density: 4,
        // 魔方密度
        data: [{
          url: '',
          link: {
            link: '',
            name: '',
            name1: ''
          }
        }]
      },
      facade: {
        page_margin: 0,
        // 页面边距
        page_padding_top: 0,
        page_padding_bottom: 0,
        page_padding_left: 0,
        page_padding_right: 0
      }
    }, {
      name: 'video',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/video_icon.png',
      title: '视频',
      content: {
        video: {},
        link: {
          link: '',
          name: '',
          name1: ''
        },
        cover: '',
        autoplay: false,
        loop: true,
        source: 0,
        address: '',
        view_width: 750,
        view_height: 422,
        isMute: true,
        isContral: true
      }
    }, {
      name: 'notice',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/gonggao_icon.png',
      title: '公告',
      content: {
        title: '',
        icon: 'https://saas.cdn.yunzongbu.cn/image/20211022/28c84b92d651186d4bf97a62d8cd0400.png',
        data: [],
        right_link: {
          link: '',
          name: '',
          name1: ''
        },
        right_text: '',
        right_icon: ''
      },
      // 外观
      facade: {
        title_color: '#d19974',
        // 文字颜色
        speed: 80,
        page_padding: 30,
        // 页面边距
        item_padding: 30,
        // 容器边距
        background_color: '#fff8f0',
        // 背景颜色
        roll_type: 1,
        // 滚动方式  1横向  2竖向
        page_margin: 0,
        radius: 0,
        item_shadow: '',
        // 阴影颜色
        item_shadow_size: 0,
        // 阴影大小
        right_title_color: '',
        right_padding: 0
      }
    }, {
      name: 'textView',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/text_icon.png',
      title: '文字',
      content: {
        title: '标题文字',
        subtitle: '内容文字'
      },
      // 外观
      facade: {
        theme: 1,
        page_margin: 0,
        // 页面间距
        item_margin: 30,
        // 内容间距
        item_background: '#FFFFFF',
        // 内容背景颜色
        item_padding: 30,
        // 内间距
        radius: 8,
        // 内容圆角
        item_shadow: '#DEDEDE',
        // 专场阴影颜色
        item_shadow_size: 10,
        // 专场阴影大小
        title_margin: 20,
        title_color: '#000000',
        // 标题颜色
        title_weight: 500,
        title_size: 28,
        title_text_align: 'left',
        desc_margin: 30,
        desc_color: '#999999',
        desc_weight: 400,
        desc_size: 24,
        desc_line_height: 40,
        desc_text_align: 'left'
      }
    }, {
      name: 'focusDisplay',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/jujiaohualang_icon.png',
      title: '聚焦画廊',
      content: {
        type: '1',
        // 1 拍品  2  商品   3 自定义
        data: []
      },
      // 外观
      facade: {
        backgroundColor: 'rgba(17, 17, 17, 1)',
        backgroundImg: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024614/image/1718367328228643240.png',
        marginTop: 0,
        marginRight: 0,
        marginBottom: 0,
        marginLeft: 0,
        paddingTop: 500,
        paddingRight: 48,
        paddingBottom: 88,
        paddingLeft: 96,
        topLeftRadius: 0,
        topRightRadius: 0,
        bottomLeftRadius: 0,
        bottomRightRadius: 0,
        link: {
          link: '',
          name: '',
          name1: ''
        },
        itemBackgroundColor: '#fff',
        itemBackgroundImg: '',
        itemShadow: '#DEDEDE',
        // 阴影颜色
        itemShadowSize: 32,
        // 阴影大小
        itemPaddingTop: 16,
        itemPaddingRight: 16,
        itemPaddingBottom: 16,
        itemPaddingLeft: 16,
        itemMargin: 20,
        itemTopLeftRadius: 8,
        itemTopRightRadius: 8,
        itemBottomLeftRadius: 8,
        itemBottomRightRadius: 8,
        imgWidth: 180,
        imgHeight: 180,
        imgTopLeftRadius: 8,
        imgTopRightRadius: 8,
        imgBottomLeftRadius: 8,
        imgBottomRightRadius: 8,
        textAlign: 'center',
        titleColor: 'rgba(17, 17, 17, 1)',
        titleFontSize: 24,
        titleFontWeight: 400,
        titlePaddingTop: 4,
        titlePaddingRight: 0,
        titlePaddingBottom: 0,
        titlePaddingLeft: 0,
        descColor: 'rgba(17, 17, 17, 1)',
        descFontSize: 24,
        descFontWeight: 500,
        descPaddingTop: 0,
        descPaddingRight: 0,
        descPaddingBottom: 0,
        descPaddingLeft: 0
      }
    }]
  },
  advanced: {
    title: '内容组件',
    children: [
    // {
    //   name: 'auctionMeeting',
    //   icon: 'https://saas.cdn.yunzongbu.cn/icon/auction_meeting_icon.png',
    //   title: '拍卖会',
    //   hasContent: true,
    //   content: {
    //     style: 1, // 风格模板选择 1 左右滑动 2 上下排列
    //     type: 1, // 按类型去数据  1 系统自动 2 手动选择
    //     system_type: 1, // 系统自动抓取  1 预展+进行+结束  2 预展+进行  3 预展 4 进行 5结束
    //     system_count: 20, // 系统自动抓取数量
    //     data: []
    //   },
    //   facade: {
    //     background_color: '', // 背景颜色
    //     column_title_font_size: 32, // 标题文字字号
    //     item_margin: 30, // 专场间距
    //     item_background: '', // 专场背景颜色
    //     name_wrap: false, // 专场名字是否换行   不换行就是超出一行显示...
    //     indicator_style: 2, // 滑动指示器样式  1 圆点  2 横条  3 数字
    //     indicator_align: 'center', // 指示器位置
    //     indicator_color: '#003D7C', // 指示器颜色
    //     page_margin: 30, // 页面间距
    //     title_color: '#000000', // 标题颜色
    //     sub_title_color: '#000000', // 副标题颜色
    //     detail_color: '#999999', // 详细介绍颜色
    //     status_bar_bg: '#003D7C', // 状态栏背景色
    //     status_bar_color: 'white', // 状态栏文字颜色   white  black 只有这俩
    //     status_bar_height: 60, // 状态栏高度
    //     item_shadow: '#DEDEDE', // 专场阴影颜色
    //     item_shadow_size: 10, // 专场阴影大小
    //     top_radius: 8, // 专场上圆角
    //     bottom_radius: 8, // 转下下圆角
    //     meeting_padding: 16, // 拍卖会间距  content.style 1 使用的
    //     meeting_padding1: 48, // 拍卖会间距  content.style 2 使用的
    //     price_color: '#003D7C', // 价格颜色
    //     name_color: '#000000', // 名称颜色
    //     name_font_size: 32, // 名称字号
    //     meeting_title_padding: 30 // 拍卖会右下左挤开距离
    //   }
    // },
    {
      name: 'templateMeetingList',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/auction_meeting_icon.png',
      title: '拍卖会',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 左右滑动 2 上下排列
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_tag: [],
        // 系统数据标签
        system_type: 1,
        // 系统自动抓取  1 预展+进行+结束  2 预展+进行  3 预展 4 进行 5结束
        system_count: 20,
        // 系统自动抓取数量
        data: [],
        label: []
      },
      facade: {
        meetingMarginTop: 32,
        meetingPaddingTop: 32,
        meetingPaddingBottom: 32,
        meetingPaddingLeft: 32,
        meetingPaddingRight: 32,
        meetingNameColor: '#000000',
        meetingNameFontSize: 32,
        meetingNameFontWeight: 500,
        meetingAlbumCountColor: '#000000',
        meetingAlbumCountFontSize: 28,
        meetingAlbumCountFontWeight: 500,
        meetingAlbumNowCountColor: '#FF3D3D',
        meetingAlbumNowCountFontSize: 28,
        meetingAlbumNowCountFontWeight: 500,
        meetingInfoColor: '#000000',
        meetingInfoFontSize: 24,
        meetingInfoFontWeight: 400,
        meetingInfoMarginTop: 16
      }
    }, {
      name: 'templateAlbumList',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/auction_album_icon.png',
      title: '专场',
      hasContent: true,
      content: {
        style: 2,
        // 风格模板选择 1 左右滑动 2 上下排列  3 简约滑动
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 预展+进行+结束  2 预展+进行  3 预展 4 进行 5结束
        open_live_type: false,
        // 直播筛选  true 开启直播筛选  false 不开启直播筛选
        live_type: 1,
        // 1 只展示直播专场  2 不展示直播专场
        album_type: [1],
        // 专场类型  1限时拍  3同步拍  2 社群拍
        system_count: 20,
        // 系统自动抓取数量
        data: [],
        label: []
      },
      facade: {
        backgroundColor: '#fff',
        pageMargin: 32,
        imgStyle: 3,
        itemBackground: '#FFFFFF',
        itemGradientBackground: '',
        itemGradientDirection: 'to bottom',
        itemMargin: 32,
        itemShadow: '#DEDEDE',
        itemShadowSize: 0,
        itemTopLeftRadius: 8,
        itemTopRightRadius: 8,
        itemBottomLeftRadius: 8,
        itemBottomRightRadius: 8,
        imgBackground: '#ccc',
        imgTopLeftRadius: 0,
        imgTopRightRadius: 0,
        imgBottomLeftRadius: 0,
        imgBottomRightRadius: 0,
        descMarginTop: 24,
        descMarginRight: 32,
        descMarginBottom: 38,
        descMarginLeft: 32,
        nameMarginTop: 0,
        nameColor: '#000000',
        nameFontWeight: 500,
        nameFontSize: 36,
        albumInfoMarginTop: 16,
        albumInfoColor: '#666666',
        albumInfoFontWeight: 400,
        albumInfoFontSize: 24,
        albumTypeMarginTop: 32,
        albumTypeColor: '#666666',
        albumTypeFontWeight: 400,
        albumTypeFontSize: 24,
        albumTimeMarginTop: 24,
        albumTimeColor: '#000000',
        albumTimeFontWeight: 500,
        albumTimeFontSize: 24,
        nowAuctionMarginTop: 32,
        nowAuctionIcon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024522/image/1716392259005711638.png',
        nowAuctionColor: '#000000',
        nowAuctionCurrColor: '#FF3D3D',
        nowAuctionFontWeight: 500,
        nowAuctionFontSize: 28,
        statusTopLeftRadius: 8,
        statusTopRightRadius: 8,
        statusBottomLeftRadius: 8,
        statusBottomRightRadius: 8,
        statusMarginTop: 20,
        statusMarginLeft: 20,
        statusFontWeight: 500,
        statusFontSize: 24,
        waitingBackgoundUrl: '',
        waitingBackgoundColor: '#FF3D3D',
        waitingColor: '#ffffff',
        ingBackgoundUrl: '',
        ingBackgoundColor: '#FF3D3D',
        ingColor: '#ffffff',
        liveingBackgoundUrl: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024522/image/1716392322615609096.png',
        liveingBackgoundColor: '#FF3D3D',
        liveingColor: '#ffffff',
        endBackgoundUrl: '',
        endBackgoundColor: '#999999',
        endColor: '#ffffff',
        horHeight: 80,
        horNameBackground: 'rgba(0, 0, 0, 0)',
        horNameGradientBackground: 'rgba(0, 0, 0, .5)',
        horNameGradientDirection: 'to bottom',
        horNameColor: '#ffffff',
        horNameFontWeight: 500,
        horNameFontSize: 32,
        horTimeColor: '#ffffff',
        horTimeFontWeight: 400,
        horTimeFontSize: 24,
        indicatorAlign: 'center',
        indicatorColor: '#000000',
        indicatorStyle: 2
      }
    },
    // {
    //   name: 'auctionAlbum',
    //   icon: 'https://saas.cdn.yunzongbu.cn/icon/auction_album_icon.png',
    //   title: '专场',
    //   hasContent: true,
    //   content: {
    //     style: 1, // 风格模板选择 1 左右滑动 2 上下排列
    //     type: 1, // 按类型去数据  1 系统自动 2 手动选择
    //     system_type: 1, // 系统自动抓取  1 预展+进行+结束  2 预展+进行  3 预展 4 进行 5结束
    //     open_live_type: false, // 直播筛选  true 开启直播筛选  false 不开启直播筛选
    //     live_type: 1, // 1 只展示直播专场  2 不展示直播专场
    //     album_type: [1], // 专场类型  1限时拍  3同步拍  2 社群拍
    //     system_count: 20, // 系统自动抓取数量
    //     data: []
    //   },
    //   facade: {
    //     column_title_font_size: 32, // 标题文字字号
    //     item_margin: 30, // 专场间距
    //     item_background: '', // 专场背景颜色
    //     name_wrap: false, // 专场名字是否换行   不换行就是超出一行显示...
    //     indicator_style: 2, // 滑动指示器样式  1 圆点  2 横条  3 数字
    //     indicator_align: 'center', // 指示器位置
    //     indicator_color: '#003D7C', // 指示器颜色
    //     page_margin: 30, // 页面间距
    //     title_color: '#000000', // 标题颜色
    //     sub_title_color: '#000000', // 副标题颜色
    //     detail_color: '#999999', // 详细介绍颜色
    //     status_bar_bg: '#003D7C', // 状态栏背景色
    //     status_bar_color: 'white', // 状态栏文字颜色   white  black 只有这俩
    //     status_bar_height: 60, // 状态栏高度
    //     item_shadow: '#DEDEDE', // 专场阴影颜色
    //     item_shadow_size: 10, // 专场阴影大小
    //     top_radius: 8, // 专场上圆角
    //     bottom_radius: 8 // 转下下圆角
    //   }
    // },
    {
      name: 'templateAuctionList',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/auction_icon.png',
      title: '拍品',
      hasContent: true,
      content: {
        background_color: '',
        style: 2,
        // 风格模板选择 1 单列展示 2 双列展示  3三列模式   4列表模式  5左右滑动  6 简约滑动
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 预展+进行+结束  2 预展+进行  3 预展 4 进行 5结束
        open_live_type: false,
        // 直播筛选  true 开启直播筛选  false 不开启直播筛选
        live_type: 1,
        // 1 只展示直播专场  2 不展示直播专场
        album_type: [1],
        // 专场类型  1限时拍  2同步拍  3 社群拍
        system_count: 20,
        // 系统自动抓取数量
        data: [],
        label: []
      },
      facade: {
        backgroundColor: '#fff',
        pageMargin: 30,
        imgStyle: 2,
        itemBackground: '#FFFFFF',
        itemGradientBackground: '',
        itemGradientDirection: 'to bottom',
        itemMargin: 20,
        itemShadow: '#DEDEDE',
        itemShadowSize: 0,
        itemTopLeftRadius: 0,
        itemTopRightRadius: 0,
        itemBottomLeftRadius: 0,
        itemBottomRightRadius: 0,
        imgBackground: '#000000',
        imgTopLeftRadius: 18,
        imgTopRightRadius: 18,
        imgBottomLeftRadius: 18,
        imgBottomRightRadius: 18,
        descMarginTop: 32,
        descMarginRight: 0,
        descMarginBottom: 20,
        descMarginLeft: 0,
        nameMarginTop: 0,
        nameColor: '#000000',
        nameFontWeight: 500,
        nameFontSize: 30,
        appraisalPriceMarginTop: 18,
        appraisalPriceColor: '#999999',
        appraisalPriceFontWeight: 400,
        appraisalPriceFontSize: 24,
        statusHeight: 32,
        statusMarginTop: 18,
        statusBackground: '#F7F7F7',
        statusColor: '#999999',
        statusFontWeight: 400,
        statusFontSize: 18,
        statusRadius: 4,
        auctionIngBackground: '#FF3D3D',
        auctionWaitBackground: '#FFAA00',
        auctionEndBackground: '#999999',
        auctionStatusColor: '#ffffff',
        priceMarginTop: 18,
        priceTextColor: '#000000',
        priceTextFontWeight: 400,
        priceTextFontSize: 28,
        priceColor: '#FF3D3D',
        priceFontWeight: 500,
        priceFontSize: 32,
        negotiatePurchaseSwitchIcon: ''
      }
    },
    // {
    //   name: 'auction',
    //   icon: 'https://saas.cdn.yunzongbu.cn/icon/auction_icon.png',
    //   title: '拍品',
    //   hasContent: true,
    //   content: {
    //     background_color: '',
    //     style: 2, // 风格模板选择 1 单列展示 2 双列展示  3三列模式   4列表模式  5左右滑动
    //     type: 1, // 按类型去数据  1 系统自动 2 手动选择
    //     system_type: 1, // 系统自动抓取  1 预展+进行+结束  2 预展+进行  3 预展 4 进行 5结束
    //     open_live_type: false, // 直播筛选  true 开启直播筛选  false 不开启直播筛选
    //     live_type: 1, // 1 只展示直播专场  2 不展示直播专场
    //     album_type: [1], // 专场类型  1限时拍  2同步拍  3 社群拍
    //     system_count: 20, // 系统自动抓取数量
    //     data: []
    //   },
    //   facade: {
    //     background_color: '',
    //     page_margin: 30, // 页面间距
    //     top_radius: 8, // 顶部圆角
    //     bottom_radius: 8, // 底部圆角
    //     title_color: '#000000', // 标题颜色
    //     sub_title_color: '#999999', // 副标题颜色
    //     price_color: '#347FF4', // 价格颜色
    //     bid_num_color: '#000000', // 出价次数颜色
    //     status_type: 1, // 状态样式
    //     img_style: 2, // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
    //     btn_bg_color: '#003D7C', // 按钮背景
    //     btn_color: '#FFFFFF', // 按钮文字颜色
    //     btn_radius: 8, // 按钮圆角
    //     top_margin: 20, // 内容上间距
    //     left_margin: 20, // 内容左间距
    //     text_margin: 20, // 文件撑开距离
    //     item_background: '#FFFFFF', // 拍品底色
    //     item_shadow: '#DEDEDE', // 拍品阴影颜色
    //     item_shadow_size: 10, // 拍品阴影大小
    //     title_size: 28, // 标题颜色
    //     title_weight: 500, // 标题文字类型 300 加细  400正常  500 加粗
    //     price_size: 28, // 标题价格字号
    //     price_weight: 500, // 标题价格文字类型 300 加细  400正常  500 加粗
    //     bid_num_size: 24, // 标题出价次数字号
    //     bid_num_weight: 400 // 标题出价次数 300 加细  400正常  500 加粗
    //   }
    // },
    {
      name: 'word',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/article_icon.png',
      title: '文章',
      hasContent: true,
      content: {
        style: 2,
        // 风格模板选择 1 单列模式 2 双列模式 3 三列模式  4 列表模式  5 左右滑动 6 多图模式
        img_style: 2,
        // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '',
        view_radius: 8,
        // 顶部圆角 多图模式
        img_radius: 8,
        // 图片圆角
        page_padding: 30,
        // 页面边距
        item_padding: 30,
        // 容器边距
        img_background: '',
        itemShadow: '',
        itemShadowSize: 0,
        itemShadowX: 0,
        itemShadowY: 0,
        descPaddingTop: 32,
        descPaddingRight: 32,
        descPaddingBottom: 32,
        descPaddingLeft: 32,
        status_background: '#FFFFFF',
        status_high: 60,
        status_radius: 8,
        item_bottom_padding: 30,
        price_color: '#003D9C',
        no_show_img: false,
        name_color: '#000000',
        name_fontsize: 24,
        time_marginTop: 0,
        time_fontsize: 22,
        time_color: '#999999',
        name_fontweight: 400,
        img_width: 120,
        img_height: 120,
        no_line1: false
      }
    }, {
      name: 'nftCollection',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/nft_icon.png',
      title: '数字藏品',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 单列模式 2 日历模式
        img_style: 2,
        // 显示方式 2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '#1E1E1E',
        page_margin: 30,
        img_radius: 8,
        status_radius: 8,
        item_margin: 30,
        status_background: '#333333',
        status_bg_image: '',
        font_weight: 400,
        title_color: '#ffffff',
        title_font_size: 32,
        title_font_weight: 500,
        stock_color: '#00eeff',
        stock_font_size: 24,
        price_font_size: 48,
        price_color: '#e6cb95',
        img_style: 2,
        status_btn_bg_color: '#000000',
        status_btn_color: '#00eeff',
        status_font_size: 24,
        btn_font_size: 22,
        btn_radius: 2,
        main_btn_bg_color: '#00eeff',
        main_btn_color: '#000000',
        sub_btn_bg_color: '#5f5f5f',
        sub_btn_color: '#ffffff',
        sub_font_size: 24,
        sub_color: '#999999',
        month_color: '#FFFFFF',
        month_font_size: 28,
        month_font_weight: 400,
        time_color: '#999999',
        time_font_size: 24,
        time_font_weight: 400,
        dian_color: '#00eeff',
        xian_color: '#333',
        showStock: false
      }
    }, {
      name: 'nftCollectionMarket',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/nft_market_icon.png',
      title: '藏品寄卖',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 单列模式 2 日历模式
        img_style: 2,
        // 显示方式 2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '#1E1E1E',
        page_margin: 30,
        img_radius: 8,
        status_radius: 8,
        item_margin: 30,
        status_background: '#333333',
        status_bg_image: '',
        font_weight: 400,
        title_color: '#ffffff',
        title_font_size: 32,
        title_font_weight: 500,
        stock_color: '#00eeff',
        stock_font_size: 24,
        price_font_size: 48,
        price_color: '#e6cb95',
        img_style: 2,
        status_btn_bg_color: '#000000',
        status_btn_color: '#00eeff',
        status_font_size: 24,
        btn_font_size: 22,
        btn_radius: 2,
        main_btn_bg_color: '#00eeff',
        main_btn_color: '#000000',
        sub_btn_bg_color: '#5f5f5f',
        sub_btn_color: '#ffffff',
        sub_font_size: 24,
        sub_color: '#999999',
        month_color: '#FFFFFF',
        month_font_size: 28,
        month_font_weight: 400,
        time_color: '#999999',
        time_font_size: 24,
        time_font_weight: 400,
        dian_color: '#00eeff',
        xian_color: '#333',
        showStock: false
      }
    }, {
      name: 'nftCollectionAlbum',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/nft_album_icon.png',
      title: '藏品系列',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 单列模式 2 日历模式
        img_style: 2,
        // 显示方式 2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '#1E1E1E',
        page_margin: 30,
        img_radius: 8,
        status_radius: 8,
        item_margin: 30,
        status_background: '#333333',
        status_bg_image: '',
        font_weight: 400,
        title_color: '#ffffff',
        title_font_size: 32,
        title_font_weight: 500,
        stock_color: '#00eeff',
        stock_font_size: 24,
        price_font_size: 48,
        price_color: '#e6cb95',
        img_style: 2,
        status_btn_bg_color: '#000000',
        status_btn_color: '#00eeff',
        status_font_size: 24,
        btn_font_size: 22,
        btn_radius: 2,
        main_btn_bg_color: '#00eeff',
        main_btn_color: '#000000',
        sub_btn_bg_color: '#5f5f5f',
        sub_btn_color: '#ffffff',
        sub_font_size: 24,
        sub_color: '#999999',
        month_color: '#FFFFFF',
        month_font_size: 28,
        month_font_weight: 400,
        time_color: '#999999',
        time_font_size: 24,
        time_font_weight: 400,
        dian_color: '#00eeff',
        xian_color: '#333'
      }
    }, {
      name: 'nftBlindBox',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/nft_blind_box_icon.png',
      title: '数字盲盒',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 单列模式 2 日历模式
        img_style: 2,
        // 显示方式 2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '#1E1E1E',
        page_margin: 30,
        img_radius: 8,
        status_radius: 8,
        item_margin: 30,
        status_background: '#333333',
        status_bg_image: '',
        font_weight: 400,
        title_color: '#ffffff',
        title_font_size: 32,
        title_font_weight: 500,
        stock_color: '#00eeff',
        stock_font_size: 24,
        price_font_size: 48,
        price_color: '#e6cb95',
        img_style: 2,
        status_btn_bg_color: '#000000',
        status_btn_color: '#00eeff',
        status_font_size: 24,
        btn_font_size: 22,
        btn_radius: 2,
        main_btn_bg_color: '#00eeff',
        main_btn_color: '#000000',
        sub_btn_bg_color: '#5f5f5f',
        sub_btn_color: '#ffffff',
        sub_font_size: 24,
        sub_color: '#999999',
        month_color: '#FFFFFF',
        month_font_size: 28,
        month_font_weight: 400,
        time_color: '#999999',
        time_font_size: 24,
        time_font_weight: 400,
        dian_color: '#00eeff',
        xian_color: '#333',
        showStock: false
      }
    }, {
      name: 'blindBox',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/blind_box_icon.png',
      title: '艺术盲盒',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 单列模式 2 日历模式
        img_style: 2,
        // 显示方式 2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '#1E1E1E',
        page_margin: 30,
        img_radius: 8,
        status_radius: 8,
        item_margin: 30,
        status_background: '#333333',
        status_bg_image: '',
        font_weight: 400,
        title_color: '#ffffff',
        title_font_size: 32,
        title_font_weight: 500,
        stock_color: '#00eeff',
        stock_font_size: 24,
        price_font_size: 48,
        price_color: '#e6cb95',
        img_style: 2,
        status_btn_bg_color: '#000000',
        status_btn_color: '#00eeff',
        status_font_size: 24,
        btn_font_size: 22,
        btn_radius: 2,
        main_btn_bg_color: '#00eeff',
        main_btn_color: '#000000',
        sub_btn_bg_color: '#5f5f5f',
        sub_btn_color: '#ffffff',
        sub_font_size: 24,
        sub_color: '#999999',
        month_color: '#FFFFFF',
        month_font_size: 28,
        month_font_weight: 400,
        time_color: '#999999',
        time_font_size: 24,
        time_font_weight: 400,
        dian_color: '#00eeff',
        xian_color: '#333'
      }
    }, {
      name: 'templateProductList',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/product_icon.png',
      title: '商品',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 左右滑动 2 上下排列
        img_style: 1,
        // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 预展+进行+结束  2 预展+进行  3 预展 4 进行 5结束
        open_live_type: false,
        // 直播筛选  true 开启直播筛选  false 不开启直播筛选
        live_type: 1,
        // 1 只展示直播专场  2 不展示直播专场
        album_type: [1],
        // 专场类型  1限时拍  2同步拍  3 社群拍
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        page_margin: 30,
        // 页面间距
        top_radius: 8,
        // 顶部圆角
        bottom_radius: 8,
        // 底部圆角
        title_color: '#000000',
        // 标题颜色
        sub_title_color: '#999999',
        // 副标题颜色
        price_color: '#347FF4',
        // 价格颜色
        sales_volumes_color: '#999999',
        // 销量颜色
        img_style: 2,
        // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
        top_margin: 20,
        // 内容上间距
        left_margin: 20,
        // 内容左间距
        text_margin: 20,
        // 文件撑开距离
        item_background: '#FFFFFF',
        // 拍品底色
        item_shadow: '#DEDEDE',
        // 拍品阴影颜色
        item_shadow_size: 10,
        // 拍品阴影大小
        title_size: 28,
        // 标题颜色
        title_weight: 500,
        // 标题文字类型 300 加细  400正常  500 加粗
        price_size: 28,
        // 标题价格字号
        price_weight: 500,
        // 标题价格文字类型 300 加细  400正常  500 加粗
        sales_volumes_size: 24,
        // 销量次数字号
        sales_volumes_weight: 400,
        // 销量次数 300 加细  400正常  500 加粗
        img_width: 120,
        img_height: 120,
        show_sale: true
      }
    }, {
      name: 'live',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/live_icon.png',
      title: '直播',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 左右滑动 2 上下排列
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 直播中+直播预告  2 直播中+直播预告+已结束
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        column_title_font_size: 32,
        // 标题文字字号
        item_margin: 30,
        // 专场间距
        item_background: '',
        // 专场背景颜色
        name_wrap: false,
        // 专场名字是否换行   不换行就是超出一行显示...
        indicator_style: 2,
        // 滑动指示器样式  1 圆点  2 横条  3 数字
        indicator_align: 'center',
        // 指示器位置
        indicator_color: '#003D7C',
        // 指示器颜色
        page_margin: 30,
        // 页面间距
        title_color: '#000000',
        // 标题颜色
        sub_title_color: '#ffffff',
        // 副标题颜色
        detail_color: '#999999',
        // 详细介绍颜色
        status_bar_bg: '#003D7C',
        // 状态栏背景色
        status_bar_color: 'white',
        // 状态栏文字颜色   white  black 只有这俩
        status_bar_height: 60,
        // 状态栏高度
        item_shadow: '#DEDEDE',
        // 专场阴影颜色
        item_shadow_size: 10,
        // 专场阴影大小
        top_radius: 8,
        // 专场上圆角
        bottom_radius: 8,
        // 转下下圆角
        img_style: 2,
        text_margin: 24,
        img_width: 240,
        img_height: 240,
        line_num: 'line3',
        title_size: 30,
        title_weight: 500
      }
    }, {
      name: 'brand',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/artist_icon.png',
      title: '作者',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 单列 2 双列 3 列表
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_sort: 1,
        // 系统排序  1 热度  2 最新
        system_count: 20,
        // 系统自动抓取数量
        img_style: 3,
        data: []
      },
      facade: {
        item_background: '',
        // 背景颜色
        page_padding: 30,
        // 页面间距
        item_padding: 30,
        // 间距
        item_bottom_padding: 30,
        // 内容下间距
        content_background: '',
        // 内容背景色
        title_color: '#000000',
        // 标题颜色
        title_fontsize: 28,
        // 标题颜色
        title_weight: 500,
        sub_title_color: '#999',
        // 副标题颜色
        sub_title_fontsize: 24,
        // 副标题颜色
        sub_title_weight: 400,
        // 副标题颜色
        item_shadow: '#DEDEDE',
        // 阴影颜色
        item_shadow_size: 10,
        // 阴影大小
        img_radius: 8,
        // 上圆角
        status_radius: 8,
        // 下圆角
        img_width: 240,
        img_height: 240,
        sub_title_padding: 20
      }
    }, {
      name: 'ticket',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/ticket_icon.png',
      title: '门票',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 单列模式 3 双列模式
        img_style: 2,
        // 显示方式 1瀑布流  2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '#FFFFFF',
        page_margin: 32,
        img_radius: 8,
        img_bottom_radius: 0,
        status_radius: 8,
        item_margin: 34,
        status_background: '#FAFAFA',
        title_color: '#000000',
        title_font_size: 24,
        title_font_weight: 500,
        price_font_size: 32,
        price_color: '#FF223B',
        price_font_weight: 500,
        img_style: 2,
        member_tab_text: '会员',
        member_tab_radius: 8,
        member_tab_border: '#CAAF94',
        member_tab_background_start: '#F4E4C3',
        member_tab_background_end: '#D8B57F',
        member_tab_color: '#703717',
        member_tab_font_size: 24,
        member_tab_font_weight: 500,
        member_color: '#703717',
        member_font_size: 24,
        member_font_weight: 400,
        img_width: 240,
        img_height: 240
      }
    }, {
      name: 'memberCard',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/member_icon.png',
      title: '会员卡',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 单列模式 3 双列模式
        img_style: 2,
        // 显示方式 1瀑布流  2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '#FFFFFF',
        page_margin: 32,
        img_radius: 8,
        img_bottom_radius: 0,
        status_radius: 8,
        item_margin: 34,
        status_background: '#FAFAFA',
        title_color: '#000000',
        title_font_size: 24,
        title_font_weight: 500,
        price_font_size: 32,
        price_color: '#FF223B',
        price_font_weight: 500,
        img_style: 2,
        member_tab_text: '会员',
        member_tab_radius: 8,
        member_tab_border: '#CAAF94',
        member_tab_background_start: '#F4E4C3',
        member_tab_background_end: '#D8B57F',
        member_tab_color: '#703717',
        member_tab_font_size: 24,
        member_tab_font_weight: 500,
        member_color: '#703717',
        member_font_size: 24,
        member_font_weight: 400,
        img_width: 240,
        img_height: 240
      }
    }, {
      name: 'appraisalQuestion',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/answer_icon.png',
      title: '问答帖子',
      hasContent: true,
      content: {
        style: 1,
        // 风格模板选择 1 单列模式 2 双列模式 3 三列模式  4 列表模式  5 左右滑动
        img_style: 1,
        // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '',
        img_radius: 8,
        // 图片圆角
        page_padding: 0,
        // 页面边距
        item_padding: 30,
        // 容器边距
        status_background: '#fff',
        // （状态栏、底图、名称价格区域）底色
        status_high: 60,
        // 网课不需要 （状态栏、底图、名称价格区域）高度
        item_bottom_padding: 30,
        status_radius: 8,
        // （状态栏、底图、名称价格区域）圆角
        content_padding_top: 30,
        content_padding_left: 30,
        content_top_radius: 0,
        content_bottom_radius: 0
      }
    }, {
      name: 'appraisalExpert',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/zhuanjia_icon.png',
      title: '问答专家',
      hasContent: true,
      content: {
        style: 2,
        // 风格模板选择 1 单列模式 2 双列模式 3 三列模式  4 列表模式  5 左右滑动
        img_style: 2,
        // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '',
        img_radius: 8,
        // 图片圆角
        page_padding: 30,
        // 页面边距
        item_padding: 30,
        // 容器边距
        status_background: '#FFFFFF',
        status_high: 60,
        status_radius: 8,
        item_bottom_padding: 30,
        price_color: '#003D9C',
        img_width: 120,
        img_height: 120
      }
    }, {
      name: 'works',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/works_icon.png',
      title: '作品',
      hasContent: true,
      content: {
        style: 2,
        // 风格模板选择 1 单列模式 2 双列模式 3 三列模式  4 列表模式  5 左右滑动
        img_style: 2,
        // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
        type: 1,
        // 按类型去数据  1 系统自动 2 手动选择
        system_type: 1,
        // 系统自动抓取  1 全部 （右侧不修改）
        system_sort: 1,
        // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
        system_count: 20,
        // 系统自动抓取数量
        data: []
      },
      facade: {
        background_color: '',
        img_radius: 8,
        // 图片圆角
        page_padding: 30,
        // 页面边距
        item_padding: 30,
        // 容器边距
        status_background: '#FFFFFF',
        status_high: 60,
        status_radius: 8,
        item_bottom_padding: 30,
        price_color: '#003D9C',
        img_width: 120,
        img_height: 120
      }
    }]
  },
  marketing: {
    title: '推广组件',
    children: [{
      name: 'wechat',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/gongzhonghao_icon.png',
      title: '公众号',
      content: {
        show_name: '公众号',
        link: '',
        show_title: true,
        title_icon: 'https://saas.cdn.yunzongbu.cn/image/20211028/c78de7ae6e7fd23b5f3eff309762e2ae.png',
        title_icon2: 'https://saas.cdn.yunzongbu.cn/image/20211028/1f97313f730870b48f51355ed430303c.png',
        title_icon_right: 'https://saas.cdn.yunzongbu.cn/image/20211028/a63488780e53f04ac78ffcc986193b1f.png',
        title_txt: '关注公众号',
        logo: '',
        name: '公众号名称',
        desc: '专业玉石拍卖鉴定',
        button_txt: '关注'
      },
      // 外观
      facade: {
        type: 1,
        // 1 模块展示   2 悬浮展示
        page_padding: 32,
        page_radius: 8,
        background_color: '#FFFFFF',
        border_color: '#48C265',
        title_color: '#48C265',
        title_margin_left: 32,
        title_margin_top: 32,
        title_size: 28,
        title_style: 500,
        img_width: 80,
        img_height: 80,
        img_radius: 40,
        img_margin_left: 32,
        img_margin_top: 24,
        name_color: '#000000',
        name_margin_left: 24,
        name_margin_top: 26,
        name_size: 28,
        name_style: 500,
        desc_color: '#999999',
        desc_margin_top: 0,
        desc_size: 24,
        desc_style: 400,
        button_background_color: '#48C265',
        button_color: '#FFFFFF',
        button_margin_left: 190,
        button_margin_top: 34,
        button_size: 28,
        button_desc_style: 500,
        button_radius: 8,
        position: 1,
        bottom_margin: 200,
        bottom_radius: 40,
        bottom_background: '#48C265',
        bottom_border: '#48C265',
        bottom_color: '#FFFFFF',
        bottom_size: 24,
        bottom_style: 400
      }
    }, {
      name: 'wechat',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/shipinhao_icon.png',
      title: '视频号',
      content: {
        show_name: '视频号',
        link: '',
        show_title: true,
        title_icon: 'https://saas.cdn.yunzongbu.cn/image/20211028/b41faf31598c0769cee59edb2182a9ad.png',
        title_icon2: 'https://saas.cdn.yunzongbu.cn/image/20211028/15b694c01a0a2334ac4c1a7b5873e315.png',
        title_icon_right: 'https://saas.cdn.yunzongbu.cn/image/20211028/a63488780e53f04ac78ffcc986193b1f.png',
        title_txt: '关注视频号',
        logo: '',
        name: '视频号名称',
        desc: '专业玉石拍卖鉴定',
        button_txt: '关注'
      },
      // 外观
      facade: {
        type: 1,
        // 1 模块展示   2 悬浮展示
        page_padding: 32,
        page_radius: 8,
        background_color: '#F5EEE8',
        border_color: '#B4804E',
        title_color: '#B4804E',
        title_margin_left: 32,
        title_margin_top: 32,
        title_size: 28,
        title_style: 500,
        img_width: 80,
        img_height: 80,
        img_radius: 40,
        img_margin_left: 32,
        img_margin_top: 24,
        name_color: '#000000',
        name_margin_left: 24,
        name_margin_top: 26,
        name_size: 28,
        name_style: 500,
        desc_color: '#999999',
        desc_margin_top: 0,
        desc_size: 24,
        desc_style: 400,
        button_background_color: '#B4804E',
        button_color: '#FFFFFF',
        button_margin_left: 190,
        button_margin_top: 34,
        button_size: 28,
        button_desc_style: 500,
        button_radius: 8,
        position: 1,
        bottom_margin: 200,
        bottom_radius: 40,
        bottom_background: '#B4804E',
        bottom_border: '#B4804E',
        bottom_color: '#FFFFFF',
        bottom_size: 24,
        bottom_style: 400
      }
    }]
  }
};
var _default = exports.default = panels;