var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("header-breadcrumb", {
        attrs: {
          name: _vm.album_id ? "专场详情" : "创建专场",
          hasBack: true,
          isFixed: true,
        },
      }),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm mt20",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c("el-form-item", { attrs: { label: "图片设置" } }, [
            _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                [
                  _c("div", [_vm._v("专场封面图")]),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.status === 3 },
                      model: {
                        value: _vm.ruleForm.image_show_style,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "image_show_style", $$v)
                        },
                        expression: "ruleForm.image_show_style",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "m0", attrs: { label: 1 } },
                        [_vm._v("横版")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          staticClass: "m0",
                          staticStyle: { "margin-left": "20px !important" },
                          attrs: { label: 2 },
                        },
                        [_vm._v("竖版")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission:disabled",
                          value: _vm.album_id
                            ? "merchantAuctionAlbumUpdate>album_image"
                            : "",
                          expression:
                            "album_id ? 'merchantAuctionAlbumUpdate>album_image' : ''",
                          arg: "disabled",
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "upload-view flex align-items-c justify-c mt10",
                          class: { shu: _vm.ruleForm.image_show_style === 2 },
                          on: { click: _vm.changeImg },
                        },
                        [
                          _vm.ruleForm.album_image
                            ? _c("el-image", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.ruleForm.album_image + "!m640",
                                  fit: "cover",
                                },
                              })
                            : _c("div", { staticClass: "imnnn" }, [
                                _c("img", {
                                  staticStyle: {
                                    width: "24px",
                                    height: "24px",
                                  },
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/merchant/23/202463/image/1717383484920428118.png!120a",
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "line-height": "30px" } },
                                  [
                                    _vm._v(
                                      "900*" +
                                        _vm._s(
                                          _vm.ruleForm.image_show_style === 2
                                            ? 1200
                                            : 500
                                        ) +
                                        "px"
                                    ),
                                  ]
                                ),
                              ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-left": "32px" } }, [
                _c("div", [_vm._v("小程序分享卡片图")]),
                _vm._v(" "),
                _c("div", { staticClass: "preText" }, [_vm._v("预览效果")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "upload-view flex align-items-c justify-c mt10",
                    staticStyle: { position: "relative" },
                    on: {
                      click: function ($event) {
                        return _vm.changeImg1("share_img")
                      },
                    },
                  },
                  [
                    _vm.ruleForm.share_img
                      ? _c("el-image", {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: {
                            src: _vm.ruleForm.share_img + "!m640",
                            fit: "cover",
                          },
                        })
                      : _c("div", { staticClass: "imnnn" }, [
                          _c("img", {
                            staticStyle: { width: "24px", height: "24px" },
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/merchant/23/202463/image/1717383484920428118.png!120a",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "line-height": "30px" } },
                            [_vm._v("比例5:4")]
                          ),
                        ]),
                    _vm._v(" "),
                    _vm.ruleForm.share_img
                      ? _c(
                          "div",
                          {
                            staticClass: "del-img item-flex-center",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.ruleForm.share_img = ""
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-delete" })]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-left": "32px" } }, [
                _c("div", [_vm._v("专场介绍视频")]),
                _vm._v(" "),
                _c("div", { staticClass: "preText" }, [_vm._v("预览效果")]),
                _vm._v(" "),
                _vm.ruleForm.video
                  ? _c(
                      "div",
                      {
                        staticClass: "mt10 flex justify-c cur",
                        on: { click: _vm.uploadVideo },
                      },
                      [
                        _c("video", {
                          staticStyle: { width: "120px", heiht: "96px" },
                          attrs: { src: _vm.ruleForm.video },
                        }),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "upload-view flex align-items-c justify-c mt10",
                        on: { click: _vm.uploadVideo },
                      },
                      [
                        _c("div", { staticClass: "imnnn" }, [
                          _c("img", {
                            staticStyle: { width: "24px", height: "24px" },
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/merchant/23/202463/image/1717383484920428118.png!120a",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "line-height": "30px" } },
                            [_vm._v("200M以内")]
                          ),
                        ]),
                      ]
                    ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.venue_type == 1
            ? _c("el-form-item", { attrs: { label: "视频直播" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission:disabled",
                        value: _vm.album_id
                          ? "merchantAuctionAlbumUpdate>is_create_live"
                          : "",
                        expression:
                          "album_id ? 'merchantAuctionAlbumUpdate>is_create_live' : ''",
                        arg: "disabled",
                      },
                    ],
                  },
                  [
                    _c("el-switch", {
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        disabled: _vm.status === 3,
                      },
                      model: {
                        value: _vm.ruleForm.is_create_live,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "is_create_live", $$v)
                        },
                        expression: "ruleForm.is_create_live",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _c("el-form-item", { attrs: { label: "视频直播" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission:disabled",
                        value: _vm.album_id
                          ? "merchantAuctionAlbumUpdate>is_create_live"
                          : "",
                        expression:
                          "album_id ? 'merchantAuctionAlbumUpdate>is_create_live' : ''",
                        arg: "disabled",
                      },
                    ],
                  },
                  [
                    _c("el-switch", {
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        disabled: _vm.status === 3,
                      },
                      model: {
                        value: _vm.ruleForm.is_open_video_live,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "is_open_video_live", $$v)
                        },
                        expression: "ruleForm.is_open_video_live",
                      },
                    }),
                  ],
                  1
                ),
              ]),
          _vm._v(" "),
          _vm.ruleForm.is_create_live === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "开播时间" } },
                [
                  _c("el-input-number", {
                    staticClass: "w100",
                    attrs: {
                      "controls-position": "right",
                      min: 2010,
                      max: 2100,
                      controls: false,
                      disabled: _vm.status === 3,
                    },
                    model: {
                      value: _vm.startPlayTime.year,
                      callback: function ($$v) {
                        _vm.$set(_vm.startPlayTime, "year", $$v)
                      },
                      expression: "startPlayTime.year",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "plr10" }, [_vm._v("年")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w50",
                    attrs: {
                      min: 1,
                      max: 12,
                      controls: false,
                      disabled: _vm.status === 3,
                    },
                    model: {
                      value: _vm.startPlayTime.month,
                      callback: function ($$v) {
                        _vm.$set(_vm.startPlayTime, "month", $$v)
                      },
                      expression: "startPlayTime.month",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "plr10" }, [_vm._v("月")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w50",
                    attrs: {
                      min: 1,
                      max: 31,
                      controls: false,
                      disabled: _vm.status === 3,
                    },
                    model: {
                      value: _vm.startPlayTime.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.startPlayTime, "date", $$v)
                      },
                      expression: "startPlayTime.date",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "plr10" }, [_vm._v("日")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w50",
                    attrs: {
                      min: 0,
                      max: 23,
                      controls: false,
                      disabled: _vm.status === 3,
                    },
                    model: {
                      value: _vm.startPlayTime.hour,
                      callback: function ($$v) {
                        _vm.$set(_vm.startPlayTime, "hour", $$v)
                      },
                      expression: "startPlayTime.hour",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "plr10" }, [_vm._v("点")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w50",
                    attrs: {
                      min: 0,
                      max: 59,
                      controls: false,
                      disabled: _vm.status === 3,
                    },
                    model: {
                      value: _vm.startPlayTime.minute,
                      callback: function ($$v) {
                        _vm.$set(_vm.startPlayTime, "minute", $$v)
                      },
                      expression: "startPlayTime.minute",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "plr10" }, [_vm._v("分")]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.is_create_live === 1 &&
          _vm.ruleForm.is_open_video_live === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "直播画面" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.status === 3 },
                      model: {
                        value: _vm.ruleForm.live_screen_direction,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "live_screen_direction", $$v)
                        },
                        expression: "ruleForm.live_screen_direction",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("竖屏直播"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("横屏直播"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom",
                            width: "200",
                            trigger: "hover",
                          },
                        },
                        [
                          _vm.ruleForm.live_screen_direction == 1
                            ? _c("img", {
                                staticStyle: { width: "175px" },
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20220419/798eab6cc118c1ce9a929cb2656f46bf.png!m640",
                                },
                              })
                            : _vm.ruleForm.live_screen_direction == 2
                            ? _c("img", {
                                staticStyle: { width: "175px" },
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20220419/83df8d9513ff997f9aac461e02e21765.png!m640",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "ft12 plr2 ml10",
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "commonlook" },
                              }),
                              _vm._v("预览效果"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.is_create_live && _vm.ruleForm.is_open_video_live === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "推流方式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.status === 3 },
                      model: {
                        value: _vm.ruleForm.live_stream_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "live_stream_type", $$v)
                        },
                        expression: "ruleForm.live_stream_type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "LEB" } }, [
                        _vm._v("快推流"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "LVB" } }, [
                        _vm._v("标准推流"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "TPB" } }, [
                        _vm._v("第三方推流"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.live_stream_type == "LEB"
                    ? _c("span", { staticClass: "ml10 color-gray ft12" }, [
                        _vm._v(
                          "（网络测速上传30Mbps以上时，选择快推流。否则选“标准推流”）"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.live_stream_type == "TPB"
            ? _c(
                "el-form-item",
                { attrs: { label: "直播拉流地址", prop: "live_stream_url" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    model: {
                      value: _vm.ruleForm.live_stream_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "live_stream_url", $$v)
                      },
                      expression: "ruleForm.live_stream_url",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.is_create_live === 1 &&
          _vm.ruleForm.is_open_video_live === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "直播回放" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.is_watch_video_playback,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "is_watch_video_playback", $$v)
                        },
                        expression: "ruleForm.is_watch_video_playback",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("公开观看"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("不公开"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "专场类型" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: !!_vm.album_id },
                  model: {
                    value: _vm.venue_type,
                    callback: function ($$v) {
                      _vm.venue_type = $$v
                    },
                    expression: "venue_type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("限时拍")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("即时拍")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 4 } }, [_vm._v("即刻拍")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("同步拍")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 5 } }, [_vm._v("抖音拍")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.$store.getters.is_multilingual
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "简体名称", prop: "album_name" } },
                  [
                    _c("el-input", {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission:disabled",
                          value: _vm.album_id
                            ? "merchantAuctionAlbumUpdate>album_name"
                            : "",
                          expression:
                            "album_id ? 'merchantAuctionAlbumUpdate>album_name' : ''",
                          arg: "disabled",
                        },
                      ],
                      staticClass: "w300",
                      attrs: { disabled: _vm.status === 3 },
                      model: {
                        value: _vm.ruleForm.album_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "album_name", $$v)
                        },
                        expression: "ruleForm.album_name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "繁体名称" } },
                  [
                    _c("el-input", {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission:disabled",
                          value: _vm.album_id
                            ? "merchantAuctionAlbumUpdate>album_name"
                            : "",
                          expression:
                            "album_id ? 'merchantAuctionAlbumUpdate>album_name' : ''",
                          arg: "disabled",
                        },
                      ],
                      staticClass: "w300",
                      attrs: { disabled: _vm.status === 3 },
                      model: {
                        value: _vm.ruleForm.album_name_zh_tw,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "album_name_zh_tw", $$v)
                        },
                        expression: "ruleForm.album_name_zh_tw",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "英文名称" } },
                  [
                    _c("el-input", {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission:disabled",
                          value: _vm.album_id
                            ? "merchantAuctionAlbumUpdate>album_name"
                            : "",
                          expression:
                            "album_id ? 'merchantAuctionAlbumUpdate>album_name' : ''",
                          arg: "disabled",
                        },
                      ],
                      staticClass: "w300",
                      attrs: { disabled: _vm.status === 3 },
                      model: {
                        value: _vm.ruleForm.album_name_en,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "album_name_en", $$v)
                        },
                        expression: "ruleForm.album_name_en",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "el-form-item",
                  { attrs: { label: "专场名称", prop: "album_name" } },
                  [
                    _c("el-input", {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission:disabled",
                          value: _vm.album_id
                            ? "merchantAuctionAlbumUpdate>album_name"
                            : "",
                          expression:
                            "album_id ? 'merchantAuctionAlbumUpdate>album_name' : ''",
                          arg: "disabled",
                        },
                      ],
                      staticClass: "w300",
                      attrs: { disabled: _vm.status === 3 },
                      model: {
                        value: _vm.ruleForm.album_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "album_name", $$v)
                        },
                        expression: "ruleForm.album_name",
                      },
                    }),
                  ],
                  1
                ),
              ],
          _vm._v(" "),
          _vm.venue_type < 4 || _vm.venue_type == 5
            ? [
                _c("el-form-item", { attrs: { label: "预展开始" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission:disabled",
                          value: _vm.album_id
                            ? "merchantAuctionAlbumUpdate>scheduled_show_time"
                            : "",
                          expression:
                            "album_id ? 'merchantAuctionAlbumUpdate>scheduled_show_time' : ''",
                          arg: "disabled",
                        },
                      ],
                      staticClass: "flex align-items-c",
                    },
                    [
                      _c("el-input-number", {
                        staticClass: "w100",
                        attrs: {
                          "controls-position": "right",
                          min: 2010,
                          max: 2100,
                          controls: false,
                          disabled: _vm.status === 3,
                        },
                        model: {
                          value: _vm.previewStartTime.year,
                          callback: function ($$v) {
                            _vm.$set(_vm.previewStartTime, "year", $$v)
                          },
                          expression: "previewStartTime.year",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "plr10" }, [_vm._v("年")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: {
                          min: 1,
                          max: 12,
                          controls: false,
                          disabled: _vm.status === 3,
                        },
                        model: {
                          value: _vm.previewStartTime.month,
                          callback: function ($$v) {
                            _vm.$set(_vm.previewStartTime, "month", $$v)
                          },
                          expression: "previewStartTime.month",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "plr10" }, [_vm._v("月")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: {
                          min: 1,
                          max: 31,
                          controls: false,
                          disabled: _vm.status === 3,
                        },
                        model: {
                          value: _vm.previewStartTime.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.previewStartTime, "date", $$v)
                          },
                          expression: "previewStartTime.date",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "plr10" }, [_vm._v("日")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: {
                          min: 0,
                          max: 23,
                          controls: false,
                          disabled: _vm.status === 3,
                        },
                        model: {
                          value: _vm.previewStartTime.hour,
                          callback: function ($$v) {
                            _vm.$set(_vm.previewStartTime, "hour", $$v)
                          },
                          expression: "previewStartTime.hour",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "plr10" }, [_vm._v("点")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: {
                          min: 0,
                          max: 59,
                          controls: false,
                          disabled: _vm.status === 3,
                        },
                        model: {
                          value: _vm.previewStartTime.minute,
                          callback: function ($$v) {
                            _vm.$set(_vm.previewStartTime, "minute", $$v)
                          },
                          expression: "previewStartTime.minute",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "plr10" }, [_vm._v("分")]),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("el-form-item", { attrs: { label: "拍卖时间" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission:disabled",
                          value: _vm.album_id
                            ? "merchantAuctionAlbumUpdate>album_time"
                            : "",
                          expression:
                            "album_id ? 'merchantAuctionAlbumUpdate>album_time' : ''",
                          arg: "disabled",
                        },
                      ],
                      staticClass: "flex align-items-c",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "fWeight500",
                          staticStyle: { "padding-right": "5px" },
                        },
                        [_vm._v("开拍")]
                      ),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w100",
                        attrs: {
                          "controls-position": "right",
                          min: 2010,
                          max: 2100,
                          controls: false,
                          disabled: _vm.status === 3 || _vm.status === 2,
                        },
                        model: {
                          value: _vm.startTime.year,
                          callback: function ($$v) {
                            _vm.$set(_vm.startTime, "year", $$v)
                          },
                          expression: "startTime.year",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "plr10" }, [_vm._v("年")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: {
                          min: 1,
                          max: 12,
                          controls: false,
                          disabled: _vm.status === 3 || _vm.status === 2,
                        },
                        model: {
                          value: _vm.startTime.month,
                          callback: function ($$v) {
                            _vm.$set(_vm.startTime, "month", $$v)
                          },
                          expression: "startTime.month",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "plr10" }, [_vm._v("月")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: {
                          min: 1,
                          max: 31,
                          controls: false,
                          disabled: _vm.status === 3 || _vm.status === 2,
                        },
                        model: {
                          value: _vm.startTime.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.startTime, "date", $$v)
                          },
                          expression: "startTime.date",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "plr10" }, [_vm._v("日")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: {
                          min: 0,
                          max: 23,
                          controls: false,
                          disabled: _vm.status === 3 || _vm.status === 2,
                        },
                        model: {
                          value: _vm.startTime.hour,
                          callback: function ($$v) {
                            _vm.$set(_vm.startTime, "hour", $$v)
                          },
                          expression: "startTime.hour",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "plr10" }, [_vm._v("点")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: {
                          min: 0,
                          max: 59,
                          controls: false,
                          disabled: _vm.status === 3 || _vm.status === 2,
                        },
                        model: {
                          value: _vm.startTime.minute,
                          callback: function ($$v) {
                            _vm.$set(_vm.startTime, "minute", $$v)
                          },
                          expression: "startTime.minute",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "plr10" }, [_vm._v("分")]),
                      _vm._v(" "),
                      _vm.venue_type == 1
                        ? [
                            _c(
                              "span",
                              {
                                staticClass: "ml20 fWeight500",
                                staticStyle: { "padding-right": "5px" },
                              },
                              [_vm._v("结拍")]
                            ),
                            _vm._v(" "),
                            _c("el-input-number", {
                              staticClass: "w100",
                              attrs: {
                                "controls-position": "right",
                                min: 2010,
                                max: 2100,
                                controls: false,
                                disabled: _vm.status === 3,
                              },
                              model: {
                                value: _vm.endTime.year,
                                callback: function ($$v) {
                                  _vm.$set(_vm.endTime, "year", $$v)
                                },
                                expression: "endTime.year",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "plr10" }, [
                              _vm._v("年"),
                            ]),
                            _vm._v(" "),
                            _c("el-input-number", {
                              staticClass: "w50",
                              attrs: {
                                min: 1,
                                max: 12,
                                controls: false,
                                disabled: _vm.status === 3,
                              },
                              model: {
                                value: _vm.endTime.month,
                                callback: function ($$v) {
                                  _vm.$set(_vm.endTime, "month", $$v)
                                },
                                expression: "endTime.month",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "plr10" }, [
                              _vm._v("月"),
                            ]),
                            _vm._v(" "),
                            _c("el-input-number", {
                              staticClass: "w50",
                              attrs: {
                                min: 1,
                                max: 31,
                                controls: false,
                                disabled: _vm.status === 3,
                              },
                              model: {
                                value: _vm.endTime.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.endTime, "date", $$v)
                                },
                                expression: "endTime.date",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "plr10" }, [
                              _vm._v("日"),
                            ]),
                            _vm._v(" "),
                            _c("el-input-number", {
                              staticClass: "w50",
                              attrs: {
                                min: 0,
                                max: 23,
                                controls: false,
                                disabled: _vm.status === 3,
                              },
                              model: {
                                value: _vm.endTime.hour,
                                callback: function ($$v) {
                                  _vm.$set(_vm.endTime, "hour", $$v)
                                },
                                expression: "endTime.hour",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "plr10" }, [
                              _vm._v("点"),
                            ]),
                            _vm._v(" "),
                            _c("el-input-number", {
                              staticClass: "w50",
                              attrs: {
                                min: 0,
                                max: 59,
                                controls: false,
                                disabled: _vm.status === 3,
                              },
                              model: {
                                value: _vm.endTime.minute,
                                callback: function ($$v) {
                                  _vm.$set(_vm.endTime, "minute", $$v)
                                },
                                expression: "endTime.minute",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "plr10" }, [
                              _vm._v("分"),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.venue_type == 4
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "拍卖时间" } },
                  [
                    _c("span", [_vm._v("每件拍品")]),
                    _vm._v(" "),
                    _c("el-input-number", {
                      staticClass: "w50 ml10 mr10",
                      attrs: {
                        controls: false,
                        min: 0,
                        disabled: _vm.status === 3,
                      },
                      model: {
                        value: _vm.ruleForm.interval_time,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "interval_time", $$v)
                        },
                        expression: "ruleForm.interval_time",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("分钟结拍")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-form-item", { attrs: { label: "出价延时" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission:disabled",
                          value: _vm.album_id
                            ? "merchantAuctionAlbumUpdate>reset_time"
                            : "",
                          expression:
                            "album_id ? 'merchantAuctionAlbumUpdate>reset_time' : ''",
                          arg: "disabled",
                        },
                      ],
                      staticClass: "flex align-items-c",
                    },
                    [
                      _c("span", [_vm._v("拍品最后")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w50 ml10 mr10",
                        attrs: {
                          controls: false,
                          min: 0,
                          disabled: _vm.status === 3,
                        },
                        model: {
                          value: _vm.reset_time_1,
                          callback: function ($$v) {
                            _vm.reset_time_1 = $$v
                          },
                          expression: "reset_time_1",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("分钟内有人出价，加时")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w50 ml10 mr10",
                        attrs: {
                          controls: false,
                          min: 0,
                          disabled: _vm.status === 3,
                        },
                        model: {
                          value: _vm.reset_time_2,
                          callback: function ($$v) {
                            _vm.reset_time_2 = $$v
                          },
                          expression: "reset_time_2",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("分钟结拍")]),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.venue_type == 1
            ? [
                _c("el-form-item", { attrs: { label: "结拍方式" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission:disabled",
                          value: _vm.album_id
                            ? "merchantAuctionAlbumUpdate>end_type"
                            : "",
                          expression:
                            "album_id ? 'merchantAuctionAlbumUpdate>end_type' : ''",
                          arg: "disabled",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.status === 3 },
                          model: {
                            value: _vm.ruleForm.end_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "end_type", $$v)
                            },
                            expression: "ruleForm.end_type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("\n              统一结拍\n              "),
                            _c(
                              "span",
                              {
                                staticClass: "ft12",
                                staticStyle: { color: "#999" },
                              },
                              [_vm._v("(所有拍品统一结拍)")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("\n              逐件结拍\n              "),
                            _c(
                              "span",
                              {
                                staticClass: "ft12",
                                staticStyle: { color: "#999" },
                              },
                              [_vm._v("(每件拍品间隔N分钟结拍)")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.ruleForm.end_type === 2
                  ? _c("el-form-item", { attrs: { label: "间隔时间" } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission:disabled",
                              value: _vm.album_id
                                ? "merchantAuctionAlbumUpdate>reset_time"
                                : "",
                              expression:
                                "album_id ? 'merchantAuctionAlbumUpdate>reset_time' : ''",
                              arg: "disabled",
                            },
                          ],
                          staticClass: "flex align-items-c",
                        },
                        [
                          _c("span", [_vm._v("每件拍品间隔")]),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticClass: "w50 ml10 mr10",
                            attrs: {
                              controls: false,
                              min: 0,
                              disabled: _vm.status === 3,
                            },
                            model: {
                              value: _vm.ruleForm.interval_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "interval_time", $$v)
                              },
                              expression: "ruleForm.interval_time",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("分钟结拍")]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("el-form-item", { attrs: { label: "出价延时" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission:disabled",
                          value: _vm.album_id
                            ? "merchantAuctionAlbumUpdate>reset_time"
                            : "",
                          expression:
                            "album_id ? 'merchantAuctionAlbumUpdate>reset_time' : ''",
                          arg: "disabled",
                        },
                      ],
                      staticClass: "flex align-items-c",
                    },
                    [
                      _c("span", [_vm._v("拍品最后")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w50 ml10 mr10",
                        attrs: {
                          controls: false,
                          min: 0,
                          disabled: _vm.status === 3,
                        },
                        model: {
                          value: _vm.reset_time_1,
                          callback: function ($$v) {
                            _vm.reset_time_1 = $$v
                          },
                          expression: "reset_time_1",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("分钟内有人出价，加时")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w50 ml10 mr10",
                        attrs: {
                          controls: false,
                          min: 0,
                          disabled: _vm.status === 3,
                        },
                        model: {
                          value: _vm.reset_time_2,
                          callback: function ($$v) {
                            _vm.reset_time_2 = $$v
                          },
                          expression: "reset_time_2",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("分钟结拍")]),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.venue_type == 2 || _vm.venue_type == 5
            ? [
                _c("el-form-item", { attrs: { label: "结拍方式" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission:disabled",
                          value: _vm.album_id
                            ? "merchantAuctionAlbumUpdate>end_type"
                            : "",
                          expression:
                            "album_id ? 'merchantAuctionAlbumUpdate>end_type' : ''",
                          arg: "disabled",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.status === 3 },
                          model: {
                            value: _vm.ruleForm.end_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "end_type", $$v)
                            },
                            expression: "ruleForm.end_type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(
                              "\n              自动倒计时\n              "
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "ft12",
                                staticStyle: { color: "#999" },
                              },
                              [_vm._v("(系统自动控制倒计时和落槌)")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v(
                              "\n              人工倒计时\n              "
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "ft12",
                                staticStyle: { color: "#999" },
                              },
                              [_vm._v("(管理人员手动控制倒计时和落槌)")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.ruleForm.end_type == 1
                  ? _c("el-form-item", { attrs: { label: "" } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission:disabled",
                              value: _vm.album_id
                                ? "merchantAuctionAlbumUpdate>reset_time"
                                : "",
                              expression:
                                "album_id ? 'merchantAuctionAlbumUpdate>reset_time' : ''",
                              arg: "disabled",
                            },
                          ],
                          staticClass: "flex align-items-c",
                        },
                        [
                          _c("span", [_vm._v("每件拍品")]),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticClass: "w50 ml10 mr10",
                            attrs: {
                              controls: false,
                              min: 0,
                              disabled: _vm.status === 3,
                            },
                            model: {
                              value: _vm.ruleForm.interval_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "interval_time", $$v)
                              },
                              expression: "ruleForm.interval_time",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("分钟")]),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml20" }, [
                            _vm._v("延时规则：最后"),
                          ]),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticClass: "w50 ml10 mr10",
                            attrs: {
                              controls: false,
                              min: 0,
                              disabled: _vm.status === 3,
                            },
                            model: {
                              value: _vm.reset_time_1,
                              callback: function ($$v) {
                                _vm.reset_time_1 = $$v
                              },
                              expression: "reset_time_1",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("秒内出价，延时")]),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticClass: "w50 ml10 mr10",
                            attrs: {
                              controls: false,
                              min: 0,
                              disabled: _vm.status === 3,
                            },
                            model: {
                              value: _vm.reset_time_2,
                              callback: function ($$v) {
                                _vm.reset_time_2 = $$v
                              },
                              expression: "reset_time_2",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("秒重新倒计时")]),
                        ],
                        1
                      ),
                    ])
                  : _vm.ruleForm.end_type == 2
                  ? _c("el-form-item", { attrs: { label: "" } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission:disabled",
                              value: _vm.album_id
                                ? "merchantAuctionAlbumUpdate>reset_time"
                                : "",
                              expression:
                                "album_id ? 'merchantAuctionAlbumUpdate>reset_time' : ''",
                              arg: "disabled",
                            },
                          ],
                          staticClass: "flex align-items-c",
                        },
                        [
                          _c("span", [_vm._v("倒数")]),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticClass: "w50 ml10 mr10",
                            attrs: {
                              controls: false,
                              min: 0,
                              disabled: _vm.status === 3,
                            },
                            model: {
                              value: _vm.ruleForm.click_hammer_num,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "click_hammer_num", $$v)
                              },
                              expression: "ruleForm.click_hammer_num",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("次落槌")]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "专场保证金" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission:disabled",
                    value: _vm.album_id
                      ? "merchantAuctionAlbumUpdate>bond_price"
                      : "",
                    expression:
                      "album_id ? 'merchantAuctionAlbumUpdate>bond_price' : ''",
                    arg: "disabled",
                  },
                ],
                staticClass: "flex align-items-c",
              },
              [
                _vm._v("\n        固定保证金\n        "),
                _c("el-input-number", {
                  staticClass: "w150 ml10 mr10",
                  attrs: {
                    controls: false,
                    min: 0,
                    placeholder: "0/不填为免保证金",
                  },
                  model: {
                    value: _vm.ruleForm.bond_price,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "bond_price", $$v)
                    },
                    expression: "ruleForm.bond_price",
                  },
                }),
                _vm._v(" "),
                _vm._v("\n        比例保证金1：\n        "),
                _c("el-input-number", {
                  staticClass: "w100 ml10 mr10",
                  attrs: { controls: false, min: 0 },
                  model: {
                    value: _vm.ruleForm.bond_bid_ratio,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "bond_bid_ratio", $$v)
                    },
                    expression: "ruleForm.bond_bid_ratio",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "ml10 color-gray ft12" }, [
                  _vm._v("（\b\b两种模式可同时设置，由客户选择使用）"),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "买方佣金", prop: "commission" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission:disabled",
                      value: _vm.album_id
                        ? "merchantAuctionAlbumUpdate>commission"
                        : "",
                      expression:
                        "album_id ? 'merchantAuctionAlbumUpdate>commission' : ''",
                      arg: "disabled",
                    },
                  ],
                },
                [
                  _c("span", [_vm._v("快速结算佣金")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w100",
                    attrs: {
                      min: 0,
                      max: 50,
                      controls: false,
                      disabled: _vm.status === 3,
                    },
                    model: {
                      value: _vm.ruleForm.commission,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "commission", $$v)
                      },
                      expression: "ruleForm.commission",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml10" }, [_vm._v("%")]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { "margin-top": "10px" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("超时结算佣金")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w100",
                    attrs: {
                      min: 0,
                      max: 50,
                      controls: false,
                      disabled: _vm.status === 3,
                    },
                    model: {
                      value: _vm.ruleForm.after_commission,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "after_commission", $$v)
                      },
                      expression: "ruleForm.after_commission",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml10" }, [_vm._v("%")]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "ml10",
                      staticStyle: { "margin-left": "20px" },
                    },
                    [_vm._v("超时时间定义")]
                  ),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w100",
                    attrs: {
                      "controls-position": "right",
                      min: 2010,
                      max: 2100,
                      controls: false,
                      disabled: _vm.status === 3,
                    },
                    model: {
                      value: _vm.commissionTime.year,
                      callback: function ($$v) {
                        _vm.$set(_vm.commissionTime, "year", $$v)
                      },
                      expression: "commissionTime.year",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "plr10" }, [_vm._v("年")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w50",
                    attrs: {
                      min: 1,
                      max: 12,
                      controls: false,
                      disabled: _vm.status === 3,
                    },
                    model: {
                      value: _vm.commissionTime.month,
                      callback: function ($$v) {
                        _vm.$set(_vm.commissionTime, "month", $$v)
                      },
                      expression: "commissionTime.month",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "plr10" }, [_vm._v("月")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w50",
                    attrs: {
                      min: 1,
                      max: 31,
                      controls: false,
                      disabled: _vm.status === 3,
                    },
                    model: {
                      value: _vm.commissionTime.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.commissionTime, "date", $$v)
                      },
                      expression: "commissionTime.date",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "plr10" }, [_vm._v("日")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w50",
                    attrs: {
                      min: 0,
                      max: 60,
                      controls: false,
                      disabled: _vm.status === 3,
                    },
                    model: {
                      value: _vm.commissionTime.hour,
                      callback: function ($$v) {
                        _vm.$set(_vm.commissionTime, "hour", $$v)
                      },
                      expression: "commissionTime.hour",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "plr10" }, [_vm._v("点")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w50",
                    attrs: {
                      min: 0,
                      max: 60,
                      controls: false,
                      disabled: _vm.status === 3,
                    },
                    model: {
                      value: _vm.commissionTime.minute,
                      callback: function ($$v) {
                        _vm.$set(_vm.commissionTime, "minute", $$v)
                      },
                      expression: "commissionTime.minute",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "plr10" }, [_vm._v("分")]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "其他设置" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission:disabled",
                    value: _vm.album_id
                      ? "merchantAuctionAlbumUpdate>other_settings"
                      : "",
                    expression:
                      "album_id ? 'merchantAuctionAlbumUpdate>other_settings' : ''",
                    arg: "disabled",
                  },
                ],
              },
              [
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c("span", { staticClass: "mr10 ft12" }, [
                      _vm._v("实名认证："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.status === 3 },
                        model: {
                          value: _vm.ruleForm.check_user_idCard,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "check_user_idCard", $$v)
                          },
                          expression: "ruleForm.check_user_idCard",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("参拍前"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 3 } }, [
                          _vm._v("中拍后"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("不实名"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c("div", { staticClass: "w300" }, [
                      _c("span", { staticClass: "ft12" }, [
                        _vm._v("隐藏展示："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "ml5 ft12",
                          staticStyle: { color: "#999" },
                        },
                        [_vm._v("(隐藏后，所有用户看不到此专场)")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: { "active-value": 0, "inactive-value": 1 },
                      model: {
                        value: _vm.ruleForm.is_show,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "is_show", $$v)
                        },
                        expression: "ruleForm.is_show",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c("div", { staticClass: "w300" }, [
                      _c("span", { staticClass: "ft12" }, [
                        _vm._v("置顶专场："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "ml5 ft12",
                          staticStyle: { color: "#999" },
                        },
                        [_vm._v("(置顶后，在专场列表里优先展示)")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.ruleForm.addtop,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "addtop", $$v)
                        },
                        expression: "ruleForm.addtop",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c("div", { staticClass: "w300" }, [
                      _c("span", { staticClass: "ft12" }, [
                        _vm._v("隐藏成交额："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "ml5 ft12",
                          staticStyle: { color: "#999" },
                        },
                        [_vm._v("(专场结拍后不展示成交额)")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: { "active-value": 0, "inactive-value": 1 },
                      model: {
                        value: _vm.ruleForm.is_show_turnovers,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "is_show_turnovers", $$v)
                        },
                        expression: "ruleForm.is_show_turnovers",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c("div", { staticClass: "w300" }, [
                      _c("span", { staticClass: "ft12" }, [
                        _vm._v("拍品隐藏成交价："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "ml5 ft12",
                          staticStyle: { color: "#999" },
                        },
                        [_vm._v("(拍品结拍后不展示成交价格)")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.ruleForm.lose_price_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "lose_price_status", $$v)
                        },
                        expression: "ruleForm.lose_price_status",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c("div", { staticClass: "w300" }, [
                      _c("span", { staticClass: "ft12" }, [
                        _vm._v("隐藏出价记录："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "ml5 ft12",
                          staticStyle: { color: "#999" },
                        },
                        [_vm._v("(专场结拍后不展示出价记录)")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: { "active-value": 0, "inactive-value": 1 },
                      model: {
                        value: _vm.ruleForm.is_show_bidRecord,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "is_show_bidRecord", $$v)
                        },
                        expression: "ruleForm.is_show_bidRecord",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c("div", { staticClass: "w300" }, [
                      _c("span", { staticClass: "ft12" }, [
                        _vm._v("停办号牌："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "ml5 ft12",
                          staticStyle: { color: "#999" },
                        },
                        [_vm._v("(开拍后，不能办理新号牌)")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.ruleForm.is_start_can_pay_bond,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "is_start_can_pay_bond", $$v)
                        },
                        expression: "ruleForm.is_start_can_pay_bond",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c("div", { staticClass: "w300" }, [
                      _c("span", { staticClass: "ft12" }, [
                        _vm._v("预展中可出价："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "ml5 ft12",
                          staticStyle: { color: "#999" },
                        },
                        [_vm._v("(预展时，即可正常出价)")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.ruleForm.is_pre_bid,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "is_pre_bid", $$v)
                        },
                        expression: "ruleForm.is_pre_bid",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "文物许可" } }, [
            _c(
              "div",
              [
                _c("el-input", {
                  staticClass: "w300",
                  attrs: { placeholder: "例如：京文物许可［2024］832号" },
                  model: {
                    value: _vm.ruleForm.record_number,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "record_number", $$v)
                    },
                    expression: "ruleForm.record_number",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "upload-view flex align-items-c justify-c mt20",
                  staticStyle: { position: "relative" },
                  on: {
                    click: function ($event) {
                      return _vm.changeImg1("record_number_image")
                    },
                  },
                },
                [
                  _vm.ruleForm.record_number_image
                    ? _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: _vm.ruleForm.record_number_image + "!m640",
                          fit: "cover",
                        },
                      })
                    : _c("div", { staticClass: "imnnn" }, [
                        _c("img", {
                          staticStyle: { width: "24px", height: "24px" },
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/202463/image/1717383484920428118.png!120a",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "line-height": "30px" } }, [
                          _vm._v("大小20M以内"),
                        ]),
                      ]),
                  _vm._v(" "),
                  _vm.ruleForm.record_number_image
                    ? _c(
                        "div",
                        {
                          staticClass: "del-img item-flex-center",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.ruleForm.record_number_image = ""
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-delete" })]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.$route.query.editnotice == 1
            ? _c("el-form-item", { attrs: { label: "弹窗介绍" } }, [
                _c(
                  "div",
                  { staticStyle: { "padding-right": "40px" } },
                  [
                    _c("Tinymce", {
                      ref: "editor1",
                      attrs: { height: 300 },
                      model: {
                        value: _vm.ruleForm.page_notice_msg,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "page_notice_msg", $$v)
                        },
                        expression: "ruleForm.page_notice_msg",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "专场介绍" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission:disabled",
                    value: _vm.album_id
                      ? "merchantAuctionAlbumUpdate>message"
                      : "",
                    expression:
                      "album_id ? 'merchantAuctionAlbumUpdate>message' : ''",
                    arg: "disabled",
                  },
                ],
              },
              [
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c("el-input", {
                      staticClass: "w200 mr10",
                      attrs: { placeholder: "输入自定义标题", maxlength: "6" },
                      model: {
                        value: _vm.ruleForm.album_message_title,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "album_message_title", $$v)
                        },
                        expression: "ruleForm.album_message_title",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.ruleForm.is_show_page_notice,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "is_show_page_notice", $$v)
                        },
                        expression: "ruleForm.is_show_page_notice",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml10 color-gray ft12" }, [
                      _vm._v("弹窗提示阅读"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "mt20",
                    staticStyle: { "padding-right": "40px" },
                  },
                  [
                    _c("Tinymce", {
                      ref: "editor",
                      staticClass: "mt10",
                      attrs: { height: 400 },
                      model: {
                        value: _vm.ruleForm.message,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "message", $$v)
                        },
                        expression: "ruleForm.message",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "formFixedBox" },
        [
          _vm.album_id
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantAuctionAlbumUpdate",
                      expression: "'merchantAuctionAlbumUpdate'",
                    },
                  ],
                  staticClass: "btn",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveAction("ruleForm")
                    },
                  },
                },
                [_vm._v("确认提交")]
              )
            : _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantAuctionAlbumCreate",
                      expression: "'merchantAuctionAlbumCreate'",
                    },
                  ],
                  staticClass: "btn",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveAction("ruleForm")
                    },
                  },
                },
                [_vm._v("确认提交")]
              ),
          _vm._v(" "),
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.backAction } },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择空间模板",
            visible: _vm.showSetSpaceTemplate,
            "close-on-click-modal": false,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSetSpaceTemplate = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "list-box mt20" },
            _vm._l(_vm.tableData.data, function (item, index) {
              return _c(
                "div",
                {
                  key: "list" + index,
                  staticClass: "list",
                  class: { active: _vm.etid == item.exhibition_template_id },
                  on: {
                    click: function ($event) {
                      return _vm.selectExhibition(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "image-box" }, [
                    _c("img", {
                      attrs: { src: item.exhibition_cover + "!m640", alt: "" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "list-name" }, [
                    _c("p", { staticClass: "line1" }, [
                      _vm._v(_vm._s(item.exhibition_title)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "select-box" }, [
                    _c("div", [
                      _vm.etid == item.exhibition_template_id
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/check.png"),
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: require("@/assets/images/nocheck.png"),
                              alt: "",
                            },
                          }),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.tableData.total > _vm.tableFrom.limit
            ? _c(
                "div",
                { staticClass: "block flex mt20" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.tableFrom.limit,
                      "current-page": _vm.tableFrom.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showSetSpaceTemplate = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.changeTmpAction },
                },
                [_vm._v("确认选择")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }