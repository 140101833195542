var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("header-breadcrumb", { attrs: { name: "空投活动" } }),
      _vm._v(" "),
      _c("div", { staticClass: "divBox" }, [
        _c(
          "div",
          {},
          [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c(
                  "el-tabs",
                  {
                    on: {
                      "tab-click": function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    model: {
                      value: _vm.tableFrom.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableFrom, "type", $$v)
                      },
                      expression: "tableFrom.type",
                    },
                  },
                  [
                    _c("el-tab-pane", { attrs: { name: "", label: "全部" } }),
                    _vm._v(" "),
                    _c("el-tab-pane", {
                      attrs: { name: "1", label: "数字藏品" },
                    }),
                    _vm._v(" "),
                    _c("el-tab-pane", { attrs: { name: "2", label: "盲盒" } }),
                    _vm._v(" "),
                    _c("el-tab-pane", { attrs: { name: "3", label: "积分" } }),
                    _vm._v(" "),
                    _c("el-tab-pane", {
                      attrs: { name: "4", label: "优惠券" },
                    }),
                    _vm._v(" "),
                    _c("el-tab-pane", { attrs: { name: "5", label: "门票" } }),
                    _vm._v(" "),
                    _c("el-tab-pane", {
                      attrs: { name: "6", label: "会员卡" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.onAdd },
                  },
                  [_vm._v("创建空投")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticStyle: { width: "100%", "margin-top": "20px" },
                attrs: {
                  border: "",
                  data: _vm.tableData.data,
                  size: "small",
                  "row-key": "task_id",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "task_id", label: "ID", "min-width": "60" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "类别", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.type == 1
                            ? _c("div", [_vm._v("数字藏品")])
                            : scope.row.type == 2
                            ? _c("div", [_vm._v("盲盒")])
                            : scope.row.type == 3
                            ? _c("div", [_vm._v("积分")])
                            : scope.row.type == 4
                            ? _c("div", [_vm._v("优惠券")])
                            : scope.row.type == 5
                            ? _c("div", [_vm._v("门票")])
                            : scope.row.type == 6
                            ? _c("div", [_vm._v("会员卡")])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "类型", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.airdrop_type == 1
                            ? _c("div", [_vm._v("名单空投")])
                            : scope.row.airdrop_type == 2
                            ? _c("div", [_vm._v("精准空投")])
                            : scope.row.airdrop_type == 3
                            ? _c("div", [_vm._v("自定义空投")])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "投放名单", "min-width": "100" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.snapshotData
                            ? _vm._l(
                                scope.row.snapshotData,
                                function (item, index) {
                                  return _c("div", { key: index }, [
                                    _vm._v(
                                      "\n                 " +
                                        _vm._s(item.title) +
                                        "\n              "
                                    ),
                                  ])
                                }
                              )
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "商品名单", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.product
                            ? _c("div", [
                                _vm._v(
                                  "\n                 " +
                                    _vm._s(scope.row.product.store_name) +
                                    "\n              "
                                ),
                              ])
                            : scope.row.memberInfo
                            ? _c("div", [
                                _vm._v(
                                  "\n                 " +
                                    _vm._s(scope.row.memberInfo.name) +
                                    "\n              "
                                ),
                              ])
                            : scope.row.productItem
                            ? _c("div", [
                                _vm._v(
                                  "\n                 " +
                                    _vm._s(scope.row.productItem.store_name) +
                                    "\n              "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "task_time",
                    label: "投放时间",
                    "min-width": "100",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.parseTime(scope.row.task_time)) +
                                "\n            "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "task_status_text",
                    label: "投放状态",
                    "min-width": "100",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "人数", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showuser(scope.row.task_id)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(scope.row.userCount) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      label: "备注",
                      "min-width": "120",
                      prop: "remark",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "操作人员", "min-width": "120" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      scope.row.createAdmin
                                        ? scope.row.createAdmin.real_name
                                        : ""
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", "min-width": "100", fixed: "right" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.task_status == 0
                            ? [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onEdit(scope.row.task_id)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: { "icon-class": "commonedit" },
                                    }),
                                    _vm._v("编辑"),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelete(
                                          scope.row.task_id,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "paginaBox flex" },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    "page-sizes": [20, 40, 60, 80],
                    "page-size": _vm.tableFrom.limit,
                    "current-page": _vm.tableFrom.page,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.tableData.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.pageChange,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("file-list", { ref: "exportList" }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看空投用户",
            visible: _vm.userflag,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userflag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { inline: "", size: "small" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "手机号：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请输入手机号", size: "small" },
                      model: {
                        value: _vm.newtableFrom.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.newtableFrom, "phone", $$v)
                        },
                        expression: "newtableFrom.phone",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getuserList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否领取：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请选择", size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.getuserList(1)
                        },
                      },
                      model: {
                        value: _vm.newtableFrom.is_receive,
                        callback: function ($$v) {
                          _vm.$set(_vm.newtableFrom, "is_receive", $$v)
                        },
                        expression: "newtableFrom.is_receive",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "全部", value: "" } }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "已领取", value: "1" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "未领取", value: "0" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "user-list" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.newlistLoading,
                      expression: "newlistLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.newtableData.data,
                    size: "small",
                    "row-key": "box_id",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "ID", "min-width": "60" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "phone",
                      label: "手机号",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "airdrop_number",
                      label: "空投数量",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "是否领取", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.is_receive
                              ? _c("div", [
                                  _vm._v(
                                    "\n              已领取\n            "
                                  ),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    "\n              未领取\n            "
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.newtableFrom.limit,
                  "current-page": _vm.newtableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.newtableData.total,
                },
                on: {
                  "size-change": _vm.newhandleSizeChange,
                  "current-change": _vm.newpageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }