var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", { attrs: { name: "邀请函" } }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "pt10" },
                [
                  _c(
                    "el-form",
                    { attrs: { size: "small", "label-width": "80px" } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.createdAction },
                            },
                            [_vm._v("创建邀请函")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%", color: "#000" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                key: "1",
                attrs: { label: "ID", width: "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(_vm._s(scope.row.id)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "3",
                attrs: { label: "邀请函图片", width: "100", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "item-flex-center" },
                          [
                            _c("el-image", {
                              staticStyle: {
                                width: "54px",
                                height: "96px",
                                display: "block",
                              },
                              attrs: {
                                src: scope.row.image + "!webp640",
                                fit: "cover",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "3",
                attrs: {
                  label: "邀请函名称",
                  "min-width": "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(_vm._s(scope.row.name)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "4",
                attrs: { label: "有效期", "min-width": "170", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "开始：" +
                              _vm._s(_vm.parseTime(scope.row.start_time))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "结束：" + _vm._s(_vm.parseTime(scope.row.end_time))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "5",
                attrs: {
                  label: "活动地点",
                  "min-width": "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(_vm._s(scope.row.address)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "10",
                attrs: {
                  label: "已发放/总量",
                  "min-width": "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showPeopleAction(scope.row, 3)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.receive_count) +
                                "/" +
                                _vm._s(scope.row.issued_number)
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "100", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "left",
                              width: "100",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyXcxLink(
                                            scope.row,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  小程序链接\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyH5Link(
                                            scope.row,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  H5链接\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImgAction(1, scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  小程序码\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImgAction(2, scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  二维码\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImgAction(3, scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  小程序海报\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImgAction(4, scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  二维码海报\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v("\n              推广链接\n            ")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editAction(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.delAction(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.drawerData.show,
            direction: "rtl",
            size: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawerData, "show", $event)
            },
            close: _vm.getList,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "record-t",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("查看邀请")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "search" },
            [
              _c(
                "el-radio-group",
                {
                  on: {
                    change: function ($event) {
                      return _vm.loadUserList(1)
                    },
                  },
                  model: {
                    value: _vm.drawerData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.drawerData, "status", $$v)
                    },
                    expression: "drawerData.status",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "0" } }, [
                    _vm._v("待核销"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("已核销"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "search" },
            [
              _c(
                "el-input",
                {
                  staticClass: "w300",
                  attrs: { placeholder: "客户昵称" },
                  on: {
                    change: function ($event) {
                      return _vm.loadUserList(1)
                    },
                  },
                  model: {
                    value: _vm.drawerData.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.drawerData, "name", $$v)
                    },
                    expression: "drawerData.name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "w300 ml10",
                  attrs: { placeholder: "客户电话" },
                  on: {
                    change: function ($event) {
                      return _vm.loadUserList(1)
                    },
                  },
                  model: {
                    value: _vm.drawerData.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.drawerData, "phone", $$v)
                    },
                    expression: "drawerData.phone",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "primary" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("导出已生成列表")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "search" },
            [
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: { type: "primary" },
                  on: { click: _vm.addUserAction },
                },
                [_vm._v("新增邀请用户")]
              ),
              _vm._v(" "),
              _c(
                "el-upload",
                {
                  ref: "uploadImg",
                  staticClass: "upload-demo mr10 mb15",
                  staticStyle: { display: "inline-block" },
                  attrs: {
                    limit: 1,
                    action: _vm.fileUrl,
                    "on-success": _vm.handleSuccess,
                    headers: _vm.myHeaders,
                    data: {
                      import_type: "import_invite_letter",
                      invite_letter_id: _vm.drawerData.id,
                    },
                    "show-file-list": false,
                    name: "excel",
                  },
                },
                [
                  _c("el-button", { attrs: { type: "primary" } }, [
                    _vm._v("导入"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.downLoad } },
                [_vm._v("下载导入模版")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "record-content" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.drawerData.listLoading,
                      expression: "drawerData.listLoading",
                    },
                  ],
                  staticClass: "order-table",
                  staticStyle: { width: "100%", color: "#000" },
                  attrs: {
                    data: _vm.drawerData.data,
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    key: "1",
                    attrs: { label: "邀请函信息", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(scope.row.invite_letter_id) +
                                  " | " +
                                  _vm._s(scope.row.inviteLetterItem.name)
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    key: "2",
                    attrs: { label: "用户", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.userItem
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "color-lan",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetails(
                                          scope.row.userItem.uid
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.userItem.real_name ||
                                          scope.row.userItem.nickname
                                      )
                                    ),
                                    scope.row.userItem.real_name
                                      ? _c("span", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                scope.row.userItem.nickname
                                              ) +
                                              ")"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    key: "3",
                    attrs: { label: "姓名", "min-width": "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("div", [_vm._v(_vm._s(scope.row.name))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    key: "4",
                    attrs: { label: "手机号", "min-width": "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("div", [_vm._v(_vm._s(scope.row.phone))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    key: "5",
                    attrs: { label: "核销状态", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.status_text))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    key: "6",
                    attrs: { label: "操作", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            !scope.row.status
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.switchStatusAction(
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("核销")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delUserAction(scope.row.id)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block flex mt20" },
                [
                  _c("div", { staticClass: "flex-one" }),
                  _vm._v(" "),
                  _c("el-pagination", {
                    attrs: {
                      "page-size": _vm.drawerData.limit,
                      "current-page": _vm.drawerData.page,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.drawerData.total,
                    },
                    on: { "current-change": _vm.pageChangeDrawer },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { editMark: _vm.getList, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.addUserData.show,
            "close-on-click-modal": false,
            center: "",
            title: "新增邀请用户",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.addUserData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex item-flex-center mt10" },
            [
              _c("div", [_vm._v("姓名：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w200",
                attrs: { placeholder: "请输入邀请用户姓名" },
                model: {
                  value: _vm.addUserData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.addUserData, "name", $$v)
                  },
                  expression: "addUserData.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex item-flex-center mt20" },
            [
              _c("div", [_vm._v("手机：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w200",
                attrs: { placeholder: "请输入邀请用户手机" },
                model: {
                  value: _vm.addUserData.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.addUserData, "phone", $$v)
                  },
                  expression: "addUserData.phone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex item-flex-center" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addUserData.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveUserAction },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImg,
            "before-close": _vm.beforeCloseImg,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex align-items-c justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.imgUrl },
            }),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }