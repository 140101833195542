var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c" }, [
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "140px" },
          },
          [_vm._v("拍前")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "100px" },
          },
          [_vm._v("内部")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("上拍审核")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("拍品审核清单")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("纸质")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("第一语言｜第二语言")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "r" },
          [
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("设置"),
            ]),
            _vm._v(" "),
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("预览"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c" }, [
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "140px" },
          },
          [_vm._v("拍中")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "100px" },
          },
          [_vm._v("买家")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("现场中拍时签字")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("确认成交书")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("纸质")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("第一语言｜第二语言")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "r" },
          [
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("设置"),
            ]),
            _vm._v(" "),
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("预览"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c" }, [
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "140px" },
          },
          [_vm._v("拍前")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "100px" },
          },
          [_vm._v("买家")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("现场办号牌时签字")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("竞买协议")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("纸质/批量打印")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("第一语言｜第二语言")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "r" },
          [
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("设置"),
            ]),
            _vm._v(" "),
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("预览"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c" }, [
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "140px" },
          },
          [_vm._v("拍前")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "100px" },
          },
          [_vm._v("买家")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("办理电话委托时签字")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("电话委托竞投授权书")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("纸质/PDF")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("第一语言｜第二语言")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "r" },
          [
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("设置"),
            ]),
            _vm._v(" "),
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("预览"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "item flex align-items-c",
          on: {
            click: function ($event) {
              return _vm.setRuleAction(
                "print_auction_transaction_order_bill_info",
                "拍品成交明细设置"
              )
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "l",
              staticStyle: { "text-align": "center", width: "140px" },
            },
            [_vm._v("拍后")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "l",
              staticStyle: { "text-align": "center", width: "100px" },
            },
            [_vm._v("买家")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "l flex-one",
              staticStyle: { "text-align": "center" },
            },
            [_vm._v("竞买人成交明细")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "l flex-one",
              staticStyle: { "text-align": "center" },
            },
            [_vm._v("拍品成交明细")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "l flex-one",
              staticStyle: { "text-align": "center" },
            },
            [_vm._v("纸质/PDF")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "l flex-one",
              staticStyle: { "text-align": "center" },
            },
            [_vm._v("第一语言｜第二语言")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "r" },
            [
              _c("el-button", { attrs: { type: "text", size: "small" } }, [
                _vm._v("设置"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "small" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.previewRuleAction(
                        "print_auction_transaction_order_bill_info"
                      )
                    },
                  },
                },
                [_vm._v("预览")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "item flex align-items-c",
          on: {
            click: function ($event) {
              return _vm.setRuleAction(
                "print_auction_order_bill_info",
                "拍品结算清单设置"
              )
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "l",
              staticStyle: { "text-align": "center", width: "140px" },
            },
            [_vm._v("拍后")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "l",
              staticStyle: { "text-align": "center", width: "100px" },
            },
            [_vm._v("买家")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "l flex-one",
              staticStyle: { "text-align": "center" },
            },
            [_vm._v("现场结算时，签字提货使用")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "l flex-one",
              staticStyle: { "text-align": "center" },
            },
            [_vm._v("拍品结算清单")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "l flex-one",
              staticStyle: { "text-align": "center" },
            },
            [_vm._v("纸质/PDF")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "l flex-one",
              staticStyle: { "text-align": "center" },
            },
            [_vm._v("第一语言｜第二语言")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "r" },
            [
              _c("el-button", { attrs: { type: "text", size: "small" } }, [
                _vm._v("设置"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "small" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.previewRuleAction(
                        "print_auction_order_bill_info"
                      )
                    },
                  },
                },
                [_vm._v("预览")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c" }, [
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "140px" },
          },
          [_vm._v("拍前")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "100px" },
          },
          [_vm._v("委托方")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("专场上传完成后，导出PDF")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("上拍通知书")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("纸质/PDF/批量导出")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("第一语言｜第二语言")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "r" },
          [
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("设置"),
            ]),
            _vm._v(" "),
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("预览"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c" }, [
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "140px" },
          },
          [_vm._v("拍后")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "100px" },
          },
          [_vm._v("委托方")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("专场结拍后，导出PDF")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("拍后告知书")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("纸质/PDF/批量导出")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("第一语言｜第二语言")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "r" },
          [
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("设置"),
            ]),
            _vm._v(" "),
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("预览"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c" }, [
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "140px" },
          },
          [_vm._v("拍后")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "100px" },
          },
          [_vm._v("委托方")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("财务对账后，导出PDF")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("委托方合同对账单")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("纸质/PDF/批量导出")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("第一语言｜第二语言")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "r" },
          [
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("设置"),
            ]),
            _vm._v(" "),
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("预览"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c" }, [
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "140px" },
          },
          [_vm._v("拍前")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "100px" },
          },
          [_vm._v("送货人")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("入库时，送货人签字")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("拍品入库清单")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("纸质/PDF")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("第一语言｜第二语言")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "r" },
          [
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("设置"),
            ]),
            _vm._v(" "),
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("预览"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c" }, [
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "140px" },
          },
          [_vm._v("拍后")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "100px" },
          },
          [_vm._v("提货人")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("出库时，提货人签字")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("拍品出库清单")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("纸质/PDF")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("第一语言｜第二语言")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "r" },
          [
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("设置"),
            ]),
            _vm._v(" "),
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("预览"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c" }, [
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "140px" },
          },
          [_vm._v("拍前")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l",
            staticStyle: { "text-align": "center", width: "100px" },
          },
          [_vm._v("内部")]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "l flex-one",
          staticStyle: { "text-align": "center" },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("拍品调拨清单")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("纸质/PDF")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "l flex-one",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("第一语言｜第二语言")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "r" },
          [
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("设置"),
            ]),
            _vm._v(" "),
            _c("el-button", { attrs: { type: "text", size: "small" } }, [
              _vm._v("预览"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "预览",
            visible: _vm.showPreview,
            "close-on-click-modal": false,
            top: "3vh",
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPreview = $event
            },
          },
        },
        [
          _vm.key == "print_auction_order_bill_info"
            ? [_c("PrintAuctionOrderBillInfo", { attrs: { preview: "1" } })]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item flex align-items-c" }, [
      _c(
        "div",
        {
          staticClass: "l",
          staticStyle: { "text-align": "center", width: "140px" },
        },
        [_vm._v("拍卖阶段")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "l",
          staticStyle: { "text-align": "center", width: "100px" },
        },
        [_vm._v("客户")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "l flex-one", staticStyle: { "text-align": "center" } },
        [_vm._v("使用场景")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "l flex-one", staticStyle: { "text-align": "center" } },
        [_vm._v("单据名称")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "l flex-one", staticStyle: { "text-align": "center" } },
        [_vm._v("文档类型")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "l flex-one", staticStyle: { "text-align": "center" } },
        [_vm._v("自定义名称")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "r" }, [_vm._v("操作")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }