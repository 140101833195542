var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap" },
            [
              _c(
                "el-input",
                {
                  staticClass: "input-with-select w200 mr20",
                  attrs: { placeholder: "输入用户名" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.user_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "user_name", $$v)
                    },
                    expression: "tableFrom.user_name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "input-with-select mr20 w200",
                  attrs: { placeholder: "输入手机号" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "phone", $$v)
                    },
                    expression: "tableFrom.phone",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "input-with-select mr20 w200",
                  attrs: { placeholder: "输入门票名称" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.ticket_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "ticket_name", $$v)
                    },
                    expression: "tableFrom.ticket_name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("导出已生成列表")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20",
          staticStyle: { width: "100%", color: "#000" },
          attrs: {
            border: "",
            data: _vm.tableData.data,
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            key: "1",
            attrs: { label: "提交用户", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.user[0].real_name ||
                                  scope.row.user[0].nickname
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "预约门票", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.ticket[0].product.store_name,
                          placement: "top",
                        },
                      },
                      [
                        _c("div", { staticClass: "line1" }, [
                          _vm._v(
                            _vm._s(scope.row.ticket[0].product.store_name)
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "联系人", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "line1" }, [
                      _vm._v(_vm._s(scope.row.contact)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "联系人电话",
              "min-width": "80",
              prop: "contact_phone",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "联系人邮箱", "min-width": "80", prop: "email" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "联系人城市", "min-width": "80", prop: "city" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "联系人职业",
              "min-width": "80",
              prop: "profession",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "参观人数", "min-width": "80", prop: "person_num" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "参观时间", "min-width": "120", prop: "date" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "备注", "min-width": "120", prop: "remark" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "提交时间", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(_vm._s(_vm.parseTime(scope.row.add_time))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.delAction(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { editMark: _vm.getList, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }