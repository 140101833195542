"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGenerateDemandApi = getGenerateDemandApi;
exports.getLiveListApi = getLiveListApi;
exports.getTemplateListApi = getTemplateListApi;
exports.getmarketMediaListApi = getmarketMediaListApi;
exports.joinRoom = joinRoom;
exports.marketMediaCreateApi = marketMediaCreateApi;
exports.switchGenerateResultStatusApi = switchGenerateResultStatusApi;
exports.switchLikeApi = switchLikeApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 获取订单生成需求
 */
function getGenerateDemandApi(data) {
  return _request.default.get("marketMedia/order/getGenerateDemand", data);
}

/**
 * @description 创建订单
 */
function marketMediaCreateApi(data) {
  return _request.default.post("marketMedia/order/create", data);
}

/**
 * @description 获取订单列表
 */
function getmarketMediaListApi(data) {
  return _request.default.get("marketMedia/order/list", data);
}

/**
 * @description 直播列表
 */
function getLiveListApi(data) {
  return _request.default.get("live/room/searchList", data);
}

/**
 * @description 海报模版列表
 */
function getTemplateListApi(data) {
  return _request.default.get("marketMedia/posterDesign/getTemplateList", data);
}

// 加入直播间
function joinRoom(data) {
  return _request.default.post("system/socket/joinRoom", data);
}

// 订单数据修改生成结果
function switchGenerateResultStatusApi(data) {
  return _request.default.post("marketMedia/orderData/switchGenerateResultStatus", data);
}

// 订单数据点赞
function switchLikeApi(data) {
  return _request.default.post("marketMedia/orderData/switchLike", data);
}