var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { "min-height": "100vh" } },
    [
      _c("header-breadcrumb", {
        attrs: { name: "批量创建委托退回出库单", "has-back": "" },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _vm.reductjson.erp_v2_out_warehouse == 1
            ? _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审批人" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selwidth",
                          attrs: {
                            placeholder: "请选择审批人",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.reviewAduitId,
                            callback: function ($$v) {
                              _vm.reviewAduitId = $$v
                            },
                            expression: "reviewAduitId",
                          },
                        },
                        _vm._l(_vm.reductjson.adminData, function (item) {
                          return _c("el-option", {
                            key: item.merchant_admin_id,
                            attrs: {
                              label: item.real_name,
                              value: item.merchant_admin_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { data: _vm.list, border: "" } },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "委托方", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.entrustUser
                          ? _c(
                              "div",
                              { staticClass: "nowrap" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row.entrustUser.real_name) +
                                    "\n            "
                                ),
                                scope.row.entrustUser.phone
                                  ? [
                                      _vm._v(
                                        "\n              (" +
                                          _vm._s(scope.row.entrustUser.phone) +
                                          ")\n            "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "合同号", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.contract
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.contract.contract_no) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "库房", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.warehouse
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.warehouse.name) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "货架", "min-width": "102" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.erpProduct,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "ep" + index,
                              staticClass: "nowrap list-cf",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.shelf ? item.shelf.name : "") +
                                  " \n          "
                              ),
                            ]
                          )
                        }
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图录号", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.pmAuction,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "ept" + index,
                              staticClass: "nowrap list-cf",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.lot) +
                                  "\n          "
                              ),
                            ]
                          )
                        }
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "拍品名称", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.pmAuction,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "epp" + index,
                              staticClass: "nowrap list-cf",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.product.store_name) +
                                  "\n          "
                              ),
                            ]
                          )
                        }
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "物流方式", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.logisticsInfo.logisticsTypeText) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "收件地址", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.logisticsInfo.logistics_address) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex align-items-c goodslist-page-box" }, [
            _c(
              "div",
              { staticClass: "flex-one flex" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.createPushOrder },
                  },
                  [_vm._v("确认创建出库单")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }