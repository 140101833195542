var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.showSetTitle,
        top: "5vh",
        "append-to-body": "",
        "close-on-click-modal": "",
        width: "400px",
        title: "设置文字",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showSetTitle = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "item-flex-center" },
        [
          _c("div", { staticClass: "w80" }, [_vm._v("中文简体")]),
          _vm._v(" "),
          _c("el-input", {
            attrs: { type: "text", placeholder: "设置文字" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
              },
            },
            model: {
              value: _vm.setTitleData.zh_cn,
              callback: function ($$v) {
                _vm.$set(_vm.setTitleData, "zh_cn", $$v)
              },
              expression: "setTitleData.zh_cn",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item-flex-center mt20" },
        [
          _c("div", { staticClass: "w80" }, [_vm._v("中文繁体")]),
          _vm._v(" "),
          _c("el-input", {
            attrs: { type: "text", placeholder: "设置文字" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
              },
            },
            model: {
              value: _vm.setTitleData.zh_tw,
              callback: function ($$v) {
                _vm.$set(_vm.setTitleData, "zh_tw", $$v)
              },
              expression: "setTitleData.zh_tw",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item-flex-center mt20" },
        [
          _c("div", { staticClass: "w80" }, [_vm._v("英文")]),
          _vm._v(" "),
          _c("el-input", {
            attrs: { type: "text", placeholder: "设置文字" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
              },
            },
            model: {
              value: _vm.setTitleData.en,
              callback: function ($$v) {
                _vm.$set(_vm.setTitleData, "en", $$v)
              },
              expression: "setTitleData.en",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item-flex-center mt20" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showSetTitle = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "ml20",
              attrs: { type: "primary" },
              on: { click: _vm.saveTitleText },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }