"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.regexp.replace");
var _setting = require("@/api/setting");
var _user = require("@/api/user.js");
var _map = _interopRequireDefault(require("@/components/map/map.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Information',
  components: {
    Maps: _map.default
  },
  data: function data() {
    var checkPhone = function checkPhone(rule, value, callback) {
      // if (value === "") {
      //   callback(new Error("请输入客服电话"));
      // } else {
      //   let regPone = null;
      //   let mobile = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
      //   let tel = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/; //座机
      //   if (value.charAt(0) == 0) {
      //     // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
      //     regPone = tel;
      //   } else {
      //     regPone = mobile;
      //   }
      //   if (!regPone.test(value)) {
      //     return callback(new Error("请填写客服电话(座机格式'区号-座机号码')"));
      //   }
      //   callback();
      // }
    };
    return {
      merModel: false,
      merData: {},
      // 默认数据
      mapKey: '',
      // 地图key
      address: '',
      submitLoading: false,
      // 提交loading
      rules: {
        mer_banner: [{
          required: false,
          message: '请上传店铺banner'
        }],
        mer_logo: [{
          required: true,
          message: '请上传LOGO'
        }],
        mer_avatar: [{
          required: true,
          message: '请上传店铺头像'
        }],
        mer_info: [{
          required: true,
          message: '请输入商户简介',
          trigger: 'blur'
        }, {
          min: 3,
          max: 200,
          message: '长度在 3 到 200 个字符',
          trigger: 'blur'
        }],
        mer_keyword: [{
          required: false,
          message: '请输入商户关键字',
          trigger: 'blur'
        }],
        service_phone: [{
          required: false,
          validator: checkPhone,
          trigger: 'blur'
        }],
        mer_address: [{
          required: false,
          message: '请输入商户地址',
          trigger: 'blur'
        }],
        company_name: [{
          required: true,
          message: '请输入公司名称',
          trigger: 'blur'
        }]
      },
      defineQrcodeData: [{
        "key": "app",
        "name": "APP",
        "imgUrl": ""
      }, {
        "key": "xcx",
        "name": "小程序",
        "imgUrl": ""
      }, {
        "key": "wxkf",
        "name": "微信客服",
        "imgUrl": ""
      }, {
        "key": "dy",
        "name": "抖音二维码",
        "imgUrl": ""
      }],
      defineMediaData: [{
        "key": "gzh",
        "name": "公众号",
        "imgUrl": "",
        account: ''
      }, {
        "key": "sph",
        "name": "视频号",
        "imgUrl": "",
        account: ''
      }, {
        "key": "xhs",
        "name": "小红书",
        "imgUrl": "",
        account: ''
      }, {
        "key": "dy",
        "name": "抖音",
        "imgUrl": "",
        account: ''
      }, {
        "key": "wb",
        "name": "微博",
        "imgUrl": "",
        account: ''
      }, {
        "key": "ks",
        "name": "快手",
        "imgUrl": "",
        account: ''
      }, {
        "key": "tt",
        "name": "今日头条",
        "imgUrl": "",
        account: ''
      }, {
        "key": "bli",
        "name": "blibli",
        "imgUrl": "",
        account: ''
      }],
      showVisible: false,
      temData: [{
        name: '原色',
        imgList: [{
          key: 'gzh',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656226870901689.png!120a'
        }, {
          key: 'sph',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656237692364751.png!120a'
        }, {
          key: 'xhs',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656220417894985.png!120a'
        }, {
          key: 'dy',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656222157990113.png!120a'
        }, {
          key: 'wb',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656246733197168.png!120a'
        }, {
          key: 'ks',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656237316209521.png!120a'
        }, {
          key: 'tt',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656242025412278.png!120a'
        }, {
          key: 'bli',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656251337841467.png!120a'
        }]
      }, {
        name: '黑色',
        imgList: [{
          key: 'gzh',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656227175295532.png!120a'
        }, {
          key: 'sph',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656238135304879.png!120a'
        }, {
          key: 'xhs',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656217513915325.png!120a'
        }, {
          key: 'dy',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656222460731106.png!120a'
        }, {
          key: 'wb',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656247146510978.png!120a'
        }, {
          key: 'ks',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/172665623383372580.png!120a'
        }, {
          key: 'tt',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/172665624232258022.png!120a'
        }, {
          key: 'bli',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656251752321885.png!120a'
        }]
      }, {
        name: '反白黑色',
        imgList: [{
          key: 'gzh',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656231276202339.png!120a'
        }, {
          key: 'sph',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656240077576072.png!120a'
        }, {
          key: 'xhs',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/172665621969051741.png!120a'
        }, {
          key: 'dy',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656224716744940.png!120a'
        }, {
          key: 'wb',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656249130946084.png!120a'
        }, {
          key: 'ks',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656235777162776.png!120a'
        }, {
          key: 'tt',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656244474946222.png!120a'
        }, {
          key: 'bli',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656253903828309.png!120a'
        }]
      }, {
        name: '灰色',
        imgList: [{
          key: 'gzh',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656227481119292.png!120a'
        }, {
          key: 'sph',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656238424452029.png!120a'
        }, {
          key: 'xhs',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/172665621780537335.png!120a'
        }, {
          key: 'dy',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656222876385683.png!120a'
        }, {
          key: 'wb',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656247484259304.png!120a'
        }, {
          key: 'ks',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656234139554773.png!120a'
        }, {
          key: 'tt',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656242653759193.png!120a'
        }, {
          key: 'bli',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/172665625216245963.png!120a'
        }]
      }, {
        name: '反白灰色',
        imgList: [{
          key: 'gzh',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656231682629783.png!120a'
        }, {
          key: 'sph',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656240375842544.png!120a'
        }, {
          key: 'xhs',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656220058216288.png!120a'
        }, {
          key: 'dy',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/172665622502445458.png!120a'
        }, {
          key: 'wb',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656249425114792.png!120a'
        }, {
          key: 'ks',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656236107314596.png!120a'
        }, {
          key: 'tt',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656244804717622.png!120a'
        }, {
          key: 'bli',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656254190700121.png!120a'
        }]
      }, {
        name: '红色',
        imgList: [{
          key: 'gzh',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656227827879851.png!120a'
        }, {
          key: 'sph',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656238700619111.png!120a'
        }, {
          key: 'xhs',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656218336881048.png!120a'
        }, {
          key: 'dy',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656223286168270.png!120a'
        }, {
          key: 'wb',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656247861484254.png!120a'
        }, {
          key: 'ks',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656234493896018.png!120a'
        }, {
          key: 'tt',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656243048549164.png!120a'
        }, {
          key: 'bli',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656252487666588.png!120a'
        }]
      }, {
        name: '反白红色',
        imgList: [{
          key: 'gzh',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656231964580950.png!120a'
        }, {
          key: 'sph',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/172665624069994213.png!120a'
        }, {
          key: 'xhs',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656220417894985.png!120a'
        }, {
          key: 'dy',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656225434484922.png!120a'
        }, {
          key: 'wb',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656249807436349.png!120a'
        }, {
          key: 'ks',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656236408417721.png!120a'
        }, {
          key: 'tt',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656245198173307.png!120a'
        }, {
          key: 'bli',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656254510204201.png!120a'
        }]
      }, {
        name: '蓝色',
        imgList: [{
          key: 'gzh',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656228199450830.png!120a'
        }, {
          key: 'sph',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656238999440256.png!120a'
        }, {
          key: 'xhs',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656218779611736.png!120a'
        }, {
          key: 'dy',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656223616433356.png!120a'
        }, {
          key: 'wb',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656248161613146.png!120a'
        }, {
          key: 'ks',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656234855348069.png!120a'
        }, {
          key: 'tt',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656243364151935.png!120a'
        }, {
          key: 'bli',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656252878878953.png!120a'
        }]
      }, {
        name: '反白蓝色',
        imgList: [{
          key: 'gzh',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656232251362060.png!120a'
        }, {
          key: 'sph',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656241005713420.png!120a'
        }, {
          key: 'xhs',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656220724947759.png!120a'
        }, {
          key: 'dy',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/172665622572430081.png!120a'
        }, {
          key: 'wb',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656250216556148.png!120a'
        }, {
          key: 'ks',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656236709661128.png!120a'
        }, {
          key: 'tt',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656245468450465.png!120a'
        }, {
          key: 'bli',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656254928326464.png!120a'
        }]
      }, {
        name: '绿色',
        imgList: [{
          key: 'gzh',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656228516837217.png!120a'
        }, {
          key: 'sph',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656239278491675.png!120a'
        }, {
          key: 'xhs',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656219070567472.png!120a'
        }, {
          key: 'dy',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656224001903430.png!120a'
        }, {
          key: 'wb',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656248469133663.png!120a'
        }, {
          key: 'ks',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656235165877610.png!120a'
        }, {
          key: 'tt',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656243765564983.png!120a'
        }, {
          key: 'bli',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656253187214597.png!120a'
        }]
      }, {
        name: '反白绿色',
        imgList: [{
          key: 'gzh',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/172665623259942394.png!120a'
        }, {
          key: 'sph',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656241308352178.png!120a'
        }, {
          key: 'xhs',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656221468646876.png!120a'
        }, {
          key: 'dy',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656226153667550.png!120a'
        }, {
          key: 'wb',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656250626708913.png!120a'
        }, {
          key: 'ks',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656237004819204.png!120a'
        }, {
          key: 'tt',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656245919479242.png!120a'
        }, {
          key: 'bli',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656255332619246.png!120a'
        }]
      }, {
        name: '橙色',
        imgList: [{
          key: 'gzh',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656228918812992.png!120a'
        }, {
          key: 'sph',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656239627652732.png!120a'
        }, {
          key: 'xhs',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/172665621939115824.png!120a'
        }, {
          key: 'dy',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/172665622429083960.png!120a'
        }, {
          key: 'wb',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656248790109062.png!120a'
        }, {
          key: 'ks',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656235439658162.png!120a'
        }, {
          key: 'tt',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656244137891712.png!120a'
        }, {
          key: 'bli',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656253529723701.png!120a'
        }]
      }, {
        name: '反白橙色',
        imgList: [{
          key: 'gzh',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656233011763183.png!120a'
        }, {
          key: 'sph',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656241630693555.png!120a'
        }, {
          key: 'xhs',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656221828335270.png!120a'
        }, {
          key: 'dy',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656226449164671.png!120a'
        }, {
          key: 'wb',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656251036291262.png!120a'
        }, {
          key: 'ks',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656237316209521.png!120a'
        }, {
          key: 'tt',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656246325400585.png!120a'
        }, {
          key: 'bli',
          url: 'https://saas.cdn.yunzongbu.cn/merchant/333425/2024918/image/1726656255736454962.png!120a'
        }]
      }]
    };
  },
  created: function created() {
    this.getMapInfo();
    this.getInfo();
  },
  computed: {
    pression: function pression() {
      var gopath = this.$route.path.replace('/merchant', '');
      return this.$store.getters.userPression[gopath] || {};
    }
  },
  methods: {
    onAdd: function onAdd() {
      var lastIndex = this.merData.mer_collection_email_data.length;
      var row = this.merData.mer_collection_email_data[lastIndex - 1];
      // if (!row.email||!row.name) return 
      this.$set(this.merData.mer_collection_email_data, lastIndex, {
        email: '',
        name: ''
      });
    },
    onDele: function onDele(index) {
      this.merData.mer_collection_email_data.splice(index, 1);
    },
    // 地图信息获取
    getCoordinates: function getCoordinates(data) {
      // this.merData.mer_address = data.address;
      this.merData.lat = data.location.lat || 34.34127;
      this.merData.long = data.location.lng || 108.93984;
      console.log(data);
    },
    // 获取历史信息
    getInfo: function getInfo() {
      var _this2 = this;
      this.merModel = false;
      (0, _user.getBaseInfo)().then(function (res) {
        res.data.bank_address = res.data.config.bank_address;
        res.data.mer_print_logo = res.data.config.mer_print_logo;
        res.data.bank = res.data.config.bank;
        res.data.bank_name = res.data.config.bank_name;
        res.data.bank_number = res.data.config.bank_number;
        res.data.company_english_name = res.data.config.company_english_name;
        res.data.mer_brand_name = res.data.config.mer_brand_name;
        res.data.mer_brand_english_name = res.data.config.mer_brand_english_name;
        res.data.mer_fax = res.data.config.mer_fax;
        res.data.mer_post_code = res.data.config.mer_post_code;
        res.data.mer_pc_website = res.data.config.mer_pc_website;
        res.data.mer_collection_category = res.data.config.mer_collection_category;
        res.data.contact_phone = res.data.config.contact_phone;
        res.data.customer_service_url = res.data.config.customer_service_url;
        res.data.wechat_app_id = res.data.config.wechat_app_id;
        res.data.company_address = res.data.config.company_address;
        res.data.company_english_address = res.data.config.company_english_address;
        res.data.h5_domain_icon = res.data.config.h5_domain_icon;
        res.data.mer_collection_email_data = res.data.config.mer_collection_email_data.length == 0 ? [{
          email: '',
          name: ''
        }] : res.data.config.mer_collection_email_data;
        res.data.mer_logo_data = res.data.config.mer_logo_data.length == 0 ? [] : res.data.config.mer_logo_data;
        res.data.mer_qrcode_data = res.data.config.mer_qrcode_data.length == 0 ? (0, _toConsumableArray2.default)(_this2.defineQrcodeData) : res.data.config.mer_qrcode_data;
        if (res.data.config.mer_media_make_logo_data.length == 0) {
          _this2.defineMediaData.forEach(function (item) {
            _this2.temData[0].imgList.forEach(function (ele) {
              if (ele.key == item.key) item.imgUrl = ele.url;
            });
          });
          res.data.mer_media_make_logo_data = _this2.defineMediaData;
        } else res.data.mer_media_make_logo_data = res.data.config.mer_media_make_logo_data;
        _this2.merData = res.data;
        _this2.merModel = true;
      });
    },
    onAssig: function onAssig(index) {
      var _this3 = this;
      this.merData.mer_media_make_logo_data.forEach(function (item, i) {
        _this3.temData[index].imgList.forEach(function (ele) {
          if (ele.key == item.key) {
            // item.imgUrl = ele.url;
            _this3.$set(_this3.merData.mer_media_make_logo_data[i], 'imgUrl', ele.url);
          }
        });
      });
      this.$forceUpdate();
      this.showVisible = false;
      console.log(this.merData.mer_media_make_logo_data);
    },
    // 提交/修改
    submitForm: function submitForm(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var dataKey = Object.keys(_this4.rules);
          var reqData = {};
          [].concat(dataKey, ['mer_print_logo', 'mer_state', 'long', 'lat', 'mini_banner', 'bank_address', 'bank_name', 'bank', 'bank_number', 'company_address', 'company_english_name', 'mer_brand_name', 'mer_brand_english_name', 'mer_fax', 'mer_post_code', 'mer_pc_website', 'mer_collection_email_data', 'contact_phone', 'customer_service_url', 'wechat_app_id', 'fee_rate', 'h5_domain_icon', 'mer_name', 'mer_collection_category', 'mer_logo_data', 'mer_qrcode_data', 'mer_media_make_logo_data', 'company_english_address']).map(function (item) {
            reqData[item] = _this4.merData[item];
          });
          _this4.submitLoading = true;
          (0, _user.merchantUpdate)(reqData).then(function (res) {
            // console.log(res);
            _this4.submitLoading = false;
            _this4.$message.success('提交成功');
            _this4.$store.commit("user/SET_ENTER_INFO", {});
          }).catch(function (rej) {
            _this4.submitLoading = false;
            _this4.$message.error(rej.data.message);
          });
        } else {
          _this4.$message('请完善信息后再进行提交');
          _this4.submitLoading = false;
          return false;
        }
      });
    },
    // 查找位置
    onSearch: function onSearch() {
      // console.log(this.$refs)
      this.$refs.mapChild.searchKeyword(this.merData.mer_address);
    },
    // 获取 map key
    getMapInfo: function getMapInfo() {
      var _this5 = this;
      var that = this;
      (0, _setting.storeGetInfoApi)().then(function (res) {
        console.log(res);
        _this5.mapKey = res.data.tx_map_key;
      }).catch(function (res) {
        that.$message.error(res.message);
      });
    },
    modalPicImg: function modalPicImg(filed, res) {
      var _this6 = this;
      this.$modalUpload(function (img) {
        var index = _this6.merData[filed].findIndex(function (item) {
          return item.key == res;
        });
        _this6.$set(_this6.merData[filed][index], 'imgUrl', img[0]);
      }, 1);
    },
    modalHand: function modalHand(type) {
      var _this7 = this;
      this.$modalUpload(function (img) {
        var len = _this7.merData[type].length;
        _this7.$set(_this7.merData[type], len, {
          "key": Date.now(),
          "name": "自定义名称",
          "imgUrl": img[0]
        });
        console.log(_this7.merData[type]);
      }, 1);
    },
    // 上传图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this8 = this;
      console.log(tit, num, i);
      var _this = this;
      var attr = [];
      this.$modalUpload(function (img) {
        console.log(img);
        if (tit === '1' && !num) {
          _this.merData.mer_logo = img[0];
        }
        if (tit === '2' && !num) {
          _this.merData.mer_avatar = img[0];
        }
        if (tit === '3' && !num) {
          _this.merData.mini_banner = img[0];
        }
        if (tit === '4' && !num) {
          _this.merData.h5_domain_icon = img[0];
        }
        if (tit === '5' && !num) {
          _this.merData.mer_print_logo = img[0];
        }
        _this8.$forceUpdate();
        console.log(_this8.merData);
      }, tit);
    }
  }
};