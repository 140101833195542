var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "invoice" },
    [
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 175px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "invoiceRef",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                height: "100%",
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.order_receipt_id
                },
                data: _vm.tableData.data,
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "30",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "订单号",
                  "min-width": "190",
                  prop: "",
                  "search-key": "keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.storeOrder
                          ? _c("div", [
                              _vm._v(_vm._s(scope.row.storeOrder.order_sn)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "用户昵称",
                  "min-width": "180",
                  "search-key": "username",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.user
                          ? _c(
                              "div",
                              {
                                staticClass: "color-lan",
                                on: {
                                  click: function ($event) {
                                    return _vm.onDetails(scope.row.user.uid)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.user.real_name ||
                                      scope.row.user.nickname
                                  )
                                ),
                                scope.row.user.real_name
                                  ? _c("span", [
                                      _vm._v(
                                        "(" +
                                          _vm._s(scope.row.user.nickname) +
                                          ")"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "订单金额",
                  "min-width": "90",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.order_price))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "落槌价",
                  "min-width": "90",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.storeOrder &&
                        scope.row.storeOrder.orderProduct
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.storeOrder.orderProduct[0]
                                    .product_price
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "佣金",
                  "min-width": "90",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.storeOrder &&
                        scope.row.storeOrder.orderProduct
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.storeOrder.orderProduct[0]
                                    .commission_price
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "所属专场",
                  "min-width": "200",
                  prop: "",
                  "show-overflow-tooltip": "",
                  "search-key": "album_id",
                  "search-type": "radio",
                  selectFilter: "",
                  options: _vm.albumlistOption,
                  "search-query": _vm.searchQuery,
                },
                on: {
                  changeVal: _vm.searchAction,
                  selectFilterChange: _vm.onSelectFilterChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.storeOrder && scope.row.storeOrder.album
                          ? _c("div", [
                              _vm._v(
                                _vm._s(scope.row.storeOrder.album.album_name)
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "订单信息",
                  "min-width": "300",
                  prop: "",
                  "search-key": "order_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.storeOrder &&
                        scope.row.storeOrder.orderProduct &&
                        scope.row.storeOrder.orderProduct.length > 0
                          ? _vm._l(
                              scope.row.storeOrder.orderProduct,
                              function (m, i) {
                                return _c("div", { key: i }, [
                                  m.cart_info.product.lot
                                    ? _c("span", { staticClass: "mr10" }, [
                                        _vm._v(
                                          "LOT " +
                                            _vm._s(m.cart_info.product.lot)
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  m.cart_info.product.store_name
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(m.cart_info.product.store_name)
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              }
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "订单状态",
                  "min-width": "100",
                  prop: "",
                  "show-overflow-tooltip": "",
                  "search-key": "order_type",
                  "search-type": "radio",
                  options: _vm.orderTypeList,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.storeOrder
                          ? _c("span", [
                              _vm._v(_vm._s(scope.row.storeOrder.status_text)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "发票金额",
                  "min-width": "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.order_price))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  prop: "",
                  label: "申请时间",
                  "min-width": "180",
                  "search-key": "date",
                  "search-type": "date",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.create_time))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "开票状态",
                  "min-width": "120",
                  prop: "",
                  "search-key": "status",
                  "search-type": "radio",
                  options: [
                    { label: "未开票", value: "0" },
                    { label: "已出票", value: "1" },
                    { label: "已寄出", value: "2" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.status == 0 ? "未开" : "已开")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "发票类型",
                  "min-width": "120",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.receipt_type == 1
                                ? "普通发票"
                                : "普通电子发票"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "发票抬头",
                  "min-width": "90",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.receipt_header_type == "1"
                                ? "个人"
                                : "企业"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "开票信息",
                  "min-width": "300",
                  prop: "",
                  "search-key": "receipt_company",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.receipt_header_type == 2
                          ? _c("div", [
                              scope.row.receipt_company
                                ? _c("div", [
                                    _vm._v(
                                      "企业名称：" +
                                        _vm._s(scope.row.receipt_company)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.duty_paragraph
                                ? _c("div", [
                                    _vm._v(
                                      "税号：" +
                                        _vm._s(scope.row.duty_paragraph)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.company_register_address
                                ? _c("div", [
                                    _vm._v(
                                      "注册地址：" +
                                        _vm._s(
                                          scope.row.company_register_address
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.company_register_phone
                                ? _c("div", [
                                    _vm._v(
                                      "注册电话：" +
                                        _vm._s(scope.row.company_register_phone)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.company_bank_name
                                ? _c("div", [
                                    _vm._v(
                                      "开户行：" +
                                        _vm._s(scope.row.company_bank_name)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.company_bank_no
                                ? _c("div", [
                                    _vm._v(
                                      "银行账号：" +
                                        _vm._s(scope.row.company_bank_no)
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.receipt_header_type == 1
                          ? _c("div", [
                              scope.row.receipt_company
                                ? _c("div", [
                                    _vm._v(
                                      "开票名称：" +
                                        _vm._s(scope.row.receipt_company)
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "客户联系人",
                  "min-width": "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.storeOrder
                          ? _c("span", [
                              _vm._v(_vm._s(scope.row.storeOrder.real_name)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "发票联系信息",
                  "min-width": "120",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.delivery_info
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.delivery_info.email
                                    ? scope.row.delivery_info.email
                                    : scope.row.delivery_info.user_address &&
                                        scope.row.delivery_info.user_address +
                                          scope.row.delivery_info.user_phone &&
                                        scope.row.delivery_info.user_phone
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "委托书照片",
                  "min-width": "120",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.entrust_agreement_image
                          ? _c(
                              "span",
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "40px",
                                    height: "40px",
                                  },
                                  attrs: {
                                    src:
                                      scope.row.entrust_agreement_image +
                                      "!120a",
                                    "preview-src-list": [
                                      scope.row.entrust_agreement_image,
                                    ],
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "发票备注",
                  "min-width": "120",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.storeOrder
                          ? _c("span", [
                              _vm._v(_vm._s(scope.row.storeOrder.mark)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "操作",
                  width: "80",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.invoiceReceiptForm(
                                      scope.row.order_receipt_id
                                    )
                                  },
                                },
                              },
                              [_vm._v("开票")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getInvoiceInfo(
                                      scope.row.order_receipt_id,
                                      "edit"
                                    )
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-b orderFoot" },
        [
          _c(
            "div",
            { staticClass: "flex-one" },
            [
              _c(
                "el-dropdown",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantSystemExcelExport:merchant_user",
                      expression: "'merchantSystemExcelExport:merchant_user'",
                    },
                  ],
                  attrs: { type: "primary", placement: "top" },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary", size: "small" },
                    },
                    [_vm._v("导出")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.exportOrder },
                            },
                            [_vm._v("导出表格")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.getExportFileList },
                            },
                            [_vm._v("导出记录")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "开票详情",
                visible: _vm.dialogVisible,
                width: "900px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "box-container" },
                    [
                      _c("div", { staticClass: "acea-row" }, [
                        _c("div", { staticClass: "list sp" }, [
                          _c(
                            "label",
                            {
                              staticClass: "name",
                              staticStyle: { color: "#333" },
                            },
                            [_vm._v("发票详情")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "list sp" }, [
                          _c(
                            "label",
                            {
                              staticClass: "name",
                              staticStyle: { color: "#333" },
                            },
                            [_vm._v("发票申请单号：")]
                          ),
                          _vm._v(_vm._s(_vm.invoiceInfo.order_receipt_id)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "title" }, [_vm._v("发票信息")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "acea-row" }, [
                        _c("div", { staticClass: "list sp" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("发票抬头："),
                          ]),
                          _vm._v(_vm._s(_vm.invoiceInfo.receipt_company)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "list sp" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("发票类型："),
                          ]),
                          _vm._v(
                            _vm._s(
                              _vm.invoiceInfo.receipt_type == 1
                                ? "普通发票"
                                : "普通电子发票"
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "list sp" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("发票抬头类型："),
                          ]),
                          _vm._v(
                            _vm._s(
                              _vm.invoiceInfo.receipt_header_type == "1"
                                ? "个人"
                                : "企业"
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "list sp" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("发票金额："),
                          ]),
                          _vm._v(_vm._s(_vm.invoiceInfo.order_price)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.invoiceInfo.delivery_info
                        ? _c("div", { staticClass: "title" }, [
                            _vm._v("联系信息："),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.invoiceInfo.delivery_info
                        ? _c("div", { staticClass: "acea-row" }, [
                            _c("div", { staticClass: "list sp" }, [
                              _c("label", { staticClass: "name" }, [
                                _vm._v("联系人姓名："),
                              ]),
                              _vm._v(
                                _vm._s(_vm.invoiceInfo.delivery_info.user_name)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "list sp" }, [
                              _c("label", { staticClass: "name" }, [
                                _vm._v("联系人电话："),
                              ]),
                              _vm._v(
                                _vm._s(_vm.invoiceInfo.delivery_info.user_phone)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "list sp" }, [
                              _c("label", { staticClass: "name" }, [
                                _vm._v("联系人地址："),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm.invoiceInfo.delivery_info.user_address
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "acea-row" }, [
                        _c("div", { staticClass: "list sp100" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("开票状态："),
                          ]),
                          _vm._v(
                            _vm._s(
                              _vm.invoiceInfo.status == 1 ? "已开" : "未开"
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "list sp100" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("发票图片："),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "list flex flex-wrap",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _vm._l(
                              _vm.invoiceInfo.receipt_image,
                              function (m, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticStyle: { position: "relative" },
                                  },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        height: "100px",
                                        "border-radius": "4px",
                                        margin: "5px 10px 5px 0",
                                        display: "block",
                                      },
                                      attrs: {
                                        src: m,
                                        "preview-src-list":
                                          _vm.invoiceInfo.receipt_image,
                                        "initial-index": i,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "del",
                                        on: {
                                          click: function ($event) {
                                            return _vm.delImgAction(i)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-close",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "upload-image flex align-items-c justify-c mr10",
                                on: { click: _vm.changeImg },
                              },
                              [_c("i", { staticClass: "el-icon-picture" })]
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "list sp100" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("发票号码："),
                          ]),
                          _c(
                            "span",
                            { staticClass: "info" },
                            [
                              _c("el-input", {
                                nativeOn: {
                                  keyup: function ($event) {
                                    _vm.invoiceData.number =
                                      _vm.invoiceData.number.replace(
                                        /[^\w]/g,
                                        ""
                                      )
                                  },
                                },
                                model: {
                                  value: _vm.invoiceData.number,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoiceData, "number", $$v)
                                  },
                                  expression: "invoiceData.number",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "list sp100" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("发票备注："),
                          ]),
                          _c(
                            "span",
                            { staticClass: "info" },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", rows: 5 },
                                model: {
                                  value: _vm.invoiceData.mark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.invoiceData, "mark", $$v)
                                  },
                                  expression: "invoiceData.mark",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100%", "margin-top": "15px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.handleInvoic },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { editMark: _vm.getList, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }