"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _index = require("@/utils/index");
var _userdetail = _interopRequireDefault(require("../views/user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      tableFrom: {
        limit: 10,
        page: 1
      },
      total: 0,
      list: [],
      showNftSale: false,
      showNftSaleLog: false,
      recordList: [],
      recordTotal: 0,
      recordTableFrom: {
        limit: 10,
        page: 1
      },
      uid: '',
      showdetail: false,
      type: 1
    };
  },
  methods: {
    loadInit: function loadInit(id, t) {
      this.list = [];
      this.total = 0;
      this.type = t;
      this.tableFrom = {
        type: t,
        series_id: id,
        page: 1,
        limit: 10
      };
      if (t === 3) {
        this.tableFrom = {
          product_id: id,
          page: 1,
          limit: 10
        };
      }
      this.showNftSale = true;
      this.load();
    },
    load: function load() {
      var _this = this;
      var data = JSON.parse(JSON.stringify(this.tableFrom));
      if (this.type === 3) {
        (0, _marketing.consignBoxListApi)(data).then(function (res) {
          _this.list = res.data.list;
          _this.total = res.data.count;
        }).catch(function () {});
        return false;
      }
      (0, _marketing.consignNftListApi)(data).then(function (res) {
        _this.list = res.data.data;
        _this.total = res.data.total;
      }).catch(function () {});
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.load('');
    },
    recordPageChange: function recordPageChange(page) {
      this.recordTableFrom.page = page;
      this.getRecordList('');
    },
    showRecordAction: function showRecordAction(item) {
      this.recordList = [];
      this.recordTotal = 0;
      this.recordTableFrom.page = 1;
      if (this.type === 3) {
        this.recordTableFrom.user_box_id = item.user_box_id;
      } else {
        this.recordTableFrom.nft_data_id = item.nft_data_id;
      }
      this.showNftSaleLog = true;
      this.getRecordList();
    },
    getRecordList: function getRecordList() {
      var _this2 = this;
      var data = JSON.parse(JSON.stringify(this.recordTableFrom));
      if (this.type === 3) {
        (0, _marketing.marketBoxListApi)(data).then(function (res) {
          _this2.recordList = res.data.data;
          _this2.recordTotal = res.data.count;
        }).catch(function () {});
        return false;
      }
      (0, _marketing.consignNftRecordListApi)(data).then(function (res) {
        _this2.recordList = res.data.data;
        _this2.recordTotal = res.data.total;
      }).catch(function () {});
    },
    // 详情
    onDetails: function onDetails(row) {
      this.uid = row.operator_id;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    disableAction: function disableAction(m) {
      var _this3 = this;
      this.$confirm("\u786E\u8BA4\u4E0B\u67B6\u8BE5\u5BC4\u552E?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var data = {
          id: m.id
        };
        var action = _marketing.disableNftApi;
        if (_this3.type === 3) {
          action = _marketing.cancelBoxConsignApi;
          data = {
            type: 2,
            consign_box_id: m.consign_box_id
          };
        }
        action(data).then(function () {
          _this3.$message.success('操作成功');
          _this3.load();
          _this3.$emit('remoreOk');
        }).catch(function (message) {});
      }).catch(function () {});
    }
  }
};