var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header-breadcrumb", {
        attrs: {
          name: _vm.$route.query.vname + "-号牌办牌",
          "has-back": true,
          "is-fixed": true,
        },
      }),
      _vm._v(" "),
      _c("el-card", [
        _c(
          "div",
          { staticStyle: { position: "relative", "padding-bottom": "60px" } },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm ",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "110px",
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "fWeight500",
                    attrs: { label: "用户ID：", prop: "name" },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w300",
                            style: { borderColor: _vm.error ? "#ae0000" : "" },
                            attrs: {
                              placeholder: "搜索客户昵称/手机号/真实姓名",
                              filterable: "",
                              remote: "",
                              "remote-method": _vm.searchUserAction,
                              loading: _vm.searchLoading,
                            },
                            on: { change: _vm.changeUserAction },
                            model: {
                              value: _vm.getUid,
                              callback: function ($$v) {
                                _vm.getUid = $$v
                              },
                              expression: "getUid",
                            },
                          },
                          [
                            _vm._l(_vm.userList, function (m, i) {
                              return _c(
                                "el-option",
                                {
                                  key: "user" + i,
                                  attrs: { value: m.uid, label: m.nickname },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "avatar flex align-items-c",
                                    },
                                    [
                                      _c("img", {
                                        attrs: { src: m.avatar, alt: "" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "user-r flex-one" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "line1" },
                                            [
                                              m.real_name
                                                ? [
                                                    _vm._v(
                                                      _vm._s(m.real_name) +
                                                        " | "
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm._v(_vm._s(m.nickname)),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c("div", [_vm._v(_vm._s(m.phone))]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c("template", { slot: "empty" }, [
                              _c("div", { staticClass: "select-empty" }, [
                                _vm._v("没有此用户"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "select-button" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: { click: _vm.createUser },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    确认创建\n                  "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "ml20",
                            attrs: { type: "text", size: "small" },
                            on: { click: _vm.showUserDetail },
                          },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "commonlook" },
                            }),
                            _vm._v("查看客户详情"),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.error
                      ? _c(
                          "div",
                          {
                            staticClass: "ft12",
                            staticStyle: { color: "#ae0000" },
                          },
                          [_vm._v(_vm._s(_vm.error))]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "姓名：", prop: "name" } },
                  [
                    _c("el-input", {
                      staticClass: "w200",
                      attrs: {
                        placeholder: "请输入真实姓名",
                        disabled:
                          _vm.idcard_type == "中国大陆" || !_vm.idcard_type,
                      },
                      model: {
                        value: _vm.ruleForm.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name", $$v)
                        },
                        expression: "ruleForm.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "手机号：", prop: "phone" } },
                  [
                    _c("el-input", {
                      staticClass: "w200",
                      attrs: {
                        placeholder: "请输入手机号",
                        disabled: !!_vm.ruleForm.uid,
                      },
                      model: {
                        value: _vm.ruleForm.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "phone", $$v)
                        },
                        expression: "ruleForm.phone",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "is-required", attrs: { label: "性别：" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: {
                          disabled:
                            _vm.idcard_type == "中国大陆" || !_vm.idcard_type,
                        },
                        model: {
                          value: _vm.ruleForm.sex,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "sex", $$v)
                          },
                          expression: "ruleForm.sex",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("\n              男\n            "),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("\n              女\n            "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "民族：", prop: "nation" } },
                  [
                    _c("el-input", {
                      staticClass: "w200",
                      attrs: {
                        placeholder: "请输入民族",
                        disabled:
                          _vm.idcard_type == "中国大陆" || !_vm.idcard_type,
                      },
                      model: {
                        value: _vm.ruleForm.nation,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "nation", $$v)
                        },
                        expression: "ruleForm.nation",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "身份证号：", prop: "cardno" } },
                  [
                    _c("el-input", {
                      staticClass: "w200",
                      attrs: {
                        placeholder: "请输入身份证号",
                        disabled:
                          _vm.idcard_type == "中国大陆" || !_vm.idcard_type,
                      },
                      model: {
                        value: _vm.ruleForm.cardno,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "cardno", $$v)
                        },
                        expression: "ruleForm.cardno",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "is-required", attrs: { label: "生日：" } },
                  [
                    _c("el-input", {
                      staticClass: "w50 tAlignC",
                      attrs: {
                        "controls-position": "right",
                        min: 2010,
                        max: 2100,
                        controls: false,
                        disabled:
                          _vm.idcard_type == "中国大陆" || !_vm.idcard_type,
                      },
                      model: {
                        value: _vm.birthdayDate.year,
                        callback: function ($$v) {
                          _vm.$set(_vm.birthdayDate, "year", $$v)
                        },
                        expression: "birthdayDate.year",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "plr10" }, [_vm._v("年")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "w30 tAlignC",
                      attrs: {
                        min: 1,
                        max: 12,
                        controls: false,
                        disabled:
                          _vm.idcard_type == "中国大陆" || !_vm.idcard_type,
                      },
                      model: {
                        value: _vm.birthdayDate.month,
                        callback: function ($$v) {
                          _vm.$set(_vm.birthdayDate, "month", $$v)
                        },
                        expression: "birthdayDate.month",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "plr10" }, [_vm._v("月")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "w30 tAlignC",
                      attrs: {
                        min: 1,
                        max: 31,
                        controls: false,
                        disabled:
                          _vm.idcard_type == "中国大陆" || !_vm.idcard_type,
                      },
                      model: {
                        value: _vm.birthdayDate.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.birthdayDate, "date", $$v)
                        },
                        expression: "birthdayDate.date",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "plr10" }, [_vm._v("日")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "地址：", prop: "address" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        placeholder: "请输入地址",
                        disabled:
                          _vm.idcard_type == "中国大陆" || !_vm.idcard_type,
                      },
                      model: {
                        value: _vm.ruleForm.address,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "address", $$v)
                        },
                        expression: "ruleForm.address",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "is-required",
                    attrs: { label: "有效期限：" },
                  },
                  [
                    _c("el-input", {
                      staticClass: "w50 tAlignC",
                      attrs: {
                        "controls-position": "right",
                        min: 2010,
                        max: 2100,
                        controls: false,
                        disabled:
                          _vm.idcard_type == "中国大陆" || !_vm.idcard_type,
                      },
                      model: {
                        value: _vm.stimeData.year,
                        callback: function ($$v) {
                          _vm.$set(_vm.stimeData, "year", $$v)
                        },
                        expression: "stimeData.year",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "plr10" }, [_vm._v("年")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "w30 tAlignC",
                      attrs: {
                        min: 1,
                        max: 12,
                        controls: false,
                        disabled:
                          _vm.idcard_type == "中国大陆" || !_vm.idcard_type,
                      },
                      model: {
                        value: _vm.stimeData.month,
                        callback: function ($$v) {
                          _vm.$set(_vm.stimeData, "month", $$v)
                        },
                        expression: "stimeData.month",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "plr10" }, [_vm._v("月")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "w30 tAlignC",
                      attrs: {
                        min: 1,
                        max: 31,
                        controls: false,
                        disabled:
                          _vm.idcard_type == "中国大陆" || !_vm.idcard_type,
                      },
                      model: {
                        value: _vm.stimeData.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.stimeData, "date", $$v)
                        },
                        expression: "stimeData.date",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "plr10" }, [_vm._v("日")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "ft14 fWeight500 plr20" }, [
                      _vm._v("至"),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "w50 tAlignC",
                      attrs: {
                        "controls-position": "right",
                        min: 2010,
                        max: 2100,
                        controls: false,
                        disabled:
                          _vm.idcard_type == "中国大陆" || !_vm.idcard_type,
                      },
                      model: {
                        value: _vm.etimeData.year,
                        callback: function ($$v) {
                          _vm.$set(_vm.etimeData, "year", $$v)
                        },
                        expression: "etimeData.year",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "plr10" }, [_vm._v("年")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "w30 tAlignC",
                      attrs: {
                        min: 1,
                        max: 12,
                        controls: false,
                        disabled:
                          _vm.idcard_type == "中国大陆" || !_vm.idcard_type,
                      },
                      model: {
                        value: _vm.etimeData.month,
                        callback: function ($$v) {
                          _vm.$set(_vm.etimeData, "month", $$v)
                        },
                        expression: "etimeData.month",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "plr10" }, [_vm._v("月")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "w30 tAlignC",
                      attrs: {
                        min: 1,
                        max: 31,
                        controls: false,
                        disabled:
                          _vm.idcard_type == "中国大陆" || !_vm.idcard_type,
                      },
                      model: {
                        value: _vm.etimeData.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.etimeData, "date", $$v)
                        },
                        expression: "etimeData.date",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "plr10" }, [_vm._v("日")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "收货人：" } },
                      [
                        _c("el-input", {
                          staticClass: "w200",
                          attrs: { placeholder: "请输入收货人" },
                          model: {
                            value: _vm.ruleForm.user_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "user_name", $$v)
                            },
                            expression: "ruleForm.user_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "手机号：" } },
                      [
                        _c("el-input", {
                          staticClass: "w200",
                          attrs: { placeholder: "请输入收货人手机号" },
                          model: {
                            value: _vm.ruleForm.user_phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "user_phone", $$v)
                            },
                            expression: "ruleForm.user_phone",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "收货地址：" } },
                  [
                    _c("el-cascader", {
                      staticStyle: { width: "300px" },
                      attrs: {
                        placeholder: "请选择省市区",
                        options: _vm.areaList,
                        props: {
                          expandTrigger: "hover",
                          value: "name",
                          label: "name",
                        },
                      },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.ruleForm.area,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "area", $$v)
                        },
                        expression: "ruleForm.area",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "ml10",
                      staticStyle: { width: "400px" },
                      attrs: { placeholder: "请输入收货地址" },
                      model: {
                        value: _vm.ruleForm.user_address,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "user_address", $$v)
                        },
                        expression: "ruleForm.user_address",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "备注:", prop: "" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "500px" },
                      attrs: {
                        type: "textarea",
                        rows: 5,
                        placeholder: "请输入备注内容",
                      },
                      model: {
                        value: _vm.ruleForm.remarks,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "remarks", $$v)
                        },
                        expression: "ruleForm.remarks",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex",
                    staticStyle: { "align-items": "flex-start" },
                  },
                  [
                    _c("div", { staticClass: "has-border my-balance" }, [
                      _c("div", [_vm._v("账户余额")]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.feeTypeInfo.type) +
                            " " +
                            _vm._s(
                              _vm.formatPriceNumber(
                                (_vm.balance || 0) +
                                  (_vm.ruleForm.add_wallet_price || 0)
                              )
                            ) +
                            _vm._s(_vm.feeTypeInfo.unit_name)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "account-txt" }, [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("div", { staticClass: "w100" }, [
                            _vm._v("余额充值："),
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: {
                              width: "180px",
                              "font-size": "18px",
                              "font-weight": "500",
                              "text-align": "center",
                            },
                            attrs: { controls: false, align: "center" },
                            model: {
                              value: _vm.ruleForm.add_wallet_price_format,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "add_wallet_price_format",
                                  $$v
                                )
                              },
                              expression: "ruleForm.add_wallet_price_format",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml10" }, [
                            _vm._v(_vm._s(_vm.feeTypeInfo.unit_name)),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex align-items-c mt20" },
                        [
                          _c("div", { staticClass: "w100" }, [
                            _vm._v("冻结保证金：\n                "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "font-size": "12px",
                                },
                              },
                              [
                                _vm._v("免保\n                  "),
                                _c("el-switch", {
                                  on: { change: _vm.onSwitchChange },
                                  model: {
                                    value: _vm.freeSwitch,
                                    callback: function ($$v) {
                                      _vm.freeSwitch = $$v
                                    },
                                    expression: "freeSwitch",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: {
                              width: "180px",
                              "font-size": "18px",
                            },
                            attrs: { controls: false },
                            model: {
                              value: _vm.ruleForm.frozen_price_format,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "frozen_price_format",
                                  $$v
                                )
                              },
                              expression: "ruleForm.frozen_price_format",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml10" }, [
                            _vm._v(_vm._s(_vm.feeTypeInfo.unit_name)),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "account-txt" }, [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("div", { staticClass: "w100" }, [
                            _vm._v("付款方式："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.ruleForm.pay_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "pay_type", $$v)
                                },
                                expression: "ruleForm.pay_type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  value: "bank_transfer",
                                  label: "银行转账",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: {
                                  value: "offline_wechat",
                                  label: "线下微信",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: {
                                  value: "offline_alipay",
                                  label: "线下支付宝",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: "offline_cash", label: "现金" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: "offline_pos", label: "pos机" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex mt20" }, [
                        _c(
                          "div",
                          {
                            staticClass: "w100",
                            staticStyle: { "line-height": "36px" },
                          },
                          [_vm._v("支付凭证：")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex" }, [
                          _vm.ruleForm.pay_evidence
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "upload-view item-flex-center mr10",
                                },
                                [
                                  _c("img", {
                                    staticClass: "pic",
                                    attrs: {
                                      src: _vm.ruleForm.pay_evidence + "!m640",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "upload-view item-flex-center",
                              on: { click: _vm.changeImg },
                            },
                            [
                              _c("img", {
                                staticClass: "up",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20220301/2016064f133d38a91c22de563f552c16.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex",
                    staticStyle: { "align-items": "flex-start" },
                  },
                  [
                    !_vm.freeSwitch
                      ? _c(
                          "el-select",
                          {
                            staticStyle: { width: "100px" },
                            model: {
                              value: _vm.ruleForm.number_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "number_type", $$v)
                              },
                              expression: "ruleForm.number_type",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: "1", label: "普通号牌" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "5", label: "VIP号牌" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "account-txt",
                        staticStyle: { "padding-right": "20px" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "w100",
                          staticStyle: {
                            height: "40px",
                            "font-size": "16px",
                            "font-weight": "500",
                            width: "150px",
                          },
                          attrs: { size: "medium" },
                          model: {
                            value: _vm.ruleForm.number_plate,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "number_plate", $$v)
                            },
                            expression: "ruleForm.number_plate",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          "margin-left": "0",
                          "margin-top": "4px",
                          "font-size": "14px",
                          "font-weight": "500",
                        },
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.showNumberListAction({ type: 1 })
                          },
                        },
                      },
                      [_vm._v("查看所有号牌")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "formFixedBox" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.confirmNumberAction },
                      },
                      [_vm._v("\n            确认办牌\n          ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form",
              { staticClass: "distinguish-input" },
              [
                _c(
                  "el-form-item",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { label: "证件类型：" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择证件类型" },
                        model: {
                          value: _vm.idcard_type,
                          callback: function ($$v) {
                            _vm.idcard_type = $$v
                          },
                          expression: "idcard_type",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "中国大陆", value: "中国大陆" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "香港", value: "香港" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "澳门", value: "澳门" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "台湾", value: "台湾" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "其他", value: "其他" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "distinguish-btn" },
              [
                (_vm.ruleForm.name && _vm.ruleForm.cardno) || _vm.ruleForm.uid
                  ? _c(
                      "el-button",
                      {
                        staticClass: "mr20",
                        attrs: { type: "text", size: "small" },
                        on: { click: _vm.clearAction },
                      },
                      [_vm._v("清空内容取消办理")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.getPeosonInfo },
                  },
                  [_vm._v("识别身份证")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "id-img" }, [
              _c("div", { staticClass: "flex align-items-c" }, [
                _c("div", { staticClass: "img-title" }, [
                  _c("div", [_vm._v("身份证")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("人像面")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item-flex-center" },
                  [
                    _vm.frontImg
                      ? _c("img", {
                          attrs: { src: _vm.frontImg },
                          on: {
                            click: function ($event) {
                              return _vm.showIdImgAction(_vm.frontImg)
                            },
                          },
                        })
                      : _c(
                          "el-upload",
                          {
                            staticClass: "el-upload-view",
                            attrs: {
                              action: "/",
                              "show-file-list": false,
                              "http-request": function (file) {
                                return _vm.uploadImg(file, "frontImg")
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "item-flex-center",
                                staticStyle: { cursor: "pointer" },
                              },
                              [
                                !_vm.frontImgLoading
                                  ? _c("img", {
                                      staticStyle: { width: "20px" },
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20220401/dd73557f682fc5c3eff8f284da5445fa.png",
                                      },
                                    })
                                  : _c("img", {
                                      staticStyle: { width: "20px" },
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20220401/a0a8a73f525c43c1035781e73fdd7c22.gif",
                                      },
                                    }),
                              ]
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex align-items-c mt20" }, [
                _c("div", { staticClass: "img-title" }, [
                  _c("div", [_vm._v("身份证")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("国徽面")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item-flex-center" },
                  [
                    _vm.backImg
                      ? _c("img", {
                          attrs: { src: _vm.backImg },
                          on: {
                            click: function ($event) {
                              return _vm.showIdImgAction(_vm.backImg)
                            },
                          },
                        })
                      : _c(
                          "el-upload",
                          {
                            staticClass: "el-upload-view",
                            attrs: {
                              action: "/",
                              "show-file-list": false,
                              "http-request": function (file) {
                                return _vm.uploadImg(file, "backImg")
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "item-flex-center",
                                staticStyle: { cursor: "pointer" },
                              },
                              [
                                !_vm.backImgLoading
                                  ? _c("img", {
                                      staticStyle: { width: "20px" },
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20220401/dd73557f682fc5c3eff8f284da5445fa.png",
                                      },
                                    })
                                  : _c("img", {
                                      staticStyle: { width: "20px" },
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20220401/a0a8a73f525c43c1035781e73fdd7c22.gif",
                                      },
                                    }),
                              ]
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人工录入",
            visible: _vm.showUserSearch,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUserSearch = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入客户真实姓名" },
            model: {
              value: _vm.uname,
              callback: function ($$v) {
                _vm.uname = $$v
              },
              expression: "uname",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c justify-c mt40" },
            [
              _c("el-button", [_vm._v("取消")]),
              _vm._v(" "),
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v("\n        确认\n      "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: (_vm.showAddNumber.user ? "修改" : "添加") + "买家号牌",
            visible: _vm.showAddNumber.show,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showAddNumber, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-item-center mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("客户类型：")]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.showAddNumber.number_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.showAddNumber, "number_type", $$v)
                    },
                    expression: "showAddNumber.number_type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "3" } }, [
                    _vm._v("免保号牌"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "2" } }, [
                    _vm._v("特殊号牌"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("客户名称：")]),
              _vm._v(" "),
              _vm.showAddNumber.user
                ? _c("div", [_vm._v(_vm._s(_vm.showAddNumber.user.nickname))])
                : _c(
                    "el-select",
                    {
                      staticClass: "w200",
                      attrs: {
                        placeholder: "搜索客户昵称/手机号/真实姓名",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchUserAction,
                        loading: _vm.searchLoading,
                      },
                      model: {
                        value: _vm.showAddNumber.uid,
                        callback: function ($$v) {
                          _vm.$set(_vm.showAddNumber, "uid", $$v)
                        },
                        expression: "showAddNumber.uid",
                      },
                    },
                    _vm._l(_vm.userList, function (m, i) {
                      return _c(
                        "el-option",
                        {
                          key: "user" + i,
                          attrs: { value: m.uid, label: m.nickname },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avatar flex align-items-c" },
                            [
                              _c("img", { attrs: { src: m.avatar, alt: "" } }),
                              _vm._v(" "),
                              _c("div", { staticClass: "user-r flex-one" }, [
                                _c(
                                  "div",
                                  { staticClass: "line1" },
                                  [
                                    m.real_name
                                      ? [_vm._v(_vm._s(m.real_name) + " | ")]
                                      : _vm._e(),
                                    _vm._v(_vm._s(m.nickname)),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("div", [_vm._v(_vm._s(m.phone))]),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
              _vm._v(" "),
              [
                _c("span", { staticClass: "w100 ml20" }, [
                  _vm._v("设置号牌："),
                ]),
                _vm._v(" "),
                _c("el-input-number", {
                  staticClass: "w100",
                  attrs: {
                    type: "number",
                    controls: false,
                    placeholder: "输入号码",
                  },
                  model: {
                    value: _vm.showAddNumber.number,
                    callback: function ($$v) {
                      _vm.$set(_vm.showAddNumber, "number", $$v)
                    },
                    expression: "showAddNumber.number",
                  },
                }),
              ],
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "album-filter flex align-items-c mt10" }, [
            _c("span", { staticClass: "w100 mt10" }, [_vm._v("参拍专场：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.showAddNumber.change_album_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.showAddNumber, "change_album_type", $$v)
                      },
                      expression: "showAddNumber.change_album_type",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: 0 } }, [
                      _vm._v("\n            全部\n          "),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: 1 } }, [
                      _vm._v("\n            选择专场\n          "),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.showAddNumber.change_album_type === 1
            ? _c(
                "div",
                { staticClass: "album-filter flex align-items-c mt10" },
                [
                  _c("span", { staticClass: "w100 mt10" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-one" },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.showAddNumber.number_plate_rule,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.showAddNumber,
                                "number_plate_rule",
                                $$v
                              )
                            },
                            expression: "showAddNumber.number_plate_rule",
                          },
                        },
                        _vm._l(_vm.albumList, function (m, i) {
                          return _c(
                            "el-checkbox-button",
                            { key: i, attrs: { label: m.album_id } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(m.album_name) +
                                  " " +
                                  _vm._s(m.auction_num) +
                                  "件\n          "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "album-filter flex align-items-c mt10" },
            [
              _c("span", { staticClass: "w100 mt10" }, [_vm._v("备注:")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w200",
                attrs: {
                  type: "textarea",
                  rows: 2,
                  placeholder: "请输入备注内容",
                },
                model: {
                  value: _vm.showAddNumber.remarks,
                  callback: function ($$v) {
                    _vm.$set(_vm.showAddNumber, "remarks", $$v)
                  },
                  expression: "showAddNumber.remarks",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.showAddNumber.btnLoading,
                  },
                  on: { click: _vm.saveAddNumberAction },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  on: {
                    click: function ($event) {
                      _vm.showAddNumber.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加号牌组",
            visible: _vm.showAddNumberGroup.show,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showAddNumberGroup, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("号牌组名称：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w200",
                attrs: { placeholder: "输入名称" },
                model: {
                  value: _vm.showAddNumberGroup.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.showAddNumberGroup, "name", $$v)
                  },
                  expression: "showAddNumberGroup.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.showAddNumberGroup.btnLoading,
                  },
                  on: { click: _vm.saveNumberGroupAction },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { staticClass: "ml20" }, [_vm._v("取消")]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加号牌",
            visible: _vm.showBatchAddNumber.show,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showBatchAddNumber, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("输入号段：")]),
              _vm._v(" "),
              _c("el-input-number", {
                staticClass: "w100",
                attrs: { min: 0, controls: false, placeholder: "最小号段" },
                model: {
                  value: _vm.showBatchAddNumber.min,
                  callback: function ($$v) {
                    _vm.$set(_vm.showBatchAddNumber, "min", $$v)
                  },
                  expression: "showBatchAddNumber.min",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml10 mr10" }, [_vm._v("至")]),
              _vm._v(" "),
              _c("el-input-number", {
                staticClass: "w100",
                attrs: { min: 0, controls: false, placeholder: "最大号段" },
                model: {
                  value: _vm.showBatchAddNumber.max,
                  callback: function ($$v) {
                    _vm.$set(_vm.showBatchAddNumber, "max", $$v)
                  },
                  expression: "showBatchAddNumber.max",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("排除数字：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w300",
                attrs: { placeholder: "输入数字(多个,分隔 如：4,7)" },
                model: {
                  value: _vm.showBatchAddNumber.filter_number,
                  callback: function ($$v) {
                    _vm.$set(_vm.showBatchAddNumber, "filter_number", $$v)
                  },
                  expression: "showBatchAddNumber.filter_number",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.showBatchAddNumber.btnLoading,
                  },
                  on: { click: _vm.batchAddNumberAction },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { staticClass: "ml20" }, [_vm._v("取消")]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看号牌",
            visible: _vm.numberList.show,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.numberList, "show", $event)
            },
          },
        },
        [
          _vm.numberList.query
            ? _c(
                "div",
                { staticClass: "list" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.numberList.listLoading,
                          expression: "numberList.listLoading",
                        },
                      ],
                      staticClass: "mt20 ft12",
                      attrs: {
                        data: _vm.numberList.list,
                        "element-loading-text": "Loading",
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("template", { slot: "empty" }, [
                        _c("div", { staticClass: "tableEmpty" }, [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "tableDiv" }, [
                            _vm._v("暂无数据"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-table-column", {
                        key: "1",
                        attrs: { label: "号牌", prop: "name" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row.num) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3619475943
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        key: "2",
                        attrs: { label: "号牌类型", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          scope.row.type == 1
                                            ? "普通号牌"
                                            : "特殊号牌"
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3036510842
                        ),
                      }),
                      _vm._v(" "),
                      [
                        _c("el-table-column", {
                          key: "3",
                          attrs: { label: "创建时间", align: "center" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(scope.row.create_time) +
                                          "\n              "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2005021215
                          ),
                        }),
                      ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt20 flex justify-e" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "page-size": _vm.numberList.query.limit,
                          "current-page": _vm.numberList.query.page,
                          layout: "total, prev, pager, next, sizes",
                          total: _vm.numberList.total,
                        },
                        on: {
                          "current-change": _vm.pageChangeNumber,
                          "size-change": _vm.handleSizeChangeLog,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { editMark: _vm.load, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            center: "",
            title: "查看图片",
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl, alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.visibleSucess, width: "300px" },
          on: {
            "update:visible": function ($event) {
              _vm.visibleSucess = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex align-items-c justify-c",
              staticStyle: { "font-size": "20px" },
            },
            [_vm._v("办理成功")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c justify-c mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.visibleSucess = false
                    },
                  },
                },
                [_vm._v("继续办理")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onPrint } },
                [_vm._v("打印竞买协议")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("CompetePrint", { ref: "compete" }),
      _vm._v(" "),
      _c("AddUserCom", { ref: "userREf" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }