"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _userdetail = _interopRequireDefault(require("../../../user/list/userdetail"));
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CouponUser',
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  filters: {
    failFilter: function failFilter(status) {
      var statusMap = {
        receive: '自己领取',
        send: '后台发送',
        give: '满赠',
        new: '新人',
        buy: '买赠送'
      };
      return statusMap[status];
    },
    statusFilter: function statusFilter(status) {
      var statusMap = {
        0: '未使用',
        1: '已使用',
        2: '已过期'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      Loading: false,
      roterPre: _settings.roterPre,
      tableFromIssue: {
        page: 1,
        limit: 10,
        coupon_name: '',
        status: '',
        real_name: '',
        phone: ''
      },
      issueData: {
        data: [],
        total: 0
      },
      uid: '',
      showdetail: false
    };
  },
  mounted: function mounted() {
    if (this.$route.query.name) {
      this.tableFromIssue.coupon_name = this.$route.query.name;
    }
    this.getIssueList();
  },
  methods: {
    // 列表
    getIssueList: function getIssueList() {
      var _this = this;
      this.Loading = true;
      (0, _marketing.receiveListApi)(this.tableFromIssue).then(function (res) {
        _this.issueData.data = res.data.list;
        _this.issueData.total = res.data.count;
        _this.Loading = false;
      }).catch(function (res) {
        _this.Loading = false;
        // this.$message.error(res.message)
      });
    },
    pageChangeIssue: function pageChangeIssue(page) {
      this.tableFromIssue.page = page;
      this.getIssueList();
    },
    handleSizeChangeIssue: function handleSizeChangeIssue(val) {
      this.tableFromIssue.limit = val;
      this.getIssueList();
    },
    exportOrder: function exportOrder() {
      var _this2 = this;
      var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.tableFromIssue), {}, {
        export_type: 'receive_coupon'
      });
      (0, _system.exportexcel)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this2.$createElement;
        _this2.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this2.$msgbox.close();
                _this2.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('receive_coupon');
    },
    // 详情
    onDetails: function onDetails(row) {
      this.uid = row.uid;
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    }
  }
};