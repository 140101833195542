var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _vm.menuTypeClient == "2"
        ? [
            _c("SidebarMenu", { staticClass: "sidebar-container w220" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "main-container w220",
                class: { hasTagsView: _vm.needTagsView },
              },
              [_c("app-main")],
              1
            ),
          ]
        : [
            _c("sidebar", { staticClass: "sidebar-container w110" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "main-container w110",
                class: { hasTagsView: _vm.needTagsView },
              },
              [_c("app-main")],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }