"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _print_auction_order_bill_info = _interopRequireDefault(require("./module/print_auction_order_bill_info"));
var _print_auction_transaction_order_bill_info = _interopRequireDefault(require("./module/print_auction_transaction_order_bill_info"));
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    PrintAuctionOrderBillInfo: _print_auction_order_bill_info.default,
    PrintAuctionTransactionOrderBillInfo: _print_auction_transaction_order_bill_info.default
  },
  data: function data() {
    return {
      key: this.$route.query.t || 'print_auction_order_bill_info'
    };
  },
  methods: {}
};