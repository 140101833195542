var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wallet-common" },
    [
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 170px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%", color: "#000" },
              attrs: {
                border: "",
                height: "100%",
                data: _vm.tableData.data,
                "highlight-current-row": "",
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "收款金额",
                  "min-width": "80",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.formatPriceNumber(scope.row.total_fee, 2)
                              ) +
                              "元\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "订单类型",
                  "min-width": "80",
                  "search-key": "order_type",
                  "search-type": "radio",
                  prop: "",
                  options: [
                    { label: "钱包充值", value: "wallet_recharge" },
                    { label: "商品订单", value: "order_product" },
                    { label: "门票订单", value: "order_ticket" },
                    { label: "会员卡订单", value: "membership_card_order" },
                    {
                      label: "在线鉴定提问订单",
                      value: "identification_question_order",
                    },
                    {
                      label: "在线鉴定学习订单",
                      value: "identification_question_study_order",
                    },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.order_type_text))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "客户",
                  "min-width": "100",
                  "search-key": "keywords",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "color-lan",
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(scope.row.user.uid)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  scope.row.user.real_name ||
                                    scope.row.user.nickname
                                )
                            ),
                            scope.row.user.real_name
                              ? _c("span", [
                                  _vm._v(
                                    "(" + _vm._s(scope.row.user.nickname) + ")"
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "支付时间",
                  "min-width": "150",
                  prop: "",
                  "search-key": "date",
                  "search-type": "date",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.create_time))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "支付方式",
                  "min-width": "80",
                  "search-key": "pay_type",
                  "search-type": "radio",
                  prop: "",
                  options: [
                    { label: "微信小程序", value: "wechat_mini" },
                    { label: "微信公众号支付", value: "wechat_h5" },
                    { label: "微信app支付", value: "wechat_app" },
                    { label: "微信浏览器支付", value: "wechat_way" },
                    { label: "银行转账", value: "bank_transfer" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.pay_type_text))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "支付凭证",
                  "min-width": "140",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          scope.row.status === 2
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value:
                                        "merchantFinanceConfirmBankTransfer",
                                      expression:
                                        "'merchantFinanceConfirmBankTransfer'",
                                    },
                                  ],
                                  staticClass: "color-hong",
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmReceivedAction(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                确认收款\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.status === 2
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value:
                                        "merchantFinanceCancelBankTransfer",
                                      expression:
                                        "'merchantFinanceCancelBankTransfer'",
                                    },
                                  ],
                                  staticClass: "color-hong ml10",
                                  on: {
                                    click: function ($event) {
                                      return _vm.closeReceivedAction(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                作废\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        scope.row.post_result_json.pay_evidence
                          ? _c(
                              "div",
                              {
                                staticClass: "color-lan",
                                on: {
                                  click: function ($event) {
                                    return _vm.showEvidenceAction(
                                      scope.row.post_result_json.pay_evidence
                                    )
                                  },
                                },
                              },
                              [_vm._v("\n              查看凭证\n            ")]
                            )
                          : scope.row.status === 1
                          ? _c("div", { staticClass: "line1" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    scope.row.admin
                                      ? scope.row.admin.real_name
                                      : ""
                                  ) +
                                  "确认收款 " +
                                  _vm._s(
                                    _vm.parseTime(
                                      scope.row.pay_time,
                                      "{y}-{m}-{d} {h}:{i}:{s}"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ])
                          : _c("div", [_vm._v(_vm._s(scope.row.out_trade_no))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "制单人",
                  "min-width": "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.operator_name))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "收款单编号",
                  "min-width": "70",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.order_id))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "状态",
                  "min-width": "80",
                  "search-key": "status",
                  "search-type": "radio",
                  prop: "",
                  options: [
                    { label: "待支付", value: "0" },
                    { label: "已支付", value: "1" },
                    { label: "待确认", value: "2" },
                    { label: "已退款", value: "-1" },
                    { label: "已作废", value: "-2" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 0
                          ? _c("div", [_vm._v("待支付")])
                          : scope.row.status === 1
                          ? _c("div", [_vm._v("已支付")])
                          : scope.row.status === 2
                          ? _c("div", [_vm._v("待确认")])
                          : scope.row.status === -1
                          ? _c("div", [_vm._v("已退款")])
                          : scope.row.status === -2
                          ? _c("div", [_vm._v("已作废")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "对账信息",
                  "min-width": "150",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.reconciliation
                          ? _c("div", [
                              _vm._v(
                                "\n            对账人：" +
                                  _vm._s(
                                    scope.row.reconciliation.admin.real_name
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.reconciliation
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.reconciliation.create_time) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "备注",
                  "min-width": "150",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.post_result_json
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.post_result_json.mark) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-b orderFoot" },
        [
          _c(
            "div",
            { staticClass: "flex-one" },
            [
              _c(
                "el-dropdown",
                { attrs: { type: "primary", placement: "top" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "4px" },
                      attrs: { type: "primary", size: "small" },
                    },
                    [_vm._v("导出")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.exportOrder },
                            },
                            [_vm._v("导出")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.getExportFileList },
                            },
                            [_vm._v("导出记录")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantFinanceCreatePayOrder",
                      expression: "'merchantFinanceCreatePayOrder'",
                    },
                  ],
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.createdAction },
                },
                [_vm._v("创建收款单")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            "close-on-click-modal": false,
            center: "",
            title: "查看凭证",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "60vh", "overflow-y": "auto" } },
            _vm._l(_vm.showImgUrl, function (item, index) {
              return _c("img", {
                key: index,
                staticStyle: { width: "100%" },
                attrs: { src: item + "!m640", alt: "" },
              })
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showCreated,
            "close-on-click-modal": false,
            center: "",
            title: "创建收款单",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCreated = $event
            },
          },
        },
        [
          _c("div", { staticClass: "created-view flex mt10" }, [
            _c("div", { staticClass: "item flex-one flex align-items-c" }, [
              _c("div", { staticClass: "t" }, [_vm._v("选择客户：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-one flex align-items-c" },
                [
                  _vm.createdData.user && _vm.createdData.user.uid
                    ? [
                        _c("img", {
                          staticStyle: {
                            width: "40px",
                            height: "40px",
                            dispaly: "block",
                          },
                          attrs: {
                            src:
                              _vm.createdData.user.avatar +
                              (_vm.createdData.user.avatar.indexOf(
                                "https://saas.cdn.yunzongbu.cn"
                              ) === -1
                                ? ""
                                : "!120a"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml10 mr10 flex-one" }, [
                          _c(
                            "div",
                            { staticClass: "fWeight500 color-black line1" },
                            [
                              _vm.createdData.user.real_name
                                ? [
                                    _vm._v(
                                      _vm._s(_vm.createdData.user.real_name)
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      _vm._s(_vm.createdData.user.nickname)
                                    ),
                                  ],
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "fWeight500" }, [
                            _vm._v(_vm._s(_vm.createdData.user.phone)),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "color-lan fWeight500",
                      on: { click: _vm.addUserAction },
                    },
                    [_vm._v("+新增")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "color-lan fWeight500 ml10",
                      on: { click: _vm.editUserAction },
                    },
                    [_vm._v("\n            选择\n          ")]
                  ),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "item flex-one flex align-items-c ml40" },
              [
                _c("div", { staticClass: "t" }, [_vm._v("收款金额：")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-one flex align-items-c" },
                  [
                    _c("el-input", {
                      staticClass: "baifen",
                      model: {
                        value: _vm.createdData.price,
                        callback: function ($$v) {
                          _vm.$set(_vm.createdData, "price", $$v)
                        },
                        expression: "createdData.price",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "created-view flex mt20" }, [
            _c("div", { staticClass: "item flex-one flex align-items-c" }, [
              _c("div", { staticClass: "t" }, [_vm._v("款项用途：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-one flex align-items-c" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "baifen",
                      model: {
                        value: _vm.createdData.use,
                        callback: function ($$v) {
                          _vm.$set(_vm.createdData, "use", $$v)
                        },
                        expression: "createdData.use",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: "wallet_recharge", label: "钱包充值" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { value: "order", label: "保证金，拍品款" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "item flex-one flex align-items-c ml40" },
              [
                _c("div", { staticClass: "t" }, [_vm._v("付款方式：")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-one flex align-items-c" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "baifen",
                        model: {
                          value: _vm.createdData.pay_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.createdData, "pay_type", $$v)
                          },
                          expression: "createdData.pay_type",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { value: "bank_transfer", label: "银行转账" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { value: "offline_wechat", label: "线下微信" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: {
                            value: "offline_alipay",
                            label: "线下支付宝",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { value: "offline_cash", label: "现金" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { value: "offline_pos", label: "pos机" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "created-view flex mt20" }, [
            _c("div", { staticClass: "item flex-one flex" }, [
              _c("div", { staticClass: "t" }, [_vm._v("付款凭证：")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one flex" }, [
                _vm.createdData.pay_evidence
                  ? _c(
                      "div",
                      { staticClass: "upload-view item-flex-center mr10" },
                      [
                        _c("img", {
                          staticClass: "pic",
                          attrs: {
                            src: _vm.createdData.pay_evidence + "!m640",
                            alt: "",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-view item-flex-center",
                    on: { click: _vm.changeImg },
                  },
                  [
                    _c("img", {
                      staticClass: "up",
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20220301/2016064f133d38a91c22de563f552c16.png",
                        alt: "",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item flex-one flex ml40" }, [
              _c(
                "div",
                { staticClass: "t", staticStyle: { "line-height": "32px" } },
                [_vm._v("收款备注：")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-one flex align-items-c" },
                [
                  _c("el-input", {
                    staticClass: "baifen",
                    attrs: { rows: 3, type: "textarea" },
                    model: {
                      value: _vm.createdData.mark,
                      callback: function ($$v) {
                        _vm.$set(_vm.createdData, "mark", $$v)
                      },
                      expression: "createdData.mark",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "create-bottom flex align-items-c" },
            [
              _c(
                "div",
                { staticClass: "flex-one flex fWeight500 color-black" },
                [
                  _c("div", [_vm._v("制单人：" + _vm._s(_vm.name))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml40" }, [
                    _vm._v(
                      "\n          制单时间：" +
                        _vm._s(
                          _vm.parseTime(new Date(), "{y}-{m}-{d} {h}:{i}")
                        ) +
                        "\n        "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showCreated = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.okAction } },
                [_vm._v("确认收款")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("ChangeUser", {
        ref: "changeUser",
        on: { changeUid: _vm.changeUidAction },
      }),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { editMark: _vm.getList, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }